import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RouteService} from 'src/app/services/route.service';
import {TaskService} from 'src/app/services/task.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-editroute',
  templateUrl: './editroute.component.html',
  styleUrls: ['./editroute.component.css']
})
export class EditrouteComponent implements OnInit {

  tasks: any;
  routeId: any;
  tasksGroup: any = {};
  routeTasks: any = {
    task_1: 0,
    task_2: 0,
    task_3: 0,
    task_4: 0,
    task_5: 0,
    task_6: 0,
    task_7: 0,
    task_8: 0,
    task_9: 0,
    task_10: 0
  };

  constructor(
    public tasksService: TaskService,
    private route: ActivatedRoute,
    public routeService: RouteService,
    private router: Router,
    private location: Location
  ) {
  }

  ngOnInit(): void {

    this.routeId = this.route.snapshot.paramMap.get('id');
    if (this.routeId != null) {
      this.getTasks();
      this.getRoute(this.routeId);
    }

  }

  getTasks(): void {
    this.tasksService.getAll().subscribe(
      result => {
        this.tasks = result.tasks;
      });
  }

  getRoute(id: number): void {
    this.routeService.getById(id).subscribe(
      result => {
        this.tasksGroup = result.route;
        this.routeTasks.task_1 = (this.tasksGroup.tasks[0]) ? this.tasksGroup.tasks[0].task_id : '';
        this.routeTasks.task_2 = (this.tasksGroup.tasks[1]) ? this.tasksGroup.tasks[1].task_id : '';
        this.routeTasks.task_3 = (this.tasksGroup.tasks[2]) ? this.tasksGroup.tasks[2].task_id : '';
        this.routeTasks.task_4 = (this.tasksGroup.tasks[3]) ? this.tasksGroup.tasks[3].task_id : '';
        this.routeTasks.task_5 = (this.tasksGroup.tasks[4]) ? this.tasksGroup.tasks[4].task_id : '';
        this.routeTasks.task_6 = (this.tasksGroup.tasks[5]) ? this.tasksGroup.tasks[5].task_id : '';
        this.routeTasks.task_7 = (this.tasksGroup.tasks[6]) ? this.tasksGroup.tasks[6].task_id : '';
        this.routeTasks.task_8 = (this.tasksGroup.tasks[7]) ? this.tasksGroup.tasks[7].task_id : '';
        this.routeTasks.task_9 = (this.tasksGroup.tasks[8]) ? this.tasksGroup.tasks[8].task_id : '';
        this.routeTasks.task_10 = (this.tasksGroup.tasks[9]) ? this.tasksGroup.tasks[9].task_id : '';
      });
  }


  save(): void {

    if (this.routeId != null) {

      const data = {
        route_id: this.routeId,
        route_tasks: this.routeTasks,
        name: this.tasksGroup.name
      };
      this.routeService.storeRouteTasks(data).subscribe(
        result => {
          if (result.code === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Grupo de tareas actualizado correctamente!',
              text: ''
            });
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Ha ocurrido un error al actualizar el Grupo de tareas',
              text: 'Contacta con el administrador del sitio'
            });
          }
        });

    } else {
      this.routeService.createRoute(this.tasksGroup).subscribe(
        result => {

          if (result.code === 201) {

            this.router.navigate(['/editroute', result.route_id]);

            Swal.fire({
              icon: 'success',
              title: 'Grupo de tareas creado correctamente!',
              text: ''
            });
          } else {
            Swal.fire({
              icon: 'warning',
              title: 'Ha ocurrido un error al crear el vehículo',
              text: 'Contacta con el administrador del sitio'
            });
          }
        });
    }

  }

  goBack() {
    this.location.back();
  }


}
