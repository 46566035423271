<div class="">
  <h2 mat-dialog-title class="header">{{data.title}}</h2>
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-6">
          <div>
            <mat-form-field appearance="outline">
              <mat-label>Matricula</mat-label>
              <input matInput placeholder="Placeholder" disabled [value]="vehicle.plate">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Marca:</mat-label>
              <input matInput placeholder="Placeholder" disabled [value]="vehicle.vehicle_model?.brand?.name">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Kilometros:</mat-label>
              <input matInput placeholder="Placeholder" disabled [value]="vehicle.kms">
            </mat-form-field>
          </div>
        </div>
        <div class="col-6">
          <div>

            <mat-form-field appearance="outline">
              <mat-label>Categoria:</mat-label>
              <input matInput placeholder="Placeholder" disabled
                [value]="vehicle.category ? vehicle.category.name : 'Sin categoría'">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Campa:</mat-label>
              <input matInput placeholder="Placeholder" disabled [value]="vehicle.campa.name">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="mb-2">
        <h3>
          Ubicación:
          <button *ngIf="vehicle?.id" (click)="openLocation({vehicle_id: vehicle.id}, false)" mat-icon-button
            matTooltip="Editar Ubicación" matTooltipPosition="right">
            <mat-icon style="color: #0badb3; font-size: 30px;">mode_edit</mat-icon>
          </button>
        </h3>
        <p>{{ getUbication(vehicle) || 'No ubicado' }}</p>
      </div>

      <div class="mb-2" *ngIf="vehicle.sub_state_id">
        <h3>Estado - SubEstado: </h3> {{ vehicle.sub_state?.state?.name }} - {{ vehicle.sub_state?.display_name }}
      </div>

      <div class="  mb-2" style="padding: 0px !important;text-align: end;">
        <button
          [disabled]="disabledVehicle(vehicle)"
          mat-raised-button class="btn-green ml-2" style="margin-right: 5px" (click)="createNewTask()">
          Crear nueva tarea
          <mat-icon>add</mat-icon>

        </button>
        <button
        [disabled]="disabledVehicle(vehicle)"
        mat-raised-button class="btn-green ml-2" style="margin-right: 5px" (click)="addComments(vehicle)">
        Comentarios
        <mat-icon>forum</mat-icon>

      </button>
      </div>

      <div class="mb-2">
        <h3>Listado de incidencias</h3>
        <mat-card class="example-card" style="padding: 0px !important;">
          <table mat-table [dataSource]="incidencesDataSource" class="">
            <ng-container matColumnDef="damage_type">
              <th mat-header-cell *matHeaderCellDef> Tipo de incidencia </th>
              <td mat-cell *matCellDef="let element"> {{element.damage_type?.description || '-'}} </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef> Descripción </th>
              <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>
            <ng-container matColumnDef="severity">
              <th mat-header-cell *matHeaderCellDef> Severidad </th>
              <td mat-cell *matCellDef="let element" class="text-center">
                {{element.severity_damage?.description || "-"}} </td>
            </ng-container>
            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef class="text-center"> Estado </th>
              <td mat-cell *matCellDef="let element" class="text-center">
                {{element.status_damage?.description || "-"}}
                <!-- <button *ngIf="element.resolved == 0" mat-raised-button color="success"
                  (click)="resolveIncidence(element, 1)">Resolver</button>
                <button *ngIf="element.resolved == 1" mat-raised-button color="warn"
                  (click)="resolveIncidence(element, 0)">Cancelar</button> -->
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsIncidences"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsIncidences;"></tr>
          </table>
          <div *ngIf="incidenceList.length === 0" class="no-records mat-typography">
            <div class="mb-2">No hay incidencias </div>
          </div>
          <mat-paginator *ngIf="incidenceList.length > 0" [length]="event.length || 0" [pageSize]="event.pageSize || 50"
            [pageSizeOptions]="[
                  5,
                  10,
                  15,
                  100
                ]" class="m-auto mat-elevation-z8" (page)="getIncidences($event)">
          </mat-paginator>
        </mat-card>
      </div>
    </div>
    <div class="col-6">

      <div class="example-list">
        <div class="example-box row no-margin item" *ngFor="let pendingTask of pendingTasksFinish"
          style="background: #ccc;cursor: default;border: 0.5px solid;">
          <div class="col-12 d-flex justify-content-between">
            <span>{{pendingTask.id}} {{pendingTask.task.name}}</span>
            <br>
            <span class="badge badge-secondary" *ngIf="pendingTask.state_pending_task_id === 1 && pendingTask.approved">
              {{pendingTask.state_pending_task.name}}</span>
            <span class="badge badge-warning" *ngIf="pendingTask.state_pending_task_id === 2">
              {{pendingTask.state_pending_task.name}}</span>
            <span class="badge badge-success"
              *ngIf="pendingTask.state_pending_task_id === 3">{{pendingTask.state_pending_task.name}}</span>

            <button mat-button class="mat-raised-button" (click)="onChangeSpecialWash(pendingTask)"
              *ngIf="pendingTask.state_pending_task_id !== 3 && [5,6,7].indexOf(pendingTask.task_id) !== -1"
              matTooltip="Cambiar a Lavado especial" matTooltipPosition="below"
              style="color: white; background-color: #6CD098; margin: 3px;">Cambiar a...
            </button>
          </div>

          <br>

          <div class="col d-flex" *ngIf="!!pendingTask.datetime_start">
            <div class="font-date">Fecha inicio: {{ setLocaleDate(pendingTask.datetime_start)}}</div>
            <div class="ml-2 font-date" *ngIf="!!pendingTask.datetime_finish">Fecha fin: {{
              setLocaleDate(pendingTask.datetime_finish) || '-' }} </div>
          </div>
          <div *ngIf="!!pendingTask.comment_state">
            <small>{{pendingTask.comment_state}}</small>
          </div>
        </div>
      </div>

      <div cdkDropList class="example-list list" (cdkDropListDropped)="drop($event)" [cdkDropListData]="pendingTasks">
        <input class="ng-hide" #input_file (change)="fileChangeEvent($event)" id="input-file-id" multiple type="file"
          accept="application/pdf, image/*" />


        <div class="example-boundary">
          <div class="example-box row no-margin item" *ngFor="let pendingTask of pendingTasks" cdkDrag
            [cdkDragData]="pendingTask" cdkDragBoundary=".example-boundary"
            [cdkDragDisabled]="pendingTask.state_pending_task_id === 3 || pendingTask.state_pending_task_id === 2 || automaticTask(pendingTask.task_id)">
            <div class="col-12 d-flex justify-content-between">
              <span>{{pendingTask.id}} {{pendingTask.task.name}}</span>
              <br>
              <span class="badge badge-secondary"
                *ngIf="pendingTask.state_pending_task_id === 1 && pendingTask.approved">
                {{pendingTask.state_pending_task.name}}</span>
              <span class="badge badge-warning" *ngIf="pendingTask.state_pending_task_id === 2">
                {{pendingTask.state_pending_task.name}}</span>
              <span class="badge badge-success"
                *ngIf="pendingTask.state_pending_task_id === 3">{{pendingTask.state_pending_task.name}}</span>
              <button mat-button (click)="startPendingTask(pendingTask)"
                *ngIf="isActionButton && pendingTask.state_pending_task_id === 1 && !automaticTask(pendingTask.task_id)">
                <mat-icon class="color-start" matTooltip="Iniciar tarea">play_circle_outline</mat-icon>
              </button>
              <button mat-button (click)="pausePendingTask(pendingTask)"
                *ngIf="isActionButton && pendingTask.state_pending_task_id === 2 && !automaticTask(pendingTask.task_id)">
                <mat-icon class="color-pause" matTooltip="Suspender tarea">pause_circle_outline</mat-icon>
              </button>
              <button mat-button (click)="finishPendingTask(pendingTask)"
                *ngIf="isActionButton && pendingTask.state_pending_task_id === 2 && !automaticTask(pendingTask.task_id)">
                <mat-icon class="color-stop" matTooltip="Finalizar tarea">stop</mat-icon>
              </button>
              <button mat-button class="mat-raised-button" (click)="onChangeSpecialWash(pendingTask)"
                *ngIf="pendingTask.state_pending_task_id !== 3 && [5,6,7].indexOf(pendingTask.task_id) !== -1"
                matTooltip="Cambiar a Lavado especial" matTooltipPosition="below"
                style="color: white; background-color: #6CD098; margin: 3px;">Cambiar a...
              </button>
              <span (click)="openFile(input_file, pendingTask, $event)" *ngIf="isBudget(pendingTask) && !automaticTask(pendingTask.task_id)">
                <label for="input-file-id" class="mat-raised-button mat-button button-upload "
                  matTooltip="Subir presupuesto (PDF, Imágenes)" matTooltipPosition="below">
                  <mat-icon style="margin-right: 0px; font-size: 18 !important; position: relative; top: 2px;"
                    class="material-icons-u">cloud_upload</mat-icon> Subir...
                </label>
              </span>
              <button mat-button (click)="deletePendingTask(pendingTask)"
                *ngIf="isActionButton && pendingTask.state_pending_task_id !== 3 && pendingTask.state_pending_task_id !== 2 && !automaticTask(pendingTask.task_id)">
                <mat-icon class="color-stop" matTooltip="Eliminar tarea">delete_forever</mat-icon>
              </button>
            </div>

            <br>

            <div class="col d-flex" *ngIf="!!pendingTask.datetime_start">
              <div class="font-date">Fecha inicio: {{ setLocaleDate(pendingTask.datetime_start) }}</div>
              <div class="ml-2 font-date" *ngIf="!!pendingTask.datetime_finish">Fecha fin: {{
                setLocaleDate(pendingTask.datetime_finish) }}
              </div>
              <div class="ml-5 font-pause"
                *ngIf="!!pendingTask.datetime_pause && pendingTask.state_pending_task_id !== 2">
                <small>Pausada</small>
              </div>
            </div>
            <div *ngIf="!!pendingTask.comment_state">
              <small>{{pendingTask.comment_state}}</small>
            </div>
            <div style="color: blue;" *ngIf="automaticTask(pendingTask.task_id)">
              <small>Nota: No se puede realizar acciones sobre tareas automáticas</small>
            </div>
            <div class="col-12"
              *ngIf="isBudget(pendingTask) && pendingTask.budget_pending_tasks && pendingTask.budget_pending_tasks.length > 0">
              <span (click)="pendingTask.showBudget = !pendingTask.showBudget"
                class="mat-raised-button mat-button badge-primary">
                {{ pendingTask.showBudget ? 'Ocultar' : 'Mostrar' }} <b>
                  {{pendingTask.budget_pending_tasks.length}}
                </b>Presupuestos.
              </span>
              <div class="row" *ngIf="pendingTask.showBudget">
                <div class="col-12" *ngFor="let budget of pendingTask.budget_pending_tasks; let i = index">
                  <div class="row" style="margin-left: 10px;">
                    <div class="col-1">
                      <b>{{i+1}})</b>
                    </div>
                    <div class="col-4">
                      {{ budget.state_budget_pending_task?.name || 'Pendiente' }}
                    </div>
                    <div class="col-3">
                      {{ budget.type == 1 ?  'MODIFICACIÓN' : 'NUEVO' }}
                    </div>
                    <div class="col-4">
                      <a [href]="budget.url" target="_blank">{{ budget.file_name }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
  <mat-dialog-actions align="center" class="p-4">
    <button mat-button class="mat-raised-button mr-3" (click)="onNoClick()" style="width: 100px;">Cerrar</button>
    <button *ngIf="storageService.getCurrentUser().role_id !== 8 &&
      storageService.getCurrentUser().role_id !== 9" mat-button class="mat-raised-button" (click)="confirmOrder()"
      [class.spinner]="showSpinner | async" [disabled]="(disableBtn | async) || (showSpinner | async)">Confirmar Orden
    </button>
  </mat-dialog-actions>

</div>
