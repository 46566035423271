import { NativeDateAdapter } from 'projects/saturn-datepicker/src/datetime';

import * as moment from 'moment';
import {Injectable} from '@angular/core';
@Injectable()
export class IDateAdapterComponent extends NativeDateAdapter {

  private static _toString(n: number) {
    const month = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    return month[n];
  }

  private static _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }

  // tslint:disable-next-line:ban-types
  format(date: Date, displayFormat: Object): string {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    if (displayFormat === 'input') {
      return moment(date).format('DD/MM/YYYY');
      // return  IDateAdapterComponent._to2digit(day) + ' ' + IDateAdapterComponent._toString(month) + ', ' + year;
    } else {
      return IDateAdapterComponent._toString(month) + ' ' + year;
    }
  }
}
