/*
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
import {Pipe, PipeTransform} from '@angular/core';
import {orderBy} from 'lodash';

@Pipe({name: 'sortBy'})
export class SortByPipe implements PipeTransform {

  transform(array: any, field: string, direction: string = 'asc'): any[] {
    if (array && array.length !== 0) {
      array.sort((a: any, b: any) => {
        const order = direction == 'asc' ? 1 : -1;
        const x = typeof(a[field]) === "string" ? a[field].toLowerCase() : a[field];
        const y = typeof(b[field]) === "string" ? b[field].toLowerCase() : b[field];
        if (x < y) {
          return -1 * order;
        } else if (x > y) {
          return 1 * order;
        } else {
          return 0;
        }
      });
    }
    return array;
  }
}


@Pipe({name: 'campasName'})
export class CampasNamesPipe implements PipeTransform {
  transform(array: any, field: string): any {
    if (array.length !== 0) {
      array.sort((a: any, b: any) => {
        if (a[field].toLowerCase() < b[field].toLowerCase()) {
          return -1;
        } else if (a[field].toLowerCase() > b[field].toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return array.map(value => value.name).join(',  ');
  }
}

@Pipe({name: 'campasCost'})
export class CostPipe implements PipeTransform {
  transform(array: any, field: string): any {
    if (!array || array.length === 0) {
      return '';
    }
    const firstCampa = array.shift();
    return firstCampa.pivot && firstCampa.pivot.cost ? true : false;
  }
}

@Pipe({
  name: 'campasCostsName'
})
export class CampasCostsNamesPipe implements PipeTransform {
  transform(array: any[], field: string): string {
    if (!array || array.length === 0) {
      return '';
    }

    // Filtrar solo las campas con cost en 1
    const filteredArray = array.filter(campa => campa.pivot && campa.pivot.cost === 1);

    // Ordenar el array filtrado
    filteredArray.sort((a, b) => {
      const nameA = a[field].toLowerCase();
      const nameB = b[field].toLowerCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });

    // Mapear los nombres y unirlos con coma
    return filteredArray.map(campa => campa[field]).join(', ');
  }
}


@Pipe({
  name: 'campasNotCostsName'
})
export class CampasNotCostsNamesPipe implements PipeTransform {
  transform(array: any[], field: string): string {
    if (!array || array.length === 0) {
      return '';
    }

    // Filtrar solo las campas con cost en 1
    const filteredArray = array.filter(campa => campa.pivot && campa.pivot.cost === 0);

    // Ordenar el array filtrado
    filteredArray.sort((a, b) => {
      const nameA = a[field].toLowerCase();
      const nameB = b[field].toLowerCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });

    // Mapear los nombres y unirlos con coma
    return filteredArray.map(campa => campa[field]).join(', ');
  }
}