import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Subject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private subject = new Subject<any[]>();

  headerCampa: any[];

  constructor() {
    this.headerCampa = [];
  }

  get entities(): any {
    return {
      1: {
        id: 1,
        logo: 'assets/ald.jpg',
        address: `Avenida de Carabanchel nº 6, Esquina Avda. de la Reina Sofia 28914 - Leganés - Madrid`
      },
      2: {
        id: 2,
        logo: 'assets/focus-logo-mod.png',
        address: `Anabel Segura, 7, 28108 Alcobendas Madrid España`
      }
    }
  }

  public getHeaderCampa() {
    return this.headerCampa;
  }

  public setHeaderCampa(campas) {
    this.headerCampa = campas;
    this.subject.next(this.headerCampa);
  }

  public getfiltros(): Observable<any> {
    return this.subject.asObservable();
  }

  getFormatDate(date): string {
    const format = "DD/MM/YYYY hh:mm A";
    const value = date ? moment.utc(date).local().format(format) : '-';
    if (value == 'Fecha inválida') {
      console.warn(date);
    }
    return value; 
  }

}
