<button mat-icon-button class="close-button" (click)="onNoClick()">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <h2>{{data.vehicle?.vehicle_model?.brand?.name}} - {{data.vehicle?.vehicle_model?.name}} - {{data.vehicle?.plate}}</h2>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button  mat-button class="mat-raised-button" style="background-color: #03989E;color: #ffffff;"
          [mat-dialog-close]="data" cdkFocusInitial class="mb-3">
          {{ setTypeTask(data.typeTask) }}  
  </button>
</mat-dialog-actions>
