import {
  Component,
  OnInit,
  DoCheck,
  ChangeDetectionStrategy,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from "@angular/core";

import { MatSelect } from "@angular/material/select";
import { MatIconRegistry } from "@angular/material/icon";

import * as moment from "moment";

import { DomSanitizer } from "@angular/platform-browser";
import { HeaderService } from "./service/header.service";
import { LocationService } from "./services/location.service";
import { UserService } from "./services/user.service";
import { TaskService } from "./services/task.service";
import { StorageService } from "./core/services/storage.service";
import {
  CAR_ARROW_RIGHT,
  CAR_DOOR,
  CAR_OFF_ICON,
  ITV,
} from "./components/shared/svgIcons.registry";
import { SwUpdate } from '@angular/service-worker';
import { Subject } from "rxjs";
import { Router } from "@angular/router";
declare const document;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy {
  title = "Focus";
  identity: any;
  regions: any = [];
  provinces: any = [];
  isExpanded = true;
  regionSelected: any = 0;
  workshopMechanic = 0;
  workshopChapa = 0;
  itv = 0;
  selectedCampa: any[];
  
  /** flags to set the toggle all checkbox campa */
  isIndeterminate = false;
  isChecked = false;

  // tslint:disable-next-line:variable-name
  protected _onDestroy = new Subject<void>();

  @ViewChild("multiSelect", { static: false }) multiSelect: MatSelect;

  constructor(
    public updates: SwUpdate,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public userService: UserService,
    public locationService: LocationService,
    public router: Router,
    public headerService: HeaderService,
    public taskService: TaskService,
    public storageService: StorageService,
  ) {
    updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    updates.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });

    updates.available.subscribe(event => {
      updates.activateUpdate().then(() => {
        // https://stackoverflow.com/a/59793087/7638125
        // let hex = "⚽️".codePointAt(0).toString(16)
        // var r = confirm(`${String.fromCodePoint(0x1f680)} ENHORABUENA ${String.fromCodePoint(0x1f3c6)} UNA NUEVA VERSI${String.fromCodePoint(0x26bd)}N ${false ? window['version'] : ''} DISPONIBLE.`);
        var r = confirm(`ENHORABUENA UNA NUEVA VERSION DISPONIBLE.`);
        if (!!r) {
          document.location.reload();
        }
        console.log("The app is updating right now");
      });
    });
    iconRegistry.addSvgIconLiteral(
      "car-off",
      sanitizer.bypassSecurityTrustHtml(CAR_OFF_ICON)
    );
    iconRegistry.addSvgIconLiteral(
      "car-arrow-right",
      sanitizer.bypassSecurityTrustHtml(CAR_ARROW_RIGHT)
    );
    iconRegistry.addSvgIconLiteral(
      "car-door",
      sanitizer.bypassSecurityTrustHtml(CAR_DOOR)
    );
    iconRegistry.addSvgIconLiteral(
      "itv",
      sanitizer.bypassSecurityTrustHtml(ITV)
    );
    this.loadUserIdentity();
  }

  get role_id() {
    return ((this.storageService.getCurrentSession() || {}).user || {}).role_id;
  }

  ngOnInit(action: string = null): void {
    this.isLoginRoute();
    if (this.storageService.isAuthenticated()) {
      moment.locale("es-ES");
    }
  }

  ngAfterViewInit(): void {
    // this.setInitialValue();
  }

  ngDoCheck(action: string = null): void {
    this.loadUserIdentity();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  loadUserIdentity(action: string = null): void {
    this.identity = this.storageService.getCurrentUser();
  }

  isLoginRoute(): boolean {
    return (
      this.router.url.includes("/login") ||
      this.router.url.includes("/reset-password")
    );
  }

  /** end select campas */

  getRegions(): void {
    if (this.storageService.isAuthenticated()) {
      this.locationService.regions().subscribe((regions) => {
        if (regions.length !== 0) {
          this.regions = regions;
          this.regionChange(this.regionSelected);
        }
      });
    }
  }

  regionChange(event) {
    if (this.storageService.isAuthenticated()) {
      if (event === 0 || event.value === 0) {
        this.regionSelected = 0;
      } else if (event.value) {
        this.regionSelected = event.value;
      } else {
        this.regionSelected = event;
      }
    }
  }

  logout() {
    this.storageService.logout();
  }

  toolbarToggle() {
    this.isExpanded = !this.isExpanded;
  }

  navigateTo(url, params) {
    this.router.navigateByUrl(url, params);
  }
}
