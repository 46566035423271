import {Action} from '@ngrx/store';

export const CHANGE_CAMPA = 'Change campa';

export class ChangeCampa implements Action {
  readonly type = CHANGE_CAMPA;

  constructor(public payload: any) {
  }
}

export type Actions = ChangeCampa;
