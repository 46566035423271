<button mat-icon-button class="close-button" (click)="onNoClick()">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <h2>{{data.vehicle.vehicle_model?.brand?.name}} {{data.vehicle?.vehicle_model?.name}} -- {{data.vehicle.plate}}</h2>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <h3 style="font-weight: 600;">{{data?.text || '¡ Dirígete a tu App móvil para iniciar la tarea !'}}</h3>
</mat-dialog-actions>
