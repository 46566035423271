import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as XLSX from "xlsx";
import * as _ from "lodash";
@Component({
  selector: "app-statistics",
  templateUrl: "./statistics.component.html",
  styleUrls: ["./statistics.component.css"],
})
export class StatisticsComponent implements OnInit {
  data;

  pickState = [
    /** NRR */
    'Pre-disponible',
    'Taller',
    'En reparación',
    'Pendiente Venta V.O.',
    /** RTR */
    'Disponible',
  ];

  pickSubState = [
    'Mecánica',
    'Chapa',
    'Transformación',
    'ITV',
    'Limpieza',
    'Taller externo',
    'Taller lunas'
  ];

  constructor( private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
  }

  onData(data: any = {}) {
    this.data = _.assign(this.data, data);
    this._cdr.detectChanges()
  }

  exportKpis() {
    var obj = {
      state: this.data.state,
      subState: this.data.subState,
    };

    var campa = document.getElementById("campa-table");
    var state = document.getElementById("states-table");

    /* create new workbook */
    var workbook = XLSX.utils.book_new();

    /* convert table "table1" to worksheet named "Sheet1" */
    var sheet1 = XLSX.utils.table_to_sheet(campa);
    XLSX.utils.book_append_sheet(workbook, sheet1, "KPIs Stock Campa");
    
    var sheet2 = XLSX.utils.table_to_sheet(state);
    XLSX.utils.book_append_sheet(workbook, sheet2, "KPIs Estados ALD Flex");


     /* convert table "table2" to worksheet named "Sheet2" */
    // var sheet2 = XLSX.utils.json_to_sheet([obj.subState]);
    // XLSX.utils.book_append_sheet(workbook, sheet2, "SubState");

    XLSX.writeFile(workbook, 'kpis-reports.xlsx');
  }
}
