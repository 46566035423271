import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatisticService } from 'src/app/services/statistic.service';

@Component({
  selector: 'app-average-by-task',
  templateUrl: './average-by-task.component.html',
  styleUrls: ['./average-by-task.component.css']
})
export class AverageByTaskComponent implements OnInit {

  @Input() title;
  show: boolean;
  @Output() onData = new EventEmitter<any>();

  constructor(
    private statisticService: StatisticService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const sub = this.statisticService.getAverageByTask().subscribe((res) => {
      this.show = true;
      this.cdr.detectChanges();
      sub.unsubscribe();
    }, (err) => {
      sub.unsubscribe();
    })
  }

}
