<button mat-icon-button class="close-button" (click)="onNoClick()">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Nombre transporte</mat-label>
    <input matInput [(ngModel)]="data.name" autocomplete="off">
    <mat-icon matSuffix>garage</mat-icon>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button class="mat-raised-button" (click)="onNoClick()">Cancelar</button>
  <button mat-button class="mat-raised-button btn-create" [mat-dialog-close]="data" cdkFocusInitial>Crear</button>
</mat-dialog-actions>
