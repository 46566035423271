import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatTableExporterModule } from "mat-table-exporter";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxDropzoneModule } from "ngx-dropzone";
import { RouterModule } from "@angular/router";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatRadioModule } from "@angular/material/radio";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatBadgeModule } from "@angular/material/badge";
import { A11yModule } from "@angular/cdk/a11y";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import {
  CampasNamesPipe,
  SortByPipe,
  CostPipe,
  CampasCostsNamesPipe,
  CampasNotCostsNamesPipe,
} from "../components/shared/sortBy.pipe";
import { ProgressSpinnerComponent } from "../core/overlay/components/progress-spinner/progress-spinner.component";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SatDatepickerModule } from "projects/saturn-datepicker/src/datepicker";
import { SatNativeDateModule } from "projects/saturn-datepicker/src/datetime";
import { GroupByPipe } from "../components/shared/group-by.pipe";
import {MatStepperModule} from '@angular/material/stepper';

@NgModule({
  declarations: [SortByPipe, GroupByPipe, CampasNamesPipe, CostPipe, CampasCostsNamesPipe, CampasNotCostsNamesPipe, ProgressSpinnerComponent],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTabsModule,
    MatTooltipModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTableExporterModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    NgxDropzoneModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatDialogModule,
    MatBadgeModule,
    A11yModule,
    SatDatepickerModule,
    SatNativeDateModule,
    DragDropModule,
    MatCheckboxModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
  ],
  exports: [
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTabsModule,
    MatTooltipModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTableExporterModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    NgxDropzoneModule,
    RouterModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatDialogModule,
    MatBadgeModule,
    A11yModule,
    SatDatepickerModule,
    SatNativeDateModule,
    DragDropModule,
    MatCheckboxModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    GroupByPipe,
    SortByPipe,
    CampasNamesPipe,
    CostPipe,
    CampasCostsNamesPipe,
    CampasNotCostsNamesPipe,
    ProgressSpinnerComponent,
    MatSlideToggleModule,
    MatStepperModule
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class SharedModule { }
