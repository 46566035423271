<button mat-icon-button class="close-button" (click)="onNoClick()">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <h2>{{data.message}}</h2>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button class="mat-raised-button" (click)="onNoClick()">No</button>
  <button mat-button class="mat-raised-button" [mat-dialog-close]="data" cdkFocusInitial>Sí</button>
</mat-dialog-actions>
