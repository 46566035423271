import { ChangeDetectorRef, Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize, map } from "rxjs/operators";
import { VehicleService } from "../../../../../services/vehicle.service";
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject, Observable } from "rxjs";
import { TaskService } from "../../../../../services/task.service";
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";
import { ModalImageComponent } from "../modal-image/modal-image.component";
import * as _ from "lodash";
import { QuestionAnswersService } from "src/app/services/question-answers.service";
import { StorageService } from "@core/services/storage.service";
import { DefleetDialogComponent } from "src/app/components/shared/defleet-dialog/defleet-dialog.component";
import { UserService } from "src/app/services/user.service";
import * as moment from "moment";

const CHECK = 11;

export interface ICheckList {
  id?: number;
  addcoment?: boolean;
  question: string;
  response: string;
}

let CHECKLIST_DATA: ICheckList[] = [];

@Component({
  selector: "app-checklist",
  templateUrl: "./checklist.component.html",
  styleUrls: ["./checklist.component.css"],
})
export class ChecklistComponent implements OnInit {
  @Output() dataEvent = new EventEmitter<string>();
  id: number;
  isRequestDefleeting: string = '0';
  data: any;
  showSpinnerSubject = new BehaviorSubject(false);
  showSpinner: Observable<boolean> = this.showSpinnerSubject.asObservable();
  // vehicle: any;
  public displayedCheckListColumns: string[] = [
    "question",
    "description",
    "validation",
    "instruction",
  ];
  loadingSubject = new BehaviorSubject(true);
  isLoading: Observable<boolean>;
  dataSourceCheckList: MatTableDataSource<ICheckList>;
  noCheckListData: any;
  questionnaire: any;
  public vehiclePictures = [];
  public vehiclePicturesGallery = [];
  images = [];
  imageSrc = [];
  imageIndex = 0;
  img = "https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg";
  img2 = "https://wittlock.github.io/ngx-image-zoom/assets/fullres.jpg";

  textError = null;
  viemAddcoment: boolean = false;
  spinnerDeclineSubject = new BehaviorSubject<boolean>(false);
  public showDeclineSpinner: Observable<boolean>;
  spinnerRentalSubject = new BehaviorSubject<boolean>(false);
  public showRentalSpinner: Observable<boolean>;
  spinnerRepairSubject = new BehaviorSubject<boolean>(false);
  public showRepairSpinner: Observable<boolean>;
  coment: string;
  send: any;

  rotulationList: any[] = [
    {
      task_id: 9,
      value: "1 panel",
    },
    {
      task_id: 10,
      value: "2 paneles",
    },
    {
      task_id: 11,
      value: "3 paneles",
    },
    {
      task_id: 12,
      value: "4 paneles",
    },
    {
      task_id: 13,
      value: "5 o más paneles",
    },
  ];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private vehicleService: VehicleService,
    private questionAnswerService: QuestionAnswersService,
    private changeDetectorRefs: ChangeDetectorRef,
    private taskService: TaskService,
    private storageService: StorageService,
    private userService: UserService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.showDeclineSpinner = this.spinnerDeclineSubject.asObservable();
    this.showRentalSpinner = this.spinnerRentalSubject.asObservable();
    this.showRepairSpinner = this.spinnerRepairSubject.asObservable();
    this.route.params.subscribe((params: any) => {
      this.id = params.id;
      this.isRequestDefleeting  = history.state?.isRequestDefleeting || '0';
      this.getQuestionnaire();
    });
  }

  ngAfterViewInit() {
    // this.magnify("myimage", 3);
  }
  public openPicture(picture) {
    const dialogRef = this.dialog.open(ModalImageComponent, {
      disableClose: true,
      data: {
        dataKey: picture,
      },
    });
  }

  get vehicle(): any {
    return this.questionnaire?.vehicle;
  }

  getQuestionnaire(): void {
    let arrayParams = [
      `ids[],${this.id}`,
      'with[],questionAnswers.question',
      'with[],questionAnswers.pendingTask',
      'with[],reception.vehiclePictures',
      'with[],reception.campa',
      'with[],vehicle.vehicleModel.brand',
      'with[],vehicle.subState.state',
      'with[],vehicle.category',
      'with[],vehicle.typeModelOrder',
      'with[],vehicle.lastReception.AllApprovedPendingTasks',
      'receptionNull,0',
      `isRequestDefleeting,${this.isRequestDefleeting}`
    ];
    this.showSpinnerSubject.next(true);
    this.vehicleService.getQuestionnaire(arrayParams).subscribe((res: any) => {
      this.showSpinnerSubject.next(false);
      this.questionnaire = _.first(res.data);
      if (!this.questionnaire?.vehicle) {
        this.router.navigateByUrl("validation");
        return;
      }
      const order = [1, 2,3,4,5,6,8,9,10,11,96,92,93,95,94];
      CHECKLIST_DATA =  this.questionnaire.question_answers.sort(function(a, b){
        return order.indexOf(a.question_id) - order.indexOf(b.question_id);
      });
      const mappedCheckListData = CHECKLIST_DATA.map((param) => {
        return { ...param, addcoment: false };
      });
      CHECKLIST_DATA = [
        ...mappedCheckListData.filter(
          (q) => q.question["id"] !== 71 && q.question["id"] !== 7
        ),
      ];
      this.dataSourceCheckList = new MatTableDataSource(CHECKLIST_DATA);
      this.noCheckListData = this.dataSourceCheckList
        .connect()
        .pipe(map((data) => data.length === 0));

      this.vehiclePictures = this.questionnaire.reception.vehicle_pictures;
      this.vehiclePictures.forEach((element) => {
        this.vehiclePicturesGallery.push(element.url);
      });
      this.images = this.vehiclePicturesGallery;
      this.imageSrc = this.vehiclePicturesGallery;
      setTimeout(() => {
        this.changeDetectorRefs.detectChanges();
      }, 10);
    }, () => {
      this.showSpinnerSubject.next(false);
    });
  }

  updateSelect(value, question_answers, select) {
    let data: any = {};
    if (question_answers.question.id === 5) {
      let task_id = null;
      let description = null;
      switch (value) {
        case "Leve":
          task_id = 5;
          description = "Leve";
          break;
        case "Intermedia":
          task_id = 6;
          description = "Intermedia";
          break;
        case "Severa":
          task_id = 7;
          description = "Severa";
          break;
        case "Especial":
          task_id = 8;
          description = "Especial";
          break;
        default:
          task_id = 6;
          break;
      }
      data = {
        id: question_answers.id,
        last_task_id: question_answers.task_id,
        questionnaire_id: question_answers.questionnaire_id,
        description,
        task_id,
      };
    }
    if (question_answers.question.id === 6) {
      data = {
        id: question_answers.id,
        last_task_id: question_answers.task_id,
        questionnaire_id: question_answers.questionnaire_id,
        description: _.find(this.rotulationList, { task_id: value }).value,
        task_id: value,
      };
    }
    if ("id" in data) {
      this.taskService.updateSelectTaskDecline(data).subscribe(
        (res: any) => {
          console.log(res.question_answer, 'UMMM')
          if (res.question_answer.question_id == 5 || res.question_answer.question_id == 6) {
            question_answers.description = res.question_answer.description;
          }
          question_answers.task_id = res.question_answer.task_id;
          this.updateApproved({ checked: false }, question_answers);
        },
        () => {
          select.value = question_answers.description;
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido un error intentelo luego",
            text: "",
          });
        }
      );
    }
  }

  updateApproved(event, element) {
    if (this.isRequestDefleeting == '1') {
      element.response = event.checked;
      return;
    };
    const dataQuestionAnswers = {
      response: event.checked,
    };
    this.questionAnswerService
      .updateResponse(dataQuestionAnswers, element.id)
      .subscribe((result) => {
        const update_has_environment_label = {
          has_environment_label: result.response ? 1 : 0
        };
        if (result.question_id === 9) {
          this.vehicleService.updateVehicle(this.vehicle.id, update_has_environment_label).subscribe();
        }
        element.response = result.response ? 1 : 0;
        this.changeDetectorRefs.detectChanges();
      });
  }



  get user(): any {
    return this.storageService.getCurrentUser() || {};
  }

  get isUnapprovedGroup(): boolean {
    if (this.user.role_id === 10) {
      return false
    }
    return !this.questionnaire?.datetime_approved;
  }

  async requestDefleeting(vehicle) {
    // console.log(this.dataSourceCheckList.data, 'CHECKLIST', this.dataSourceCheckList.data.map((i)=>{return  _.clone(i.question).description  +': '+ (i.response?'Si': 'No')}))
    // return;
    await this.questionAnswerService
        .updateResponseAll({question_answers: this.dataSourceCheckList.data}, this.id)
        .subscribe();

    this.vehicleService.updateVehicle(vehicle.id, {
      reviewed_by: this.user.id,
      review_datetime: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
    }).subscribe((res)=>{
      Swal.fire({
        icon: "success",
        title: "Revisión procesada",
        text: "",
      }).then(()=>{
        this.router.navigateByUrl("/validation");
      });

    });

    // const dialogRef = this.dialog.open(DefleetDialogComponent, {
    //   width: '500px',
    //   data: {
    //     vehicle: this.vehicle
    //   }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(result); // Pizza!
    //   // si viene una solicitud entonces se debe ir a validaciones, sino serguir el flujo actual
    //   if (!!result?.isRequestDefleeing && result?.isRequestDefleeing) {
    //     this.router.navigateByUrl("/validation");
    //   } else {
    //     this.getQuestionnaire();
    //   }

    // });
  }

  validateCheck(button) {
    button.disabled = true;
    this.spinnerRentalSubject.next(true);
    const data = {
      questionnaire_id: this.questionnaire.id
    };
    this.taskService
      .validateCheck(data)
      .pipe(finalize(() => this.spinnerRentalSubject.next(false)))
      .subscribe(
        (res) => {
          Swal.fire({
            icon: "success",
            title: "Solicitud aprobada",
            text: "",
          });
          this.userService.fetchNonification.next(true);
          this.router.navigateByUrl("validation");
          setTimeout(() => {
            window.location.reload()
          }, 3000);
        },
        (err) => {
          button.disabled = false;
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido un error al aprobar la solicitud",
            text: "",
          });
        }
        );
  }



  ocultarAlertaFormato() {
    // @ts-ignore
    $(".div-container-upload").show();
    // @ts-ignore
    $(".modal-error-format").hide();
  }

  public SaveUpdateComent(element: any) {
    let coment = { description_response: element.description_response };
    this.taskService.updateComent(element.id, coment).subscribe(
      (data) => {
        this.viewComentFalse(element.id);
        element.addcoment = false;
        Swal.fire({
          icon: "success",
          title: "Comentario actualizado.",
          text: "",
        });
        this.changeDetectorRefs.detectChanges();
      },
      () => {
        element.addcoment = false;
        Swal.fire({
          icon: "error",
          title: "Ha ocurrido un error intente mas tarde",
          text: "",
        });
        this.changeDetectorRefs.detectChanges();
      }
    );
  }

  ViewAddComent(id) {
    for (let index = 0; index < CHECKLIST_DATA.length; index++) {
      if (CHECKLIST_DATA[index].id == id) {
        CHECKLIST_DATA[index].addcoment = true;
      }
    }
    this.dataSourceCheckList = new MatTableDataSource(CHECKLIST_DATA);
  }

  viewComentFalse(id) {
    for (let index = 0; index < CHECKLIST_DATA.length; index++) {
      if (CHECKLIST_DATA[index].id == id) {
        CHECKLIST_DATA[index].addcoment = false;
      }
    }
    this.dataSourceCheckList = new MatTableDataSource(CHECKLIST_DATA);
  }

  setDate(date){
    return date ? moment.utc(date).local().format("DD/MM/YYYY hh:mm A") : '-'
  }
  magnify(imgID, zoom) {
    var img, glass, w, h, bw;
    img = document.getElementById(imgID);
    /*create magnifier glass:*/
    glass = document.createElement("DIV");
    glass.setAttribute("class", "img-magnifier-glass");
    /*insert magnifier glass:*/
    img.parentElement.insertBefore(glass, img);
    /*set background properties for the magnifier glass:*/
    glass.style.backgroundImage = "url('" + img.src + "')";
    glass.style.backgroundRepeat = "no-repeat";
    glass.style.backgroundSize =
      img.width * zoom + "px " + img.height * zoom + "px";
    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    glass.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mousemove", moveMagnifier);
    /*and also for touch screens:*/
    glass.addEventListener("touchmove", moveMagnifier);
    img.addEventListener("touchmove", moveMagnifier);
    function moveMagnifier(e) {
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;
      /*prevent the magnifier glass from being positioned outside the image:*/
      if (x > img.width - w / zoom) {
        x = img.width - w / zoom;
      }
      if (x < w / zoom) {
        x = w / zoom;
      }
      if (y > img.height - h / zoom) {
        y = img.height - h / zoom;
      }
      if (y < h / zoom) {
        y = h / zoom;
      }
      /*set the position of the magnifier glass:*/
      glass.style.left = x - w + "px";
      glass.style.top = y - h + "px";
      /*display what the magnifier glass "sees":*/
      glass.style.backgroundPosition =
        "-" + (x * zoom - w + bw) + "px -" + (y * zoom - h + bw) + "px";
    }
    function getCursorPos(e) {
      var a,
        x = 0,
        y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }

}
