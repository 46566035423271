import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessorieService {
  private _apiUrl = `${environment.api_url}`;
  constructor(private _http: HttpClient) { }

  getAccessories(arrayParams = []): Observable<any> {
    let params = this.getParams(arrayParams);
    return this._http.get(this._apiUrl + "/accessories", { params });
  }

 /** Updates vehicle Accessories. */
 getAccessoriesVehicle(arrayParams = []): Observable<any> {
  let params = this.getParams(arrayParams);
  return this._http.get(`${this._apiUrl}/accessory-vehicle`, { params });
}

  /** Updates vehicle Accessories. */
  updateAccessoriesVehicle(body, checked): Observable<any> {
    return this._http.post(`${this._apiUrl}/accessory-vehicle${checked ? '' : '/delete'}`, body);
  }


  createAccessorie(name: string, accessory_type_id: number): Observable<any> {
    const bodyAccessorie = {
      name,
      accessory_type_id
    };
    return this._http.post(this._apiUrl + "/accessories", bodyAccessorie);
  }

  updateAccessorie(name: string, accessory_type_id: number, id: number): Observable<any> {
    const bodyAccessorie = {
      name,
      accessory_type_id
    };
    return this._http.put(this._apiUrl + `/accessories/${id}`, bodyAccessorie);
  }

  getAccessoryType(): Observable<any> {
    return this._http.get(`${this._apiUrl}/accessory-types`);
  }

  getParams(arrayParams = []): HttpParams {
    let params = new HttpParams();
    const arr = Array.isArray(arrayParams) ? arrayParams : [];
    arr.forEach(param => {
      const split = param.split(',');
      if (split.length === 2) {
        params = params.append(split[0].trim(), split[1].trim());
      }
    });
    return params;
  } 

}
