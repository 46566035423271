import { Component, Inject, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IDialogData } from "../../../interfaces/dialog.interface";
import { forkJoin, Observable, ReplaySubject, Subject } from "rxjs";
import { UploadFileService } from "../../../services/upload-file.service";
import * as XLSX from "xlsx";
import { CampasService } from "../../../services/campas.service";
import { StatesService } from "../../../services/states.service";
import { CategoriesService } from "../../../services/categories.service";
import * as moment from "moment";
import { VehicleService } from "../../../services/vehicle.service";
import Swal from "sweetalert2";
import { StorageService } from "../../../core/services/storage.service";
import { User } from "@core/models/user.model";
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BrandService } from "src/app/services/brand.service";
import { VehicleModelService } from "src/app/services/vehicle-model.service";
import { takeUntil } from "rxjs/operators";
import { CompanyService } from "src/app/services/company.service";

const KEY_FIELD = 'key-filed-import-vehicle';
export class Vehicle {
  id: number;
  remote_id: number;
  company_id: number;
  campa_id: number;
  category_id: number;
  sub_state_id: number;
  color_id: number;
  plate: string;
  vehicle_model_id: number;
  type_model_order_id: number;
  kms: string;
  last_change_state: string;
  last_change_sub_state: string;
  next_itv: string;
  has_environment_label: boolean;
  observations: string;
  priority: number;
  version: string;
  vin: string;
  first_plate: string;
  latitude: string;
  longitude: string;
  trade_state_id: string;
  documentation: number;
  ready_to_delivery: number;
  seater: number;
  constructor(o: any = {}) {
    this.remote_id = o.remote_id || null;
    this.company_id = o.company_id || null;
    this.campa_id = o.campa_id || null;
    this.category_id = o.category_id || null;
    this.sub_state_id = o.sub_state_id || 1;
    this.color_id = o.color_id || null;
    this.plate = o.plate || null;
    this.vehicle_model_id = o.vehicle_model_id || null;
    this.type_model_order_id = o.type_model_order_id || null;
    this.kms = o.kms || null;
    this.last_change_state = o.last_change_state || moment().utc().format('YYYY-MM-DD HH:mm:ss');
    this.last_change_sub_state = o.last_change_sub_state || moment().utc().format('YYYY-MM-DD HH:mm:ss');
    this.next_itv = o.next_itv || null;
    this.has_environment_label = o.has_environment_label || null;
    this.observations = o.observations || null;
    this.priority = o.priority || 0;
    this.version = o.version || null;
    this.vin = o.vin || null;
    this.first_plate = o.first_plate || null;
    this.latitude = o.latitude || null;
    this.longitude = o.longitude || null;
    this.trade_state_id = o.trade_state_id || null;
    this.documentation = o.documentation || 1;
    this.ready_to_delivery = o.ready_to_delivery || 0;
    this.seater = Number(((o.seater || '').match(/\d+/) || [])[0] || 0);
  }
}


const validarString = (cadenaAnalizar) => {
  if (!cadenaAnalizar) {
    return cadenaAnalizar;
  }
  var numeros = "";
  var letras = "";
  for (var i = 0; i < cadenaAnalizar.length; i++) {
    var caracter = cadenaAnalizar.charAt(i);
    if (1 < 2 && caracter === '0') {
      numeros += caracter;
    } else {
      letras += caracter;
    }
    //....
  }
  return letras;
}

interface IProgressBar {
  progress: number;
}

@Component({
  selector: "app-upload-file-dialog",
  templateUrl: "./upload-file-dialog.component.html",
  styleUrls: ["./upload-file-dialog.component.css"],
})
export class UploadFileDialogComponent implements OnInit {
  dataSheet = new Subject();
  keys: string[];
  @ViewChild("file") file;
  @ViewChild('inputFile') inputFile: ElementRef;
  isExcelFile: boolean;
  public files: Set<File> = new Set();
  valueProgressBar = 0;
  progress;
  user: User;
  canBeClosed = true;
  primaryButtonText = "Importar Datos";
  showCancelButton = true;
  uploading = false;
  uploadSuccessful = false;
  disableImportBtn = true;
  jsonVehicles: object;
  excelName: string;
  fileName: string;
  status: { [key: string]: { progress: Observable<number> } } = {};
  progressX = new Subject<number>();
  value: any;
  valueProgress: Observable<number>;
  campas;
  categories;
  states;
  isLoading = false;
  finishImport = false;
  requestsArray = [];

  progressBars: IProgressBar[] = [
    {
      progress: 0,
    },
  ];

  form: FormGroup;
  allVehicles: any[] = [];
  auxVehicles: any[] = [];
  objField: any = {};
  vehicle: Vehicle = new Vehicle();
  columnKeys: any[] = [];
  fieldName: any = {
    id: 'Id',
    plate: 'Matrícula',
    remote_id: '',
    company_id: 'Empresa',
    campa_id: 'Campa',
    category_id: 'Categoria',
    sub_state_id: 'Sub-Estado',
    color_id: 'Color',
    brand_id: 'Marca',
    vehicle_model_id: 'Modelo del vehiculo',
    type_model_order_id: 'Canal',
    kms: 'Kilometraje',
    last_change_state: '',
    last_change_sub_state: '',
    next_itv: 'Proximo itv',
    has_environment_label: '',
    observations: 'Observaciones',
    priority: 'Prioridad',
    version: 'Version',
    vin: 'Vin',
    first_plate: 'Fecha de Matriculacion',
    latitude: '',
    longitude: '',
    trade_state_id: '',
    documentation: '',
    ready_to_delivery: '',
    seater: 'Plazas',
  }
  index: number = 0;

  public filteredBrands: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filteredModels: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  brands = [];
  models = [];
  companies = [];
  objExistsVehicle = {}
  lengthExistsVehicle = 0;
  protected _onDestroy = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<UploadFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    public uploadFilesService: UploadFileService,
    public campaService: CampasService,
    public stateService: StatesService,
    public categoryService: CategoriesService,
    public vehicleService: VehicleService,
    public storageService: StorageService,
    private brandService: BrandService,
    private companyService: CompanyService,
    private vehicleModelService: VehicleModelService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.user = this.storageService.getCurrentUser();
    this.disableImportBtn = this.files.size === 0;
    let value: any = {};
    Object.keys(this.fieldName).forEach((key) => {
      value[key] = [null];
    })
    this.form = this.fb.group(value);
    this.getCampas();
    this.getCategories();
    this.getCompanies();
    this.getStates();
  }


  onChange(event) {
    //  this.isLoading = true;
    let data;
    const target: DataTransfer = <DataTransfer>(event.target);
    this.isExcelFile = !!target.files[0].name.match(/(.xls|.xlsx)/);

    if (target.files.length > 1) {
      this.inputFile.nativeElement.value = '';
    }

    if (this.isExcelFile) {
      const reader: FileReader = new FileReader();
      reader.onload = async (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        data = XLSX.utils.sheet_to_json(ws, { raw: false });
        this.objField = {};
        if (Array.isArray(data) && data.length > 0) {
          this.columnKeys = Object.keys(data[0]);
        }

        const storage = localStorage.getItem(KEY_FIELD);
        const objField = storage ? JSON.parse(storage) : {};

        Object.keys(new Vehicle()).forEach((key) => {
          if (this.fieldName[key]) {
            this.objField[key] = objField[key] || null
          }
        });
        this.auxVehicles = data;

        this.setVehicle();

        /*
                data.forEach(element => {
                  let date = new Date(element['Fecha Matriculación']);
                  let formatDate = this.formatDate(date);
                  if (formatDate == 'NaN-NaN-NaN') formatDate = null;
                  let vehicle = this.uploadFilesService.runDelete ? {
                    plate: element['Matricula'] ? element['Matricula'] : null
                  } : {
                    ubication: element['Ubicación Texto'] ? element['Ubicación Texto'] : null,
                    campa: element['Campa'] ? element['Campa'] : null,
                    category: element['Categoría'] ? element['Categoría'] : null,
                    plate: element['Matricula'] ? element['Matricula'] : null,
                    brand: element['Marca'] ? element['Marca'] : null,
                    vehicle_model: element['Modelo'] ? element['Modelo'] : null,
                    kms: element['KM'] ? element['KM'].replace(/\,/g, '') : null,
                    version: element['Versión'] ? element['Versión'] : null,
                    vin: element['Bastidor'] ? element['Bastidor'] : null,
                    color: element['Color'] ? element['Color'] : null,
                    channel: element['Canal'] ? element['Canal'] : null,
                    zone: element['Zona'] ? element['Zona'] : null,
                    street: validarString(element['Calle'] ? element['Calle'] : null),
                    square: element['Posicion'] ? element['Posicion'] : null,
                    first_plate: formatDate,
                    priority: 0,
                    sub_state: element['Sub Estado Flexible'] ? element['Sub Estado Flexible'] : null
                  }
                  this.allVehicles.push(vehicle);
                });
                */
        // this.importExcel(_.chunk(this.allVehicles, 300), this.allVehicles, 0);
      };

      reader.readAsBinaryString(target.files[0]);

      reader.onloadend = (e) => {
        this.keys = Object.keys(data[0]);
        this.dataSheet.next(data)
      }
    } else {
      this.inputFile.nativeElement.value = '';
    }
  }

  async setVehicle() {
    if (!this.objField.plate) {
      return;
    }
    if (this.allVehicles.length === 0) {
      let arrayParams = [];
      this.auxVehicles.forEach((x) => {
        arrayParams.push(`plates[],${x[this.objField.plate]}`);
      })
      arrayParams.push(`per_page,${this.auxVehicles.length}`);
      const value = await this.vehicleService.filterVehiclesWithParams(arrayParams).toPromise()
      this.objExistsVehicle = {};
      value.vehicles.data.forEach((x) => {
        this.objExistsVehicle[x.plate] = x;
      });
    }
    this.allVehicles = []
    this.auxVehicles.forEach((x) => {
      if (this.objExistsVehicle[x[this.objField.plate]]) {
        this.allVehicles.push(x);
      } else {
        this.allVehicles.unshift(x);
      }
    })
    this.lengthExistsVehicle = Object.keys(this.objExistsVehicle).length;
    let value = {};
    Object.keys(this.objField).forEach((key) => {
      value[key] = !!this.objField[key] ? this.allVehicles[0][this.objField[key]] : null;
    });
    localStorage.setItem(KEY_FIELD, JSON.stringify(this.objField));
    this.vehicle = new Vehicle(value || {});
    this.isLoading = true;
    const campas = await this.campaService.getAllWithParameters([
      `withCoincidence,name;${this.vehicle.campa_id};50`
    ]).toPromise().then();
    if (campas.length === 1) {
      this.vehicle.campa_id = campas[0].id;
      this.vehicle.company_id = campas[0].company_id;
    }
    this.models = await this.vehicleModelService.getAllWithParameters([
      `withCoincidence,name;${this.vehicle.vehicle_model_id};50`
    ]).toPromise().then();
    if (this.models.length === 1) {
      this.vehicle.vehicle_model_id = this.models[0].id;
      this.form.patchValue({
        brand_id: this.models[0].brand_id
      })
    }
    this.isLoading = false;

    this.form.patchValue(Object.assign(JSON.parse(JSON.stringify(this.objExistsVehicle[this.vehicle.plate] || {})), this.vehicle));
  }

  importExcel(arr, arrAll, i) {
    if (!arr[i]) {
      this.valueProgressBar = 100;
      return;
    }
    let vehicles = this.uploadFilesService.runDelete ? {
      plates: arr[i].map((x) => x.plate)
    } : {
      vehicles: arr[i]
    }
    let aux = 0;
    arr.forEach((element, j) => {
      if (j <= i) {
        aux = aux + element.length
      }
    });
    /*this.vehicleService.importVehicles(vehicles, this.uploadFilesService.runDelete).subscribe(
      result => {
        this.valueProgressBar = (aux / arrAll.length) * 100;
        if(aux === arrAll.length){
          this.isLoading = false;
        }
        this.importExcel(arr, arrAll, i + 1);
      },
      error => {
        console.error(error);
        this.valueProgressBar = (aux / arrAll.length) * 100;
      }
    );*/
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  updateProgressBar() {
    this.status[this.fileName] = {
      progress: this.valueProgress,
    };

    return this.status;
  }

  getCampas() {
    const company = {
      company_id: this.user.company_id,
    };
    this.campaService.getAll(company.company_id).subscribe((campas) => {
      this.campas = campas;
    });
  }

  getCategories() {
    this.categoryService.getCategories().subscribe((categories) => {
      this.categories = categories;
    });
  }

  getCompanies() {
    this.companyService.getAll().subscribe((res) => {
      this.companies = res.data;
    });
  }

  getStates() {
    this.stateService.getStates().subscribe((states) => {
      this.states = states;
    });
  }

  getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  downloadFile() {
    window.location.href = "assets/download-excel-file/MobiusExcelExample.xlsx";
  }

  closeDialog() {
    // const percentDone = Math.round(100 * event.loaded / event.total);
    // if everything was uploaded already, just close the dialog
    if (this.uploadSuccessful) {
      return this.dialogRef.close();
    }
    this.valueProgress = this.progressX.asObservable();
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = this.files.values().next();
    this.fileName = file.value.name;
    this.progress = this.updateProgressBar();
    const importDataVehicle = [];

    reader.onload = async (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: "binary", cellDates: true });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        this.excelName = name;
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData);
      this.jsonVehicles = JSON.parse(dataString);
      this.uploading = true;

      // The dialog should not be closed while uploading
      this.canBeClosed = false;
      this.dialogRef.disableClose = true;

      // Hide the cancel-button
      this.showCancelButton = false;

      this.isLoading = true;
      for (let i = 0; i < this.jsonVehicles[this.excelName].length; i++) {
        const percentDone = Math.round(
          (100 * i) / this.jsonVehicles[this.excelName].length
        );
        this.progressX.next(percentDone);
        const dataVehicle = {
          company_id: null,
          remote_id: null,
          campa_id: null,
          category: null,
          state_id: null,
          ubication: null,
          plate: null,
          brand: null,
          vehicle_model: null,
          kms: null,
          version: null,
          vin: null,
          first_plate: null,
          priority: null,
          latitude: null,
          longitude: null,
        };
        const excelVehicleModel =
          this.jsonVehicles[this.excelName][i]["Modelo"];
        let excelCampaName = this.jsonVehicles[this.excelName][i]["Donde Esta"];
        if (excelCampaName !== "" && excelCampaName !== undefined) {
          excelCampaName = excelCampaName
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
        } else {
          excelCampaName = "";
        }
        const state = this.jsonVehicles[this.excelName][i]["Estado Flexible"];
        const campaId = this.campas.filter(
          (obj) =>
            obj.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") ===
            excelCampaName
        );
        const categoryId = this.categories.filter(
          (obj) =>
            obj.name === this.jsonVehicles[this.excelName][i]["Categoría"]
        );
        const stateId = this.states.filter((obj) => {
          if (state) {
            return obj.name.toLowerCase().includes(state.toLowerCase());
          }
        });
        const vehiclebrands = this.jsonVehicles[this.excelName][i]["Marca"];
        const vehicleModel = this.jsonVehicles[this.excelName][i]["Modelo"];
        const vehicleVersion = this.jsonVehicles[this.excelName][i]["Versión"];
        const ubication = this.jsonVehicles[this.excelName][i]["Donde Esta"];
        const firstPlate = new Date(
          this.jsonVehicles[this.excelName][i]["Fecha Matriculación"]
        );

        dataVehicle.company_id =
          this.storageService.getCurrentUser().company_id;
        dataVehicle.remote_id =
          this.jsonVehicles[this.excelName][i]["ID Vehículo"];
        dataVehicle.campa_id = campaId.length !== 0 ? campaId[0].id : null;
        // dataVehicle.campa_id = campaId.length !== 0 ? campaId[0].id : this.storageService.getCurrentUser().campas[0].id;
        dataVehicle.category = categoryId.length !== 0 ? categoryId[0].name : 1;
        dataVehicle.state_id = stateId.length !== 0 ? stateId[0].id : 1;
        dataVehicle.ubication =
          ubication !== "" && ubication !== undefined ? ubication : "";
        // dataVehicle.ubication = ubication !== '' && ubication !== undefined ? ubication :
        // this.storageService.getCurrentUser().campas[0].name;
        dataVehicle.plate =
          this.jsonVehicles[this.excelName][i]["Matricula"] || null;
        dataVehicle.brand = vehiclebrands ? vehiclebrands : "-";
        dataVehicle.vehicle_model = vehicleModel ? vehicleModel : "-";
        dataVehicle.kms =
          this.jsonVehicles[this.excelName][i]["Ult KM"] || null;
        dataVehicle.vin =
          this.jsonVehicles[this.excelName][i]["Bastidor"] || null;
        dataVehicle.first_plate = moment(firstPlate).format("DD/MM/YYYY hh:mm A");
        dataVehicle.priority = false;
        dataVehicle.version = vehicleVersion ? vehicleVersion : "-";
        importDataVehicle.push(dataVehicle);

        if (i + 1 === this.jsonVehicles[this.excelName].length) {
          this.progressX.complete();
        }
      }
      let sizeToImport = 0;
      while (importDataVehicle.length - sizeToImport >= 3000) {
        this.requestsArray.push(
          /*this.vehicleService.importVehicleFromExcel({
            vehicles: importDataVehicle.slice(
              sizeToImport,
              sizeToImport + 3000
            ),
          })*/
        );

        // await this.importData(importDataVehicle.slice(sizeToImport, sizeToImport + 3000));
        sizeToImport += 3000;
      }
      if (sizeToImport < importDataVehicle.length) {
        this.requestsArray.push(
          /*this.vehicleService.importVehicleFromExcel({
            vehicles: importDataVehicle.slice(
              sizeToImport,
              importDataVehicle.length
            ),
          })*/
        );
        // await this.importData(importDataVehicle.slice(sizeToImport, importDataVehicle.length));
      }

      forkJoin(this.requestsArray).subscribe(
        (result) => {
          // The OK-button should have the text "Finish" now
          this.primaryButtonText = "Finalizado";
          this.canBeClosed = true;
          this.dialogRef.disableClose = false;

          // ... the upload was successful...
          this.uploadSuccessful = true;
          this.isLoading = false;

          Swal.fire({
            icon: "success",
            title: "Importar datos",
            text: "Datos importados correctamente.",
          });

          // ... and the component is no longer uploading
          this.uploading = false;
        },
        (err) => {
          console.error("err", err);
          this.canBeClosed = true;
          this.dialogRef.disableClose = false;
          this.uploadSuccessful = false;
          this.showCancelButton = true;
          this.isLoading = false;
          this.dialogRef.close();
          Swal.fire({
            icon: "error",
            title: "Importar datos",
            text: "Ha ocurrido un error al importar los datos, contacte con el administrador del sitio.",
          });
        }
      );
    };

    reader.readAsBinaryString(file.value);
    this.progress = this.status;
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  importData(importDataVehicle) {
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (const key in files) {
      // tslint:disable-next-line:radix
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
      }
    }
    this.disableImportBtn = this.files.size === 0;

    this.files.forEach((file) => {
      const ext = this.getFileExtension(file.name);
      if (ext !== "xlsx") {
        Swal.fire({
          icon: "error",
          title: "Solo se admiten ficheros con formato xlsx.",
        });
        this.files.clear();
      }
    });
  }

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, {
        type: "binary",
        cellDates: true,
        cellNF: false,
        cellText: false,
      });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData);

    };
    reader.readAsBinaryString(file);
  }

  saveVehicle() {
    this.isLoading = true;
    let obs;
    if (!this.form.value.id) {
      obs = this.vehicleService.createVehicle(this.form.value)
    } else {
      obs = this.vehicleService.updateVehicle(this.form.value.id, this.form.value)
    }
    const sub = obs.subscribe((res: any) => {
      this.isLoading = false;
      sub.unsubscribe();
      this.objExistsVehicle[res.vehicle.plate] = res.vehicle;
      this.setVehicle();
    }, () => {
      this.isLoading = false;
      sub.unsubscribe();
    })
  }
}
