import {Component, OnInit} from '@angular/core';
import {TaskService} from '../services/task.service';
import {UserService} from '../services/user.service';
import {StorageService} from '../core/services/storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  identity: any;
  panelOpenState = false;

  workshopMechanic: number = 0;
  workshopChapa: number = 0;
  itv: number = 0;


  constructor(
    public userService: UserService,
    public taskService: TaskService,
    public storageService: StorageService
  ) {
  }

  ngOnInit(): void {
    // this.searchValuesGlobals();
  }

  searchValuesGlobals() {
    this.taskService.getPendingTasksInWorkshopMechanic().subscribe(
      result => {
        this.workshopMechanic = result['data'].length;
      },
      error => {
        console.error(error);
      }
    );

    this.taskService.getPendingTasksInWorkshopChapa().subscribe(
      result => {
        this.workshopChapa = result['data'].length;
      },
      error => {
        console.error(error);
      }
    );

    this.taskService.getPendingTasksInWorkshopItv().subscribe(
      result => {
        this.itv = result['data'].length;
      },
      error => {
        console.error(error);
      }
    );
  }

}
