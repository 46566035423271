<div style="display: flex; justify-content: space-between;">
  <div>
    <h1 style="color: #03989e;">Sistema de alertas</h1>
  </div>
  <button mat-icon-button class="close-button" (click)="onNoClick()">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>
<div class="filter__input">
  <input class="filter__input__size" type="text" [(ngModel)]="matriculaFilter" placeholder="Filtrar por matrícula..." (input)="getNotificationAll()">
  <!-- <button class="btn btn-primary me-md-2" (click)="applyMatriculaFilter()">Filtrar</button> -->
</div>
<div style="overflow-x: scroll" class="mat-elevation-z8 mb-5 mt-3">
  <table matTableExporter #exporter="matTableExporter" #table mat-table class="mat-elevation-z8"
  [dataSource]="dataSource">
  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef>
      {{ objColumns["view"] }}
    </th>
    <td mat-cell *matCellDef="let element">
      <a mat-icon-button style="margin-left: 5px" (click)="goTo(element)">
        <div mat-card-avatar class="d-flex align-items-center">
          <mat-icon aria-hidden="false" aria-label="Ver">remove_red_eye</mat-icon>
        </div>
      </a>
    </td>
  </ng-container>
    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef>
        {{ objColumns["created_at"] }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.created_at }}
      </td>
    </ng-container>
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>
        {{ objColumns["user"] }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.user?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="plate">
      <th mat-header-cell *matHeaderCellDef>
        {{ objColumns["plate"] }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.vehicle?.plate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="approval_external">
      <th mat-header-cell *matHeaderCellDef style="width: 100px;">
        {{ objColumns["approval_external"] }}
      </th>
      <td mat-cell *matCellDef="let element" style="text-align: center;">
        {{ (element?.approval_external ? element?.approval_external?.name : '-') }}
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>
        {{ objColumns["comment"] }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.comment }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="row-hover"></tr>
  </table>
  <mat-card [class.loading-excel]="isLoadingData" *ngIf="isLoadingData | async" style="
  display: flex;
  justify-content: center;
  align-items: center;
">

    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </mat-card>
  <div *ngIf="!(isLoading | async)">
    <div *ngIf="noVehicleData | async" class="no-records mat-typography">
      <h3>No hay registros</h3>
    </div>
  </div>

  <!-- <div class="d-flex"> -->
  <!-- <div style="width: 100%"> -->
    <mat-paginator #paginator [length]="event.length || 0" [pageSize]="event.pageSize || 50"
    (page)="getNotificationAll($event);" [pageSizeOptions]="[
      5,
      10,
      25,
      100
    ]">
  </mat-paginator>
  <!-- </div> -->
  <!-- </div> -->
</div>
