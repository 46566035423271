<div class="container p-3">
  <div class="row justify-content-end mb-3">
    <div class="col">
      <button mat-icon-button class="close-button" (click)="onNoClick()">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col text-center">
      <div class="img-magnifier-container">
        <img
          id="myimage"
          [src]="urlImage"
          style=" width: 100%; max-width: 750px; object-fit: contain; max-height: 750px;"
        />
      </div>
    </div>
  </div>
</div>
