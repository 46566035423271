<div class="container py-5">
  <div class="row">
    <mat-card style="width: 100%;">
      <mat-card-header>
        <div class="col-12">
          <p class="greenLink mb-4"><a [routerLink]="['/dashboard']">Inicio </a> > <a [routerLink]="['/vehicles']">Vehículos </a>
            > Autorizaciones pendientes</p>
<!--          <h2>Autorizaciones pendientes</h2>-->
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="col-12">
          <table class="table">
            <tbody>
            <tr>
              <td>
                <div class="{{colorWorkshopMechanic}}">En taller mecánico</div>
              </td>
              <td></td>
              <td>
                {{workshopMechanic}}
              </td>
            </tr>
            <tr>
              <td>
                <div class="{{colorWorkshopChapa}}">En taller chapa</div>
              </td>
              <td></td>
              <td>
                {{workshopChapa}}
              </td>
            </tr>
            <tr>
              <td>
                <div class="{{colorItv}}">En ITV</div>
              </td>
              <td></td>
              <td>
                {{itv}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
