<!--<div class="bodyLogin">-->
<!--  <div class="container">-->
<!--&lt;!&ndash;    <div class="row ">&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="col-12 text-center">&ndash;&gt;-->
<!--&lt;!&ndash;        <p class="">20210214 - 18:09</p>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--    <div class="row justify-content-center pt-3 ">-->
<!--      <img src="..\..\assets\logo.png" style="width:300px;">-->
<!--    </div>-->
<!--    <h1 class="text-center">Reestablecer contraseña</h1>-->
<!--    <div class="row justify-content-center mt-5">-->
<!--      <form class="col-md-6 text-center" id="loginForm" method="post">-->
<!--        <div class="form-group">-->
<!--          <input type="number" class="form-control darkGreen" [(ngModel)]="code" placeholder="Intruducir código"-->
<!--                 name="email" required="required">-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <input type="text" class="form-control darkGreen" [(ngModel)]="email" placeholder="Email"-->
<!--                 name="email" required="required">-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <input type="password" class="form-control darkGreen" [(ngModel)]="password" placeholder="Contraseña"-->
<!--                 name="email" required="required">-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <input type="password" class="form-control" name="password" [(ngModel)]="passwordConfirm"-->
<!--                 placeholder="Repetir contraseña" required="required">-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <button type="button" class="btn btn-block mt-4 bgLightGreen" [disabled]="!(code && email && password && passwordConfirm)" (click)="resetPassword()">Restablecer</button>-->
<!--        </div>-->
<!--      </form>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<div class="bodyLogin">-->
<!--  <div class="container ">-->
<!--    <div class="row justify-content-center pt-3">-->
<!--      <img src="..\..\assets\logo.png" style="width:300px; ">-->
<!--    </div>-->

<!--    <div class="row justify-content-center mt-5">-->
<!--      <form class="col-md-6 text-center" id="loginForm">-->
<!--        <div class="form-group">-->
<!--          <input type="text" class="form-control" placeholder="Email" id="passField">-->
<!--        </div>-->

<!--        <div class="row">-->
<!--          <div class="col-6">-->
<!--            <button type="button" class="btn btn-block mt-4 bgLightGreen" onclick="Login()">Aceptar</button>-->
<!--          </div>-->
<!--          <div class="col-6">-->
<!--            <button type="button" class="btn btn-block mt-4 bgLightGreen" (click)="goLoginPage()">-->
<!--&lt;!&ndash;              <mat-icon>Inicio</mat-icon>&ndash;&gt;-->
<!--              <span>Volver</span>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->


<!--      </form>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form validate-form p-l-55 p-r-55 p-t-178" [formGroup]="resetForm" autocomplete="off"
            id="loginForm" (submit)="resetPassword()">
        <div style="margin-bottom: 90px;" [class.m-bottom]="loginInvalid">
            <span class="login100-form-title" style="padding: 15px;">
						    <img src="..\..\assets\focus-login-vertical.jpg" class="login__logo">
					  </span>
        </div>

        <div style="padding-top: 0; position: relative;">
          <label class="label-test"><h2>Reestablecer contraseña</h2></label>
          <div class="wrap-input100 validate-input m-b-16" data-validate="Please enter email">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Correo electrónico</mat-label>
              <input matInput placeholder="Correo electrónico" formControlName="email" required autocomplete="off">
              <mat-icon matSuffix>email</mat-icon>
              <mat-error
                *ngIf="resetForm.get('email').hasError('pattern')&& !resetForm.get('email').hasError('required')">
                Por favor, introduce una dirección de correo electrónico válida.
              </mat-error>
              <mat-error
                *ngIf="resetForm.get('email').hasError('required')">
                Este campo es requerido.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="wrap-input100 validate-input m-b-16" data-validate="Please enter code">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Código</mat-label>
              <input matInput placeholder="Código" type="number" formControlName="code" required #elementCode
                     readonly="readonly">
              <mat-icon matSuffix>lock</mat-icon>
              <mat-error
                *ngIf="resetForm.get('code').hasError('pattern')&& !resetForm.get('code').hasError('required')">
                Formato de código inválido
              </mat-error>
              <mat-error
                *ngIf="resetForm.get('code').hasError('required')">
                Este campo es requerido.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="wrap-input100 validate-input m-b-16" data-validate="Please enter code">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Contraseña</mat-label>
              <input matInput type="password" placeholder="Contrseña" formControlName="password" #elementPassword
                     readonly="readonly"
                     [type]="hide ? 'password' : 'text'" required>
              <mat-icon matSuffix (click)="hide = !hide" style="cursor: pointer;"
                        title="Ver contraseña">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="resetForm.hasError('required', 'password')">
                Por favor ingrese su nueva contraseña
              </mat-error>
            </mat-form-field>
          </div>
          <div class="wrap-input100 validate-input m-b-16" data-validate="Please enter code">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Confirmar contraseña</mat-label>
              <input matInput placeholder="Confirmar contraseîa" formControlName="confirmPassword"
                     #elementConfirmPassword readonly="readonly"
                     [type]="hideConfirm ? 'password' : 'text'"
                     [errorStateMatcher]="matcher">
              <mat-icon matSuffix (click)="hideConfirm = !hideConfirm" style="cursor: pointer;"
                        title="Ver contraseña">{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="resetForm.hasError('notSame')">
                Las contraseñas no coinciden
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="container-login100-form-btn" style="margin-bottom: 50px;">
          <button mat-raised-button class="login100-form-btn" type="submit" [class.spinner]="showSpinner | async"
                  [disabled]="!resetForm.valid || (showSpinner | async)" color="primary">Enviar
          </button>
        </div>
        <div class="flex-col-c p-t-170 p-b-40" style="margin-top: -12rem;">
          <span class="txt1 p-b-9">
            <a [routerLink]="'/recover'" class="txt2" style="color: #008080e0;"> Volver</a>
          </span>
        </div>
      </form>
    </div>
  </div>
</div>

