import { Component, Inject, OnInit, ChangeDetectorRef } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { IPendingTaskDialog } from "../../../interfaces/dialog.interface";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { TaskService } from "../../../services/task.service";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import Swal from "sweetalert2";
import * as JSZip from 'jszip';
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import * as S3 from "aws-sdk/clients/s3";
import { environment } from "../../../../environments/environment";
import { BudgetPendingTaskService } from "src/app/services/budget-pending-task.service";
import { VehicleService } from "../../../services/vehicle.service";
import { StorageService } from "@core/services/storage.service";
import { IncidenceService } from "src/app/services/incidence.service";
import * as _ from "lodash";
import * as moment from "moment";
import { PendingTasksService } from "src/app/services/pending-tasks.service";
import { MatTableDataSource } from "@angular/material/table";
import { ModalSimpleTaskComponent } from "@modules/dashboard/components/modal-simple-task/modal-simple-task.component";
import { LocationDialogComponent } from "../location-dialog/location-dialog.component";
import { UserService } from "src/app/services/user.service";
import { CommentsComponent } from "@modules/ald/file-vehicles/components/comments/comments.component";

@Component({
  selector: "app-pending-tasks-dialog",
  templateUrl: "./pending-tasks-dialog.component.html",
  styleUrls: ["./pending-tasks-dialog.component.css"],
})
export class PendingTasksDialogComponent implements OnInit {
  showSpinnerSubject = new BehaviorSubject(false);
  showSpinner: Observable<boolean> = this.showSpinnerSubject.asObservable();
  disableBtnSubject = new BehaviorSubject(true);
  disableBtn: Observable<boolean> = this.disableBtnSubject.asObservable();

  private env = environment;
  private specialWashId = 8;
  private exteriorWashId = 28;
  pendingTask: any;
  pendingTasks: any[] = [];
  pendingTasksFinish: any[] = [];
  event: any = {};
  incidenceList: any[] = [];
  incidencesDataSource = new MatTableDataSource<any>();
  vehicle: any = {};

  config: any = {
    region: this.env.AWSregion,
    accessKeyId: this.env.AWSaccessKeyId,
    secretAccessKey: this.env.AWSsecretAccessKey,
    bucketName: this.env.AWSbucketName,
  };

  s3: any = new S3({
    accessKeyId: this.config.accessKeyId,
    secretAccessKey: this.config.secretAccessKey,
    region: this.env.AWSregion,
  });

  taskTypes: object[] = [];
  choosenTask: number;
  incidences: Object[] = null;
  noIncidences: boolean;

  displayedColumnsIncidences: string[] = [
    "damage_type",
    "description",
    "severity",
    "state",
  ];

  sub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PendingTasksDialogComponent>,
    public dialogMatRef: MatDialog,
    private taskService: TaskService,
    public dialog: MatDialog,
    private vehicleService: VehicleService,
    public confirmDialog: MatDialog,
    public storageService: StorageService,
    private _cdr: ChangeDetectorRef,
    private incidenceSevice: IncidenceService,
    private budgetPendingTaskService: BudgetPendingTaskService,
    private pendingTasksService: PendingTasksService,
    @Inject(MAT_DIALOG_DATA) public data: IPendingTaskDialog,
    private userService: UserService
  ) { }

  get isActionButton(): boolean {
    return [2, 3, 11, 12].indexOf(this.storageService.getCurrentUser().role_id) > -1;
  }

  ngOnInit(): void {
    this.vehicle = this.data.vehicle || {};
    this.getDataVehicle();
  }

  disabledVehicle(vehicle: any) {
    return this.vehicleService.disabledVehicle(vehicle);
  }
  addComments(vehicle){
    const pendingTasks = vehicle.last_reception?.approved_pending_tasks || [];
    const dialogRef = this.dialogMatRef.open(CommentsComponent, {
      width: "700px",
      height: `${window.innerHeight - 485}px`,
      data: { vehicle: this.vehicle, reception:vehicle.last_reception, pendingTask: pendingTasks[0] , ispendingTask: false},
    });
  }
  getDataVehicle() {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = undefined;
    }
    const endPoints = this.vehicleService.endPoints([], 0, null, [
      "with[],lastReception.pendingTasks.statePendingTask",
      "with[],lastReception.pendingTasks.task.subState.state",
      "with[],lastReception.pendingTasks.incidences",
      "with[],lastReception.pendingTasks.budgetPendingTasks",
      "with[],lastReception.pendingTasks.budgetPendingTasks.stateBudgetPendingTask",
      `plate,${this.vehicle.plate}`
    ]);
    this.sub = endPoints.endpoints[0].subscribe(
      (res: any) => {
        this.sub.unsubscribe();
        if (res.vehicles.data.length === 0) {
          this.onNoClick();
          return
        }
        this.vehicle = res.vehicles.data[0];
        console.log('vehicle', this.vehicle)
        this.setVehicle();
        this.getIncidences();
        this.vehicleService.refresh.next({
          vehicle: this.data.vehicle
        });
      },
      () => {
        this.sub.unsubscribe();
        this.sub = undefined;
      }
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    this.disableBtnSubject.next(false);
    const value: any = this.pendingTasks[event.currentIndex];
    if (value.state_pending_task_id != 2 && !this.automaticTask(value.task_id)) {
      moveItemInArray(this.pendingTasks, event.previousIndex, event.currentIndex);
    }
  }

  getIncidences(paginate = {}): void {
    const event: any = _.assign(
      {
        length: 0,
        pageIndex: 0,
        pageSize: 5,
        previousPageIndex: 0,
      },
      paginate
    );

    this.event = event;
    const params = [
      `vehicleIds[],${this.data.vehicle.id}`,
      "with[],statusDamage",
      "with[],damageType",
      "with[],severityDamage",
      "notNullDamageTypeId,0",
      `page,${this.event.pageIndex + 1}`,
      `per_page,${this.event.pageSize}`,
    ];
    const subs = this.incidenceSevice.getAllDamages(params).subscribe((res) => {
      this.incidenceList = [];
      this.incidencesDataSource = new MatTableDataSource([]);
      this.incidenceList.push(...res["data"]);
      this.incidencesDataSource.data = this.incidenceList;
      this.incidencesDataSource = new MatTableDataSource(this.incidenceList);
      setTimeout(() => {
        this.event.length = res.total;
        this._cdr.detectChanges();
      }, 500);
      this._cdr.detectChanges();
      subs.unsubscribe();
    });
  }

  setLocaleDate(date) {
    return moment.utc(date).local().format("DD/MM/YYYY hh:mm A")
  }

  resolveIncidence(incidence, value) {
    let data = {
      resolved: value,
    };
    this.incidenceSevice.updateIncidence(incidence["id"], data).subscribe(
      (result) => {
        this.getDataVehicle();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  setVehicle() {
    this.pendingTasks = [];
    this.pendingTasksFinish = [];
    const pendingTasks = _.map(
      this.vehicle.last_reception?.pending_tasks || [],
      (x) => {
        x.budget_pending_tasks.map((budget) => {
          budget.file_name = budget.url.split("/").reverse()[0];
          return budget;
        });
        return x;
      }
    );
    this.pendingTasks = _.filter(pendingTasks, (x) => {
      return [3, 4].indexOf(x.state_pending_task_id) === -1
    });
    this.pendingTasks = _.filter(this.pendingTasks, (x) => x.task_id != 77);
    this.pendingTasksFinish = _.filter(pendingTasks, { state_pending_task_id: 3 });
    this._cdr.detectChanges();
  }

  cancelPendingTask(pendingTask) {
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, {
      width: "350px",
      disableClose: true,
      autoFocus: false,
      data: {
        title: "Cancelar tarea",
        message: `¿Está seguro que desea cancelar la tarea en curso seleccionada?`,
        id: pendingTask.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const data = {
          pending_task_id: result.id,
          description: "",
        };

        this.taskService.cancelPendingTasks(data).subscribe(
          (res) => {
            Swal.fire({
              icon: "success",
              title:
                "La tarea pendiente selecciona ha sido cancelada correctamente.",
            });
            this.dialogRef.close();
          },
          (err) => {
            Swal.fire({
              icon: "error",
              title: "Error al cancelar tarea",
              text: "Contacta con el administrador del sitio.",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        );
      }
    });
  }

  get user(): any {
    return this.storageService.getCurrentUser() || {};
  }

  openFile(input_file, pendingTask, event) {
    event.preventDefault();

    Swal.fire({
      title: 'Debe indicar el tipo de presupuesto:',
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Nuevo',
      denyButtonText: 'Modificación',
    }).then((result) => {
      if (result.isConfirmed) {
        this.pendingTask = pendingTask;

        if (input_file) {
          input_file.dataset.budget_type = 0;
          input_file.value = null;
          input_file.id = pendingTask.id;
          input_file.data
          input_file.click();
        }
      } else if (result.isDenied) {
        this.pendingTask = pendingTask;
        if (input_file) {
          input_file.dataset.budget_type = 1;
          input_file.value = null;
          input_file.id = pendingTask.id;
          input_file.click();
        }
      }
    });
  }
  uploadFile(pending_task_id, budget_type, file){
    const keyFile = Math.random().toString(36).substring(2);
    this.s3.upload(
      {
        Key: `${keyFile}.${file.name.split(".").pop()}`,
        Bucket: this.config.bucketName,
        Body: file,
        ACL: "public-read",
      },
      (error, data) => {
        if (error) {
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido un error al cargar la imagen",
            text: "Contacta con el administrador del sitio",
          });
        } else {
          let role_id = this.user.role_id;
          if (this.pendingTask.task.id === 2) {
            role_id = 9;
          }
          if (this.pendingTask.task.id === 3) {
            role_id = 8;
          }
          const budgetPendingTask = {
            pending_task_id: pending_task_id,
            url: data.Location,
            role_id,
            type:budget_type
          };
          this.budgetPendingTaskService
            .createBudgetPendingTask(budgetPendingTask)
            .subscribe(
              (result) => {
                Swal.fire({
                  icon: "success",
                  title: "Presupuesto agregado correctamente",
                });
                this.userService.fetchNonification.next(true);
                const pendingTask = _.find(this.pendingTasks, {
                  id: result.pending_task_id,
                });
                if (!!pendingTask) {
                  result.file_name = result.url.split("/").reverse()[0];
                  pendingTask.budget_pending_tasks.push(result);
                }
              },
              (error) => {
                console.error(error);
              }
            );
        }
      }
    );
  }
  fileChangeEvent(event) {
    let file;
    let extFirstFile = event.target.files[0].name.split('.').pop();
    if (event.target.files.length>1 || (event.target.files.length==1 && extFirstFile.toLowerCase() !== 'pdf')){
      const zip = new JSZip();
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const filePath = `${file.name}`; // Ruta completa dentro del directorio "imagenes"
        zip.file(filePath, file);
      }
      zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
        file = new File([content], 'images.zip', { type: 'application/zip' });
        this.uploadFile(_.toNumber(event.target.id), event.target.dataset.budget_type, file);
      });
    } else {
      file = event.target.files[0];
      this.uploadFile(_.toNumber(event.target.id), event.target.dataset.budget_type, file);
    }
  }

  isBudget(pendingTask): boolean {
    return (
      (pendingTask.state_pending_task_id !== 3 && pendingTask.task.id == 2) ||
      pendingTask.state_pending_task_id == 1 ||
      pendingTask.state_pending_task_id == 2
    );
  }

  confirmOrder() {
    let pending_tasks: any[] = [];
    this.showSpinnerSubject.next(true);
    this.pendingTasks.map((item: any, index) => {
      pending_tasks.push({
        id: item.id,
        order: index + 1,
        state_pending_task_id:
          index === 0 ? (item.state_pending_task_id === 2 ? 2 : 1) : null,
        datetime_pending:
          item.state_pending_task_id === 2 ? item.datetime_pending : null,
        datetime_start:
          item.state_pending_task_id === 2 ? item.datetime_start : null,
        datetime_finish: null,
      });
    });
    this.taskService
      .confirmOrderPendingTasks({
        vehicle_id: this.vehicle.id,
        pending_tasks,
      })
      .subscribe(
        (data) => {
          this.showSpinnerSubject.next(false);
          this.disableBtnSubject.next(true);
          Swal.fire({
            icon: "success",
            title: "El orden de las tareas ha sido modificado correctamente.",
          });
          this.getDataVehicle();
        },
        () => {
          this.showSpinnerSubject.next(false);
        }
      );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectTask(task: object): void {
    this.choosenTask = task["id"];
  }

  onChangeSpecialWash(pendingTask): void {
    this.taskService.getById(this.specialWashId).subscribe(
      (result) => {
        let data = { task_id: result.id, duration: result.duration };
        this.taskService.updatePendingTasks(pendingTask.id, data).subscribe(
          (res) => {
            this.pendingTasks = this.pendingTasks.filter(x=>x.state_pending_task_id !== 3).map((x: any) => {
              if (x.task.id == pendingTask.task_id) {
                x.task.id = result.id;
                x.task.name = result.name;
                return x;
              } else return x;
            });
          },
          (err) => {
            console.error(err);
          }
        );
      },
      (err) => {
        console.error(err);
      }
    );
    this.GetPendinkTaskUpdateModal();
  }

  createNewTask() {
    const dialogRef = this.dialog.open(ModalSimpleTaskComponent, {
      width: "450px",
      data: {
        vehicle: this.vehicle,
        title: `Nueva tarea para: ${this.vehicle.plate}`,
      },
    });
    const sub = dialogRef.componentInstance.change.subscribe((data) => {
      this.getDataVehicle();
      sub.unsubscribe();
    })
  }
  automaticTask(task_id) {
    return [37, 39, 38, 53, 61, 64, 68, 73, 75, 77].indexOf(task_id) !== -1;
  }


  GetPendinkTaskUpdateModal() {
    const arrayParams = [
      "with[],lastReception.lastQuestionnaire",
      "with[],lastReception.pendingTasks.task",
      "with[],lastReception.pendingTasks.statePendingTask",
      "with[],lastReception.pendingTasks.budgetPendingTasks.stateBudgetPendingTask",
      "with[],lastReception.pendingTasks.Incidences",
      "with[],vehicleModel.brand",
      "with[],square.street.zone",
      "with[],campa",
    ];
    this.vehicleService
      .byIdWithParams(this.data["vehicle"]["id"], arrayParams)
      .subscribe(
        (res) => {
          this.pendingTasks = res["last_reception"]["pending_tasks"].filter(
            (pendingTask) => pendingTask["approved"] === 1
          );
          this.showSpinnerSubject.next(false);
          this.data.vehicle.pending_tasks = this.pendingTasks;
          this.vehicleService.refresh.next({
            vehicle: this.data.vehicle,
          });
        },
        (err) => console.error(err)
      );
  }

  startPendingTask(pendingTask) {
    if (pendingTask.budget_pending_tasks.filter(p=>p.state_budget_pending_task_id == 1).length>0){
      Swal.fire({
        icon: "error",
        title: "No puede iniciar la tarea",
        text: "La tarea seleccionada tiene presupuestos pendientes por aprobar!",
      });
      return;
    }

    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, {
      width: "350px",
      disableClose: true,
      autoFocus: false,
      data: {
        title: "Iniciar tarea",
        message: `¿Está seguro que desea iniciar la tarea?`,
        id: pendingTask.id,
      },
    });
    dialogRef.afterClosed().subscribe((confirm: Boolean) => {
      if (confirm) {
        this.pendingTasksService.startPendingTask({ pending_task_id: pendingTask.id }).subscribe((res) => {
          this.getDataVehicle();
        }, (error) => {
          console.error(error);
        });
      }
    });
  }

  openLocation(pendingTask, runFinish = true) {
    const dialogRef = this.dialog.open(LocationDialogComponent, {
      width: "500px",
      height: "60vh",
      disableClose: true,
      autoFocus: false,
      data: {
        vehicle_id: pendingTask.vehicle_id
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        if (runFinish) {
          this.runFiniskTask(pendingTask)
        } else {
          this.getDataVehicle();
        }
      }
    });
  }

  finishPendingTask(pendingTask) {
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, {
      width: "350px",
      disableClose: true,
      autoFocus: false,
      data: {
        title: "Finalizar tarea",
        message: `¿Está seguro que desea finalizar la tarea?`,
        id: pendingTask.id,
      },
    });
    dialogRef.afterClosed().subscribe((confirm: Boolean) => {
      if (confirm) {
        this.openLocation(pendingTask);
      }
    });
  }

  runFiniskTask(pendingTask) {
    this.pendingTasksService.finishPendingTask({ pending_task_id: pendingTask.id }).subscribe((result) => {
      this.getDataVehicle();
    }, (error) => {
      console.error(error);
    });
  }

  pausePendingTask(pendingTask) {
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, {
      width: "350px",
      disableClose: true,
      autoFocus: false,
      data: {
        title: "Pausar tarea",
        message: `¿Está seguro que desea pausar la tarea?`,
        id: pendingTask.id,
      },
    });
    dialogRef.afterClosed().subscribe(async (confirm: Boolean) => {
      if (confirm) {
        const { value: text } = await Swal.fire({
          input: "textarea",
          inputLabel: "Añadir Motivo",
          inputPlaceholder: "",
          inputAttributes: {
            "aria-label": "",
          },
          showCancelButton: true,
          cancelButtonText: "Cerrar",
          inputValidator: (value) => {
            if (!value) {
              return "El motivo es Obligatorio";
            }
          },
        });
        if (text) {
          const data = {
            state_pending_task_id: 1,
            comment_state: text,
          };
          this.pendingTasksService
            .updatePendingTask(data, pendingTask.id)
            .subscribe(
              (result) => {
                this.getDataVehicle();
              },
              (error) => {
                console.error(error);
              }
            );
        } else {
          Swal.fire("Para pausar la tarea, debe agregar el motivo");
        }
      }
    });
  }

  deletePendingTask(pendingTask) {
    const dialogRef = this.confirmDialog.open(ConfirmationDialogComponent, {
      width: "350px",
      disableClose: true,
      autoFocus: false,
      data: {
        title: "Eliminar tarea",
        message: `¿Está seguro que desea eliminar la tarea?`,
        id: pendingTask.id,
      },
    });
    dialogRef.afterClosed().subscribe(async (confirm: Boolean) => {
      if (confirm) {
        const { value: text } = await Swal.fire({
          input: "textarea",
          inputLabel: "Añadir Motivo",
          inputPlaceholder: "",
          inputAttributes: {
            "aria-label": "",
          },
          showCancelButton: true,
          cancelButtonText: "Cerrar",
          inputValidator: (value) => {
            if (!value) {
              return "El motivo es Obligatorio";
            }
          },
        });

        if (text) {
          const body = {
            approved: 0,
            comment_state: text,
            order: -1
          };
          this.pendingTasksService
            .updatePendingTask(body, pendingTask.id)
            .subscribe(
              (result) => {
                this.getDataVehicle();
              },
              (error) => {
                console.error(error);
              }
            );
        } else {
          Swal.fire("Para cancelar la tarea, debe agregar el motivo");
        }
      }
    });
  }

  getUbication(x) {
    return x && x.square
      ? `${x.square.street.zone.name}, ${x.square.street.name}, ${x.square.name}`
      : "No ubicado";
  }
}
