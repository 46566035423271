<div class="container py-5">
  <div class="row">
    <mat-card style="width: 100%;">
      <mat-card-header>
        <div class="col-8">
          <p class="greenLink"><a [routerLink]="'/validation'">Inicio </a> >
            <a [routerLink]="['/validation']">Validación </a> > {{ isRequestDefleeting == '0' ?  'Pendiente validación' : 'En Estudio'}}
            > {{vehicle?.plate }} {{ vehicle?.brands}}
          </p>
          <p class="greenLink"> Origen del vehículo: {{vehicle?.type_model_order?.name }}
          </p>
          <p class="greenLink"> Recepción: {{vehicle?.last_reception?.id }}
          </p>
          <p class="greenLink"> Canal: {{vehicle?.type_model_order?.name }}
          </p>
          <p class="greenLink"> Sub-Estado: {{vehicle?.sub_state?.name }}
          </p>
        </div>
        <div class="col-4">
          <h2 *ngIf="isRequestDefleeting == '0'" class="ap-h">{{ isUnapprovedGroup ? 'Pendiente' : 'Aprobado' }}</h2>
          <h2 *ngIf="isRequestDefleeting == '1' && vehicle?.review_datetime" class="ap-h" style="color: #e91e63 !important;">Revisado</h2>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="col-12">
          <mat-tab-group mat-align-tabs="center" #tabGroup>
            <mat-tab label="Lista de Verificación">

              <div class="container mt-3">
                <div class="row" style="overflow: hidden;">
                  <div class="col-12">
                    <div class="mat-elevation-z8 mb-4">
                      <table [ngClass]="{noEvent: !isUnapprovedGroup && isRequestDefleeting == '0'}" mat-table matTableExporter
                        #exporter="matTableExporter" style="border: 1px solid;border-color: beige;"
                        [dataSource]="dataSourceCheckList" matSort>
                        <ng-container matColumnDef="question">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Pregunta</th>
                          <td mat-cell *matCellDef="let element"> {{element.question.question}} <p *ngIf="element?.pending_task?.state_pending_task_id === 3" style="font-size:10px;color:red; font-weight:700;">TAREA REALIZADA <br/>{{ setDate(element?.pending_task?.datetime_finish) }}</p></td>
                        </ng-container>

                        <ng-container matColumnDef="description">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción</th>
                          <td mat-cell *matCellDef="let element" class="">
                            <div *ngIf="element.question.id !== 5 || element.question.id !== 6" class="">
                              {{element.description || '-'}}
                            </div>
                            <!-- Example single danger button -->
                            <div *ngIf="element.question.id == 5">
                              <mat-form-field appearance="fill">
                                <mat-label>Selecciona una limpieza</mat-label>
                                <select #select (change)="updateSelect($event.target.value, element , select)"
                                  matNativeControl [value]="element.description">
                                  <option value="Leve">Leve</option>
                                  <option value="Intermedia">Intermedia</option>
                                  <option value="Severa">Severa</option>
                                  <option value="Especial">Especial</option>
                                </select>
                              </mat-form-field>
                            </div>
                            <!-- Example single danger button -->
                            <div *ngIf="element.question.id == 6">
                              <mat-form-field appearance="fill">
                                <mat-label>Paneles rotulados</mat-label>
                                <mat-select #select2 (selectionChange)="updateSelect($event.value, element , select2)"
                                  matNativeControl [value]="element.task_id">
                                  <mat-option *ngFor="let item of rotulationList" [value]="item.task_id">{{item.value}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="validation">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="text-center">No/Si</div>
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div class="">
                              <mat-slide-toggle color="primary" checked="{{element.response === 1 ? true : false}}"
                                (change)="updateApproved($event, element)">
                              </mat-slide-toggle>
                            </div>

                          </td>
                        </ng-container>
                        <ng-container matColumnDef="instruction">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="text-center "> Instrucciones </div>
                          </th>
                          <td mat-cell *matCellDef="let element">
                            <div (click)="element.addcoment = true"
                              *ngIf="!element.addcoment && element?.description_response">
                              <p style="max-width: 200px;word-wrap: break-word;">{{element.description_response }}</p>
                            </div>
                            <div *ngIf="!element?.description_response" class="add-comment"
                              (click)="ViewAddComent(element.id)">
                              Añadir comentario
                            </div>
                            <div *ngIf="element.addcoment">
                              <textarea type="text" class="form-control" [(ngModel)]="element.description_response"
                                style="resize: none;"></textarea>
                              <div class="d-flex justify-content-around m-1">
                                <div class="btn btn-primary btn-sm mr-2 " (click)="SaveUpdateComent(element)">Aceptar
                                </div>
                                <div class="btn btn-secondary btn-sm" (click)="viewComentFalse(element.id)">Cancelar
                                </div>
                              </div>
                            </div>

                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedCheckListColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedCheckListColumns;" [ngClass]="{noEvent: row?.pending_task?.state_pending_task_id === 3}"></tr>
                      </table>
                      <mat-card *ngIf="isLoading | async"
                        style="display: flex; justify-content: center; align-items: center">
                        <mat-progress-spinner color="primary" mode="indeterminate">
                        </mat-progress-spinner>
                      </mat-card>
                      <div *ngIf="!(isLoading | async)">
                        <div *ngIf="noCheckListData | async" class="no-records mat-typography">
                          <h3>No hay registros</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Rol tecnico no puede realizar acciones user.role_id = 10 -->
                <div *ngIf=" user.role_id == 7 && vehicle" class="d-flex justify-content-end">
                  <button  *ngIf="isRequestDefleeting == '1' && !vehicle?.review_datetime" mat-raised-button class="btn-green" style="margin-right: 20px; width: 180px;" (click)="requestDefleeting(vehicle)">Revisar</button>

                  <button *ngIf="isUnapprovedGroup && isRequestDefleeting == '0'" [disabled]="vehicle?.sub_state_id === 8" mat-raised-button class="btn-blue"
                  #btnValidate style="width: 150px;" (click)="validateCheck(btnValidate)">Validar</button>

                </div>
              </div>
            </mat-tab>
            <mat-tab label="Fotos">
              <div class="container" style="display: flex; justify-content: center;">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group-inline-seg1 modalContainer">
                      <div class="modal-error-format alert alert-danger" role="alert" style="display: none;">
                        <div class="modal-header">
                          <h3>Atención!</h3>
                        </div>
                        <div class="modal-body">
                          <span>{{this.textError}}</span>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-danger" (click)="ocultarAlertaFormato()">Cerrar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div (click)="openPicture(picture.url)" class="col mt-3" *ngFor="let picture of vehiclePictures">
                      <a class="btn btn-greenCar mb-3" role="button" data-bs-toggle="button">
                        <img src="{{picture.url}}" class="img max-width-image ">
                        <br>{{ picture.place ? picture.place : picture.created_at | date:"MM/dd/yy, HH:mm:ss" }}
                      </a>

                    </div>

                    <div *ngIf="vehiclePictures.length === 0"
                      style="display: block; text-align: center;transform: translate3d(5px, 10px, 26px);">
                      <img class="m-3" src="..\..\assets\nophoto.png" style="width:200px; "><br>
                      <h3 *ngIf="vehiclePictures.length === 0"> No hay fotos disponibles</h3>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-card-content>


    </mat-card>
    <div *ngIf="showSpinnerSubject | async" class="spinner-global">
      <mat-progress-spinner color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </div>

</div>
