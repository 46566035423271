import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BrandService {
  env = environment;
  currentTab: number;
  token: string;
  reqHeader = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
  brandList: any[] = []
  private apiUrl = this.env.api_url;

  constructor(private http: HttpClient) {}

  getAll(): Observable<any> {
    return this.http.get(this.apiUrl + "/brands");
  }

  saveBrandVehicle(brand: string): Observable<any> {
    const bodyBrand = {
      name: brand,
    };
    return this.http.post<any>(this.apiUrl + "/brands", bodyBrand);
  }

  updateBrand(name: string, id: number) {
    const bodyBrand = {
      name,
    };
    return this.http.put(this.apiUrl + `/brands/${id}`, bodyBrand);
  }

  deleteBrand(id: number){
    return this.http.delete(this.apiUrl + `/brands/${id}`);
  }
}
