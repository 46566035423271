import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {SKIP_INTERCEPTOR_HEADER} from '../core/config/consts';
import * as _ from 'lodash';

const DEFLEETING = 8;
const REQUEST_DEFLEETING = 26;
const TECHNICIAN_ALD =  12;
const INVARAT =  7;
const ADMIN =  1;

@Injectable({
  providedIn: 'root'
})

export class UserService {

  env = environment;
  identity: object;
  reqHeader = new HttpHeaders().set('Content-Type', 'application/json').set(SKIP_INTERCEPTOR_HEADER, 'DoNotIntercept');

  fetchNonification: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private cookies: CookieService
  ) {
  }

  private apiUrl = this.env.api_url;
  public token: string;

  userLogin(data): Observable<any> {
    const params = JSON.stringify(data);
    return this.http.post(this.apiUrl + '/login', params);
  }

  setToken(token: string): void {
    this.cookies.set('Authorization', token);
  }

  getToken(): string {
    return this.cookies.get('Authorization');
  }

  removeToken(): void {
    return this.cookies.delete('Authorization');
  }

  getIdentity(): any {
    const identity = JSON.parse(sessionStorage.getItem('identity'));
    if (identity) {
      this.identity = identity;
    } else {
      this.identity = null;
    }
    return this.identity;
  }

  getUserLogged(): Object {
    const identity = JSON.parse(localStorage.getItem('currentUser'));
    return identity;
  }

  getAllUsers(): any {
    let params = new HttpParams();
    params = params.append('with[]', 'company');
    params = params.append('with[]', 'campas');
    params = params.append('with[]', 'role');
    return this.http.get<any>(this.apiUrl + '/users/getall', {params});
  }

  getById(id: number): any {
    let params = new HttpParams();
    params = params.append('with[]', 'company');
    params = params.append('with[]', 'campas');
    return this.http.get<any>(this.apiUrl + '/users/' + id, {params});
  }

  // tslint:disable-next-line:variable-name
  getByRoleType(role_id, data): any {
    let params = new HttpParams();
    params = params.append('with[]', 'company');
    params = params.append('with[]', 'campas');
    return this.http.post<any>(this.apiUrl + '/users/role/' + role_id, data, {params});
  }

  createUser(data: any): any {
    return this.http.post<any>(this.apiUrl + '/users', data);
  }

  updateUser(id, data): any {
    return this.http.put<any>(this.apiUrl + `/users/update/${id}`, data);
  }

  deleteUser(id): any {
    return this.http.delete(this.apiUrl + `/users/delete/${id}`);
  }

  deleteUserCampas(data): any {
    return this.http.post<any>(this.apiUrl + '/users/delete-campa', data);
  }

  updateStatus(data: any): any {
    this.token = this.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set('Authorization', this.token);
    return this.http.post<any>(this.apiUrl + '/users/status', params, {headers});
  }

  createImage(data: any): any {
    this.token = this.getToken();
    let params = JSON.stringify(data);
    const headers = new HttpHeaders().set('Authorization', this.token);
    return this.http.post<any>(this.apiUrl + '/users/pictures', params, {headers});
  }

  assignCampas(data: any): any {
    return this.http.post<any>(this.apiUrl + '/users/assign-campa', data);
  }

  getAllUsersType(): Observable<any> {
    let params = new HttpParams();
    // params = params.append('with[]', 'company');
    return this.http.get(this.apiUrl + '/roles/getall', {params});
  }

  verifyEmail(data): any {
    return this.http.post(this.apiUrl + '/password/send-code', data, {headers: this.reqHeader});
  }

  resetPassword(data: any): any {
    return this.http.post<any>(this.apiUrl + '/password/reset', data, {headers: this.reqHeader});
  }

  getTypeUserApp() {
    return this.http.get<any>(this.apiUrl + '/type-user-app');
  }

  getNotifications(arrayParams: any = []): Observable<any> {
    let params = this.getParams(arrayParams);
    return this.http.get(this.apiUrl + '/users/notifications', {params});
  }

  getMessages(arrayParams: any = []): Observable<any> {
    let params = this.getParams(arrayParams);
    return this.http.get(this.apiUrl + '/users/messages', {params});
  }

  readMessage(id, data={}): any {
    return this.http.post<any>(this.apiUrl + '/users/messages/' + id, data);
  }
  storeMessage(data): any {
    return this.http.post<any>(this.apiUrl + '/users/messages', data);
  }


  private getParams(arrayParams) {
    let params = new HttpParams();
    arrayParams.map((element) => {
      let param = element.split(",");
      params = params.append(param[0].trim(), param[1].trim());
    });
    return params;
  }

  isDefleeting(rol, subState) {
    let success = false;

    if (subState != REQUEST_DEFLEETING && _.includes([ADMIN, INVARAT, TECHNICIAN_ALD], rol)) {
      success = true;
    }

    if (subState === REQUEST_DEFLEETING  && _.includes([ADMIN, TECHNICIAN_ALD], rol)) {
      success = true;
    }

    if (subState === DEFLEETING  && _.includes([ADMIN, TECHNICIAN_ALD], rol)) {
      success = true;
    }

    return success;
  }
}
