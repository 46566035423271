import { Component, OnInit } from '@angular/core';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.css']
})
export class AuthorizationComponent implements OnInit {

  workshopMechanic: number = 0;
  workshopChapa: number = 0;
  itv: number = 0;

  colorWorkshopMechanic: string = 'red';
  colorWorkshopChapa: string = 'red';
  colorItv: string = 'red';


  constructor(
    public taskService: TaskService
  ) { }

  ngOnInit(): void {
    this.searchValuesGlobals();
  }

  searchValuesGlobals(){
    this.taskService.getPendingTasksInWorkshopMechanic().subscribe(
      result =>{
        this.workshopMechanic = result['data'].length;
        if(result['data'].length > 0)
          this.colorWorkshopMechanic = 'red';
        else
          this.colorWorkshopMechanic = 'green';
      },
      error => {
        console.error(error);
      }
    );

    this.taskService.getPendingTasksInWorkshopChapa().subscribe(
      result => {
        this.workshopChapa = result['data'].length;
        if(result['data'].length > 0)
          this.colorWorkshopChapa = 'red';
        else
          this.colorWorkshopChapa = 'green'
      },
      error => {
        console.error(error);
      }
    );

    this.taskService.getPendingTasksInWorkshopItv().subscribe(
      result =>{
        this.itv = result['data'].length;
        if(result['data'].length > 0)
          this.colorItv = 'red';
        else
          this.colorItv = 'green';
      },
      error => {
        console.error(error);
      }
    )
  }

}
