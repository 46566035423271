import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IInitTaskData} from '../../../interfaces/dialog.interface';

@Component({
  selector: 'app-init-tasks-dialog',
  templateUrl: './init-tasks-dialog.component.html',
  styleUrls: ['./init-tasks-dialog.component.css']
})
export class InitTasksDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InitTasksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IInitTaskData
  ) {
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
