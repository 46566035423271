import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class StreetService {
  private _apiUrl = environment.api_url;
  constructor(private http: HttpClient) {}

  getStreets(zones: any[] = [], zoneId: number = null): Observable<any> {
    let params = new HttpParams();
    (zoneId ? [{id: zoneId}] : zones).forEach((zone) => {
      params = params.append("zoneIds[]", String(zone.id));
    });
    params = params.append("with[]", "zone");
    return this.http.get(this._apiUrl + "/streets", { params });
  }
  
  getStreetsWithParams(arrayParams = []): Observable<any> {
    let params = this.getParams(arrayParams);
    params = params.append("with[]", "zone");
    return this.http.get(this._apiUrl + "/streets", { params });
  }

  saveStreet(name: string, zoneId: number): Observable<any> {
    const bodyStreet = {
      zone_id: zoneId,
      name,
    };
    return this.http.post(this._apiUrl + "/streets", bodyStreet);
  }

  updateStreet(name: string, zoneId: number, id: number): Observable<any> {
    const bodyStreet = {
      name,
      zone_id: zoneId,
    };
    return this.http.put(this._apiUrl + `/streets/${id}`, bodyStreet);
  }

  private getParams(arrayParams) {
    let params = new HttpParams();
    arrayParams.map((element) => {
      let param = element.split(",");
      params = params.append(param[0], param[1]);
    });
    return params;
  }
}
