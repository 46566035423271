<div class="container py-5">
  <div class="row">
    <mat-card style="width: 100%">
      <mat-card-header>
        <div class="col-8">
          <p class="greenLink">
            <a [routerLink]="'/dashboard'">Inicio </a> >
            <a>Movimientos </a>
          </p>
        </div>
      </mat-card-header>
      <mat-card-content>
        <mat-tab-group mat-align-tabs="center">
          <mat-tab label="Movimientos">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <button (click)="applyfilter = !applyfilter" class="btn btn-primary me-md-2" type="button">
                <mat-icon style="font-size: 20px !important">filter_list</mat-icon>
                Filtrar
              </button>
              <button (click)="importAsXlsx(1)" class="btn btn-primary" type="button">
                <mat-icon style="font-size: 20px !important">file_download</mat-icon>
                Excel
              </button>
            </div>
            <br>
            <form *ngIf="applyfilter" [formGroup]="filterFormTransfers">
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button class="btn btn-primary me-md-2" style="margin-left: 5px" (click)="cleanFilters()">
                  <mat-icon style="font-size: 20px !important">delete_outline</mat-icon>
                  Limpiar
                </button>
              </div>
              <div class="row p-3">
                <div class="col">
                  <div class="clearfix">
                    <div class="float-md-left text-center mx-auto w-100">
                      <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                        style="padding-bottom: 0px !important">
                        <mat-label>Filtrar por matrícula</mat-label>
                        <input formControlName="plate" oninput="this.value = this.value.toUpperCase().trim()" matInput
                          autocomplete="off" (keyup)="getTransfers()" />
                      </mat-form-field>
                    </div>
                  </div>

                  <!-- <div class="clearfix">
                    <div class="float-md-left text-center mx-auto w-100">
                      <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                        style="padding-bottom: 0px !important">
                        <mat-label>Filtrar por fecha</mat-label>
                        <input (dateChange)="onDatesChange(ref.value)" matInput #ref formControlName="date"
                          [matDatepicker]="picker" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div> -->
                  <div class="clearfix">
                    <div class="float-md-left text-center mx-auto w-100">
                      <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0" style="padding-bottom: 0px !important">
                        <mat-label>Filtrar desde fecha</mat-label>
                        <input (dateChange)="onDateChange('desde', desdeRef.value)" matInput #desdeRef formControlName="dateDesde" [matDatepicker]="desdePicker" />
                        <mat-datepicker-toggle matSuffix [for]="desdePicker"></mat-datepicker-toggle>
                        <mat-datepicker #desdePicker></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  
                  <div class="clearfix">
                    <div class="float-md-left text-center mx-auto w-100">
                      <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0" style="padding-bottom: 0px !important">
                        <mat-label>Filtrar hasta fecha</mat-label>
                        <input (dateChange)="onDateChange('hasta', hastaRef.value)" matInput #hastaRef formControlName="dateHasta" [matDatepicker]="hastaPicker" />
                        <mat-datepicker-toggle matSuffix [for]="hastaPicker"></mat-datepicker-toggle>
                        <mat-datepicker #hastaPicker></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="clearfix">
                    <div class="float-md-left text-center mx-auto w-100">
                      <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                        style="padding-bottom: 0px !important">
                        <mat-label>Filtrar por Zona</mat-label>
                        <mat-select formControlName="zones" placeholder="Filtrar por Zona" [multiple]="true"
                          #multiSelectBrand (selectionChange)="getTransfers()">
                          <mat-option *ngFor="let z of zoneList" [value]="z.id">
                            {{ z.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="clearfix">
                    <div class="float-md-left text-center mx-auto w-100">
                      <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                        style="padding-bottom: 0px !important">
                        <mat-label>Filtrar por Área</mat-label>
                        <mat-select formControlName="streets" placeholder="Filtrar por Área" [multiple]="true"
                          #multiSelectBrand (selectionChange)="getTransfers()">
                          <mat-option *ngFor="let s of streetList" [value]="s.id">
                            {{ s.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="clearfix">
                    <div class="float-md-left text-center mx-auto w-100">
                      <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                        style="padding-bottom: 0px !important">
                        <mat-label>Filtrar por Plazas</mat-label>
                        <mat-select formControlName="squares" placeholder="Filtrar por Plaza" [multiple]="true"
                          #multiSelectBrand (selectionChange)="getTransfers()">
                          <mat-option *ngFor="let s of squareList" [value]="s.id">
                            {{ s.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="clearfix">
                    <div class="float-md-left text-center mx-auto w-100">
                      <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                        style="padding-bottom: 0px !important">
                        <mat-label>Filtrar por Operador</mat-label>
                        <mat-select formControlName="users" placeholder="Filtrar por Plaza" [multiple]="true"
                          #multiSelectBrand (selectionChange)="getTransfers()">
                          <mat-option *ngFor="let u of usersList" [value]="u.id">
                            {{ u.name }} - {{ u.email }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <table #table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="transfersDataSource"
              class="mat-elevation-z8 table mb-0" matSort #sort="matSort" (matSortChange)="sortData($event)">
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.date }}
                </td>
              </ng-container>

              <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Hora</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.time }}
                </td>
              </ng-container>

              <ng-container matColumnDef="vehicle.plate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Matrícula</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.vehicle ? element.vehicle.plate : "-" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="vehicle.vehicle_model.brand.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Marca</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.vehicle
                  ? element.vehicle.vehicle_model?.brand?.name
                  : "-"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="user.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Operario</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.user?.name || "-" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="vehicle.vehicle_model.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Modelo</th>
                <td mat-cell *matCellDef="let element">
                  {{
                  element.vehicle ? element.vehicle.vehicle_model?.name : "-"
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="square.street.zone.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Zona</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.square ? element.square.street.zone.name : "-" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="square.street.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Área</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.square ? element.square.street.name : "-" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="square.name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Plaza</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.square ? element.square.name : "-" }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="transfersCampaDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: transfersCampaDisplayedColumns"></tr>
            </table>
            <div *ngIf="!(isLoadingDataTransfers | async)">
              <div *ngIf="noData | async" class="no-records mat-typography">
                <h3>No hay movimientos disponibles</h3>
              </div>
            </div>
            <mat-card *ngIf="isLoadingDataTransfers | async" style="
                display: flex;
                justify-content: center;
                align-items: center;
              " class="spinner loading-excel">
              <mat-progress-spinner color="primary" mode="indeterminate">
              </mat-progress-spinner>
            </mat-card>
            <mat-paginator [length]="event.length || 0" [pageSize]="event.pageSize" [pageSizeOptions]="[
                5,
                10,
                15,
                100
              ]" class="m-auto mat-elevation-z8" (page)="getTransfers($event)">
            </mat-paginator>
          </mat-tab>
          <mat-tab label="Localización ">
            <div class="container mt-4">
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button class="btn btn-primary me-md-2" type="button" (click)="importAsXlsx(2)">
                  <mat-icon style="font-size: 20px !important">file_download</mat-icon>
                  Excel
                </button>
              </div>
              <div class="row header-custom">
                <div class="col-5">
                  <div class="row">
                    <div class="col-9">
                      <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Zona</mat-label>
                        <mat-select [multiple]="true" [(ngModel)]="zone_ids" name="name"
                          (selectionChange)="changeSelect('zone')">
                          <mat-option *ngFor="let item of zoneList" [value]="item.id">{{ item.name }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!-- <div class="col-3">
                        <button *ngIf="zone_id" (click)="edit(groupZones[zone_id], 0)" mat-mini-fab class="btn-green">
                          <mat-icon>edit</mat-icon>
                        </button>
                      </div> -->
                  </div>
                </div>
                <div class="col-5" *ngIf="zone_ids.length">
                  <div class="row">
                    <div class="col-9">
                      <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Área</mat-label>
                        <mat-select [multiple]="true" [(ngModel)]="street_ids" name="name"
                          (selectionChange)="changeSelect('street')">
                          <mat-option *ngFor="let item of streetList" [value]="item.id">{{ item.name }}
                            ({{item.zone.name}})
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!-- <div class="col-3">
                        <button *ngIf="street_id" (click)="edit(street, 1)" mat-mini-fab class="btn-green">
                          <mat-icon>edit</mat-icon>
                        </button>
                      </div> -->
                  </div>
                </div>
                <!-- <div class="col-2" style="text-align: end;">
                    <button [matMenuTriggerFor]="menu" mat-mini-fab class="btn-green">
                      <mat-icon>add</mat-icon>
                    </button>
                  </div> -->
                <!-- <mat-menu #menu="matMenu" xPosition="before">
                    <button (click)="openModal(1)" mat-menu-item>
                      <mat-icon class="btn-gree">add</mat-icon>Zona
                    </button>
                    <button (click)="openModal(2)" mat-menu-item>
                      <mat-icon>add</mat-icon>Área
                    </button>
                    <button (click)="openModal(3)" mat-menu-item>
                      <mat-icon>add</mat-icon>Plaza
                    </button>
                  </mat-menu> -->
              </div>
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort #sort2="matSort"
                (matSortChange)="sortData2($event)">
                <ng-container matColumnDef="street.zone.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Zona</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.street.zone.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="street.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Área</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.street.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Plaza</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehículo</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle ? element.vehicle.plate : "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef>Editar</th>
                  <td mat-cell *matCellDef="let element">
                    <a *ngIf="element.vehicle !== null; else vehicle" style="cursor: pointer"
                      (click)="openLocation(element)">
                      <mat-icon style="color: #03989e">edit</mat-icon>
                    </a>

                    <a *ngIf="element.vehicle !== null" style="cursor: pointer" (click)="freeSquare(element)">
                      <mat-icon style="color: #03989e">delete</mat-icon>
                    </a>

                    <ng-template #vehicle>
                      <mat-form-field class="mr-4 pt-2">
                        <mat-select [formControl]="plateMultiCtrl" placeholder="Asignar matrícula" [multiple]="false"
                          (selectionChange)="plateChange($event)" #multiSelectPlate>
                          <mat-option>
                            <ngx-mat-select-search [showToggleAllCheckbox]="true"
                              [toggleAllCheckboxIndeterminate]="false" [toggleAllCheckboxChecked]="true"
                              [formControl]="plateFilterCtrl" placeholderLabel="Buscar matrícula...."
                              [toogleAllCheckboxTooltipPosition]="'left'"
                              [toggleAllCheckboxTooltipMessage]="'Seleccionar todas'"
                              noEntriesFoundLabel="no se encontraron matrícula coincidentes"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="let item of filteredPlate | async" [value]="item">
                            {{ item.plate }}
                          </mat-option>
                        </mat-select>
                        <!-- <mat-icon style="position: absolute;" class="ml-2" aria-hidden="false" aria-label="Example home icon">search -->
                        <!-- </mat-icon> -->
                      </mat-form-field>
                    </ng-template>

                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="squareDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: squareDisplayedColumns"></tr>
              </table>
              <div *ngIf="!(isLoadingDataLocations | async)">
                <div *ngIf="noData | async" class="no-records mat-typography">
                  <h3>No hay registro disponibles</h3>
                </div>
              </div>
              <mat-card *ngIf="isLoadingDataLocations | async" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " class="spinner loading-excel">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
              </mat-card>
              <mat-paginator [length]="event2.length || 0" [pageSize]="event2.pageSize" [pageSizeOptions]="[
              5,
              10,
              15,
              100
            ]" class="m-auto mat-elevation-z8" (page)="getList($event)">
              </mat-paginator>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
</div>
