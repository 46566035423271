export const config = {
    prod: {
        api_url: 'https://api.focus.grupomobius.com/api'
    },
    demo: {
        api_url: 'https://demo.api.focus.grupomobius.com/api'
    },
    local: {
        api_url: 'http://localhost:8000/api'
    },
    test: {
        api_url: 'http://api.focus.test/api'
    },
    mirror: {
        api_url: 'https://api.ald.focus.grupomobius.com/api'
    },
    dev: {
        api_url: 'https://dev.api.focus.grupomobius.com/api'
    }
}
