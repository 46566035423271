import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {LoginObject} from './login-object.model';
import {Session} from '../../core/models/session.model';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import { navegateMain } from '@core/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  reqHeader = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) {
  }

  get navegateMain() {
    return navegateMain;
  }

  env = environment;
  private apiUrl = this.env.api_url;

  // @ts-ignore
  private static extractData(res: HttpResponse) {
    return res;
  }

  private static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
    }
    // Return an observable with a user-facing error message.
    return throwError(
      error);
  }

  login(loginObj: LoginObject): Observable<Session> {
    return this.http.post<Session>(this.apiUrl + '/auth/signin', loginObj, {headers: this.reqHeader}).pipe(
      map(res => AuthenticationService.extractData(res)),
      catchError(AuthenticationService.handleError)
    );
  }

  logout(): Observable<boolean> {
    return this.http.post(this.apiUrl + '/logout', {}).pipe(
      map(res => AuthenticationService.extractData(res)),
      catchError(AuthenticationService.handleError)
    );
  }
}
