import * as _ from "lodash";
import { Subject } from "rxjs";
const isChart = false;
export const navegateMain = isChart ? '/dashboard-statistic' : '/dashboard';
const hiddendSetting = true;
export class Item {
  role_id;
  company_id;
  workshop_id;
  email;
  space: any = {
    type: "space",
    height: "50px",
  };
  constructor(role_id, company_id, workshop_id, email) {
    this.role_id = role_id;
    this.company_id = company_id;
    this.workshop_id = workshop_id;
    this.email = email;
    console.info(
      JSON.stringify(
        {
          company_id: this.company_id,
          role_id: this.role_id,
          workshop_id: this.workshop_id,
        },
        null,
        4
      )
    );
  }
  get dashboard() {
    return {
      type: "item",
      matTooltip: "Resumen",
      navigateTo: "/dashboard",
      icon: "assignment",
      label: "Resumen",
    };
  }
  get dashboardRemarketing() {
    return {
      type: "item",
      matTooltip: "Resumen",
      navigateTo: "/dashboard-remarketing",
      icon: "home",
      label: "Resumen",
    };
  }

  get ansInvarat() {
    return {
      type: "item",
      matTooltip: "Respuesta Invarat",
      navigateTo: "/ans-invarat",
      icon: "question_answer",
      label: "Respuesta Invarat",
    };
  }

  get control() {
    return {
      type: "item",
      matTooltip: "Control de operaciones",
      navigateTo: "/op-control",
      icon: "assessment",
      label: "Control de operaciones",
    };
  }
  get workshopsControl() {
    return {
      type: "item",
      matTooltip: "Control de operaciones",
      navigateAll: "/workshops",
      navigateTo: "/workshops/op-control",
      icon: "assessment",
      label: "Control de operaciones",
    };
  }

  get workshopsReapir() {
    return {
      type: "item",
      matTooltip: "Reparación",
      navigateTo: "/workshops/repair-checklist",
      icon: "assessment",
      label: "Reparación",
    };
  }

  get workshopsPendingTask() {
    return {
      type: "item",
      matTooltip: "Tareas Pendientes",
      navigateAll: "/workshops",
      navigateTo: "/workshops/pending-tasks",
      icon: "price_check",
      label: "Tareas Pendientes",
    };
  }

  get pendingTasks() {
    return {
      type: "item",
      matTooltip: "Tareas Pendientes",
      navigateAll: "/vehicles",
      navigateTo: "/vehicles/pending-tasks",
      icon: "add_task",
      label: "Tareas Pendientes",
    };
  }

  get commercial() {
    return {
      type: "item",
      matTooltip: "Control de estados",
      navigateTo: "/op-commercial",
      icon: "assignment",
      label: "Control de estados",
    };
  }
  get inventory() {
    return {
      type: "item",
      matTooltip: "Inventario",
      navigateTo: "/stock",
      icon: "markunread_mailbox",
      label: "Inventario",
    };
  }

  get works() {
    return {
      type: "item",
      matTooltip: "Trabajos",
      navigateTo: "/works",
      icon: "work",
      label: "Trabajos",
    };
  }

  get transfers() {
    return {
      type: "item",
      matTooltip: "Movimientos",
      navigateTo: "/transfers",
      icon: "rounded_corner",
      label: "Movimientos",
    };
  }

  get controlStates() {
    return {
      type: "item",
      matTooltip: "Control de estados",
      navigateTo: "/control-states",
      icon: "assignment",
      label: "Control de estados",
    };
  }
  get aditionalTask() {
    return {
      type: "item",
      matTooltip: "Tarea Adicional",
      navigateTo: "/aditional-task",
      icon: "task",
      label: "Tarea Adicional",
    };
  }
  get vehiclesExit() {
    return {
      type: "item",
      matTooltip: "Salidas de vehículos",
      navigateTo: "/vehicles-exit",
      icon: "emoji_transportation",
      label: "Salidas de vehículos",
    };
  }
  get workshops() {
    return {
      type: "item",
      matTooltip: "Talleres",
      navigateTo: "/workshops",
      icon: "handyman",
      label: "Talleres",
    };
  }
  get statistic() {
    return {
      type: "item",
      matTooltip: "Principal",
      navigateTo: navegateMain,
      icon: "home",
      label: "Principal",
    };
  }
  get history() {
    return {
      type: "item",
      matTooltip: "Historial",
      navigateTo: "/history",
      icon: "history",
      label: "Historial",
    };
  }
  get validation() {
    const matBadgeSub = new Subject();

    return {
      type: "item",
      matTooltip: "Validación",
      navigateTo: "/validation",
      icon: "domain_verification",
      label: "Validación",
      matBadgeSub,
      matBadge: matBadgeSub.asObservable(),
    };
  }
  get budgets() {
    const matBadgeSub = new Subject();
    return {
      type: "item",
      matTooltip: "Presupuestos",
      navigateTo: "/budgets",
      icon: "price_check",
      label: "Presupuestos",
      matBadgeSub,
      matBadge: matBadgeSub.asObservable(),
    };
  }
  get divider() {
    return {
      type: "divider",
      dnone: this.role_id === 6,
    };
  }
  get vehicles() {
    return {
      type: "item",
      matTooltip: "Vehículos",
      navigateTo: "/vehicles",
      icon: "directions_car",
      label: "Vehículos",
      readonly: false,
    };
  }

  get accordionSettings() {
    return {
      type: "accordion",
      titleIcon: "directions_car",
      title: "Configuraciones",
      items: new Array<any>(this.settingVehicle, this.settingLocation),
    };
  }

  get listVehicle() {
    return {
      type: "item",
      matTooltip: "Listado de vehículos",
      navigateTo: "/vehicles",
      icon: "list",
      label: "Listado de vehículos",
    };
  }
  get garageVehicles() {
    return {
      type: "item",
      matTooltip: "En taller mecánico",
      navigateAll: "/vehicles",
      navigateTo: "/vehicles/garage-vehicles",
      icon: "construction",
      label: "En taller mecánico",
    };
  }
  get garageSheet() {
    return {
      type: "item",
      matTooltip: "En taller chapa",
      navigateAll: "/vehicles",
      navigateTo: "/vehicles/garage-sheet",
      svgIcon: "car-door",
      label: "En taller chapa",
    };
  }
  get garageItv() {
    return {
      type: "item",
      matTooltip: "En ITV",
      navigateAll: "/vehicles",
      navigateTo: "/vehicles/garage-itv",
      svgIcon: "itv",
      label: "En ITV",
    };
  }
  get company() {
    return {
      type: "item",
      matTooltip: "Empresas",
      navigateTo: "/company",
      icon: "business",
      label: "Empresas",
    };
  }
  get campa() {
    return {
      type: "item",
      matTooltip: "Campas",
      navigateTo: "/campa",
      icon: "store",
      label: "Campas",
    };
  }
  get user() {
    return {
      type: "item",
      matTooltip: "Usuarios",
      navigateTo: "/user",
      icon: "people_alt",
      label: "Usuarios",
    };
  }
  get task() {
    return {
      type: "item",
      matTooltip: "Tareas",
      navigateTo: "/task",
      icon: "list_alt",
      label: "Tareas",
    };
  }
  get authorizations() {
    return {
      type: "item",
      matTooltip: "Autorizaciones",
      navigateTo: "/authorizations",
      icon: "list_alt",
      label: "Autorizaciones",
    };
  }

  get incidences() {
    const matBadgeSub = new Subject();
    return {
      type: "item",
      matTooltip: "Incidencias",
      navigateTo: "/incidences",
      icon: "warning",
      label: "Incidencias",
      matBadgeSub,
      matBadge: matBadgeSub.asObservable(),
    };
  }

  get settingVehicle() {
    return {
      type: "item",
      matTooltip: "Gestion de caracteristicas de vehículos",
      navigateTo: "/settings/vehicle",
      icon: "queue",
      label: "Gestion de Caracteristicas",
    };
  }

  get settingBrand() {
    return {
      type: "item",
      matTooltip: "Marca",
      navigateTo: "/settings/brand",
      icon: "",
      label: "Marca",
    };
  }
  get settingModel() {
    return {
      type: "item",
      matTooltip: "Modelo",
      navigateTo: "/settings/model",
      icon: "",
      label: "Modelo",
    };
  }
  get settingColor() {
    return {
      type: "item",
      matTooltip: "Color",
      navigateTo: "/settings/color",
      icon: "",
      label: "Color",
    };
  }
  get settingCategories() {
    return {
      type: "item",
      matTooltip: "Categoría",
      navigateTo: "/settings/categories",
      icon: "",
      label: "Categoría",
    };
  }
  get settingAccessories() {
    return {
      type: "item",
      matTooltip: "Accesorios",
      navigateTo: "/settings/accessories",
      icon: "",
      label: "Accesorios",
    };
  }

  get settingLocation() {
    return {
      type: "item",
      matTooltip: "Localizaciones",
      navigateTo: "/settings/location",
      icon: "location_on",
      label: "Localización",
    };
  }
  get FileVehicles() {
    return {
      type: "item",
      matTooltip: "Ficha vehículo",
      navigateTo: "/file-vehicle",
      icon: "pending_actions",
      label: "Ficha vehículo",
    };
  }
  get Bese() {
    return {
      type: "item",
      matTooltip: "Componente Base",
      navigateTo: "/base",
      icon: "view_column",
      label: "Componente Base",
    };
  }

  get defleeting() {
    return {
      type: "item",
      matTooltip: "Defleeting",
      navigateTo: "/defleeting",
      icon: "domain_verification",
      label: "Defleeting",
    };
  }

  get reports() {
    return {
      type: "item",
      matTooltip: "Informes",
      navigateTo: "/reports",
      icon: "list",
      label: "Informes",
    };
  }

  get authorizationPendingTasks() {
    const matBadgeSub = new Subject();
    return {
      type: "item",
      matTooltip: "Tareas por aprobar",
      navigateTo: "/authorization-pending-tasks",
      icon: "list",
      label: "Tareas por aprobar",
      matBadgeSub,
      matBadge: matBadgeSub.asObservable(),
    };
  }

  get controlInvarat() {
    return {
      type: "item",
      matTooltip: "Control Invarat",
      navigateTo: "/control-pending-task-recovery",
      icon: "handyman",
      label: "Control Invarat",
    };
  }
  get settings() {
    return {
      type: "item",
      matTooltip: "Configuración",
      navigateTo: "/settings/general",
      icon: "settings",
      label: "Configuración",
    };
  }

  getItems() {
    if (this.company_id === 1 || this.company_id === 5) {
      switch (this.role_id) {
        case 1: // admin@mail.com
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            this.dashboard,
            this.controlStates,
            {
              type: "accordion",
              titleIcon: "assessment",
              title: "Monitoreo",
              items: new Array<any>(
                // this.control,
                // this.commercial,
                this.inventory,
                this.works,
                this.transfers,
                // this.movements
              ),
            },
            // this.budgets,
            this.history,
            this.divider,
            {
              type: "accordion",
              titleIcon: "directions_car",
              title: "Vehículos",
              items: new Array<any>(
                this.listVehicle,
                // this.pendingTasks,
                this.vehiclesExit
              ),
            },
            // this.garageVehicles,
            // this.garageSheet,
            // this.garageItv,
            this.company,
            this.settings,
            {
              type: "accordion",
              titleIcon: "directions_car",
              title: "Configuraciones",
              hiddend: hiddendSetting,
              items: new Array<any>(
                // this.settingVehicle,
                this.settingBrand,
                this.settingModel,
                this.settingColor,
                this.settingCategories,
                this.settingAccessories,
                this.settingLocation,
                this.campa,
                this.user,
                this.task,
              ),
            }
          ));
        case 2: // global-ald@mail.com
          let items = new Array<any>(
            this.dashboard,
            this.controlStates,
            {
              type: "accordion",
              titleIcon: "assessment",
              title: "Monitoreo",
              items: new Array<any>(
                // this.control,
                // this.commercial,
                this.inventory,
                this.works,
                this.transfers,
                // this.movements
              ),
            },
            // this.budgets,
            this.divider,
            {
              type: "accordion",
              titleIcon: "directions_car",
              title: "Vehículos",
              items: new Array<any>(
                this.listVehicle,
                this.pendingTasks,
                this.vehiclesExit
              ),
            },
            this.divider,
            {
              type: "accordion",
              titleIcon: "directions_car",
              title: "Configuraciones",
              hiddend: hiddendSetting,
              items: new Array<any>(
                this.settingBrand,
                this.settingModel,
                this.settingColor,
                this.settingCategories,
                this.settingAccessories,
                this.settingLocation,
                this.campa,
                this.user,
              ),

            },
            this.incidences,

          );
          if (this.email == "admin-ald@mail.com") {
            items = _.reject(items, { navigateTo: "purchased-operations" });
          }
          return (isChart ? [this.statistic] : []).concat(items);
        case 3: // campa-ald@mail.com
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            this.dashboard,
            //this.FileVehicles,
            {
              type: "accordion",
              titleIcon: "assessment",
              title: "Monitoreo",
              items: new Array<any>(
                // this.control,
                // this.commercial,
                this.inventory,
                this.works,
                this.transfers,
                // this.movements
              ),
            },
            // this.budgets,
            this.divider,
            {
              type: "accordion",
              titleIcon: "directions_car",
              title: "Vehículos",
              items: new Array<any>(
                this.listVehicle,
                this.pendingTasks,
                this.vehiclesExit
              ),
            },
            this.incidences,
            {
              type: "accordion",
              titleIcon: "directions_car",
              title: "Configuraciones",
              hiddend: hiddendSetting,
              items: new Array<any>(
                this.settingBrand,
                this.settingModel,
                this.settingColor,
                this.settingCategories,
                this.settingAccessories,
                this.settingLocation,
                this.user,
              ),
            },
            //this.Bese,
          ));
        case 4:
          return [];
        case 5: // control@mail.com
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            this.dashboard,
            // {
            //   type: "accordion",
            //   titleIcon: "assessment",
            //   title: "Monitoreo",
            //   items: new Array<any>(this.control, this.commercial),
            // },
            this.history,
            this.divider,
          ));
        case 6:
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            this.dashboard
          ));
        case 7:
          if (this.email == "tecnico39@invarat.com" || this.email == "tecnico71@invarat.com"){
                return (isChart ? [this.statistic] : []).concat(new Array<any>(
                  this.dashboard,
                  this.controlStates,
                  this.validation,
                  this.budgets,
                  this.authorizationPendingTasks,
                  {
                    type: "accordion",
                    titleIcon: "directions_car",
                    title: "Vehículos",
                    items: new Array<any>(this.vehicles),
                  },
                  this.incidences
                ));
          }
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            this.dashboard,
            // {
            //   type: "accordion",
            //   titleIcon: "assessment",
            //   title: "Monitoreo",
            //   items: new Array<any>(this.control, this.commercial),
            // },
            this.validation,
            this.budgets,
            this.authorizationPendingTasks,
            // this.controlInvarat,
            {
              type: "accordion",
              titleIcon: "directions_car",
              title: "Vehículos",
              items: new Array<any>(this.vehicles),
            },

            this.incidences

          ));
        case 8:
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            this.dashboard,
            this.works,
            this.pendingTasks,
            this.vehicles,
            this.incidences,
          ));
        case 9:
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            this.dashboard,
            this.works,
            this.pendingTasks,
            this.vehicles,
            this.incidences,
          ));
        case 10:
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            // this.dashboard,
            this.validation
          ));

        case 11: // Gestor tecnico
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            this.dashboard,
            //this.FileVehicles,
            {
              type: "accordion",
              titleIcon: "assessment",
              title: "Monitoreo",
              items: new Array<any>(
                // this.control,
                // this.commercial,
                this.inventory,
                this.works,
                this.transfers,
                // this.movements
              ),
            },
            // this.budgets,
            this.divider,
            {
              type: "accordion",
              titleIcon: "directions_car",
              title: "Vehículos",
              items: new Array<any>(
                this.listVehicle,
                this.pendingTasks,
                this.vehiclesExit
              ),
            },
            this.incidences,
            {
              type: "accordion",
              titleIcon: "directions_car",
              title: "Configuraciones",
              hiddend: hiddendSetting,
              items: new Array<any>(
                this.settingBrand,
                this.settingModel,
                this.settingColor,
                this.settingCategories,
                this.settingAccessories,
                this.settingLocation,
                this.user,
              ),
            },
            //this.Bese,
          ));
        case 12:
        let items2 = new Array<any>(
          this.dashboard,
          this.controlStates,
          {
            type: "accordion",
            titleIcon: "assessment",
            title: "Monitoreo",
            items: new Array<any>(
              // this.control,
              // this.commercial,
              this.inventory,
              this.works,
              this.transfers,
              // this.movements
            ),
          },
          // this.controlInvarat,
          this.defleeting,
          this.reports,
          // this.budgets,
          this.divider,
          {
            type: "accordion",
            titleIcon: "directions_car",
            title: "Vehículos",
            items: new Array<any>(
              this.listVehicle,
              this.pendingTasks,
              this.vehiclesExit
            ),
          },
          this.divider,
          {
            type: "accordion",
            titleIcon: "directions_car",
            title: "Configuraciones",
            hiddend: hiddendSetting,
            items: new Array<any>(
              this.settingBrand,
              this.settingModel,
              this.settingColor,
              this.settingCategories,
              this.settingAccessories,
              this.settingLocation,
              this.campa,
              this.user,
              this.task,
            ),

          },
          this.incidences,

        );
        if (this.email == "admin-ald@mail.com") {
          items2 = _.reject(items, { navigateTo: "purchased-operations" });
        }
        return (isChart ? [this.statistic] : []).concat(items2);

        default:
          return [];
      }
    } else if (this.company_id === 2) {
      if (!!this.workshop_id) {
        return (isChart ? [this.statistic] : []).concat(new Array<any>(
          this.workshopsPendingTask
        ));
      }
      switch (this.role_id) {
        case 1:
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            this.dashboardRemarketing,
            this.workshopsControl,
            this.controlStates,
            this.authorizations,
            this.workshopsReapir,
            this.user
          ));
        case 2:
          let items = new Array<any>(
            this.statistic,
            this.dashboardRemarketing,
            this.workshopsControl,
            this.controlStates,
            this.authorizations,
            this.user
          );
          return (isChart ? [this.statistic] : []).concat(items);
        case 3:
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            this.dashboardRemarketing,
            this.workshopsControl,
            this.workshopsReapir
          ));
        case 4:
          return [];
        case 5:
          return (isChart ? [this.statistic] : []).concat(new Array<any>(this.statistic));
        case 6:
          return (isChart ? [this.statistic] : []).concat(new Array<any>(
            this.dashboardRemarketing,
            this.workshopsControl,
            this.controlStates,
            this.authorizations,
            this.workshopsReapir
          ));
        case 7:
          return (isChart ? [this.statistic] : []).concat(new Array<any>(this.statistic));
        case 8:
          return (isChart ? [this.statistic] : []).concat(new Array<any>(this.statistic));
        case 9:
          return (isChart ? [this.statistic] : []).concat(new Array<any>(this.statistic));
        default:
          return [];
      }
    }
  }
}
