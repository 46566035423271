<div [formGroup]="filterForm">
    <h1 mat-dialog-title style="font-weight: 500;">{{data.title}}</h1>

    <div mat-dialog-content class="d-block">
        <mat-radio-group class="radio-group" aria-label="Select an option" formControlName="state_pending_task_id">
            <mat-radio-button class="radio-button" [value]="3">Tarea terminada</mat-radio-button>
            <mat-radio-button class="radio-button" [value]="1">Tarea para realizar</mat-radio-button>
        </mat-radio-group>
    </div>

    <div mat-dialog-content class="d-block">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Selecciona una tarea</mat-label>
            <mat-select formControlName="tasks" required >
                <mat-option *ngFor="let item of task" [value]="item">{{item.name}} - {{item.sub_state.name}}</mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <div mat-dialog-content class="d-block">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Comentario</mat-label>
            <textarea matInput class="text-comment" formControlName="comment"></textarea>
        </mat-form-field>
    </div>

    <div  mat-dialog-content style="height: 45px; display: flex !important; justify-content: center;">
        <input style="display:none" multiple class="ng-hide" #input_file (change)="fileChangeEvent($event)" id="input-file-id" multiple type="file"
        accept="image/jpeg,image/png,.jpeg,.jpg,.png,application/pdf, .xls, .xlsx" />

        <button *ngIf="!document"
            [disabled]="filterForm.invalid"
            mat-raised-button mat-button
            style="margin: auto;"
            (click)="updateQuestionaires(
                input_file
            )"
            >
            <mat-icon
            class="material-icons-u icon-file">attachment</mat-icon>
            Adjuntar archivo
        </button>
        <button mat-raised-button mat-button *ngIf="document" style="margin:auto;" (click)="exportFile(document, 'zip')">
          <mat-icon  class="material-icons-u icon-file"  >file_download</mat-icon>
          Descargar archivo
        </button>
        <input style="display:none" #input_file_budget (change)="fileChangeEventBudget($event)" id="input-file-budget-id" multiple type="file"
          accept="application/pdf" />
        <button [disabled]="filterForm.invalid" *ngIf="!budget?.url"
          style="margin: auto;" (click)="openFile(input_file_budget, $event)" class="mat-button button-upload "
          matTooltip="Subir presupuesto (PDF)" matTooltipPosition="below">
            <mat-icon
              class="material-icons-u icon-file">cloud_upload</mat-icon>&nbsp;Subir presupuesto
        </button>
        <button class="mat-button button-upload " mat-button mat-raised-button  *ngIf="!!budget?.url" mat-dialog-content style="margin:auto;" (click)="exportFile(budget?.url, 'pdf')">
          <mat-icon class="material-icons-u icon-file" >file_download</mat-icon>
          Descargar presupuesto
        </button>
    </div>
    <div mat-dialog-actions align="center">
        <button mat-button class="mat-raised-button mb-2" (click)="onNoClick()">Cancelar</button>
        <button [disabled]="filterForm.invalid" mat-raised-button class="mat-raised-button btn-green mb-2"
            cdkFocusInitial (click)="SaveP0endingTaskFinished()">Crear
        </button>
        <!--
            <button [disabled]="filterForm.invalid" mat-raised-button class="mat-raised-button btn-green mb-2"
                cdkFocusInitial (click)="SaveP0endingTaskFinished(true)">Crear en nuevo grupo
            </button>
        -->


    </div>
</div>
