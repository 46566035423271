<div *ngIf="show" class="row" style="height: 100%;">
    <mat-card style="width: 100%">
        <mat-card-header>
            <div style="margin-right: 32px;width: 100%;text-align: center;">{{title}}</div>
        </mat-card-header>
        <mat-card-content>
            <div>
                <div>
                  <div style="display: block">
                    <canvas baseChart
                      [data]="barChartData"
                      [options]="barChartOptions"
                      [plugins]="barChartPlugins"
                      [type]="barChartType"
                      (chartHover)="chartHovered($event)"
                      (chartClick)="chartClicked($event)">
                    </canvas>
                  </div>
                </div>
              </div>
        </mat-card-content>
    </mat-card>
</div>

