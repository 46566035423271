import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { UserService } from "../services/user.service";
import { StorageService } from "@core/services/storage.service";
import { id } from "aws-sdk/clients/datapipeline";

@Injectable({
  providedIn: "root",
})
export class CampasService {
  env = environment;
  private apiUrl = this.env.api_url;
  public token: string;
  reqHeader = new HttpHeaders().set("Content-Type", "application/json");

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private storageService: StorageService
  ) {}

  getAll(data: any): Observable<any> {
    let params = new HttpParams();
    params = params.append("with", "company");
    params = params.append("with", "campaTypeModelOrders.typeModelOrder");
    if (data) {
      params = params.append("companies[]", data);
    }
    return this.http.get(this.apiUrl + "/campas/getall", { params });
  }

  getAllWithParameters(arrayParams = {}): any {
    let params = this.getParams(arrayParams);
    return this.http.get<any>(this.apiUrl + "/campas/getall", { params });
  }

  private getParams(arrayParams) {
    let params = new HttpParams();
    arrayParams.map((element) => {
      let param = element.split(",");
      params = params.append(param[0], param[1]);
    });
    return params;
  }

  getById(id: number): any {
    let params = new HttpParams();
    params = params.append("with[]", "company");
    params = params.append("with[]", "campaTypeModelOrders");
    // params = params.append('with[]', 'campas');
    return this.http.get<any>(this.apiUrl + "/campas/" + id, { params });
  }

  getCampasByCompanyId(data: any): any {
    let params = new HttpParams();

    params = params.append("with", "company");
    // params = params.append('with[]', 'campas');
    return this.http.post<any>(this.apiUrl + "/campas/by-company", data, {
      params,
    });
  }

  getCampasByRegion(data: any): any {
    return this.http.post<any>(this.apiUrl + "/campas/by-region", data);
  }

  createCampa(data: any): any {
    return this.http.post<any>(this.apiUrl + "/campas", data);
  }

  updateCampa(id, data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.put<any>(this.apiUrl + `/campas/update/${id}`, params, {
      headers,
    });
  }

  updateStatus(data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.post<any>(this.apiUrl + "/campas/status", params, {
      headers,
    });
  }

  deleteCampa(id) {
    return this.http.delete<any>(this.apiUrl + `/campas/delete/${id}`);
  }
}
