import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PendingTasksService {
  env = environment;
  private apiUrl = this.env.api_url;
  public token: string;

  constructor(private http: HttpClient) {}

  getPendingTask(arrayParams=[]): Observable<any> {
    let params = this.getParams(arrayParams);
    return this.http.get(this.apiUrl + "/pending-tasks/filter?",  { params });
  }

  startPendingTask(data: Object): Observable<any> {
    let params = new HttpParams();
    return this.http.post(
      this.apiUrl + "/pending-tasks/start-pending-task",
      data,
      { params }
    );
  }

  finishPendingTask(data: Object): Observable<any> {
    let params = new HttpParams();
    return this.http.post(
      this.apiUrl + "/pending-tasks/finish-pending-task",
      data,
      { params }
    );
  }

  updatePendingTask(data: Object, id: number): Observable<any> {
    let params = new HttpParams();
    return this.http.put(this.apiUrl + "/pending-tasks/update/" + id, data, {
      params,
    });
  }

  private getParams(arrayParams) {
    let params = new HttpParams();
    arrayParams.map((element) => {
      let param = element.split(",");
      params = params.append(param[0], param[1]);
    });
    return params;
  }

  approvedPendingTask(data, id) {
    return this.http.put<any>(
      this.apiUrl + `/pending-tasks/approved/${id}/tasks`,
      data
    );
  }
}
