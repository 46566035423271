import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  NavigationExtras,
  CanLoad, Route
} from '@angular/router';
import { StorageService } from '../services/storage.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: StorageService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    const validUrl = (navigateTo) => {
      return url.search(navigateTo) > -1
    }
    const item: any = _.first(_.filter(this.authService.items, (x) => {
      return x.type === 'accordion' ? _.filter(x.items, (y) => {
        return validUrl(y.navigateAll || y.navigateTo);
      }) : x.navigateTo ? validUrl(x.navigateAll || x.navigateTo) : false;
    }));
    const enc = this.checkLogin(url);
    return enc ? !!item : false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    const firstItem = _.first(this.authService.items);
    if (!!firstItem && this.authService.isAuthenticated()) {
      if (!!firstItem && firstItem.navigateTo !== url) {
        if (['/login', '/', ''].indexOf(location.hash.replace('#', '')) > -1) {
          this.router.navigate([firstItem.navigateTo]);
        }
      }
      return true;
    }
    if (!firstItem && !!this.authService.currentSession && !this.authService.isAuthenticated() && ['/login', '/', ''].indexOf(location.hash.replace('#', '')) > -1) {
      Swal.fire({
        icon: "error",
        title: "Acceso no autorizado",
        text: "El usuario no poseee un role, empresa o taller valido.",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      this.authService.removeCurrentSession();
    }

    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;

    // // Create a dummy session id
    // const sessionId = 123456789;
    //
    // // Set our navigation extras object
    // // that contains our global query params and fragment
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {session_id: sessionId},
    //   fragment: 'anchor'
    // };

    // Navigate to the login page with extras
    this.router.navigate(['/login']);
    return false;
  }
}
