import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  Subscription,
} from "rxjs";
import * as _ from "lodash";
import { StockService } from "../../services/stock.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableExporterDirective } from "mat-table-exporter";
import { SquareService } from "../../services/square.service";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import * as moment from "moment";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import { UserService } from "src/app/services/user.service";
import { ZoneService } from "src/app/services/zone.service";
import { StreetService } from "src/app/services/street.service";
import { map, first, takeUntil } from 'rxjs/operators';
import Swal from "sweetalert2";
import { ExcelService } from "@core/services/excel.service";
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
import { MatSort, Sort } from "@angular/material/sort";
import { HeaderService } from "src/app/service/header.service";
import { StorageService } from "@core/services/storage.service";
import { CampasService } from "src/app/services/campas.service";
import { MatDialog } from "@angular/material/dialog";
import { LocationDialogComponent } from "src/app/components/shared/location-dialog/location-dialog.component";
import { VehicleService } from "src/app/services/vehicle.service";
import { ConfirmationDialogComponent } from "src/app/components/shared/confirmation-dialog/confirmation-dialog.component";
const api_sort = false;

@Component({
  selector: "app-transfers",
  templateUrl: "./transfers.component.html",
  styleUrls: ["./transfers.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class TransfersComponent implements OnInit {
  noData: Observable<boolean> = of(true);
  transfersList: any[] = [];
  date: any;
  squareList: any[] = [];
  zoneList: any[] = [];
  streetList: any[] = [];
  usersList: any[] = [];
  event: any = {};
  event2: any = {};
  dateDesde: string;
dateHasta: string;
  transfersDataSource = new MatTableDataSource<any>();
  transfersCampaDisplayedColumns: string[] = [
    "date",
    "time",
    "vehicle.plate",
    "vehicle.vehicle_model.brand.name",
    "vehicle.vehicle_model.name",
    "user.name",
    "square.street.zone.name",
    "square.street.name",
    "square.name",
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;
  filterFormTransfers: UntypedFormGroup;
  selectedPlate
  applyfilter: boolean = false;
  public plateFilterCtrl: UntypedFormControl = new UntypedFormControl();
  vehicles: any = [];
  public plateMultiCtrl: UntypedFormControl = new UntypedFormControl();
  protected filteredPlateCache: any[] = [];
  public filteredPlate: ReplaySubject<any[]> = new ReplaySubject<any[]>(
    1
  );
  isLoadingDataTransfers: Observable<boolean>;
  loadingDataTransfersSubject = new BehaviorSubject(false);
  isLoadingDataLocations: Observable<boolean>;
  loadingDataLocationsSubject = new BehaviorSubject(false);
  @ViewChild("sort", { static: false }) sort: MatSort;
  @ViewChild("sort2", { static: false }) sort2: MatSort;

  campas: any[] = [];
  zones: any[] = [];
  streets: any[] = [];
  squares: any[] = [];
  groupZones: any = {};
  groupStreets: any = {};
  groupSquares: any = {};
  zone_ids: number[] = [];
  street: any;
  street_ids: number[] = [];
  square_id: number;
  squareDisplayedColumns: string[] = [
    "street.zone.name",
    "street.name",
    "name",
    "vehicle",
    "edit",
  ];

  dataSource = new MatTableDataSource<any>();

  sub: Subscription;

  constructor(
    public dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private _stockService: StockService,
    private _cdr: ChangeDetectorRef,
    private userService: UserService,
    private _squareService: SquareService,
    private _zoneService: ZoneService,
    private excelService: ExcelService,
    private vehicleService: VehicleService,
    private _streetService: StreetService,
    private headerService: HeaderService,
    private zoneService: ZoneService,
    private streetService: StreetService,
    private storageService: StorageService,
    private campaService: CampasService,
    private cdr: ChangeDetectorRef,
    private _dialog: MatDialog
  ) { }

  sortData(sort: Sort) {
    if (api_sort) {
      this.getTransfers();
    }
  }

  sortData2(sort: Sort) {
    if (api_sort) {
      this.getList();
    }
  }

  ngOnInit(): void {
    this.plateFilterCtrl.valueChanges
      .subscribe(() => {
        this.getVehicles()
      });
    this.isLoadingDataTransfers =
      this.loadingDataTransfersSubject.asObservable();
    this.isLoadingDataLocations =
      this.loadingDataLocationsSubject.asObservable();
    this.buildFormFilterOut();
    this.getSquareList();
    this.getStreetList();
    this.getUsers();
    this.getTransfers();

    this.getList();

    this.headerService.getfiltros().subscribe(() => {
      this.zone_ids = [];
      this.street_ids = [];
      this.getList();
      this.getTransfers();
    });

    this.zoneService.isLoadingData =
      this.zoneService.loadingDataSubject.asObservable();
    let obs: Observable<any>;
    const user: any = this.storageService.getCurrentUser() || {};
    if (user.id === 1) {
      obs = this.campaService.getAllWithParameters([]);
    } else {
      obs = of(user.campas);
    }
    obs.subscribe((response) => {
      this.campas = response;
    });
  }


  getVehicles(): void {
    this.vehicleService.getSearch(this.campas, this.plateFilterCtrl.value, 4).subscribe((res: any) => {
      this.vehicles = res.vehicles.data;
      this.filteredPlateCache = this.vehicles.slice();
      this.plateMultiCtrl.setValue([...this.vehicles]);
      this.filteredPlate.next(this.vehicles.slice());
    });
  }

  getList(paginate = {}) {
    this.squareList = [];
    this.dataSource = new MatTableDataSource(this.squareList);
    const campas = this.headerService.getHeaderCampa();
    if (campas.length === 0) {
      return;
    }
    const event: any = _.assign(
      {
        length: 0,
        pageIndex: 0,
        pageSize: 5,
        previousPageIndex: 0,
      },
      paginate
    );
    this.event2 = event;
    let filterArray = this.getArrayParamLocation();
    const arrayParams = [
      `page,${this.event2.pageIndex + 1}`,
      `per_page,${this.event2.pageSize}`,
    ];
    this.loadingDataLocationsSubject.next(true);
    const params = filterArray.concat(arrayParams)
    const sub = forkJoin([this._squareService.getSquaresWithParams(params), this._zoneService.getZones(this.headerService.headerCampa)]).subscribe(resp => {
      const squareList = [];
      this.dataSource = new MatTableDataSource([]);
      squareList.push(...resp[0].data)
      this.dataSource = new MatTableDataSource(squareList)
      this.getSquareList();
      if (!api_sort) {
        this.dataSource.sortingDataAccessor = (item, property) => {
          return _.at(item, property);
        };
      }
      this.dataSource.sort = this.sort2;
      this.zoneList = resp[1];

      this.event2.length = 0
      setTimeout(() => {
        this.event2.length = resp[0].total;
        this._cdr.detectChanges();
      }, 500);
      this.loadingDataLocationsSubject.next(false);
      sub.unsubscribe()
      this.cdr.detectChanges()
    }, () => {
      this.loadingDataLocationsSubject.next(false);
      this.cdr.detectChanges()
      sub.unsubscribe()
    })
  }

  getArrayParamLocation() {
    let arrayParams = [
      "with[],street.zone",
      "with[],vehicle",
    ];

    this.headerService.headerCampa.forEach((id: any) => {
      arrayParams.push(`campaIds[],${id}`);
    });

    const zones: any[] = this.zone_ids;
    if (zones.length > 0) {
      zones.forEach((x) => {
        arrayParams.push(`zoneIds[],${x}`);
      });
    }

    const streets: any[] = this.street_ids;
    if (streets.length > 0) {
      streets.forEach((x) => {
        arrayParams.push(`streetIds[],${x}`);
      });
    }
    return arrayParams
  }

  changeSelect(field) {
    if (field === "zone") {
      let params = []
      this.zone_ids.forEach(element => {
        params.push(`zoneIds[],${String(element)}`)
      });
      this.zone_ids.length ? null : this.street_ids = []
      this.getStreetList(params)
    }
    this.getList()
    this.cdr.detectChanges();
  }

  edit(element, currentTab) {
    // if (!element) {
    //   return;
    // }
    // this.zoneService.currentTab = currentTab;
    // const onDismiss: Subject<any> = new Subject<any>();
    // this._dialog.open(DialogLocationComponent, {
    //   width: "250px",
    //   data: {
    //     name: element.name,
    //     id: element.id,
    //     street_id: element.street_id,
    //     onDismiss,
    //   },
    // });
    // onDismiss.subscribe((res: any) => {
    //   let index = -1;
    //   if (currentTab === 0) {
    //     index = _.findIndex(this.zones, { id: res.data.id });
    //     if (index > -1) {
    //       this.zones[index] = res.data;
    //     }
    //   } else if (currentTab === 1) {
    //     index = _.findIndex(this.groupZones[res.data.zone_id].streets, {
    //       id: res.data.id,
    //     });
    //     if (index > -1) {
    //       this.groupZones[res.data.zone_id].streets[index] = res.data;
    //     }
    //   } else {
    //     index = _.findIndex(this.groupSquares[this.street_id], {
    //       id: res.data.id,
    //     });
    //     if (index > -1) {
    //       this.groupSquares[this.street_id][index].name = res.data.name;
    //       this.dataSource = new MatTableDataSource<any>(
    //         this.groupSquares[this.street_id]
    //       );
    //     }
    //   }
    //   this.cdr.detectChanges();
    //   onDismiss.unsubscribe();
    // });
    // this.sub = this._dialog.afterAllClosed.subscribe((x) => {
    //   this.sub.unsubscribe();
    // });
  }

  openModal(index: number) {

  }

  get dataExist(): boolean {
    return null; //this._zoneService.dataExist;
  }

  get statusSpinner(): Observable<boolean> {
    return null; //this._zoneService.isLoadingData;
  }

  getSquareList() {
    let arrayParams = [
      "noPaginate",
    ];
    const streets: any[] =
      this.filterFormTransfers.controls.streets.value || [];
    if (streets.length > 0) {
      streets.forEach((x) => {
        arrayParams.push(`streetIds[],${x}`);
      });
    }
    const user = this.storageService.getCurrentUser();
    if (!!user.campas) {
      user.campas.forEach((campa) => {
        arrayParams.push(`campaIds[],${String(campa.id)}`);
      });
    }
    const subs = this._squareService.getSquaresWithParams(arrayParams).subscribe((res) => {
      this.squareList = res;
      subs.unsubscribe();
    });
  }

  getStreetList(params = []) {
    const subs = this._streetService.getStreetsWithParams(params).subscribe((res) => {
      this.streetList = res;
      subs.unsubscribe();
    });
  }

  buildFormFilterOut() {
    this.filterFormTransfers = this._formBuilder.group({
      date: [[]],
      dateDesde: [[]],
      dateHasta: [[]],
      plate: [[]],
      squares: [[]],
      streets: [[]],
      zones: [[]],
      users: [[]],
    });
  }

  // onDatesChange(value) {
  //   if (value) {
  //     const dateSplit = value.split("-");
  //     this.date = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;
  //   } else {
  //     this.date = null;
  //   }
  //   this.getTransfers();
  // }
  onDateChange(identifier: string, value: string) {
    if (value) {
      const dateSplit = value.split("-");
      const formattedDate = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`;

      if (identifier === 'desde') {
        this.dateDesde = formattedDate;
      } else if (identifier === 'hasta') {
        this.dateHasta = formattedDate;
      }
    } else {
      if (identifier === 'desde') {
        this.dateDesde = null;
      } else if (identifier === 'hasta') {
        this.dateHasta = null;
      }
    }

    // Luego, puedes usar this.dateDesde y this.dateHasta para filtrar en un rango.
    this.getTransfers();
  }

  plateChange(event) {
    this.selectedPlate = event.value,
      this.openLocation({ vehicle_id: this.selectedPlate.id })
  }

  getUsers(): void {
    const sub = this.userService.getAllUsers().subscribe(
      (res: any) => {
        this.usersList = res;
        sub.unsubscribe();
      },
      () => {
        sub.unsubscribe();
      }
    );
  }

  get user(): any {
    return this.storageService.getCurrentUser() || {};
  }

  freeSquare(square) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      disableClose: true,
      autoFocus: false,
      data: {
        title: "Aviso",
        message: `Esta plaza está en uso por el vehículo con matrícula ${square.vehicle.plate}, Desea liberar la plaza?`,
        id: square.id,
      },
    });
    dialogRef.afterClosed().subscribe((confirm: Boolean) => {
      if (confirm) {
        this.loadingDataLocationsSubject.next(true);
        const sub = this._squareService.updateSquartVehicle({
          vehicle_id: null,
          id: square.id,
          user_id: this.user.id
        }).subscribe((data) => {
          sub.unsubscribe();
          this.getList();
          this.loadingDataLocationsSubject.next(false);
        }, () => {
          this.loadingDataLocationsSubject.next(false);
          sub.unsubscribe();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error conectando al servidor...",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        });
      }
    });
  }

  getTransfers(paginate = {}) {
    const campas = this.headerService.getHeaderCampa();
    if (campas.length === 0) {
      this.transfersList = [];
      this.transfersDataSource = new MatTableDataSource(this.transfersList);
      this.noData = of(true);
      return;
    }
    const event: any = _.assign(
      {
        length: 0,
        pageIndex: 0,
        pageSize: 5,
        previousPageIndex: 0,
      },
      paginate
    );
    this.event = event;
    let filterArray = this.getArrayParamsTransfers();
    const dateDesde = this.dateDesde;
    const dateHasta = this.dateHasta;
    if (dateDesde) {
      filterArray.push(`created_at_gte,${dateDesde}`);
    }
    if (dateHasta) {
      filterArray.push(`created_at_lte,${dateHasta}`);
    }
    const arrayParams = [
      `page,${this.event.pageIndex + 1}`,
      `per_page,${this.event.pageSize}`,
    ];
    this.headerService.headerCampa.forEach((id: any) => {
      arrayParams.push(`vehicleCampaIds[],${id}`);
    });
    const data: any = {};
    data["_sort"] =
      this.sort && this.sort["_direction"] ? this.sort.active : "";
    data["_order"] = this.sort && this.sort["_direction"];
    if (data._sort && api_sort) {
      arrayParams.push(
        `${data._order === "asc" ? "order" : "orderDesc"},${data._sort}`
      );
    }
    const params = filterArray.concat(arrayParams);
    this.loadingDataTransfersSubject.next(true);
    const subs = this._stockService.getVehiclesMove(params).subscribe(
      (res) => {
        let count = 0;
        const list = res.data.map((resp) => {
          resp.time = moment.utc(resp.created_at).local().format("hh:mm A");
          resp.date = moment.utc(resp.created_at).local().format("DD/MM/YYYY");
          if (!resp.user?.id) {
            count++;
          }
          return resp;
        });
        if (!!count) {
          console.warn('Movimientos sin operarios: ', count);
        }
        this.transfersList = [];
        this.transfersDataSource = new MatTableDataSource([]);
        this.transfersList.push(...list);
        this.transfersDataSource.data = this.transfersList;
        this.transfersDataSource = new MatTableDataSource(this.transfersList);
        if (!api_sort) {
          this.transfersDataSource.sortingDataAccessor = (item, property) => {
            return _.at(item, property);
          };
        }
        this.getSquareList();
        this.transfersDataSource.sort = this.sort;
        this.noData = this.transfersDataSource
          .connect()
          .pipe(map((data) => data.length === 0));
        this.loadingDataTransfersSubject.next(false);
        this.event.length = 0;
        this.loadingDataTransfersSubject.next(false);
        setTimeout(() => {
          this.event.length = res.total;
          this._cdr.detectChanges();
        }, 500);
        subs.unsubscribe();
        this._cdr.detectChanges();
      },
      () => {
        subs.unsubscribe();
      }
    );
  }

  getArrayParamsTransfers(): any[] {
    let arrayParams = ['whereHasVehicle,0'];
    const date: any = this.date;
    if (date) {
      arrayParams.push(`created_at,${this.date}`);
    }

    if (this.filterFormTransfers.controls.plate.value) {
      arrayParams.unshift(
        `vehiclePlate,${this.filterFormTransfers.controls.plate.value}`
      );
    }

    const zones: any[] = this.filterFormTransfers.controls.zones.value || [];
    if (zones.length > 0) {
      zones.forEach((x) => {
        arrayParams.push(`zoneIds[],${x}`);
      });
    }
    const streets: any[] =
      this.filterFormTransfers.controls.streets.value || [];
    if (streets.length > 0) {
      streets.forEach((x) => {
        arrayParams.push(`streetIds[],${x}`);
      });
    }
    const squares: any[] =
      this.filterFormTransfers.controls.squares.value || [];
    if (squares.length > 0) {
      squares.forEach((x) => {
        arrayParams.push(`squareIds[],${x}`);
      });
    }

    const users: any[] = this.filterFormTransfers.controls.users.value || [];
    if (users.length > 0) {
      users.forEach((x) => {
        arrayParams.push(`userIds[],${x}`);
      });
    }

    return arrayParams;
  }

  cleanFilters() {
    this.filterFormTransfers.reset();
    this.date = null;
    this.getTransfers();
  }

  openLocation({ vehicle_id }) {
    const dialogRef = this._dialog.open(LocationDialogComponent, {
      width: "500px",
      height: "60vh",
      disableClose: true,
      autoFocus: false,
      data: {
        vehicle_id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
      }
      this.getList();
    });
  }

  importAsXlsx(number) {
    // this.matTableExporter.exportTable("xlsx", {
    //   fileName: "Traslados Vehículos",
    //   sheet: "sheet_name",
    // });



    if (number === 1) {
      this.loadingDataTransfersSubject.next(true);
      let filterArray = this.getArrayParamsTransfers();
      const arrayParams = [`page,${1}`, `per_page,${this.event.length}`];
      this.headerService.headerCampa.forEach((id: any) => {
        arrayParams.push(`vehicleCampaIds[],${id}`);
      });
      const params = filterArray.concat(arrayParams);
      const subs = this._stockService.getVehiclesMove(params).subscribe(
        (res) => {
          const arr = res.data.map((resp) => {
            return {
              Fecha: moment.utc(resp.created_at).local().format("DD/MM/YYYY"),
              Hora: moment.utc(resp.created_at).local().format("hh:mm A"),
              Matrícula: resp.vehicle ? resp.vehicle.plate : "-",
              Marca: resp.vehicle
                ? resp.vehicle.vehicle_model?.brand?.name
                : "-",
              Modelo: resp.vehicle ? resp.vehicle.vehicle_model?.name : "-",
              Operario: resp.user?.name || "-",
              Zona: resp.square ? resp.square.street.zone.name : "-",
              Calle: resp.square ? resp.square.street.name : "-",
              Plaza: resp.square ? resp.square.name : "-",
              delete: resp.deleted_at ? resp.deleted_at : ''
            };
          });

          this.excelService.exportAsExcelFile(arr, "vehicles-movements");
          this.loadingDataTransfersSubject.next(false);
          Swal.fire({
            icon: "success",
            title: "Creación de archivo",
            text: "El archivo se genero exitosamente.",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          subs.unsubscribe();
        },
        () => {
          this.loadingDataTransfersSubject.next(false);
          subs.unsubscribe();
        }
      );
    } else {






      this.loadingDataLocationsSubject.next(true);

      const arrayParams = [];
      this.headerService.headerCampa.forEach((id: any) => {
        arrayParams.push(`vehicleCampaIds[],${id}`);
      });
      let filterArray = this.getArrayParamLocation();


      const params = filterArray.concat(arrayParams)
      const sub = this._squareService.getSquaresWithParams(params).subscribe(res => {

        const arr = res.map((resp) => {
          return {
            Zona: resp.street.zone.name || "-",
            Calle: resp.street.name || "-",
            Plaza: resp.name || "-",
            Vehiculo: resp.vehicle?.plate || "-",
          };
        });
        this.excelService.exportAsExcelFile(arr, "Locations");
        this.loadingDataLocationsSubject.next(false);
        Swal.fire({
          icon: "success",
          title: "Creación de archivo",
          text: "El archivo se genero exitosamente.",
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        sub.unsubscribe()

      }, () => {
        this.loadingDataLocationsSubject.next(false);
        sub.unsubscribe()
      })







      /*
      console.log('traza', this.squareList)
      const arr = this.squareList.map((resp) => {
        return {
          Zona: resp.street.zone.name || "-",
          Calle: resp.street.name || "-",
          Plaza: resp.name || "-",
          Vehiculo: resp.vehicle?.plate || "-",
        };
      });
      this.excelService.exportAsExcelFile(arr, "Locations");
      Swal.fire({
        icon: "success",
        title: "Creación de archivo",
        text: "El archivo se genero exitosamente.",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      */





    }
  }
}
