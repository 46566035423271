import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuestionAnswersService {

  env = environment;
  private apiUrl = this.env.api_url;
  public token: string;

  constructor(
    private http: HttpClient
  ) { }

  updateResponse(data: Object, id: number): Observable<any> {
    let params = new HttpParams();
    return this.http.put(this.apiUrl + "/question-answers/update-response/" + id, data, { params });
  }

  updateResponseAll(data: Object, id: number): Observable<any> {
    let params = new HttpParams();
    return this.http.put(this.apiUrl + "/question-answers/update-response/" + id + '/all', data, { params });
  }

  updateQuestionaires(data: Object, id: number): Observable<any> {
    let params = new HttpParams();
    return this.http.put(this.apiUrl + "/questionnaire/" + id, data, { params });
  }
}
