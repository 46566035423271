<mat-nav-list style="height: 100% !important;" [class.height-sidebar]="storageService.getCurrentUser().role_id == 1">
  <a [routerLink]="'/op-control'"><img class="logo" src="..\..\assets\logo.png"></a>
  <a mat-list-item [routerLink]="'/opcontrol'"><span class="material-icons mr-3">assessment</span>Control de operaciones
  </a>
  <a mat-list-item [routerLink]="'/opcommercial'"><span class="material-icons mr-3">assignment</span>Control de estados
  </a>
  <a mat-list-item [routerLink]="'/defleet'"><span class="mr-2 iconify" data-icon="mdi:car-off" data-inline="false"
                                                   style="zoom: 1.6;"></span>Gestión de defleet </a>
  <a mat-list-item [routerLink]="'/bookings'"><span class="mr-2 iconify" data-icon="mdi:car-arrow-right"
                                                    data-inline="false" style="zoom: 1.6;"></span>Gestión de reservas
  </a>
  <a *ngIf="storageService.getCurrentUser().role_id == 3 || storageService.getCurrentUser().role_id == 1" mat-list-item [routerLink]="'/purchased'"><span
    class="material-icons mr-3">euro</span>Gestión económica </a>
  <a mat-list-item [routerLink]="'/statistics'"><span class="material-icons mr-3">assessment</span>Estadísticas </a>
  <a mat-list-item [routerLink]="'/history'"><span class="material-icons mr-3">history</span>Historial </a>
  <hr>
  <a *ngIf="storageService.getCurrentUser().role_id == 2" mat-list-item [routerLink]="'/vehicles'"><span
    class="material-icons mr-3">directions_car</span>Vehículos </a>
  <mat-accordion *ngIf="storageService.getCurrentUser().role_id == 3 || storageService.getCurrentUser().role_id == 1">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header style="padding: 0 16px !important;">
        <mat-panel-title>
          <span class="material-icons mr-3">directions_car</span><span class="color-white" style="margin-top: 4px;font-size:16px;">Vehículos</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <a mat-list-item [routerLink]="'/vehicles'"><span class="material-icons mr-3">list</span>Listado de vehículos </a>
      <a mat-list-item [routerLink]="'/authorization'"><span class="material-icons mr-3">bookmark_added</span>Autorizaciones
        pendientes </a>
    </mat-expansion-panel>
  </mat-accordion>

  <a *ngIf="storageService.getCurrentUser().role_id == 2 || storageService.getCurrentUser().role_id == 1" mat-list-item [routerLink]="'/garage-vehicles'">
    <span class="material-icons mr-3">construction</span>
    <p style="display: contents;">
      <span matBadge="{{workshopMechanic}}" matBadgeOverlap="false">En taller mecánico</span>
    </p>
  </a>
  <!--    <span class="material-icons mr-3">construction</span>En taller mecánico <span class="number">{{workshopMechanic}}</span></a>-->
  <a *ngIf="storageService.getCurrentUser().role_id == 2 || storageService.getCurrentUser().role_id == 1" mat-list-item [routerLink]="'/garage-sheet'">
    <span class="mr-2 iconify" data-icon="mdi:car-door" data-inline="false" style="zoom: 1.6;"></span>
    <p style="display: contents;">
      <span matBadge="{{workshopChapa}}" matBadgeOverlap="false">En taller chapa</span>
    </p>
    <!--    <span class="mr-2 iconify" data-icon="mdi:car-door" data-inline="false" style="zoom: 1.6;"></span>En taller chapa <span-->
    <!--    class="number">{{workshopChapa}}</span> -->
  </a>
  <a *ngIf="storageService.getCurrentUser().role_id == 2 || storageService.getCurrentUser().role_id == 1" mat-list-item [routerLink]="'/garage-itv'">
    <img class="mr-3" src="assets/itv.png" width="25px" alt="ITV">
    <p style="display: contents;">
      <span matBadge="{{itv}}" matBadgeOverlap="false">En ITV</span>
    </p>
  </a>
  <!--    En ITV<span class="number">{{itv}}</span></a>-->
  <hr>
  <a *ngIf="storageService.getCurrentUser().role_id == 2 || storageService.getCurrentUser().role_id == 1" mat-list-item [routerLink]="'/chat'"><span
    class="material-icons mr-3">chat</span>Chat </a>
  <a *ngIf="storageService.getCurrentUser().role_id == 1" mat-list-item
     [routerLink]="'/company'"><span class="material-icons mr-3">person</span>Empresas </a>
  <a *ngIf="storageService.getCurrentUser().role_id == 2 || storageService.getCurrentUser().role_id == 3 || storageService.getCurrentUser().role_id == 1" mat-list-item
     [routerLink]="'/users'"><span class="material-icons mr-3">person</span>Usuarios </a>
  <a *ngIf="storageService.getCurrentUser().role_id == 1" mat-list-item [routerLink]="'/campa'"><span
    class="material-icons mr-3">store</span>Campas </a>
  <a *ngIf="storageService.getCurrentUser().role_id == 1" mat-list-item [routerLink]="'/task'"><span class="material-icons mr-3">list_alt</span>Tareas
  </a>
  <a *ngIf="storageService.getCurrentUser().role_id == 1 " mat-list-item [routerLink]="'/routes'"><span
    class="material-icons mr-3">edit_road</span>Grupo de tareas</a>
<!--  <hr>-->
<!--  <a mat-list-item [routerLink]="['/logout/1']"><span class="material-icons mr-3">exit_to_app</span>Salir </a>-->
</mat-nav-list>
