import {
  Component,
  OnInit,
  DoCheck,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  Input,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { ReplaySubject, Subject } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { take, takeUntil } from "rxjs/operators";

//store
import { Store } from "@ngrx/store";

import * as moment from "moment";

import { HeaderService } from "../../../service/header.service";
import { CampasService } from "../../../services/campas.service";
import { LocationService } from "../../../services/location.service";
import { UserService } from "../../../services/user.service";
import { TaskService } from "../../../services/task.service";
import { StorageService } from "../../../core/services/storage.service";
import { AppState } from "../../../store/app.state";
import * as CampaActions from "../../../store/campa.action";
//icons
import {
  CAR_ARROW_RIGHT,
  CAR_DOOR,
  CAR_OFF_ICON,
  ITV,
} from "../../../components/shared/svgIcons.registry";

//material
import { MatIconRegistry } from "@angular/material/icon";
import { MatSelect } from "@angular/material/select";
import { IncidenceService } from "src/app/services/incidence.service";
import * as _ from 'lodash';
import { VehicleService } from "src/app/services/vehicle.service";
import { NotificationDialogComponent } from "../../shared/notification-dialog/notification-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { BudgetPendingTaskService } from "src/app/services/budget-pending-task.service";
import { ChecklistComponent } from "@modules/invarat/validation/components/checklist/checklist.component";
import { MessagesDialogComponent } from "../../shared/messages-dialog/messages-dialog.component";
@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, DoCheck, OnDestroy {
  year: Date = new Date();
  title = "Focus";
  identity: any;
  campas: any = [];
  vehicles: any = [];
  regions: any = [];
  provinces: any = [];
  campaSelect: any = [];
  filterCampas: any = 0;
  isExpanded = true;
  regionSelected: any = 0;
  workshopMechanic = 0;
  workshopChapa = 0;
  itv = 0;
  selectedCampa: any[];
  navigationSubscription;
  view: any;

  @Input() filterText: string;

  /** control for the selected campa for multi-selection */
  public campaMultiCtrl: UntypedFormControl = new UntypedFormControl();

  public plateMultiCtrl: UntypedFormControl = new UntypedFormControl();

  selectedPlate;
  /** control for the MatSelect filter keyword multi-selection */
  public campaMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public plateFilterCtrl: UntypedFormControl = new UntypedFormControl();

  public filteredCampasMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(
    1
  );

  public filteredPlate: ReplaySubject<any[]> = new ReplaySubject<any[]>(
    1
  );

  /** local copy of filtered campas to help set the toggle all checkbox campa */
  protected filteredCampasCache: any[] = [];
  protected filteredPlateCache: any[] = [];

  /** flags to set the toggle all checkbox campa */
  isIndeterminate = false;
  isChecked = false;
  ListMenu = [
    {
      rol: 1,
      tipo: "",
      descrip: "",
    },
    {
      rol: 1,
      tipo: "",
      descrip: "",
    },
    {
      rol: 1,
      tipo: "",
      descrip: "",
    },
  ];
  // tslint:disable-next-line:variable-name
  protected _onDestroy = new Subject<void>();
  settingsList: any[] = [];
  notifications: any;
  messages: any;

  @ViewChild("multiSelect", { static: false }) multiSelect: MatSelect;
  @ViewChild("multiSelectPlate", { static: false }) multiSelectPlate: MatSelect;
  @ViewChild(ChecklistComponent) child;


  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public userService: UserService,
    public campasService: CampasService,
    public locationService: LocationService,
    public router: Router,
    public dialog: MatDialog,
    public headerService: HeaderService,
    public taskService: TaskService,
    public storageService: StorageService,
    private incidenceService: IncidenceService,
    private vehicleService: VehicleService,
    private changeDetectorRefs: ChangeDetectorRef,
    private budgetPendingTask: BudgetPendingTaskService,
    private store: Store<AppState>
  ) {
    this.year.getFullYear()

    iconRegistry.addSvgIconLiteral(
      "car-off",
      sanitizer.bypassSecurityTrustHtml(CAR_OFF_ICON)
    );
    iconRegistry.addSvgIconLiteral(
      "car-arrow-right",
      sanitizer.bypassSecurityTrustHtml(CAR_ARROW_RIGHT)
    );
    iconRegistry.addSvgIconLiteral(
      "car-door",
      sanitizer.bypassSecurityTrustHtml(CAR_DOOR)
    );
    iconRegistry.addSvgIconLiteral(
      "itv",
      sanitizer.bypassSecurityTrustHtml(ITV)
    );
    this.loadUserIdentity();
  }

  get isDev(): boolean {
    return !!this.storageService.mode;
  }

  get user(): any {
    return this.storageService.getCurrentUser() || {};
  }

  get company_id(): number {
    return (this.user || {}).company_id || null;
  }

  get items(): any[] {
    return this.storageService.items || [];
  }

  get matBadge(): any {
    return {
      workshopMechanic: this.workshopMechanic,
      workshopChapa: this.workshopChapa,
      itv: this.itv
    }
  }

  ngOnInit(action: string = null): void {
    if (this.child) {
      this.getAllNotifications();
      window.location.reload()
    }
    this.isLoginRoute();
    if (this.storageService.isAuthenticated()) {
      moment.locale("es-ES");
    }
    this.plateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((value) => {
        this.getVehicles()
      });
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.settingsList = (_.find(this.items, { title: 'Configuraciones' }) || {}).items;
        this.getCampas();
      }
    });
    setTimeout(() => {
      this.getAllNotifications();
    }, 3000);

    this.headerService.getfiltros().subscribe((campa) => {
      this.getAllNotifications();

    });
    this.userService.fetchNonification.subscribe((enc: boolean) => {
      if (enc) {
        this.userService.getNotifications().subscribe((res: any) => {
          this.notifications = res;
        });
        const arrayParams = [
          "with[],vehicle",
          "with[],reception",
          "with[],user",
          "with[],model",
          "with[],reads.user",
          "reads,0",
          'per_page,5',
          'page,1',
        ];
        const campas = this.user.campas;
        console.log('CAMPAS', campas)
        campas.forEach((campa) => {
          arrayParams.push(`campas[],${String(campa.id)}`);
        });

        this.userService.getMessages(arrayParams).subscribe((msg: any) => {
          this.messages = msg;
          this.changeDetectorRefs.detectChanges();
        });
      } else {
        this.notifications = [];
      }
    });
    this.userService.fetchNonification.next(Object.keys(this.user).length > 0);
  }

  oninput(elPlate) {
    elPlate.searchSelectInput.nativeElement.setAttribute('oninput', "this.value = this.value.toUpperCase().trim()");
  }

  getAllNotifications() {
    (this.storageService.items || []).forEach((item) => {
      if ('matBadge' in item) {
        console.log('item.navigateTo', item.navigateTo)
        let i = 0
        if (item.navigateTo === '/incidences') {
          const campas = this.headerService.getHeaderCampa();
          const enc = [1].indexOf(this.user.role_id) == -1;
          let arrayParams = [
            'per_page,1',
            'page,1',
            'notNullDamageTypeId,0'
          ];
          arrayParams.push(`statusDamageIds[],1`);

          if (enc && campas.length === 0) {
            item.matBadgeSub.next(0)
            return;
          }
          (!enc ? [] : campas).forEach((campa_id) => {
            arrayParams.push(`vehicleCampaIds[],${String(campa_id)}`);
          });

          const sub = this.incidenceService.getAllDamages(arrayParams).subscribe((res) => {
            item.matBadgeSub.next(res.total)
            sub.unsubscribe();
          }, () => {
            sub.unsubscribe();
          });
        }
        else if (item.navigateTo === '/validation') {
          const campas = this.headerService.getHeaderCampa();
          const enc = [1].indexOf(this.user.role_id) == -1;
          let arrayParams = [
            'per_page,5',
            'page,1',
            'isApproved,0',
            'receptionNull,0'
          ];
          if (enc && campas.length === 0) {
            item.matBadgeSub.next(0)
            return;
          }
          (!enc ? [] : campas).forEach((campa_id) => {
            arrayParams.push(`vehicleCampaIds[],${String(campa_id)}`);
          });

          const sub = this.vehicleService.getQuestionnaire(arrayParams).subscribe((res: any) => {
            item.matBadgeSub.next(res.total)
            sub.unsubscribe();
          }, () => {
            sub.unsubscribe();
          });
        } else if (item.navigateTo === '/budgets') {
          const campas = this.headerService.getHeaderCampa();
          const enc = [1].indexOf(this.user.role_id) == -1;
          let arrayParams = [
            'stateBudgetPendingTaskIds[],1',
            'per_page,5',
            'page,1',
          ];
          if (enc && campas.length === 0) {
            item.matBadgeSub.next(0)
            return;
          }
          (!enc ? [] : campas).forEach((campa_id) => {
            arrayParams.push(`vehicleCampaIds[],${String(campa_id)}`);
          });

          const sub = this.vehicleService.filterVehiclesWithParams(arrayParams).subscribe((res) => {
            item.matBadgeSub.next(res.vehicles.total)
            sub.unsubscribe();
          }, () => {
            sub.unsubscribe();
          });
        } else if (item.navigateTo === '/authorization-pending-tasks') {
          const campas = this.headerService.getHeaderCampa();
          const enc = [1].indexOf(this.user.role_id) == -1;
          let arrayParams = [
            'per_page,5',
            'page,1',
            'pendingTaskCostAuthorized,0',
          ];

          arrayParams.push('with[],lastReception.notApprovedPendingTaskCosts');

          if (enc && campas.length === 0) {
            item.matBadgeSub.next(0)
            return;
          }
          (!enc ? [] : campas).forEach((campa_id) => {
            arrayParams.push(`vehicleCampaIds[],${String(campa_id)}`);
          });

          const sub = this.vehicleService.filterVehiclesWithParams(arrayParams).subscribe((res: any) => {
            item.matBadgeSub.next(res.vehicles.data.length)
            sub.unsubscribe();
          }, () => {
            sub.unsubscribe();
          });
        }

      }
    });
  }

  ngDoCheck(action: string = null): void {
    this.loadUserIdentity();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  loadUserIdentity(action: string = null): void {
    this.identity = this.user;
  }

  isLoginRoute(): boolean {
    return (
      this.router.url.includes("/login") ||
      this.router.url.includes("/recover") ||
      this.router.url.includes("/reset-password")
    );
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredCampasMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: any, b: any) =>
          a && b && a.id === b.id;
      });
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredCampasMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe((val) => {
        if (selectAllValue) {
          this.campaMultiCtrl.patchValue(val);
        } else {
          this.campaMultiCtrl.patchValue([]);
        }
      });
  }

  /** Select campas */
  protected filterCampasMulti() {
    if (!this.campas) {
      return;
    }
    // get the search keyword
    let search = this.campaMultiFilterCtrl.value;
    if (!search) {
      this.filteredCampasCache = this.campas.slice();
      this.filteredCampasMulti.next(this.filteredCampasCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the campas
    this.filteredCampasCache = this.campas.filter(
      (campa) => campa.name.toLowerCase().indexOf(search) > -1
    );
    this.filteredCampasMulti.next(this.filteredCampasCache);
  }

  protected setToggleAllCheckboxState() {
    let filteredLength = 0;
    if (this.campaMultiCtrl && this.campaMultiCtrl.value) {
      this.filteredCampasCache.forEach((el) => {
        if (this.campaMultiCtrl.value.indexOf(el) > -1) {
          filteredLength++;
        }
      });
      this.isIndeterminate =
        filteredLength > 0 && filteredLength < this.filteredCampasCache.length;
      this.isChecked =
        filteredLength > 0 &&
        filteredLength === this.filteredCampasCache.length;
    }
  }

  /** end select campas */

  getRegions(): void {
    if (this.storageService.isAuthenticated()) {
      this.locationService.regions().subscribe((regions) => {
        if (regions.length !== 0) {
          this.regions = regions;
          this.regionChange(this.regionSelected);
        }
      });
    }
  }

  getCampas(): void {
    if (this.storageService.isAuthenticated() && this.campas.length === 0) {
      this.campas = this.storageService
        .getCurrentUser()
        .campas.filter(
          (campa) => {
            return this.user.role_id === 1 ? true : campa.company_id === this.user.company_id
          }
        )
        .sort((a: any, b: any) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        });
      this.getVehicles();
      this.campaMultiCtrl.setValue([...this.campas]);
      this.headerService.headerCampa = [
        ...this.campas.map((item) => item.id),
      ];
      this.filteredCampasMulti.next(this.campas.slice());

      // listen for search field value changes
      this.campaMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterCampasMulti();
          this.setToggleAllCheckboxState();
        });

      // listen for multi select field value changes
      this.campaMultiCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.campaChange(this.campaMultiCtrl.value);
          this.setToggleAllCheckboxState();
        });
    }
  }

  getVehicles(): void {
    const plate = (this.plateFilterCtrl.value || '').trim() || null;
    this.vehicleService.getSearch(this.campas, plate, 4, true).subscribe((res: any) => {
      this.vehicles = res.vehicles.data;
      this.filteredPlateCache = this.vehicles.slice();
      this.plateMultiCtrl.setValue([...this.vehicles]);
      this.filteredPlate.next(this.vehicles.slice());
    });
  }

  regionChange(event) {
    if (this.storageService.isAuthenticated()) {
      if (event === 0 || event.value === 0) {
        this.regionSelected = 0;
      } else if (event.value) {
        this.regionSelected = event.value;
      } else {
        this.regionSelected = event;
      }
      // this.getCampas();
    }
  }

  campaChange(event) {
    if (event.value) {
      this.selectedCampa = [...event.value.map((item) => item.id)];
    } else {
      this.selectedCampa = event.map((item) => item?.id || 0);
    }
    this.headerService.setHeaderCampa(this.selectedCampa);
    this.store.dispatch(
      new CampaActions.ChangeCampa({ ids: this.selectedCampa })
    );
  }

  plateChange(event, fmSelet: any) {
    this.selectedPlate = event.value
    this.router.navigate(['file-vehicle', this.selectedPlate.id]).then(() => {
      this.plateMultiCtrl.reset();
      this.selectedPlate = undefined;
      if (fmSelet) {
        ['mat-form-field-should-float', 'mat-focused'].forEach((className) => fmSelet._elementRef.nativeElement.classList.remove(className));
      }
      this.changeDetectorRefs.detectChanges();
    });
  }

  searchValuesGlobals() {
    if (this.identity) {
      this.taskService.getPendingTasksInWorkshopMechanic().subscribe(
        (result) => {
          this.workshopMechanic = result.data.length;
        },
        (error) => {
          console.error(error);
        }
      );

      this.taskService.getPendingTasksInWorkshopChapa().subscribe(
        (result) => {
          this.workshopChapa = result.data.length;
        },
        (error) => {
          console.error(error);
        }
      );

      this.taskService.getPendingTasksInWorkshopItv().subscribe(
        (result) => {
          this.itv = result.data.length;
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  logout() {
    this.campas = [];
    this.userService.fetchNonification.next(false);
    this.filteredCampasMulti.next([]);
    this.storageService.logout();
  }

  toolbarToggle() {
    this.isExpanded = !this.isExpanded;
  }

  navigateTo(url, params) {
    this.router.navigateByUrl(url, params);
  }

  openNotifications() {
    if (this.notifications?.data) {
      const dialogRef = this.dialog.open(NotificationDialogComponent, {
        width: "800px",
        disableClose: true,
        autoFocus: false,
        data: this.notifications,
      });
    }

  }
  openMessages() {
      const dialogRef = this.dialog.open(MessagesDialogComponent, {
        width: "800px",
        disableClose: true,
        autoFocus: false,
        data: this.messages,
      });

  }
}
