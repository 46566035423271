import { PrivacyComponent } from "./privacy/privacy.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { RecoverComponent } from "./components/recover/recover.component";
import { StatisticsComponent } from "./modules/ald/statistic/components/statistics/statistics.component";
import { EditrouteComponent } from "./components/editroute/editroute.component";
import { AuthorizatedGuard } from "./core/guards/authorizated.guard";
import { SelectivePreloadingStrategyService } from "./selective-preloading-strategy.service";
import { AuthGuard } from "./core/guards/auth.guard";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { StockComponent } from "@modules/stock/stock.component";
import { TransfersComponent } from "./modules/transfers/transfers.component";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "recover", component: RecoverComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  {
    path: "statistics",
    component: StatisticsComponent,
    canActivate: [AuthorizatedGuard],
  },
  {
    path: "editroute/:id",
    component: EditrouteComponent,
    canActivate: [AuthorizatedGuard],
  },
  {
    path: "editroute",
    component: EditrouteComponent,
    canActivate: [AuthorizatedGuard],
  },
  { path: "privacy", component: PrivacyComponent },
  { path: "stock", component: StockComponent },
  { path: "transfers", component: TransfersComponent },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "op-control",
    loadChildren: () =>
      import("./modules/ald/op-control/op-control.module").then(
        (m) => m.OpControlModule
      ),
    data: { preload: true },
  },
  {
    path: "op-commercial",
    loadChildren: () =>
      import("./modules/ald/op-commercial/op-commercial.module").then(
        (m) => m.OpCommercialModule
      ),
    data: { preload: true },
  },
  {
    path: "aditional-task",
    loadChildren: () =>
      import("./modules/ald/aditional-task/aditional-task.module").then(
        (m) => m.AditionalTaskModule
      ),
    data: { preload: true },
  },

  {
    path: "workshops",
    loadChildren: () =>
      import("./modules/ald/workshops/workshops.module").then(
        (m) => m.WorkshopsModule
      ),
    data: { preload: true },
    canActivate: [AuthGuard],
  },
  {
    path: "purchased-operations",
    loadChildren: () =>
      import(
        "./modules/ald/purchased-operations/purchased-operations.module"
      ).then((m) => m.PurchasedOperationsModule),
    data: { preload: true },
  },
  {
    path: "vehicles",
    loadChildren: () =>
      import("./modules/ald/vehicles/vehicles.module").then(
        (m) => m.VehiclesModule
      ),
    data: { preload: true },
  },
  {
    path: "works",
    loadChildren: () =>
      import("./modules/ald/works/works.module").then((m) => m.WorksModule),
    data: { preload: true },
  },
  {
    path: "user",
    loadChildren: () =>
      import("./modules/ald/user/user.module").then((m) => m.UserModule),
    data: { preload: true },
  },
  {
    path: "company",
    loadChildren: () =>
      import("./modules/ald/company/company.module").then(
        (m) => m.CompanyModule
      ),
    data: { preload: true },
  },
  {
    path: "campa",
    loadChildren: () =>
      import("./modules/ald/campa/campa.module").then((m) => m.CampaModule),
    data: { preload: true },
  },
  {
    path: "task",
    loadChildren: () =>
      import("./modules/ald/task/task.module").then((m) => m.TaskModule),
    data: { preload: true },
  },
  {
    path: "history",
    loadChildren: () =>
      import("./modules/ald/history/history.module").then(
        (m) => m.HistoryModule
      ),
    data: { preload: true },
  },
  {
    path: "dashboard-statistic",
    loadChildren: () =>
      import("./modules/ald/statistic/statistic.module").then(
        (m) => m.StatisticModule
      ),
    data: { preload: true },
  },
  {
    path: "validation",
    loadChildren: () =>
      import("./modules/invarat/validation/validation.module").then(
        (m) => m.ValidationModule
      ),
    data: { preload: true },
  },
  {
    path: "defleeting",
    loadChildren: () =>
      import("./modules/defleeting/defleeting.module").then(
        (m) => m.DefleetingModule
      ),
    data: { preload: true },
  },
  {
    path: "reports",
    loadChildren: () =>
      import("./modules/ald/reports/reports.module").then(
        (m) => m.ReportsModule
      ),
    data: { preload: true },
  },
  {
    path: "incidences",
    loadChildren: () =>
      import("./modules/invarat/incidence/incidence.module").then(
        (m) => m.IncidenceModule
      ),
  },
  {
    path: "incidences/:id",
    loadChildren: () =>
      import("./modules/invarat/incidence/incidence.module").then(
        (m) => m.IncidenceModule
      ),
  },
  {
    path: "delivery/:id/:type_delivery_note",
    loadChildren: () =>
      import("./modules/vehicles-exit/components/albaran/albaran.module").then(
        (m) => m.AlbaranModule
      ),
  },
  {
    path: "budgets",
    loadChildren: () =>
      import("./modules/invarat/budget/budget.module").then(
        (m) => m.BudgetModule
      ),
    data: { preload: true },
  },
  {
    path: "authorization-pending-tasks",
    loadChildren: () =>
      import("./modules/invarat/authorization-pending-tasks/authorization-pending-tasks.module").then(
        (m) => m.AuthorizationPendingTasksModule
      ),
    data: { preload: true },
  },
  {
    path: "workshops",
    loadChildren: () =>
      import("./modules/workshops/workshops.module").then(
        (m) => m.WorkshopsModule
      ),
    data: { preload: true },
  },
  {
    path: "authorizations",
    loadChildren: () =>
      import("./modules/authorizations/authorizations.module").then(
        (m) => m.AuthorizationsModule
      ),
    data: { preload: true },
  },
  {
    path: "vehicles-exit",
    loadChildren: () =>
      import("./modules/vehicles-exit/vehicles-exit.module").then(
        (m) => m.VehiclesExitModule
      ),
    data: { preload: true },
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./modules/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
    data: { preload: true },
  },
  {
    path: "ans-invarat",
    loadChildren: () =>
      import("./modules/ald/ans-invarat/ans-invarat.module").then(
        (m) => m.AnsInvaratModule
      ),
    data: { preload: true },
  },
  {
    path: "dashboard-remarketing",
    loadChildren: () =>
      import(
        "./modules/principal-remarketing/principal-remarketing.module"
      ).then((m) => m.PrincipalRemarketingModule),
    data: { preload: true },
  },
  {
    path: "control-states",
    loadChildren: () =>
      import("./modules/control-states/control-states.module").then(
        (m) => m.ControlStatesModule
      ),
    data: { preload: true },
    canActivate: [AuthGuard],
  },
  {
    path: "file-vehicle/:id",
    loadChildren: () =>
      import("./modules/ald/file-vehicles/file-vehicles.module").then(
        (m) => m.FileVehiclesModule
      ),
    data: { preload: true },
  },
  // {
  //   path: "control-pending-task-recovery",
  //   loadChildren: () =>
  //     import("./modules/invarat/control-pending-task-recovery/control-pending-task-recovery.module").then(
  //       (m) => m.ControlPendingTaskRecoveryModule
  //     ),
  //   data: { preload: true },
  // },
  {
    path: "base",
    loadChildren: () =>
      import("./modules/base-component/base-component.module").then(
        (m) => m.BaseComponentModule
      ),
    data: { preload: true },
  },
  { path: "", redirectTo: "dashboard-statistic", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: SelectivePreloadingStrategyService,
    useHash: true,
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
