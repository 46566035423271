import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class IncidenceService {
  isIncidenceUpdate: boolean = false;
  env = environment;
  private apiUrl = this.env.api_url;

  constructor(private http: HttpClient) {}

  updateIncidence(id: number, data: any): any {
    return this.http.put<any>(this.apiUrl + "/incidences/update/" + id, data);
  }

  getAllIncidences(arrayParams): Observable<any> {
    let params = this.getParams(arrayParams);
    return this.http.get<any>(this.apiUrl + "/incidences/getall", { params });
  }

  getAllDamages(arrayParams): Observable<any> {
    let params = this.getParams(arrayParams);
    return this.http.get<any>(this.apiUrl+'/damages', {params});
  }
  
  getStatesDamages(): Observable<any> {
    return this.http.get(this.apiUrl + "/status-damages");
  }

  getSeverityDamages(): Observable<any> {
    return this.http.get(this.apiUrl + "/severity-damages");
  }

  private getParams(arrayParams) {
    let params = new HttpParams();
    arrayParams.map((element) => {
      let param = element.split(",");
      params = params.append(param[0], param[1]);
    });
    return params;
  }
}
