import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {UserService} from '../services/user.service';


@Injectable()
export class IdentityGuard implements CanActivate {

  constructor(
    public userService: UserService,
    public router: Router,
  ) {

  }

  canActivate(): boolean {
    const identity = this.userService.getIdentity();

    if (identity) {
      return true;
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }

  }
}
