import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  env = environment;
  private apiUrl = this.env.api_url;
  public token: string;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  getAll(): any {
    return this.http.get<any>(this.apiUrl + '/grouptasks/getall');
  }

  getById(id: number): any {
    this.token = this.userService.getToken();
    const headers = new HttpHeaders().set('Authorization', this.token);
    return this.http.get<any>(this.apiUrl + '/routes/' + id, {headers});
  }

  createRoute(data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set('Authorization', this.token);
    return this.http.post<any>(this.apiUrl + '/routes', params, {headers});
  }

  updateRoute(data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set('Authorization', this.token);
    return this.http.post<any>(this.apiUrl + '/routes/update', params, {headers});
  }

  storeRouteTasks(data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set('Authorization', this.token);
    return this.http.post<any>(this.apiUrl + '/routes/tasks', params, {headers});
  }
}
