import { Injectable } from '@angular/core';
import { Session } from '../models/session.model';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { Item } from '@core/utils';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  mode: boolean;
  private localStorageService;
  currentSession: Session = null;
  isLoggedIn = false;
  items: any[] = [];

  // store the URL so we can redirect after logging in
  redirectUrl: string | null = null;

  constructor(private router: Router) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
    this.loadItems();
  }

  loadItems() {
    const user: any = (this.currentSession || {}).user || {};
    const item = new Item(user.role_id, user.company_id, user.workshop_id, user.email);
    this.items = item.getItems();
  }

  setCurrentSession(session: Session): void {
    //session.user.workshop_id = 1;
    this.currentSession = session;
    this.loadItems();
    this.localStorageService.setItem('currentUser', JSON.stringify(session));
  }

  loadSessionData(): Session {
    const sessionStr = this.localStorageService.getItem('currentUser');
    return (sessionStr) ? JSON.parse(sessionStr) as Session : null;
  }

  getCurrentSession(): Session {
    return this.currentSession;
  }

  removeCurrentSession(): void {
    localStorage.clear()
    this.currentSession = null;
    this.loadItems();
  }

  getCurrentUser(): User {
    const session: Session = this.getCurrentSession();
    return (session && session.user) ? session.user : null;
  }

  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null);
  }

  getCurrentToken(): string {
    const session = this.getCurrentSession();
    return (session && session.token) ? session.token : null;
  }

  logout(): void {
    this.removeCurrentSession();
    this.router.navigateByUrl('/login');
  }
}
