// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { env } from "./env";

export const environment = {
  entity_id: 1,
  production: false,
  api_url: env.api_url,
  AWSregion: 'eu-west-1',
  AWSaccessKeyId: 'AKIA4MTWL22SYBNCPPDU',
  AWSsecretAccessKey: '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',
  AWSbucketName: 'mcdevfocus'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
