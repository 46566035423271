import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler} from '@angular/common/http';
import {SKIP_INTERCEPTOR_HEADER} from '../config/consts';
import {StorageService} from '../services/storage.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private localAuthService: StorageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.localAuthService.getCurrentToken();
    let tokenizedReq;
    const host = req.url.split('/api')[0];
    if (!this.localAuthService.mode && ['http://localhost:8000', 'https://dev.api.focus.grupomobius.com'].indexOf(host) > -1 ) {
      document.querySelector('body').style.setProperty('--primary-color', '#01aac0');
      this.localAuthService.mode = true;
    }
    if (req.headers.has(SKIP_INTERCEPTOR_HEADER)) {
      const clonedRequest = req.clone({
        headers: req.headers.delete(SKIP_INTERCEPTOR_HEADER)
      });
      return next.handle(clonedRequest);
    } else if (token) {
      if (req.method === 'GET') {
        tokenizedReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          }
        });
      } else {
        tokenizedReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            // 'Content-Type': 'application/json'
          }
        });
      }
      return next.handle(tokenizedReq);
    }
    return next.handle(req);
  }
}
