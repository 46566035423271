import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class StockService {
  env = environment;
  private apiUrl = this.env.api_url;

  constructor(
    private http: HttpClient) { }

  getReceptions(arrayParams = []): Observable<any> {
    let params = this.getParams(arrayParams);
    return this.http.get(this.apiUrl + "/reception", { params });
  }

  getVehiclesInCampa(arrayParams = []): Observable<any> {
    let params = this.getParams(arrayParams);
    params = params.append("with[]", "vehicleModel.brand");
    params = params.append("with[]", "typeModelOrder");
    params = params.append("with[]", "subState.state");
    params = params.append("with[]", "campa");
    params = params.append(
      "with[]",
      "lastReception.approvedPendingTasks.task.subState.state"
    );
    params = params.append("with[]", "lastReception");
    return this.http.get(this.apiUrl + "/vehicles/filter", { params });
  }

  getVehiclesExit(arrayParams = []): Observable<any> {
    let params = this.getParams(arrayParams);
    params = params.append("with[]", "vehicle.typeModelOrder");
    params = params.append("with[]", "vehicle.vehicleModel.brand");
    params = params.append("with[]", "vehicle.lastDeliveryVehicle");
    return this.http.get(this.apiUrl + "/vehicle-exits", { params });
  }

  getVehiclesMove(arrayParams = []): Observable<any> {
    let params = this.getParams(arrayParams);
    params = params.append("with[]", "user");
    params = params.append("with[]", "vehicle");
    params = params.append("with[]", "vehicle.typeModelOrder");
    params = params.append("with[]", "vehicle.vehicleModel.brand");
    params = params.append("with[]", "square.street.zone");
    params = params.append("states[]", "1");
    return this.http.get(this.apiUrl + "/history-locations", { params });
  }

  getVehiclesDelivery(arrayParams = []): Observable<any> {
    let params = this.getParams(arrayParams);
    params = params.append("with[]", "vehicle.typeModelOrder");
    params = params.append("with[]", "vehicle.vehicleModel.brand");
    params = params.append("pendindTaskNull", "0");
    params = params.append("vehicleDeleted", "0");
    return this.http.get(this.apiUrl + "/delivery-vehicles", { params });
  }

  getVehiclesAccesory(arrayParams = []): Observable<any> {
    let params = this.getParams(arrayParams);
    params = params.append("with[]", "vehicle.typeModelOrder");
    params = params.append("with[]", "vehicle.vehicleModel.brand");
    // params = params.append("vehicleDeleted", "0");
    return this.http.get(this.apiUrl + "/accessory-vehicle", { params });
  }

  private getParams(arrayParams) {
    let params = new HttpParams();
    arrayParams.map((element) => {
      let param = element.split(",");
      params = params.append(param[0], param[1]);
    });
    return params;
  }

  deleteDelivery(id, commentary = null): Observable<any> {
    return this.http.delete(this.apiUrl + `/delivery-vehicles/${id}`, {body:{commentary}});
  }

  updateSquartVehicle(body): Observable<any> {
    return this.http.put(`${this.apiUrl}/squares/${body.id}`, body);
  }


}
