import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import {
  MatDialog,
  MatDialogRef, MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { BehaviorSubject, forkJoin, Observable, Subscription } from "rxjs";
import * as _ from 'lodash';
import { SquareService } from "src/app/services/square.service";
import { ZoneService } from "src/app/services/zone.service";
import { StreetService } from "src/app/services/street.service";
import { VehicleService } from "src/app/services/vehicle.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { StorageService } from "@core/services/storage.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-location-dialog",
  templateUrl: "./location-dialog.component.html",
  styleUrls: ["./location-dialog.component.css"],
})
export class LocationDialogComponent implements OnInit {
  showSpinnerSubject = new BehaviorSubject(false);
  showSpinner: Observable<boolean> = this.showSpinnerSubject.asObservable();

  commentary: string;
  textButtomSave: string;
  add_task_id: boolean;
  vehicle: any;
  zones: any[] = [];
  streets: any[] = [];
  squares: any[] = [];
  groupZones: any = {};
  groupStreets: any = {};
  groupSquares: any = {};
  zone_id: number;
  street_id: number;
  square_id: number;
  square_use_id: number;
  street_name: string;
  square_name: string;
  useSquares: any[] = [];

  sub: Subscription;

  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LocationDialogComponent>,
    private _squareService: SquareService,
    private _zoneService: ZoneService,
    private _streetService: StreetService,
    private _vehicleService: VehicleService,
    private storageService: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  get user(): any {
    return this.storageService.getCurrentUser() || {};
  }

  get isContent(): boolean {
    return this.vehicle && this.vehicle.plate && (this.data.subStates || [10]).indexOf(this.vehicle.sub_state_id) > -1 && [1, 2].indexOf(this.user.role_id) === -1;
  }

  get ubication(): string {
    if (this.zone_id || this.street_id || this.square_id) {
      return `${this.groupZones[this.zone_id].name || ''} ${this.street_name || ''} ${this.square_name || ''}`;
    }
    return '';
  }

  ngOnInit(): void {
    this.showSpinnerSubject.next(true);
    let arrayParams = [
      "with[],street.zone",
      "with[],vehicle",
    ];
    const user = this.storageService.getCurrentUser();
    if (!!user.campas) {
      user.campas.forEach((campa) => {
        arrayParams.push(`campaIds[],${String(campa.id)}`);
      });
    }
    this.add_task_id = this.data.add_task_id;
    this.textButtomSave = this.data.textButtomSave || 'Registrar ubicación';
    const sub = forkJoin([this._zoneService.getZones(), this._streetService.getStreets(), this._squareService.getSquaresWithParams(arrayParams), this._vehicleService.getById(this.data.vehicle_id)]).subscribe((res: any) => {
      this.vehicle = _.first(res[3].vehicles.data);
      this.showSpinnerSubject.next(false);
      this.groupSquares = _.groupBy(_.filter(res[2], (x) => {
        x.display_name = `${x.name} - ${!!x.vehicle_id ? '(' + x.vehicle?.plate + ')' : ''}`
        if (this.vehicle && x.vehicle_id === this.vehicle.id) {
          this.square_id = x.id;
          this.square_name = x.name;
          this.street_id = x.street_id;
        }
        if (!!x.vehicle_id) {
          this.useSquares.push(x);
          // return this.vehicle?.square ? x.vehicle_id !== this.vehicle.square.vehicle_id : true;
        }
        return true;
      }), 'street_id');
      this.groupStreets = _.groupBy(_.filter(res[1], (x) => {
        x.squares = !!this.groupSquares[x.id] ? _.filter(this.groupSquares[x.id]) : [];
        if (this.street_id === x.id) {
          this.street_name = x.name;
          this.zone_id = x.zone_id;
        }
        return x.squares.length > 0;
      }), 'zone_id');
      this.zones = _.filter(res[0], (x) => {
        x.streets = !!this.groupStreets[x.id] ? _.filter(this.groupStreets[x.id]) : [];
        return x.streets.length > 0;
      });
      this.zones.forEach((zone) => {
        this.groupZones[zone.id] = zone;
      });
      sub.unsubscribe();
    }, () => {
      sub.unsubscribe();
      this.showSpinnerSubject.next(false);

    });
  }

  onChange(event) {
    const key = event.source.ngControl.name;
    if (key === 'zone_id') {
      this.street_id = null;
      this.square_id = null;
      this.street_name = null;
      this.square_name = null;
    }
    if (key === 'street_id') {
      this[key.replace('id', 'name')] = (_.find(this.groupStreets[this.zone_id], { id: this[key] }) || {}).name || '';
    }
    if (key === 'square_id') {
      this[key.replace('id', 'name')] = (_.find(this.groupSquares[this.street_id], { id: this[key] }) || {}).name || '';
    }
  }

  async updateUbication() {
    const squareUse = _.find(this.useSquares, { id: this.square_id });
    if (squareUse &&  this.vehicle.square && squareUse.id === this.vehicle.square.id) {
      this.dialogRef.close(squareUse);
    } else if (!!squareUse && !!squareUse.vehicle) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "350px",
        disableClose: true,
        autoFocus: false,
        data: {
          title: "Aviso",
          message: `Esta plaza está en uso por el vehículo con matrícula ${squareUse.vehicle.plate}, Desea liberar la plaza?`,
          id: this.square_id,
        },
      });
      dialogRef.afterClosed().subscribe((confirm: Boolean) => {
        if (confirm) {
          this.showSpinnerSubject.next(true);
          const sub = this._squareService.updateSquartVehicle({
            vehicle_id: null,
            id: squareUse.id,
            user_id: this.user.id
          }).subscribe((data) => {
            sub.unsubscribe();
            this.showSpinnerSubject.next(false);
            this.newLocation({
              vehicle_id: this.vehicle.id,
              id: this.square_id,
              user_id: this.user.id
            });
          }, () => {
            this.showSpinnerSubject.next(false);
            sub.unsubscribe();
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Error conectando al servidor...",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          });
        }
      });

    } else {
      this.newLocation({
        vehicle_id: this.vehicle.id,
        id: this.square_id,
        user_id: this.user.id
      });
    }
  }

  newLocation(value) {
    if (this.sub) {
      return
    }
    this.showSpinnerSubject.next(true);
    this.sub = this._squareService.updateSquartVehicle(value).subscribe((data) => {
      this.sub.unsubscribe();
      this.sub = undefined;
      this.showSpinnerSubject.next(false);
      data.commentary = this.commentary;
      this.onSave.emit(data);
      this.dialogRef.close(data);
    }, () => {
      this.showSpinnerSubject.next(false);
      this.sub.unsubscribe();
      this.sub = undefined;
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Error conectando al servidor...",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    });
  }

  cancel() {
    this.onCancel.emit();
    this.dialogRef.close();
  }

}
