<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form validate-form p-l-55 p-r-55 p-t-178" [formGroup]="loginForm" (submit)="login()"
        id="loginForm">
        <div style="margin-bottom: 90px;" [class.m-bottom]="loginInvalid">
          <span class="login100-form-title" style="padding: 15px;">
            <img src="..\..\assets\focus-login-vertical.jpg" class="login__logo">
          </span>
        </div>
        <div style="position: relative" *ngIf="loginInvalid">
          <mat-error class="text-error" style="text-align: center;">
            El nombre de usuario y/o la contraseña son incorrectos.
          </mat-error>
        </div>
        <div style="padding-top: 30px;">
          <div class="wrap-input100 validate-input m-b-16" data-validate="Please enter username">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Correo electrónico </mat-label>
              <input matInput type="email" placeholder="Correo electrónico" formControlName="email" required>
              <mat-icon matSuffix>email</mat-icon>
              <mat-error
                *ngIf="loginForm.get('email').hasError('pattern')&& !loginForm.get('email').hasError('required')">
                Por favor, introduce una dirección de correo electrónico válida.
              </mat-error>
              <mat-error *ngIf="loginForm.get('email').hasError('required')">
                Este campo es requerido.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="wrap-input100 validate-input" data-validate="Please enter password">
            <mat-form-field appearance="outline" style="width: 100%;margin-bottom: 16px;">
              <mat-label>Contraseña</mat-label>
              <input matInput type="password" autocomplete="off" placeholder="Contraseña" formControlName="password"
                [type]="hide ? 'password' : 'text'" (keyup.enter)="login()" required>
              <mat-icon matSuffix (click)="hide = !hide" style="cursor: pointer;" title="Ver contraseña">{{hide ?
                'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="loginForm.get('password').hasError('required')">
                Este campo es requerido.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="container-login100-form-btn" style="margin-bottom: -50px;">
          <button mat-raised-button class="login100-form-btn" type="submit" [class.spinner]="showSpinner"
            [disabled]="disableBtn || showSpinner" color="primary">Acceder
          </button>
        </div>
        <div class="flex-col-c pn-t-130 p-b-40">
          <a [routerLink]="'/recover'" class="link-pass" style="color: #008080e0;"> He olvidado mi contraseña!</a>
        </div>
      </form>
    </div>
  </div>
</div>
