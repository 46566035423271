import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageService } from '@core/services/storage.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import Swal from 'sweetalert2';
import * as _ from 'lodash';

const REQUEST_DEFLEETING = 26;
const TECHNICIAN_ALD = 12;
const ADMIN =  1;
const TASK_REQUEST_DEFLEET = 77;

@Component({
  selector: 'app-defleet-dialog',
  templateUrl: './defleet-dialog.component.html',
  styleUrls: ['./defleet-dialog.component.css']
})
export class DefleetDialogComponent implements OnInit {

  vehicle: any;
  reception: any;
  isChanelDefleeting: any;
  constructor(
    public dialogRef: MatDialogRef<DefleetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private vehicleService: VehicleService,
    private storageService: StorageService,
  ) {
  }

  get user(): any {
    return this.storageService.getCurrentUser() || {};
  }
  get isRequestDefleet(): boolean {
    if (!this.vehicle) {
      return false;
    }
    return !!this.reception.all_approved_pending_tasks.find(el=>el.task_id===TASK_REQUEST_DEFLEET &&  [null,1,2].indexOf(el.state_pending_task_id) > -1 );
  }
  get hasPendingTaskStarted():boolean{
    if (!this.vehicle) {
      return false;
    }
    return this.isRequestDefleet ? !!this.reception.all_approved_pending_tasks.find(el=>[null, 1,2].indexOf(el.state_pending_task_id) > -1 && el.task_id!=TASK_REQUEST_DEFLEET )
    : !!this.reception.all_approved_pending_tasks.find(el=> el.state_pending_task_id == 2);
  }
  get checkDefleeting(): boolean {
    if (!this.vehicle) {
      return false;
    }
    if (_.includes([ADMIN, TECHNICIAN_ALD], this.user.role_id) &&
        this.vehicle?.sub_state_id === REQUEST_DEFLEETING &&
        !this.vehicle?.datetime_defleeting
      ) {
      return true;
    }
    return false;
  }

  get rDefleeting() {
    return REQUEST_DEFLEETING;
  }

  ngOnInit(): void {
    this.vehicle = this.data.vehicle;
    this.reception  = (this.vehicle.reception || this.vehicle.last_reception);
    console.log(this.vehicle, 'DATA', this.reception)
    this.isChanelDefleeting = _.includes([1 , 4], this.vehicle.type_model_order_id)
  }

  onRequest(): void {
    if (this.vehicle.sub_state_id !== this.rDefleeting) {
      const sub = this.vehicleService.updateVehicle(this.vehicle.id, {
        sub_state_id: this.rDefleeting,
      }).subscribe((res) => {
        sub.unsubscribe();

        if (res.vehicle?.original) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: res.vehicle?.original.error,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          this.onClose();
          return
        }
        Swal.fire({
          icon: "success",
          title: "Solicitado defletado",
          text: "",
        });
        this.onClose({isRequestDefleeing: true});
      }, (error) => {
        console.log('error', error)
        sub.unsubscribe();
      });
    } else {
      const r = _.find(this.reception.all_approved_pending_tasks, (pending_task) => pending_task.task.sub_state_id == REQUEST_DEFLEETING);
      const sub = this.vehicleService.cancelDefleeting(this.vehicle.id, {pending_task: r.id}).subscribe((resp) => {
        sub.unsubscribe();
        console.log('resp', resp)
        Swal.fire({
          icon: "success",
          title: "Cancelado solicitud de estudio",
          text: "",
        });
        this.onClose();
      }, (error) => {
        console.log('error', error)
        sub.unsubscribe();
      });
    }
  }


  onDefleeting(): void {
    const sub = this.vehicleService.defleeting(this.vehicle.id).subscribe(
      (res: any) => {
        console.log('DEFLETADOOO',res)
        sub.unsubscribe();
        Swal.fire({
          icon: "success",
          title: res.sub_state_id === 8 ? "Vehículo defletado" : "Vehículo fue retomado",
          text: "",
        });
        this.onClose(res);
      },
      (err: any) => {
        Swal.fire({
          icon: "error",
          title: err.error.message,
          text: "",
        });
        sub.unsubscribe();
      }
    );
  }

  onClose(value: any = null): void {
    this.dialogRef.close(value);
  }

}
