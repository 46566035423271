import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ProvincesComponent } from "./components/config/provinces/provinces.component";
import { RegionsComponent } from "./components/config/regions/regions.component";
import { RoutesComponent } from "./components/config/routes/routes.component";
import { TasksTypesComponent } from "./components/config/tasks-types/tasks-types.component";
import { StatesComponent } from "./components/config/states/states.component";
import { StatesTypesComponent } from "./components/config/states-types/states-types.component";
import { LoginComponent } from "./login/login.component";

// Material design
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatPaginatorIntl,
} from "@angular/material/paginator";
import { RecoverComponent } from "./components/recover/recover.component";
import { EditrouteComponent } from "./components/editroute/editroute.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { UserService } from "./services/user.service";
import { AuthorizationComponent } from "./components/authorization/authorization.component";
import { ConfirmationDialogComponent } from "./components/shared/confirmation-dialog/confirmation-dialog.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from '@angular/material/button';

// Saturn Date Range Picker
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
} from "projects/saturn-datepicker/src/datetime";
import { IDateAdapterComponent } from "./interfaces/date.interface";
import { getEsPaginatorIntl } from "./components/shared/es-paginator-intl";
import { StorageService } from "./core/services/storage.service";
import { AuthorizatedGuard } from "./core/guards/authorizated.guard";
import { TokenInterceptorService } from "./core/interceptors/token-interceptor.service";
import { HttpErrorInterceptor } from "./core/interceptors/http-error-interceptor.service";
import { IdentityGuard } from "./services/identity.guard";
import { CreateCustomerDialogComponent } from "./components/shared/create-customer-dialog/create-customer-dialog.component";
import { UploadFileDialogComponent } from "./components/shared/upload-file-dialog/upload-file-dialog.component";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { StoreModule } from "@ngrx/store";
import { ReactiveFormsModule } from "@angular/forms";

// Reducers
import { campaReducer } from "./store/campa.reducer";
import { CreateTransportDialogComponent } from "./components/shared/create-transport-dialog/create-transport-dialog.component";
import { SharedModule } from "@modules/shared.module";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { MenuModule } from "./components/menu/menu.module";
import { PendingTasksDialogComponent } from "./components/shared/pending-tasks-dialog/pending-tasks-dialog.component";
import { InitTasksDialogComponent } from "./components/shared/init-tasks-dialog/init-tasks-dialog.component";
import { InitCheckListRepairDialogComponent } from "./components/shared/init-check-list-repair-dialog/init-check-list-repair-dialog.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { StockComponent } from "@modules/stock/stock.component";
import { ServiceWorkerModule } from '@angular/service-worker';
import { TransfersComponent } from './modules/transfers/transfers.component';
import { LocationDialogComponent } from "./components/shared/location-dialog/location-dialog.component";
import { DefleetDialogComponent } from "./components/shared/defleet-dialog/defleet-dialog.component";
import { NotificationDialogComponent } from "./components/shared/notification-dialog/notification-dialog.component";
import { MessagesDialogComponent } from "./components/shared/messages-dialog/messages-dialog.component";
const CUSTOMS_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: "short", year: "numeric", day: "numeric" },
  },
  display: {
    dateInput: "input",
    monthYearLabel: { year: "numeric", month: "short" },
    dateA11yLabel: { year: "numeric", month: "short", day: "numeric" },
    monthYearA11yLabel: { year: "numeric", month: "short" },
  },
};

@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        ProvincesComponent,
        RegionsComponent,
        RoutesComponent,
        TasksTypesComponent,
        StatesComponent,
        StatesTypesComponent,
        LoginComponent,
        RecoverComponent,
        EditrouteComponent,
        AuthorizationComponent,
        ResetPasswordComponent,
        ConfirmationDialogComponent,
        NotificationDialogComponent,
        MessagesDialogComponent,
        CreateCustomerDialogComponent,
        UploadFileDialogComponent,
        DefleetDialogComponent,
        CreateTransportDialogComponent,
        PendingTasksDialogComponent,
        LocationDialogComponent,
        InitTasksDialogComponent,
        InitCheckListRepairDialogComponent,
        PrivacyComponent,
        StockComponent,
        TransfersComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatFormFieldModule,
        SharedModule,
        ReactiveFormsModule,
        StoreModule.forRoot({
            campa: campaReducer,
        }, {}),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        MenuModule,
        MatButtonModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    exports: [],
    providers: [
        MatDatepickerModule,
        CookieService,
        IdentityGuard,
        UserService,
        StorageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        AuthorizatedGuard,
        Location,
        { provide: MAT_DATE_LOCALE, useValue: "es-Es" },
        { provide: DateAdapter, useClass: IDateAdapterComponent },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOMS_DATE_FORMATS },
        { provide: MatPaginatorIntl, useValue: getEsPaginatorIntl() },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
