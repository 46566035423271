import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {UserService} from '../services/user.service';
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatesService {

  env = environment;
  private apiUrl = this.env.api_url;
  public token: string;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  getStates(company_id = null): any {
    let params = new HttpParams();
    if (company_id) {
      params = params.append('companies[]', String(company_id));
    }
    return this.http.get<any>(this.apiUrl + '/states/getall', {params}).pipe(map((res: any) => {
      return res;
    }));
  }

  getSubStates(states: any [] = []): Observable<any> {
    let params = new HttpParams();
    params = params.append('with', 'state');
    states.forEach((state) => {
      params = params.append('stateIds[]', String(state.id));
    })
    return this.http.get(this.apiUrl + '/sub-states/getall', {params});
  }

  getTradeStates() {
    return this.http.get<any>(this.apiUrl + '/trade-states');
  }

  getVehiclesByTradeState(data) {
    return this.http.post<any>(this.apiUrl + '/vehicles/by-trade-state/company', data);
  }


}
