<!--<div class="bodyLogin">-->
<!--  <div class="container ">-->
<!--    <div class="row justify-content-center pt-3">-->
<!--      <img src="..\..\assets\logo.png" style="width:300px; ">-->
<!--    </div>-->

<!--    <div class="row justify-content-center mt-5">-->
<!--      <form class="col-md-6 text-center" id="loginForm">-->
<!--        <div class="form-group">-->
<!--          <input type="text" class="form-control" placeholder="Email" id="passField">-->
<!--        </div>-->

<!--        <div class="row">-->
<!--          <div class="col-6">-->
<!--            <button type="button" class="btn btn-block mt-4 bgLightGreen" onclick="Login()">Aceptar</button>-->
<!--          </div>-->
<!--          <div class="col-6">-->
<!--            <button type="button" class="btn btn-block mt-4 bgLightGreen" (click)="goLoginPage()">-->
<!--&lt;!&ndash;              <mat-icon>Inicio</mat-icon>&ndash;&gt;-->
<!--              <span>Volver</span>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->


<!--      </form>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form validate-form p-l-55 p-r-55 p-t-178" [formGroup]="form"
            id="loginForm" (submit)="verifyEmail()">
        <div style="margin-bottom: 90px;" [class.m-bottom]="loginInvalid">
            <span class="login100-form-title" style="padding: 15px;">
						    <img src="..\..\assets\focus-login-vertical.jpg" class="login__logo">
					  </span>
        </div>

        <div style="padding-top: 0; position: relative;">
          <label class="label-test"><h2>Recuperar mi contraseña</h2></label>
          <div class="wrap-input100 validate-input m-b-16" data-validate="Please enter username">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Correo electrónico</mat-label>
              <input matInput placeholder="Correo electrónico" formControlName="email" required>
              <mat-icon matSuffix>email</mat-icon>
              <mat-error
                *ngIf="form.get('email').hasError('pattern')&& !form.get('email').hasError('required')">
                Por favor, introduce una dirección de correo electrónico válida.
              </mat-error>
              <mat-error
                *ngIf="form.get('email').hasError('required')">
                Este campo es requerido.
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="container-login100-form-btn" style="margin-bottom: -50px;">
          <button mat-raised-button class="login100-form-btn" type="submit" [class.spinner]="showSpinner | async"
                  [disabled]="disableBtn || (showSpinner | async)" color="primary">Enviar
          </button>
        </div>


        <div class="flex-col-c p-t-170 p-b-40">
          <span class="txt1 p-b-9">
            <a [routerLink]="'/login'" class="txt2" style="color: #008080e0;"> Volver</a>
          </span>
        </div>
      </form>
    </div>
  </div>
</div>
