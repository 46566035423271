import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/services/user.service';
import * as moment from 'moment';
import { VehicleService } from 'src/app/services/vehicle.service';
import { Router } from '@angular/router';
import { NavigationExtras } from '@angular/router';
import { HeaderService } from 'src/app/service/header.service';

export interface IDialogMessage {
  data: any;
  total: number;
}

export class Message {
  id: Number;
  comment: string;
  vehicle: any;
  type: string;
  user: any;
  model: any;
  created_at: string;
  approval_external: any;
  constructor(o: any = {}) {
    o = !!o ? o : {};
    this.id = o.id || 0;
    this.comment = o.comment || '';
    this.vehicle = o.vehicle || {};
    this.user = o.user || {};
    this.model = o.model || {};
    this.created_at = o.created_at ? moment.utc(o.created_at).local().format('DD/MM/YYYY HH:mm'): '-';
    this.approval_external = o.model?.approval_external;
  }
}

@Component({
  selector: 'app-messages-dialog',
  templateUrl: './messages-dialog.component.html',
  styleUrls: ['./messages-dialog.component.css']
})
export class MessagesDialogComponent implements OnInit {

  messages: Array<Message> = new Array<Message>();
  event: any = {};
  loadingSubject = new BehaviorSubject(true);
  loadingDataSubject = new BehaviorSubject(false);
  isLoading: Observable<boolean>;
  isLoadingData: Observable<boolean>;
  noVehicleData: Observable<boolean> = of(true);
  dataSource = new MatTableDataSource<any>();
  matriculaFilter: string = '';

  constructor(
    public dialogRef: MatDialogRef<MessagesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogMessage,
    private _cdr: ChangeDetectorRef,
    private userService: UserService,
    private vehicleService: VehicleService,
    public router: Router,
    private headerService: HeaderService

  ) {
  }

  get objColumns(): any {
    return {
      view: "Ver",
      created_at: "Fecha",
      user: "Usuario",
      plate: "Matricula",
      approval_external: "Estado",
      comment: "Comentario"
    }
  }

  get displayedColumns(): string[] {
    return _.keys(this.objColumns);
  }

  ngOnInit(): void {
    const event: any = _.assign(
      {
        length: 0,
        pageIndex: 0,
        pageSize: 5,
        previousPageIndex: 0,
      },
      {}
    );

    this.event = event;
    this.isLoading = this.loadingSubject.asObservable();
    this.isLoadingData = this.loadingDataSubject.asObservable();
    this.messages = this.data.data.map(x => new Message(x));
    this.dataSource = new MatTableDataSource(this.messages);
    this.dataSource.filterPredicate = (data: Message, filter: string) => {
      return data.vehicle.plate.toLowerCase().includes(filter);
    };
    setTimeout(() => {
      this.event.length = this.data.total;
      this._cdr.detectChanges();
    }, 500);
  }

  applyMatriculaFilter() {
    this.dataSource.filter = this.matriculaFilter.trim().toLowerCase();
  }

  getNotificationAll(paginate = {}) {
    const event: any = _.assign(
      {
        length: 0,
        pageIndex: 0,
        pageSize: 5,
        previousPageIndex: 0,
      },
      paginate
    );

    this.event = event;
    const arrayParams = [
      "with[],model.approvalExternal",
      "with[],vehicle",
      "with[],reception",
      "with[],user",
      "with[],model",
      "with[],reads.user",
      "reads,0",
      `per_page,${this.event.pageSize}`,
      `page,${this.event.pageIndex + 1}`,
    ];
    const campas = this.headerService.getHeaderCampa();
    campas.forEach((campa_id) => {
      arrayParams.push(`campas[],${String(campa_id)}`);
    });
    if (!!this.matriculaFilter.trim().toLowerCase()){
      arrayParams.push(`plate,${this.matriculaFilter.trim().toLowerCase()}`);
    }
    this.userService.getMessages(arrayParams).subscribe((res: any) => {
      console.log('getMessages', res.data)
      this.messages = res.data.map(x => new Message(x));
      this.dataSource = new MatTableDataSource(this.messages);
      setTimeout(() => {
        this.event.length = res.total;
        this._cdr.detectChanges();
      }, 500);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  goTo(row) {
    this.userService.readMessage(row.id).subscribe((x)=>{
      this.userService.fetchNonification.next(true);
    });
    this.dialogRef.close();
    this.vehicleService.GetPlateByURLFileVehicle = row.vehicle;
    this.router.navigate(["file-vehicle", row.vehicle.id],{ queryParams: {message_id:row.id}});
  }
}
