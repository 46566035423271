import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { HttpClient, HttpParams } from '@angular/common/http';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  env = environment;
  private apiUrl = this.env.api_url;
  constructor(
    private http: HttpClient,
    private localAuthService: StorageService) {

  }

  getParams(arrayParams = []): HttpParams {
    let params = new HttpParams();
    const arr = Array.isArray(arrayParams) ? arrayParams : [];
    arr.forEach(param => {
      const split = param.split(',');
      if (split.length === 2) {
        params = params.append(split[0].trim(), split[1].trim());
      }
    });
    return params;
  }

  public exportAsExcelFile(json: any[], excelFileName: string, colWch = {}): void {
    function fitToColumn(cols) {
      // get maximum character of each column
      // const wch = Math.max(...cols.map(a => a ? a.toString().length : 0));
      return cols.map((a) => {
        const wch = colWch[a] || a.toString().length;
        return { wch };
      });
    }
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet['!cols'] = fitToColumn(_.keys(_.first(json)));

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    const date = moment(new Date()).format('YYYY-MM-DD');
    FileSaver.saveAs(data, fileName + '_' + date + EXCEL_EXTENSION);
  }

  export(endPoint, fileName, arrayParams): Promise<any> {
    let params = this.getParams(arrayParams);
    return this.http.get(`${this.apiUrl}${endPoint}`, { responseType: 'blob', params }).toPromise()
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName || "filename.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again         
      });
  }
}