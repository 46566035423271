<div class="container py-5">
  <div class="row">
    <mat-card style="width: 100%">
      <mat-card-header>
        <div class="col-8">
          <p class="greenLink">
            <a [routerLink]="'/dashboard'">Inicio </a> >
            <a>Inventario </a>
          </p>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="row mt-2">
          <div class="col">
            <div class="clearfix col mt-4">
              <div class="
                  float-md-left
                  text-center
                  mx-auto
                  flex-column
                  d-flex
                  align-items-baseline
                ">
                <!-- <mat-form-field
                  appearance="outline"
                  class="mx-auto w-100 w-md-50 pb-0"
                  style="padding-bottom: 0px !important"
                > -->
                <mat-label>Seleccione tipo de busqueda</mat-label>
                <!-- <mat-select
                    [(value)]="selectedType"
                    (selectionChange)="getStockAll()"
                  >
                    <mat-option value="in">Entrada</mat-option>
                    <mat-option value="stock">En Campa</mat-option>
                    <mat-option value="out">Salida</mat-option>
                  </mat-select> -->

                <mat-radio-group class="mt-3 mb-3 row" [(ngModel)]="selectedType" (change)="getStockAll()"
                  aria-label="Seleccione tipo de busqueda">
                  <mat-radio-button class="col" value="in">Entrada</mat-radio-button>
                  <mat-radio-button class="col" value="stock">Stock</mat-radio-button>
                  <mat-radio-button class="col" value="out">Traslados</mat-radio-button>
                  <mat-radio-button class="col" value="delivery">Entregas</mat-radio-button>
                  <mat-radio-button class="col" value="accesories">Accesorios</mat-radio-button>
                </mat-radio-group>
                <!-- </mat-form-field> -->
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button class="btn btn-primary me-md-2" type="button" (click)="applyfilter = !applyfilter">
                    <mat-icon style="font-size: 20px !important">filter_list</mat-icon>
                    Filtrar
                  </button>
                  <button class="btn btn-primary me-md-2" type="button" (click)="importAsXlsx()">
                    <mat-icon style="font-size: 20px !important">file_download</mat-icon>
                    Excel
                  </button>
                </div>
              </div>
            </div>

            <form *ngIf="applyfilter" [formGroup]="filterFormIn">
              <div *ngIf="selectedType === 'in'" class="row p-3">
                <div class="clearfix">
                  <div class="clearfix ml-3">
                    <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                      style="padding-bottom: 0px !important">
                      <mat-label>Filtrar por Matrícula</mat-label>
                      <input matInput oninput="this.value = this.value.toUpperCase().trim()" (keyup)="getStockAll()"
                        formControlName="plate" autocomplete="off" />
                    </mat-form-field>
                  </div>
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field appearance="outline" class="mx-auto w-50 w-md-50 pb-0"
                      style="padding-bottom: 0px !important">
                      <mat-label>Fecha desde</mat-label>
                      <input matInput #ref formControlName="searchDateFrom"
                        [matDatepicker]="pickerFrom" />
                      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                      <mat-datepicker #pickerFrom></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="mx-auto w-50 w-md-50 pb-0" style="padding-left: 1em;">
                      <mat-label>Fecha hasta</mat-label>
                        <input readonly matInput [matDatepicker]="pickerTo"
                          formControlName="searchDateTo">
                        <mat-datepicker-toggle matSuffix [for]="pickerTo">
                        </mat-datepicker-toggle>
                      <mat-datepicker #pickerTo></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>

                <!--div class="clearfix ml-3">
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field
                      appearance="outline"
                      class="mx-auto w-100 w-md-50 pb-0"
                      style="padding-bottom: 0px !important"
                    >
                      <mat-select
                        formControlName="campas"
                        placeholder="Filtrar por Campa"
                        [multiple]="true"
                        (selectionChange)="getStockAll()"
                        #multiSelectBrand
                      >
                        <mat-option
                          *ngFor="let campa of campaList"
                          [value]="campa"
                        >
                          {{ campa.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div-->

                <div class="clearfix ml-3">
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                      style="padding-bottom: 0px !important">
                      <mat-select formControlName="brands" placeholder="Filtrar por Marcas" [multiple]="true"
                        (selectionChange)="getStockAll()" #multiSelectBrand>
                        <mat-option *ngFor="let brand of brands" [value]="brand">
                          {{ brand.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="clearfix ml-3">
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                      style="padding-bottom: 0px !important">
                      <mat-label>Tipo de Negocio</mat-label>
                      <mat-select formControlName="business" placeholder="Filtrar por Negocio" [multiple]="true"
                        (selectionChange)="getStockAll()" #multiSelectBrand>
                        <mat-option *ngFor="let b of typeModelOrders" [value]="b">
                          {{ b.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="clearfix ml-3">
                  <button mat-raised-button class="btn-green float-right clear-filter" style="margin-left: 5px"
                    (click)="cleanFilters()">
                    <mat-icon style="font-size: 20px !important">delete_outline</mat-icon>
                    Limpiar
                  </button>
                </div>
              </div>
            </form>

            <form *ngIf="applyfilter" [formGroup]="filterFormStock" class="ml-3">
              <div *ngIf="selectedType === 'stock'" class="row">
                <div class="clearfix ml-3">
                  <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                    style="padding-bottom: 0px !important">
                    <mat-label>Filtrar por Matrícula</mat-label>
                    <input matInput oninput="this.value = this.value.toUpperCase().trim()" (keyup)="getStockAll()"
                      [(ngModel)]="plateStock" autocomplete="off" [ngModelOptions]="{ standalone: true }" />
                  </mat-form-field>
                </div>
                <div class="clearfix">
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field appearance="outline" class="mx-auto w-50 w-md-50 pb-0"
                      style="padding-bottom: 0px !important">
                      <mat-label>Fecha de recepción desde</mat-label>
                      <input matInput #ref formControlName="searchDateFrom"
                        [matDatepicker]="pickerFrom" />
                      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                      <mat-datepicker #pickerFrom></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="mx-auto w-50 w-md-50 pb-0" style="padding-left: 1em;">
                      <mat-label>Fecha de recepción hasta</mat-label>
                        <input readonly matInput [matDatepicker]="pickerTo"
                          formControlName="searchDateTo">
                        <mat-datepicker-toggle matSuffix [for]="pickerTo">
                        </mat-datepicker-toggle>
                      <mat-datepicker #pickerTo></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <!--div class="clearfix ml-3">
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field
                      appearance="outline"
                      class="mx-auto w-100 w-md-50 pb-0"
                      style="padding-bottom: 0px !important"
                    >
                      <mat-select
                        formControlName="campas"
                        placeholder="Filtrar por Campa"
                        [multiple]="true"
                        (selectionChange)="getStockAll()"
                        #multiSelectBrand
                      >
                        <mat-option
                          *ngFor="let campa of campaList"
                          [value]="campa"
                        >
                          {{ campa.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div-->

                <div class="clearfix ml-3">
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                      style="padding-bottom: 0px !important">
                      <mat-select formControlName="stocks" placeholder="Filtrar por Estado" [multiple]="true"
                        (selectionChange)="getStockAll()" #multiSelectBrand>
                        <mat-option *ngFor="let state of statesList" [value]="state">
                          {{ state.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="clearfix ml-3">
                  <button mat-raised-button class="btn-green float-right clear-filter" style="margin-left: 5px"
                    (click)="cleanFilters()">
                    <mat-icon style="font-size: 20px !important">delete_outline</mat-icon>
                    Limpiar
                  </button>
                </div>
              </div>
            </form>

            <form *ngIf="applyfilter" [formGroup]="filterFormOut">
              <div *ngIf="selectedType === 'out'" class="row p-3">
                <div class="clearfix ml-3">
                  <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                    style="padding-bottom: 0px !important">
                    <mat-label>Filtrar por Matrícula</mat-label>
                    <input matInput oninput="this.value = this.value.toUpperCase().trim()" (keyup)="getStockAll()"
                      [(ngModel)]="plate" autocomplete="off" [ngModelOptions]="{ standalone: true }" />
                  </mat-form-field>
                </div>
                <div class="clearfix">
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field appearance="outline" class="mx-auto w-50 w-md-50 pb-0"
                      style="padding-bottom: 0px !important">
                      <mat-label>Fecha desde</mat-label>
                      <input matInput #ref formControlName="searchDateFrom"
                        [matDatepicker]="pickerFrom" />
                      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                      <mat-datepicker #pickerFrom></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="mx-auto w-50 w-md-50 pb-0" style="padding-left: 1em;">
                      <mat-label>Fecha hasta</mat-label>
                        <input readonly matInput [matDatepicker]="pickerTo"
                          formControlName="searchDateTo">
                        <mat-datepicker-toggle matSuffix [for]="pickerTo">
                        </mat-datepicker-toggle>
                      <mat-datepicker #pickerTo></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="clearfix ml-3">
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                      style="padding-bottom: 0px !important">
                      <mat-select formControlName="brands" placeholder="Filtrar por Marcas" [multiple]="true"
                        (selectionChange)="getStockAll()" #multiSelectBrand>
                        <mat-option *ngFor="let brand of brands" [value]="brand">
                          {{ brand.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="clearfix ml-3">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-select formControlName="business" placeholder="Filtrar por Negocio" [multiple]="true"
                      (selectionChange)="getStockAll()" #multiSelectModel>
                      <mat-option *ngFor="let b of typeModelOrders" [value]="b">
                        {{ b.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="clearfix ml-3">
                  <button mat-raised-button class="btn-green float-right clear-filter" style="margin-left: 5px"
                    (click)="cleanFilters()">
                    <mat-icon style="font-size: 20px !important">delete_outline</mat-icon>
                    Limpiar
                  </button>
                </div>
              </div>
            </form>

            <form *ngIf="applyfilter" [formGroup]="filterFormDelivery">
              <div *ngIf="selectedType === 'delivery'" class="row p-3">
                <div class="clearfix ml-3">
                  <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                    style="padding-bottom: 0px !important">
                    <mat-label>Filtrar por Matrícula</mat-label>
                    <input matInput oninput="this.value = this.value.toUpperCase().trim()" (keyup)="getStockAll()"
                      [(ngModel)]="plateDelivery" autocomplete="off" [ngModelOptions]="{ standalone: true }" />
                  </mat-form-field>
                </div>
                <div class="clearfix">
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field appearance="outline" class="mx-auto w-50 w-md-50 pb-0"
                      style="padding-bottom: 0px !important">
                      <mat-label>Fecha desde</mat-label>
                      <input matInput #ref formControlName="searchDateFrom"
                        [matDatepicker]="pickerFrom" />
                      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                      <mat-datepicker #pickerFrom></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="mx-auto w-50 w-md-50 pb-0" style="padding-left: 1em;">
                      <mat-label>Fecha hasta</mat-label>
                        <input readonly matInput [matDatepicker]="pickerTo"
                          formControlName="searchDateTo">
                        <mat-datepicker-toggle matSuffix [for]="pickerTo">
                        </mat-datepicker-toggle>
                      <mat-datepicker #pickerTo></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="clearfix ml-3">
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                      style="padding-bottom: 0px !important">
                      <mat-select formControlName="brands" placeholder="Filtrar por Marcas" [multiple]="true"
                        (selectionChange)="getStockAll()" #multiSelectBrand>
                        <mat-option *ngFor="let brand of brands" [value]="brand">
                          {{ brand.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="clearfix ml-3">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-select formControlName="business" placeholder="Filtrar por Negocio" [multiple]="true"
                      (selectionChange)="getStockAll()" #multiSelectModel>
                      <mat-option *ngFor="let b of typeModelOrders" [value]="b">
                        {{ b.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="clearfix ml-3">
                  <button mat-raised-button class="btn-green float-right clear-filter" style="margin-left: 5px"
                    (click)="cleanFilters()">
                    <mat-icon style="font-size: 20px !important">delete_outline</mat-icon>
                    Limpiar
                  </button>
                </div>
              </div>
            </form>
            <form *ngIf="applyfilter" [formGroup]="filterFormAccesories">
              <div *ngIf="selectedType === 'accesories'" class="row p-3">
                <div class="clearfix ml-3">
                  <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                    style="padding-bottom: 0px !important">
                    <mat-label>Filtrar por Matrícula</mat-label>
                    <input matInput oninput="this.value = this.value.toUpperCase().trim()" (keyup)="getStockAll()"
                      [(ngModel)]="plateAccesories" autocomplete="off" [ngModelOptions]="{ standalone: true }" />
                  </mat-form-field>
                </div>
                <div class="clearfix ml-3">
                  <div class="float-md-left text-center mx-auto">
                    <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                      style="padding-bottom: 0px !important">
                      <mat-select formControlName="brands" placeholder="Filtrar por Marcas" [multiple]="true"
                        (selectionChange)="getStockAll()" #multiSelectBrand>
                        <mat-option *ngFor="let brand of brands" [value]="brand">
                          {{ brand.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="clearfix ml-3">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-select formControlName="business" placeholder="Filtrar por Negocio" [multiple]="true"
                      (selectionChange)="getStockAll()" #multiSelectModel>
                      <mat-option *ngFor="let b of typeModelOrders" [value]="b">
                        {{ b.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="clearfix ml-3">
                  <mat-form-field appearance="outline" class="mx-auto w-100 w-md-50 pb-0"
                    style="padding-bottom: 0px !important">
                    <mat-label>Filtrar por Accesorio</mat-label>
                    <input matInput oninput="this.value = this.value.toUpperCase().trim()" (keyup)="getStockAll()"
                    [(ngModel)]="accesoryName" autocomplete="off" [ngModelOptions]="{ standalone: true }" />
                  </mat-form-field>
                </div>
                <div class="clearfix ml-3">
                  <button mat-raised-button class="btn-green float-right clear-filter" style="margin-left: 5px"
                    (click)="cleanFilters()">
                    <mat-icon style="font-size: 20px !important">delete_outline</mat-icon>
                    Limpiar
                  </button>
                </div>
              </div>
            </form>
            <div *ngIf="selectedType === 'out'">
              <table #table mat-table matTableExporter #exporter="matTableExporter"
                [dataSource]="vehiclesOutCampaDataSource" class="mat-elevation-z8 table" matSort #sort="matSort"
                (matSortChange)="sortData($event)">
                <ng-container matColumnDef="created_at">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ objOutColumns["created_at"] }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.created_at | date: "dd-MM-yyyy HH:mm" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.plate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ objOutColumns["vehicle.plate"] }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.plate || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.vehicle_model.brand.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ objOutColumns["vehicle.vehicle_model.brand.name"] }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.vehicle_model?.brand?.name || '-'
                    }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.vehicle_model.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ objOutColumns["vehicle.vehicle_model.name"] }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.vehicle_model?.name || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.type_model_order.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ objOutColumns["vehicle.type_model_order.name"] }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.type_model_order?.name || "-" }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="vehicle.last_delivery_vehicle.data_delivery.note">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ objOutColumns["vehicle.last_delivery_vehicle.data_delivery.note"] }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.last_delivery_vehicle?.data_delivery?.note || "-" }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="vehiclesOutCampaDisplayedColumns"></tr>
                <tr mat-row *matRowDef="
                    let row;
                    columns: vehiclesOutCampaDisplayedColumns
                  "></tr>
              </table>
              <mat-card *ngIf="isLoadingDataOut | async" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " class="spinner loading-excel">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
              </mat-card>
              <mat-paginator [length]="event.length || 0" [pageSize]="event.pageSize || 50" [pageSizeOptions]="[
                  5,
                  10,
                  15,
                  100
                ]" class="m-auto mat-elevation-z8" (page)="getStockAll($event)">
              </mat-paginator>
            </div>

            <div *ngIf="selectedType === 'delivery'">
              <table #table mat-table matTableExporter #exporter="matTableExporter"
                [dataSource]="vehiclesDeliveryDataSource" class="mat-elevation-z8 table" matSort #sort1="matSort"
                (matSortChange)="sortData($event)">
                <ng-container matColumnDef="created_at">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Entregado</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.created_at | date: "dd-MM-yyyy HH:mm" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="campa.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Campa</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.campa?.name || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="data_delivery.customer">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.data_delivery?.customer || "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.plate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Matrícula</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.plate || "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.vehicle_model.brand.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Marca</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.vehicle_model?.brand?.name || "-"}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.vehicle_model.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Modelo</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.vehicle_model?.name || "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.type_model_order.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Negocio</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.type_model_order?.name || "-"}}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="vehiclesDeliveryDisplayedColumns"></tr>
                <tr mat-row *matRowDef="
                    let row;
                    columns: vehiclesDeliveryDisplayedColumns
                  "></tr>
              </table>
              <mat-card *ngIf="isLoadingDataDelivery | async" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " class="spinner loading-excel">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
              </mat-card>
              <mat-paginator [length]="event.length || 0" [pageSize]="event.pageSize || 50" [pageSizeOptions]="[
                  5,
                  10,
                  15,
                  100
                ]" class="m-auto mat-elevation-z8" (page)="getStockAll($event)">
              </mat-paginator>
            </div>

            <div *ngIf="selectedType === 'accesories'">
              <table #table mat-table matTableExporter #exporter="matTableExporter"
                [dataSource]="vehiclesAccesoriesDataSource" class="mat-elevation-z8 table" matSort #sort1="matSort"
                (matSortChange)="sortData($event)">
                <ng-container matColumnDef="created_at">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Registrado</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.created_at | date: "dd-MM-yyyy HH:mm" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="accessory.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Accesorio</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.accessory?.name || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.plate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Matrícula</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.plate || "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.vehicle_model.brand.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Marca</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.vehicle_model?.brand?.name || "-"}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.vehicle_model.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Modelo</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.vehicle_model?.name || "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.type_model_order.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Negocio</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.type_model_order?.name || "-"}}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="vehiclesAccesoriesDisplayedColumns"></tr>
                <tr mat-row *matRowDef="
                    let row;
                    columns: vehiclesAccesoriesDisplayedColumns
                  "></tr>
              </table>
              <mat-card *ngIf="isLoadingDataAccesories | async" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " class="spinner loading-excel">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
              </mat-card>
              <mat-paginator [length]="event.length || 0" [pageSize]="event.pageSize || 50" [pageSizeOptions]="[
                  5,
                  10,
                  15,
                  100
                ]" class="m-auto mat-elevation-z8" (page)="getStockAll($event)">
              </mat-paginator>
            </div>

            <div *ngIf="selectedType === 'in'">
              <table #table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="stockDataSource"
                class="mat-elevation-z8 table" matSort #sort2="matSort" (matSortChange)="sortData($event)">
                <ng-container matColumnDef="vehicle.plate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Matrícula</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle ? element.vehicle.plate : "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="created_at">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Recepción</th>
                  <td mat-cell *matCellDef="let element">
                    {{ setDate(element.created_at) }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="type_reception.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de Recepción</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.type_reception?.name || "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.vehicle_model.brand.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Marca</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.vehicle_model ? element.vehicle.vehicle_model.brand.name : "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle.vehicle_model.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Modelo</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle?.vehicle_model ? element.vehicle.vehicle_model.name : "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="campa.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Campa</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.campa ? element.campa.name : "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="type_model_order.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Negocio</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.type_model_order ? element.type_model_order?.name : "-" }}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="stockDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: stockDisplayedColumns"></tr>
              </table>
              <mat-card *ngIf="isLoadingDataIn | async" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " class="spinner loading-excel">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
              </mat-card>
              <mat-paginator [length]="event.length || 0" [pageSize]="event.pageSize || 50" [pageSizeOptions]="[
                  5,
                  10,
                  15,
                  100
                ]" class="m-auto mat-elevation-z8" (page)="getStockAll($event)">
              </mat-paginator>
            </div>

            <div *ngIf="selectedType === 'stock'">
              <table #table mat-table matTableExporter #exporter="matTableExporter"
                [dataSource]="vehiclesInCampaDataSource" class="mat-elevation-z8 table" matSort #sort3="matSort"
                (matSortChange)="sortData($event)">
                <ng-container matColumnDef="last_reception.created_at">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
                  <td mat-cell *matCellDef="let element">
                    {{ setDate(element.last_reception?.created_at)  }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="plate">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Matrícula</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.plate || "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle_model.brand.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Marca</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle_model ? element.vehicle_model?.brand?.name : "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="vehicle_model.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Modelo</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.vehicle_model ? element.vehicle_model.name : "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="campa.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Campa</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.campa ? element.campa.name : "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="type_model_order.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Negocio</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.type_model_order ? element.type_model_order.name : "-" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="sub_state.state.name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.sub_state?.state?.name || '-'}}
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="vehiclesInCampaDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: vehiclesInCampaDisplayedColumns"></tr>
              </table>
              <mat-card *ngIf="isLoadingDataStock | async" style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                " class="spinner loading-excel">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
              </mat-card>
              <mat-paginator [length]="event.length || 0" [pageSize]="event.pageSize || 50" [pageSizeOptions]="[
                  5,
                  10,
                  15,
                  100
                ]" class="m-auto mat-elevation-z8" (page)="getStockAll($event)">
              </mat-paginator>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
