import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  env = environment;
  private apiUrl = this.env.api_url;
  public token: string;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }


  provinces(): any {
    return this.http.get<any>(this.apiUrl + '/provinces/getall');
  }

  regions(): any {
    return this.http.get<any>(this.apiUrl + '/regions/getall');
  }

}
