import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  env = environment;
  private apiUrl = this.env.api_url;

  constructor(private http: HttpClient) {
  }

  getAll(): any {
    let params = new HttpParams();
    params.append('per_page', '15');
    return this.http.get<any>(this.apiUrl + '/companies/getall', {params});
  }

  getById(id) {
    return this.http.get<any>(this.apiUrl + `/companies/${id}`);
  }

  createCompany(data: any): any {
    return this.http.post<any>(this.apiUrl + '/companies', data);
  }

  updateCompany(id, data: any): any {
    return this.http.put<any>(this.apiUrl + `/companies/update/${id}`, data);
  }

  deleteCompany(id) {
    return this.http.delete<any>(this.apiUrl + `/companies/delete/${id}`);
  }


}
