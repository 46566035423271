<table class="mat-calendar-table">
  <thead class="mat-calendar-table-header">
    <tr><th *ngFor="let day of _weekdays" [attr.aria-label]="day.long">{{day.narrow}}</th></tr>
    <tr><th class="mat-calendar-table-header-divider" colspan="7" aria-hidden="true"></th></tr>
  </thead>
  <tbody sat-calendar-body
         [label]="_monthLabel"
         [rows]="_weeks"
         [todayValue]="_todayDate"
         [selectedValue]="_selectedDate"
         [begin]="_beginDateNumber"
         [end]="_endDateNumber"
         [beginSelected]="_beginDateSelected"
         [isBeforeSelected]="_beginDateSelected && _dateAdapter.compareDate(activeDate, _beginDateSelected) < 0"
         [rangeFull]="_rangeFull"
         [rangeMode]="rangeMode"
         [rangeHoverEffect]="rangeHoverEffect"
         [labelMinRequiredCells]="3"
         [activeCell]="_dateAdapter.getDate(activeDate) - 1"
         (selectedValueChange)="_dateSelected($event)"
         (keydown)="_handleCalendarBodyKeydown($event)">
  </tbody>
</table>
