export const CAR_OFF_ICON = `
  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M20.5,19.85L6.41,5.76L2.41,1.76L1.11,3L4.57,6.46L3,11V19A1,1 0 0,0 4,20H5A1,1 0 0,0 6,19V18H16.11L20.84,22.73L22.11,21.46L20.5,19.85M6.5,15A1.5,1.5 0 0,1 5,13.5A1.5,1.5 0 0,1 6.5,12A1.5,1.5 0 0,1 8,13.5A1.5,1.5 0 0,1 6.5,15M5,10L5.78,7.67L8.11,10H5M17.5,5.5L19,10H13.2L16.12,12.92C16.5,12.17 17.37,11.86 18.12,12.21C18.87,12.57 19.18,13.47 18.83,14.21C18.68,14.5 18.43,14.77 18.12,14.92L21,17.8V11L18.92,5C18.71,4.4 18.14,4 17.5,4H7.2L8.7,5.5H17.5Z" />
  </svg>
`;
export const CAR_ARROW_RIGHT = `
  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 18C12 14.69 14.69 12 18 12C19.09 12 20.12 12.3 21 12.81V12L18.92 6C18.72 5.42 18.16 5 17.5 5H6.5C5.84 5 5.28 5.42 5.08 6L3 12V20C3 20.55 3.45 21 4 21H5C5.55 21 6 20.55 6 20V19H12.09C12.04 18.67 12 18.34 12 18M6.5 6.5H17.5L19 11H5L6.5 6.5M6.5 16C5.67 16 5 15.33 5 14.5S5.67 13 6.5 13 8 13.67 8 14.5 7.33 16 6.5 16M19 21V19H15V17H19V15L22 18L19 21" />
  </svg>
`;
export const CAR_DOOR = `
  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19,14H16V16H19V14M22,21H3V11L11,3H21A1,1 0 0,1 22,4V21M11.83,5L5.83,11H20V5H11.83Z" />
  </svg>
`;

export const ITV = `
  <svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   width="28.761532mm"
   height="21.295254mm"
   viewBox="0 0 28.761532 21.295254"
   version="1.1"
   id="svg8"
   inkscape:version="0.92.4 (5da689c313, 2019-01-14)"
   sodipodi:docname="itv.svg">
  <defs
     id="defs2" />
  <sodipodi:namedview
     id="base"
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1.0"
     inkscape:pageopacity="0.0"
     inkscape:pageshadow="2"
     inkscape:zoom="1.979899"
     inkscape:cx="47.18774"
     inkscape:cy="8.2636728"
     inkscape:document-units="mm"
     inkscape:current-layer="g3081"
     showgrid="false"
     fit-margin-top="0"
     fit-margin-left="0"
     fit-margin-right="0"
     fit-margin-bottom="0"
     inkscape:window-width="1920"
     inkscape:window-height="1017"
     inkscape:window-x="-8"
     inkscape:window-y="-8"
     inkscape:window-maximized="1" />
  <metadata
     id="metadata5">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <g
     inkscape:label="Capa 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(59.509968,114.19418)">
    <g
       id="g3081"
       transform="matrix(0.26458333,0,0,0.26458333,-151.24003,-105.56605)">
      <g
         id="g842">
        <path
           inkscape:connector-curvature="0"
           d="m 346.6983,47.875727 m 16.72,0 h -16.722 V -4.3872726 h 16.723 V 47.875727 Z"
           id="path3057"
           style="fill:#ffffff;fill-opacity:1" />
        <path
           inkscape:connector-curvature="0"
           d="m 346.6983,-19.014273 c 0,-4.621 3.744,-8.369 8.359,-8.369 4.621,0 8.365,3.748 8.365,8.369 0,4.619 -3.744,8.359 -8.365,8.359 -4.615,0 -8.359,-3.74 -8.359,-8.359"
           id="path3059"
           style="fill:#ffffff;fill-opacity:1" />
        <path
           inkscape:connector-curvature="0"
           d="m 392.6903,31.149727 h -6.272 v -41.805 h 6.272 v -16.728 h -6.272 v -5.227 l -16.724,5.227 v 75.259 h 22.996 v -16.726"
           id="polyline3061"
           style="fill:#ffffff;fill-opacity:1" />
        <path
           inkscape:connector-curvature="0"
           d="m 421.9563,17.561727 -8.36,-21.9489996 h -18.818 l 18.818,52.2629996 h 18.813 l 22.992,-68.986 h -18.813 l -14.632,38.672"
           id="polyline3063"
           style="fill:#ffffff;fill-opacity:1" />
      </g>
    </g>
  </g>
</svg>
`;
