import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { StorageService } from '@core/services/storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
  loading: boolean;
  vehicle: any = {};
  reception: any = {};
  pendingTask: any = {};
  user: any;
  ispendingTask = false;
  formGroup = new UntypedFormGroup({
    comment: new UntypedFormControl(''),
    type: new UntypedFormControl('')
  });
  comment: any;
  constructor(
    public dialogRef: MatDialogRef<CommentsComponent>,
    private cdr: ChangeDetectorRef,
    private storageService: StorageService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {  }

  ngOnInit(): void {
    console.log(this.data)
    this.vehicle = this.data.vehicle;
    this.pendingTask = this.data.pendingTask;
    this.reception = this.data.reception;
    this.user = this.storageService.getCurrentUser();
  }

  closeDialog(result) {
    this.dialogRef.close({resul: result});
  }

  addMessage(){
    this.loading = true;
    const data = {
      vehicle_id: this.vehicle.id,
      comment: this.formGroup.controls.comment.value,
      type: !!this.ispendingTask ? 'APPROVAL_DECLINED' : this.formGroup.controls.type.value,
      reception_id: this.reception.id,
      model_id: this.pendingTask.id,
      model_type: 'App\\Models\\PendingTask',
    }
    this.userService.storeMessage(data).subscribe(
      result => {
        this.loading = false;
        this.formGroup.reset();
        this.userService.fetchNonification.next(true);
        this.cdr.detectChanges();
        if (!!this.ispendingTask ) {
          this.closeDialog(result);
        } else {
          this.exit();
        }
      },
      error => {
        this.loading = false;
        console.error(error);
      }
    )
  }

  exit(): void {
    this.dialogRef.close();
  }

}
