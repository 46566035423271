<div>
  <div>
    Notificaciones
  </div>
  <button mat-icon-button class="close-button" (click)="onNoClick()">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>
<div style="overflow-x: scroll" class="mat-elevation-z8 mb-5 mt-3">
  <table matTableExporter #exporter="matTableExporter" #table mat-table class="mat-elevation-z8"
  [dataSource]="dataSource">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        {{ objColumns["title"] }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.title }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="goTo(row)" class="row-hover"></tr>
  </table>
  <mat-card [class.loading-excel]="isLoadingData" *ngIf="isLoadingData | async" style="
  display: flex;
  justify-content: center;
  align-items: center;
">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </mat-card>
  <div *ngIf="!(isLoading | async)">
    <div *ngIf="noVehicleData | async" class="no-records mat-typography">
      <h3>No hay registros</h3>
    </div>
  </div>

  <!-- <div class="d-flex"> -->
  <!-- <div style="width: 100%"> -->
    <mat-paginator #paginator [length]="event.length || 0" [pageSize]="event.pageSize || 50"
    (page)="getNotificationAll($event);" [pageSizeOptions]="[
      5,
      10,
      25,
      100
    ]">
  </mat-paginator>
  <!-- </div> -->
  <!-- </div> -->
</div>