import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class VehicleModelService {
  env = environment;
  private apiUrl = this.env.api_url;
  token: string;
  reqHeader = new HttpHeaders().set("Access-Control-Allow-Origin", "*");

  constructor(private http: HttpClient) {}

  getAll(): Observable<any>  {
    let params = new HttpParams();
    return this.http.get<any>(this.apiUrl + "/vehicle-models", { params });
  }

  saveModelVehicle(brandId: number, model: string): Observable<any> {
    const bodyModel = {
      brand_id: brandId,
      name: model,
    };
    return this.http.post<any>(this.apiUrl + "/vehicle-models", bodyModel);
  }

  updateModel(name: string, brandId: number, id: number): Observable<any> {
    const bodyModel = {
      name,
      brand_id: brandId
    };
    return this.http.put(this.apiUrl + `/vehicle-models/${id}`, bodyModel);
  }

  getAllWithParameters(arrayParams = {}): any {
    let params = this.getParams(arrayParams);
    return this.http.get<any>(this.apiUrl + "/vehicle-models", { params });
  }

  private getParams(arrayParams) {
    let params = new HttpParams();
    arrayParams.map((element) => {
      let param = element.split(",");
      params = params.append(param[0].trim(), param[1].trim());
    });
    return params;
  }
}
