import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ICreateTransportDialog} from '../../../interfaces/dialog.interface';

@Component({
  selector: 'app-create-transport-dialog',
  templateUrl: './create-transport-dialog.component.html',
  styleUrls: ['./create-transport-dialog.component.css']
})
export class CreateTransportDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CreateTransportDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ICreateTransportDialog) {
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
