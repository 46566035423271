import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CategoriesService {
  env = environment;
  private apiUrl = this.env.api_url;

  constructor(private http: HttpClient) {}

  getCategories(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/categories/getall");
  }

  createCategory(category: string, description: string): Observable<any> {
    const bodyCategory = {
      name: category,
      description,
    };
    return this.http.post(this.apiUrl + "/categories", bodyCategory);
  }

  updateCategory(category: string, description: string, id: number): Observable<any> {
    const bodyCategory = {
      name: category,
      description
    };
    return this.http.put(this.apiUrl + `/categories/update/${id}`, bodyCategory);
  }
}
