import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TypeModelOrderService {
  env = environment;
  private apiUrl = this.env.api_url;
  token: string;
  reqHeader = new HttpHeaders().set("Access-Control-Allow-Origin", "*");

  constructor(private http: HttpClient) {}

  getAll(): Observable<any> {
    let params = new HttpParams();
    return this.http.get(this.apiUrl + "/type-model-order", { params });
  }
}
