import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatisticService } from 'src/app/services/statistic.service';

@Component({
  selector: 'app-average-by-substate',
  templateUrl: './average-by-substate.component.html',
  styleUrls: ['./average-by-substate.component.css']
})
export class AverageBySubstateComponent implements OnInit {
  @Input() title;
  show: boolean;
  @Output() onData = new EventEmitter<any>();

  constructor(
    private statisticService: StatisticService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const sub = this.statisticService.getAverageBySubstate().subscribe((res) => {
      this.show = true;
      this.cdr.detectChanges();
      sub.unsubscribe();
    }, (err) => {
      sub.unsubscribe();
    });
  }

}
