<div class="container py-5">
  <div class="row">
    <div class="col-8">
      <p class="greenLink"><a [routerLink]="'/dashboard'">Inicio </a> > <a [routerLink]="'/routes'">Listado grupo de
        tareas </a> > Grupo de tareas</p>
      <!--      <h2>Grupo de tareas</h2>-->
    </div>
<!--    <div class="col-7 mb-3">-->
<!--      &lt;!&ndash;      <label for="routeName" class="form-label">Nombre</label>&ndash;&gt;-->
<!--      &lt;!&ndash;      <input type="text" class="form-control" [(ngModel)]="tasksGroup.name" name="routeName" id="routeName"&ndash;&gt;-->
<!--      &lt;!&ndash;             placeholder="Introducir nombre">&ndash;&gt;-->
<!--    </div>-->
  </div>
<!--  <div class="row" *ngIf="this.routeId">-->
<!--    <div class="col-6">-->
<!--      <div class="row">-->

<!--        <div class="col-12 mb-3">-->
<!--          <div class="form-group">-->
<!--            <h4>Tarea 1</h4>-->
<!--            <mat-form-field appearance="outline" style="min-width:400px">-->
<!--              <mat-label>Tarea 1</mat-label>-->
<!--              <mat-select style="min-width:380px" [(ngModel)]="routeTasks.task_1">-->
<!--                <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 mb-3">-->
<!--          <div class="form-group">-->
<!--            <h4>Tarea 2</h4>-->
<!--            <mat-form-field appearance="outline" style="min-width:400px">-->
<!--              <mat-label>Tarea 2</mat-label>-->
<!--              <mat-select style="min-width:380px" [(ngModel)]="routeTasks.task_2">-->
<!--                <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 mb-3">-->
<!--          <div class="form-group">-->
<!--            <h4>Tarea 3</h4>-->
<!--            <mat-form-field appearance="outline" style="min-width:400px">-->
<!--              <mat-label>Tarea 3</mat-label>-->
<!--              <mat-select style="min-width:380px" [(value)]="routeTasks.task_3" [(ngModel)]="routeTasks.task_3">-->
<!--                <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 mb-3">-->
<!--          <div class="form-group">-->
<!--            <h4>Tarea 4</h4>-->
<!--            <mat-form-field appearance="outline" style="min-width:400px">-->
<!--              <mat-label>Tarea 4</mat-label>-->
<!--              <mat-select style="min-width:380px" [(value)]="routeTasks.task_4" [(ngModel)]="routeTasks.task_4">-->
<!--                <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 mb-3">-->
<!--          <div class="form-group">-->
<!--            <h4>Tarea 5</h4>-->
<!--            <mat-form-field appearance="outline" style="min-width:400px">-->
<!--              <mat-label>Tarea 5</mat-label>-->
<!--              <mat-select style="min-width:380px" [(value)]="routeTasks.task_5" [(ngModel)]="routeTasks.task_5">-->
<!--                <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-6">-->
<!--      <div class="row">-->
<!--        <div class="col-12 mb-3">-->
<!--          <div class="form-group">-->
<!--            <h4>Tarea 6</h4>-->
<!--            <mat-form-field appearance="outline" style="min-width:400px">-->
<!--              <mat-label>Tarea 6</mat-label>-->
<!--              <mat-select style="min-width:380px" [(value)]="routeTasks.task_6" [(ngModel)]="routeTasks.task_6">-->
<!--                <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 mb-3">-->
<!--          <div class="form-group">-->
<!--            <h4>Tarea 7</h4>-->
<!--            <mat-form-field appearance="outline" style="min-width:400px">-->
<!--              <mat-label>Tarea 7</mat-label>-->
<!--              <mat-select style="min-width:380px" [(value)]="routeTasks.task_7" [(ngModel)]="routeTasks.task_7">-->
<!--                <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 mb-3">-->
<!--          <div class="form-group">-->
<!--            <h4>Tarea 8</h4>-->
<!--            <mat-form-field appearance="outline" style="min-width:400px">-->
<!--              <mat-label>Tarea 8</mat-label>-->
<!--              <mat-select style="min-width:380px" [(value)]="routeTasks.task_8" [(ngModel)]="routeTasks.task_8">-->
<!--                <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 mb-3">-->
<!--          <div class="form-group">-->
<!--            <h4>Tarea 9</h4>-->
<!--            <mat-form-field appearance="outline" style="min-width:400px">-->
<!--              <mat-label>Tarea 9</mat-label>-->
<!--              <mat-select style="min-width:380px" [(value)]="routeTasks.task_9" [(ngModel)]="routeTasks.task_9">-->
<!--                <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-12 mb-3">-->
<!--          <div class="form-group">-->
<!--            <h4>Tarea 10</h4>-->
<!--            <mat-form-field appearance="outline" style="min-width:400px">-->
<!--              <mat-label>Tarea 10</mat-label>-->
<!--              <mat-select style="min-width:380px" [(value)]="routeTasks.task_10" [(ngModel)]="routeTasks.task_10">-->
<!--                <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <div class="col-4 py-3">-->
<!--      <div class="form-check">-->
<!--        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">-->
<!--        <label class="form-check-label" for="flexCheckDefault"> Activo </label>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-7 py-3">-->
<!--      <div class="text-right">-->
<!--        <button type="button" (click)="save()" class="btn btn-green  ">Grabar datos</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
  <div class="row justify-content-center align-items-center">
    <div class="col-md-6">
      <mat-card style="width: 100%;">
        <button mat-icon-button class="close-button" (click)="goBack()">
          <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
        <mat-card-header class="info-color white-text text-center py-4">
          <h2>{{routeId ? 'Editar' : 'Crear'}} grupo de tarea</h2>
        </mat-card-header>
        <mat-card-content>
          <form #routeForm="ngForm" (ngSubmit)="save()" class="text-center border border-light">
            <div class="row">
              <div class="col-6">
                <!-- First name -->
                <p>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Nombre</mat-label>
                    <input matInput type="text" [(ngModel)]="tasksGroup.name" name="routeName" id="routeName"
                           placeholder="Introducir nombre" #routeName="ngModel" required>
                    <mat-icon matSuffix>account_circle</mat-icon>
                    <!--                  <mat-error *ngIf="routeName.errors.required">-->
                    <!--                    Por favor, introduce un nombre válido.-->
                    <!--                  </mat-error>-->
                    <mat-error [hidden]="routeName.valid || routeName.pristine">
                      Este campo es requerido.
                    </mat-error>
                  </mat-form-field>
                </p>
              </div>
              <div class="col-6">
                <!-- First name -->
                <p style="padding: 18px; text-align: center;">
                  <mat-checkbox name="activeRoute" [(ngModel)]="tasksGroup.active"> Activo</mat-checkbox>
                </p>
              </div>
            </div>
            <div class="row" *ngIf="this.routeId">
              <div class="col-6">
                <p>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Tarea 1</mat-label>
                    <mat-select [(ngModel)]="routeTasks.task_1" name="task1">
                      <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>task</mat-icon>
                  </mat-form-field>
                </p>
                <p>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Tarea 2</mat-label>
                    <mat-select [(ngModel)]="routeTasks.task_2" name="task2">
                      <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>task</mat-icon>
                  </mat-form-field>
                </p>
                <p>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Tarea 3</mat-label>
                    <mat-select [(value)]="routeTasks.task_3" [(ngModel)]="routeTasks.task_3"
                                name="task3">
                      <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>task</mat-icon>
                  </mat-form-field>
                </p>
                <p>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Tarea 4</mat-label>
                    <mat-select [(value)]="routeTasks.task_4" [(ngModel)]="routeTasks.task_4"
                                name="task4">
                      <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>task</mat-icon>
                  </mat-form-field>
                </p>
                <p>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Tarea 5</mat-label>
                    <mat-select [(value)]="routeTasks.task_5" [(ngModel)]="routeTasks.task_5"
                                name="task5">
                      <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>task</mat-icon>
                  </mat-form-field>
                </p>
              </div>
              <div class="col-6">
                <p>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Tarea 6</mat-label>
                    <mat-select [(value)]="routeTasks.task_6"
                                [(ngModel)]="routeTasks.task_6" name="task6">
                      <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>task</mat-icon>
                  </mat-form-field>
                </p>
                <p>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Tarea 7</mat-label>
                    <mat-select [(value)]="routeTasks.task_7"
                                [(ngModel)]="routeTasks.task_7" name="task7">
                      <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>task</mat-icon>
                  </mat-form-field>
                </p>
                <p>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Tarea 8</mat-label>
                    <mat-select [(value)]="routeTasks.task_8"
                                [(ngModel)]="routeTasks.task_8" name="task8">
                      <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>task</mat-icon>
                  </mat-form-field>
                </p>
                <p>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Tarea 9</mat-label>
                    <mat-select [(value)]="routeTasks.task_9"
                                [(ngModel)]="routeTasks.task_9" name="task9">
                      <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>task</mat-icon>
                  </mat-form-field>
                </p>
                <p>
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label>Tarea 10</mat-label>
                    <mat-select [(value)]="routeTasks.task_10"
                                [(ngModel)]="routeTasks.task_10" name="task10">
                      <mat-option *ngFor="let task of tasks" [value]="task.task_id">{{task.name}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix>task</mat-icon>
                  </mat-form-field>
                </p>
              </div>
            </div>

            <button class="submit-btn btn-green" mat-raised-button type="submit"
                    [disabled]="!routeForm.valid">{{routeId ? 'Editar' : 'Crear'}}
              grupo de tarea
            </button>
          </form>
        </mat-card-content>
      </mat-card>

      <div class="container-form">

        <!--        <mat-form-field appearance="outline">-->
        <!--          <mat-label>Nombre</mat-label>-->
        <!--          <input type="text" matInput [(ngModel)]="newUserName" placeholder="Nombre"-->
        <!--                 (change)="validateName($event.target.value)">-->
        <!--          <span class="error" *ngIf="nameInvalid">-->
        <!--            El nombre no puede contener caracteres especiales <strong>required</strong>-->
        <!--          </span>-->
        <!--        </mat-form-field>-->
        <!--          <mat-form-field appearance="outline">-->
        <!--            <mat-label>Apellidos</mat-label>-->
        <!--            <input type="text" matInput [(ngModel)]="newuserSurname" placeholder="Apellidos">-->
        <!--          </mat-form-field>-->
        <!--          <mat-form-field appearance="outline">-->
        <!--            <mat-label>Correo electrónico</mat-label>-->
        <!--            <input type="email" matInput [(ngModel)]="newUserEmail" placeholder="Correo electrónico">-->
        <!--          </mat-form-field>-->
        <!--          <mat-form-field appearance="outline">-->
        <!--            <mat-label>Teléfono</mat-label>-->
        <!--            <input type="text" matInput [(ngModel)]="newUserPhone" placeholder="Teléfono">-->
        <!--          </mat-form-field>-->
        <!--        <mat-form-field appearance="outline">-->
        <!--          <mat-label>Tipo de usuario</mat-label>-->
        <!--          <mat-select required [(ngModel)]="newUserTypeId">-->
        <!--            <mat-option *ngFor="let userType of usersType" value="{{userType.id}}">{{userType.name}}</mat-option>-->
        <!--          </mat-select>-->
        <!--        </mat-form-field>-->
        <!--        <mat-form-field appearance="outline">-->
        <!--          <mat-label>Campa</mat-label>-->
        <!--          <mat-select required [(ngModel)]="newUserCampaId">-->
        <!--            <mat-option *ngFor="let campa of campas" value="{{campa.campa_id}}">{{campa.name}}</mat-option>-->
        <!--          </mat-select>-->
        <!--        </mat-form-field>-->
        <!--          <button mat-button class="btn btn-primary" (click)="addUser()">Guardar</button>-->
        <!--          <button mat-button class="btn btn-primary mt-2" (click)="newUser()">Cancelar</button>-->
        <!--      </div>-->
      </div>
    </div>
  </div>
</div>




