import { HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BudgetPendingTaskService {

  env = environment;
  private apiUrl = this.env.api_url;
  public token: string;
  reqHeader = new HttpHeaders().set("Content-Type", "application/json");

  constructor(private http: HttpClient) { }

  createBudgetPendingTask(data: any): any{
    let params = new HttpParams();
    return this.http.post(this.apiUrl + "/budget-pending-task", data, { params });
  }

  getAll(page = 0, per_page = 5, state =  "1", param = null) {
    let params = new HttpParams();
    params = params.append("page", `${page}`);
    params = params.append("per_page", `${per_page}`);
    if(state){
      params = params.append("stateBudgetPendingTasks[]", state);
    }
    params = params.append("with[]", "stateBudgetPendingTask");
    params = params.append("with[]", "pendingTask.vehicle.vehicleModel.brand");
    params = params.append("with[]", "pendingTask.vehicle.campa");
    params = params.append("with[]", "pendingTask.task.subState");
    if (Array.isArray(param)){
      param.forEach(p =>{
        const split = p.split(",")
        params = params.append(split[0].trim(), split[1].trim());
      })
    }
    return this.http.get<any>(this.apiUrl + "/budget-pending-task", { params })
  }

  update(data: any, id: any){
    let params = new HttpParams();
    return this.http.put<any>(this.apiUrl + "/budget-pending-task/" + id , data, { params });
  }
}
