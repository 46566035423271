<mat-sidenav-container autosize style="height: 100%" role="main">
  <mat-sidenav #sidenav mode="side" class="sidenav" opened="true" [class.menu-open]="isExpanded" id="sidenav" *ngIf="
      user &&
      !isLoginRoute() &&
      (company_id === 1 || company_id === 2 || company_id === 5)
    " role="navigation" [class.menu-close]="!isExpanded" fixedInViewport="true">
    <!--    <app-sidebar *ngIf="identity && !isLoginRoute()"></app-sidebar>-->
    <div [class.logo__sidenav]="isExpanded" *ngIf="isExpanded">
      <img class="logo" src="..\..\assets\focus-logo-blanco-mod.png" />
      <!--      <img class="logo" src="..\..\assets\logo-icon.png">-->
    </div>
    <div *ngIf="!isExpanded" style="margin-bottom: -60px">
      <img class="logo-icon" src="..\..\assets\logo-icon.png" />
      <!--      <img class="logo" src="..\..\assets\logo-icon.png">-->
    </div>
    <div style="margin-top: -15px">
      <mat-nav-list *ngIf="!isLoginRoute()" [class.adjust-padding]="!isExpanded" class="mat-typography"
        [class.margin-adjust]="isExpanded" [class.m__Gglobal]="isExpanded">
        <ng-container *ngFor="let item of items">
          <mat-list-item *ngIf="item.type === 'item'" [matTooltip]="!isExpanded ? item.matTooltip : ''"
            matTooltipClass="tooltip-menu" matTooltipPosition="right" (click)="navigateTo(item.navigateTo, '')">
            <mat-icon *ngIf="!!item.icon" mat-list-icon>{{
              item.icon
              }}</mat-icon>
            <mat-icon *ngIf="!!item.svgIcon" mat-list-icon svgIcon="{{ item.svgIcon }}" aria-hidden="false"
              aria-label=""></mat-icon>
            <h4 mat-line *ngIf="isExpanded">{{ item.label }}</h4>
            <span *ngIf="item.matBadge" [matBadge]="item.matBadge | async" matBadgeOverlap="false"
              [class.badge-position-open]="isExpanded" [class.badge-position-close]="!isExpanded"></span>
          </mat-list-item>
          <div *ngIf="item.type === 'space'" [style.height]="item.height"></div>
          <mat-divider *ngIf="item.type === 'divider'" [class.d-none]="item.dnone"></mat-divider>
          <mat-accordion *ngIf="item.type === 'accordion' && !item.hiddend">
            <mat-expansion-panel>
              <mat-expansion-panel-header style="padding: 0 15px !important">
                <mat-panel-title>
                  <span class="material-icons mr-3 color-white">{{ item.titleIcon }}</span><span class="color-white"
                    style="margin-top: 4px; font-size: 16px; padding-left: 4px">{{ item.title }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list-item *ngFor="let d of item.items" [matTooltip]="!isExpanded ? d.matTooltip : ''"
                matTooltipClass="tooltip-menu" matTooltipPosition="right" (click)="navigateTo(d.navigateTo, '')">
                <mat-icon mat-list-icon>{{ d.icon }}</mat-icon>
                <h4 mat-line *ngIf="isExpanded" style="white-space: normal">
                  {{ d.label }}
                </h4>
              </mat-list-item>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content bgGreen class="mat-drawer-content">
    <mat-toolbar color="primary" *ngIf="user && !isLoginRoute()"
      style="background: var(--primary-color); position: sticky; top: 0; z-index: 2">
      <a mat-icon-button (click)="toolbarToggle()" style="color: #f0f8ff">
        <!--      <a mat-icon-button (click)="sidenav.toggle()" style="color: #f0f8ff;">-->
        <mat-icon>menu</mat-icon>
      </a>

      <span class="mat-typography" style="margin-top: 1px">{{user.role?.description}}</span>

      <span *ngIf="user.workshop_id" class="mat-typography" style="margin-top: 1px">
        {{ user.name }}
        {{ user.surname }}</span>
      <span style="margin-right: 50px"></span>

      <div class="col-2 text-center font-size-16 select-align" id="campa" *ngIf="
          storageService.isAuthenticated() &&
          !user.workshop_id &&
          user.company_id !== 2 && user.role_id != 10
        ">
        <mat-form-field class="mr-3 pt-2">
          <mat-select [formControl]="campaMultiCtrl" placeholder="Campas" [multiple]="true" #multiSelect>
            <mat-option>
              <ngx-mat-select-search [showToggleAllCheckbox]="true" [toggleAllCheckboxIndeterminate]="isIndeterminate"
                [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleSelectAll($event)"
                [formControl]="campaMultiFilterCtrl" placeholderLabel="Buscar campas...."
                [toogleAllCheckboxTooltipPosition]="'left'" [toggleAllCheckboxTooltipMessage]="'Seleccionar todas'"
                noEntriesFoundLabel="no se encontraron campas coincidentes"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let campa of filteredCampasMulti | async" [value]="campa">
              {{ campa.name }}
            </mat-option>
            <!--mat-option [value]="null">
              Sin Campa
            </mat-option-->
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="user.role_id != 10" class="col-2 text-center font-size-16 select-align" id="plate">
        <mat-form-field #fmSelet class="mr-4 pt-2">
          <mat-select (click)="oninput(elPlate)" [formControl]="plateMultiCtrl" placeholder="Matrícula" [multiple]="false"
            (selectionChange)="plateChange($event, fmSelet)" #multiSelectPlate>
            <mat-option>
              <ngx-mat-select-search #elPlate [showToggleAllCheckbox]="true" [toggleAllCheckboxIndeterminate]="isIndeterminate"
                [toggleAllCheckboxChecked]="isChecked" [formControl]="plateFilterCtrl"
                placeholderLabel="Buscar matrícula...." [toogleAllCheckboxTooltipPosition]="'left'"
                [toggleAllCheckboxTooltipMessage]="'Seleccionar todas'"
                noEntriesFoundLabel="no se encontraron matrícula coincidentes"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of filteredPlate | async" [value]="item">
              {{ item.plate }}
            </mat-option>
          </mat-select>
          <mat-icon style="position: absolute;" class="ml-2" aria-hidden="false" aria-label="Example home icon">search
          </mat-icon>
        </mat-form-field>
      </div>
      <span class="toolbar-spacer"></span>
      <div>
        <a mat-icon-button style="margin-left: 5px" (click)="openMessages()">
          <div mat-card-avatar class="d-flex align-items-center">
            <mat-icon aria-hidden="false" aria-label="Mensajes"  [matBadge]="messages?.total || ''" matBadgeColor="warn">forum</mat-icon>
          </div>
        </a>
      </div>
      <div *ngIf="settingsList">
        <a mat-icon-button [matMenuTriggerFor]="settings" style="margin-left: 5px">
          <div mat-card-avatar class="d-flex align-items-center">
            <mat-icon aria-hidden="false" aria-label="Configuración">settings</mat-icon>
          </div>
        </a>
        <mat-menu #settings="matMenu">
          <button mat-menu-item *ngFor="let item of settingsList" [routerLink]="[item.navigateTo]">
            {{ item.label }}
          </button>
        </mat-menu>
      </div>
      <div>
        <a mat-icon-button style="margin-left: 5px" (click)="openNotifications()">
          <div mat-card-avatar class="d-flex align-items-center">
            <mat-icon aria-hidden="false" aria-label="Notificaciones">notifications</mat-icon>
          </div>
        </a>
      </div>
      <span *ngIf="isDev" style="color: red;background: white;border-radius: 25px;width: 150px;text-align: center;">DESARROLLO</span>
      <a mat-icon-button [matMenuTriggerFor]="menu" style="margin-left: 5px">
        <div mat-card-avatar class="example-header-image" style="background-image: url({{user.avatar ? user.avatar : 'assets/none-img.png'}})"></div>
      </a>
      <mat-menu #menu="matMenu" [overlapTrigger]="false" xPosition="before" (focus)="(false)">
        <div class="user-menu-title ng-tns-c101-10">
          <h4 class="user-menu-title__name">
          </h4>
          <a mat-menu-item class="user-menu-title__link">
            <mat-icon mat-list-icon aria-hidden="false" style="color: #03989e">mail</mat-icon>
            {{ user.email }}
          </a>
        </div>
        <mat-divider></mat-divider>
        <a mat-menu-item [routerLink]="['/user/profileuser']">
          <mat-icon mat-list-icon aria-hidden="false" style="color: #03989e">edit</mat-icon>
          <span>Editar mi perfil</span>
        </a>
        <button mat-menu-item style="height: 0; position: absolute"></button>
        <button mat-menu-item (click)="logout()">
          <mat-icon mat-list-icon aria-hidden="false" style="color: #03989e">exit_to_app</mat-icon>
          <span>Salir</span>
        </button>
      </mat-menu>
    </mat-toolbar>
    <main class="{{ user ? 'main-margin-bottom' : '' }}">
      <router-outlet></router-outlet>
    </main>
    <footer class="footer mt-auto py-3" style="font-weight: 500;">
      <div class="container">
        <span class="text-muted">Focus &copy; {{year | date: 'yyyy'}} by Mobius.tech</span>
      </div>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
