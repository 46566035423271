import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { UserService } from "./user.service";
import { VehicleData } from "../interfaces/vehicles.interface";
import { catchError, map } from "rxjs/operators";
import { VehicleModel } from "../core/models/vehicle.model";
import { HeaderService } from "../service/header.service";
import { StorageService } from "@core/services/storage.service";
import * as _ from 'lodash';

const DEFLEETING = 8;
const REQUEST_DEFLEETING = 26;
const TECHNICIAN_ALD =  12;
const INVARAT =  7;
const ADMIN =  1;
export interface IVehicleData {
  vehicles: {
    data: VehicleModel[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: [];
    next_page_url: string;
    path: string;
    per_page: string;
    prev_page_url: string;
    to: string;
    total: number;
  };
}

@Injectable({
  providedIn: "root",
})
export class VehicleService {

  orderTask = [11, 3, 2, 5, 6, 4];

  arrayParamsApproved = [
    "with[],lastReception.approvedPendingTasks.statePendingTask",
    "with[],lastReception.approvedPendingTasks.task.subState.state",
    "with[],lastReception.approvedPendingTasks.incidences",
    "with[],lastReception.approvedPendingTasks.budgetPendingTasks",
    "with[],lastReception.approvedPendingTasks.budgetPendingTasks.stateBudgetPendingTask",
    "with[],lastReception.lastQuestionnaire.questionAnswers",
    "with[],vehicleModel.brand",
    "with[],typeModelOrder",
    "with[],orders",
    "with[],campa",
    "with[],category",
    "with[],subState.state",
    "with[],tradeState",
    "with[],reservations",
    "with[],requests.customer",
    "withUbication,1"
  ];
  vehicle: any;
  env = environment;
  private apiUrl = this.env.api_url;
  public token: string;
  plate: any;
  dataPendingTask: any;
  dataPrincipal: any;
  dataFileVehicle: any;
  reqHeader = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
  refresh: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private storageService: StorageService,
    private headerService: HeaderService) {
  }

  getAll(pageNumber): Observable<any> {
    let params = new HttpParams();
    params = params.append("with[]", "square.street.zone");
    params = params.append("page", String(pageNumber));
    return this.http.get(this.apiUrl + "/vehicles", { params });
  }

  getTypeDeliveryNote(): Observable<any> {
    return this.http.get(`${this.apiUrl}/type-delivery-note`);
  }

  getById(id: number): any {
    let params = new HttpParams();
    params = params.append("with[]", "vehicleModel.brand");
    params = params.append("with[]", "typeModelOrder");
    params = params.append("with[]", "category");
    params = params.append("with[]", "square.street.zone");
    params = params.append("with[]", "subState.state");
    params = params.append("with[]", "campa");
    params = params.append("ids[]", String(id))
    return this.http.get<any>(this.apiUrl + "/vehicles/filter", { params });
  }

  getByCompany(data): any {
    return this.http
      .post<any>(this.apiUrl + "/vehicles/all-by-company", data)
      .pipe(catchError((err) => throwError(err)));
  }

  getByCampa(data): Observable<VehicleData> {
    return this.http
      .post<any>(this.apiUrl + "/vehicles/all-by-campa", data)
      .pipe(catchError((err) => throwError(err)));
  }

  getSearch(campas = [], plate = null, per_page = null, campaNull = false): Observable<VehicleData> {
    let params = new HttpParams();
    if (plate && plate.length > 0) {
      params = params.append('plate', plate);
    }
    if (per_page) {
      params = params.append('per_page', String(per_page));
    }
   /* campas.forEach((x) => {
      params = params.append("campas[]", String(x.id));
    }) */
  /*  if (campaNull) {
      params = params.append("campas[]", String(0));
    }*/
    return this.http.get<any>(this.apiUrl + "/vehicles/filter", { params });
  }

  createVehicle(data: any): any {
    return this.http.post<any>(this.apiUrl + "/vehicles", data);
  }

  updateVehicle(id: number, data: any): Observable<any> {
    return this.http.put(this.apiUrl + `/vehicles/update/${id}`, data);
  }

  updateSubStateVehicle(id: number, data: any): Observable<any> {
    return this.http.put(this.apiUrl + `/vehicles/update-sub-state/${id}`, data);
  }

  updateSquareVehicle(body): Observable<any> {
    return this.http.put(this.apiUrl + `/squares/${body.id}`, body);
  }


  setVehicleInCampa(data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.post<any>(this.apiUrl + "/vehicles/on-campa", params, {
      headers,
    });
  }

  createImage(data: any): any {
    return this.http.post<any>(this.apiUrl + "/vehicle-pictures", data);
  }
  deleteImage(id: any): any {
    return this.http.delete<any>(this.apiUrl + "/vehicle-pictures/" + id);
  }

  createDNI(data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.post<any>(
      this.apiUrl + "/reservation-request/update-url-dni",
      params,
      { headers }
    );
  }

  createContract(data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.post<any>(
      this.apiUrl + "/reservation-request/update-url-contract",
      params,
      { headers }
    );
  }

  updateVehicleDocs(data: any) {
    return this.http.post<any>(this.apiUrl + "/reservations/update", data);
  }

  getElegibleVehicleDefleet(page, data: any): any {
    let params = new HttpParams();
    params = params.append("page", String(data.page));
    params = params.append("with[]", "vehicleModel.brand");
    params = params.append("with[]", "category");
    params = params.append("with[]", "subState.state");
    params = params.append("with[]", "campa");
    params = params.append("with[]", "tradeState");
    if (data.campas) {
      data.campas.map(
        (campa) => (params = params.append("campas[]", String(campa)))
      );
    }
    if (data.subStates) {
      data.subStates.map(
        (subState) => (params = params.append("subStates[]", String(subState)))
      );
    }
    if (data.states) {
      data.states.map(
        (state) => (params = params.append("states[]", String(state)))
      );
    }
    if (data.plate) {
      params = params.append("plate", String(data.plate));
    }
    if (data.brands) {
      data.brands.map(
        (brand) => (params = params.append("brands[]", String(brand)))
      );
    }
    if (data.vehicleModels) {
      data.vehicleModels.map(
        (vehicleModel) =>
          (params = params.append("vehicleModels[]", String(vehicleModel)))
      );
    }
    if (data.categories) {
      data.categories.map(
        (category) => (params = params.append("categories[]", String(category)))
      );
    }
    if (data.tradeStates) {
      data.tradeStates.map(
        (tradeState) =>
          (params = params.append("tradeState[]", String(tradeState)))
      );
    }
    if (data.ubication) {
      params = params.append("ubication", String(data.ubication));
    }
    return this.http.get<any>(this.apiUrl + `/vehicles/defleet`, { params });
  }

  reservationTime(data): any {
    return this.http.post<any>(this.apiUrl + "/reservation-time", data);
  }

  createReservationTime(data): any {
    return this.http.post<any>(this.apiUrl + `/reservation-time/create`, data);
  }

  updateReservationTime(data): any {
    return this.http.post<any>(this.apiUrl + `/reservation-time/update`, data);
  }

  vehicleWithReservationRequest(data: any): any {
    return this.http.post<any>(
      this.apiUrl + "/get-reservation/by-vehicle",
      data
    );
  }

  deliveryVehicle(data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.post<any>(this.apiUrl + "/vehicles/delivery", params, {
      headers,
    });
  }

  getVehiclesPickup(): any {
    this.token = this.userService.getToken();
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.get<any>(this.apiUrl + "/vehicles/pickup", { headers });
  }

  getVariablesDefleet(): any {
    return this.http.get<any>(this.apiUrl + "/defleet-variables");
  }

  getTransports() {
    return this.http.get(this.apiUrl + "/transports/getall");
  }

  createVariablesDefleet(data: any): any {
    return this.http.put<any>(this.apiUrl + "/defleet-variables", data);
  }

  setVariablesDefleet(data: any): any {
    return this.http.put<any>(this.apiUrl + "/defleet-variables", data);
  }

  getVehiclePictures(data: any): any {
    return this.http.post<any>(
      this.apiUrl + "/vehicle-pictures/by-vehicle",
      data
    );
  }

  importVehicleFromExcel(data): any {
    return this.http.post<any>(
      this.apiUrl + "/vehicles/create-from-excel",
      data
    );
  }

  deleteVehicle(id): any {
    return this.http.delete<any>(this.apiUrl + `/vehicles/delete/${id}`);
  }

  returnVehicle(id, body): Observable<any> {
    return this.http.post(this.apiUrl + `/vehicles/return/${id}`, body);
  }

  checkVehicleWithoutOrder(data) {
    return this.http.post<any>(
      this.apiUrl + "/reservation/without-order",
      data
    );
  }

  checkVehicleWithoutContract(data) {
    return this.http.post<any>(
      this.apiUrl + "/reservation/without-contract",
      data
    );
  }

  getTypeReservations() {
    return this.http.get<any>(this.apiUrl + "/type-reservations");
  }

  getVehilesReadyToDeliveryByCampa(data) {
    return this.http.post<any>(
      this.apiUrl + "/vehicles/ready-to-delivery/campa",
      data
    );
  }

  getVehilesReadyToDeliveryByCompany(data) {
    return this.http.post<any>(
      this.apiUrl + "/vehicles/ready-to-delivery/company",
      data
    );
  }

  getVehiclesByTradeStateReservedCampa(data) {
    return this.http.post<any>(
      this.apiUrl + "/vehicles/by-trade-state/campa",
      data
    );
  }

  getVehiclesByTradeStateReservedCompany(data) {
    return this.http.post<any>(
      this.apiUrl + "/vehicles/by-trade-state/company",
      data
    );
  }

  createTransport(data) {
    return this.http.post<any>(this.apiUrl + "/transports", data);
  }

  /** Endpoints for Bookings. */

  getVehiclesPreBookingByStateCompany(data) {
    return this.http.post<any>(
      this.apiUrl + "/vehicles/by-state-company",
      data
    );
  }

  vehiclesUnapprovedTask(page) {
    return this.http.get(
      this.apiUrl + `/vehicles/ald/unapproved-task?page=${page}`
    );
  }
  getVehiclesPreBookingByStateCampa(data) {
    return this.http.post<any>(this.apiUrl + "/vehicles/by-state-campa", data);
  }

  vehicleRequestedForReservationByCompany(data): any {
    let params = new HttpParams();
    params = params.append("with[]", "reservations.transport");
    return this.http.post<any>(
      this.apiUrl + "/vehicle-with-reservation-without-order/company",
      data
    );
  }

  vehicleRequestedForReservationByCampa(data): any {
    let params = new HttpParams();
    params = params.append("with[]", "vehicleModel.brand");
    params = params.append("with[]", "category");
    params = params.append("with[]", "subState.state");
    params = params.append("with[]", "campa");
    params = params.append("with[]", "tradeState");
    params = params.append("with[]", "reservations.transport");
    params = params.append("with[]", "requests.customer");
    params = params.append("campas[]", `32`);
    return this.http.get<any>(
      this.apiUrl + "/vehicle-with-reservation-without-order/campa",
      { params }
    );
  }

  vehiclesWithoutContractByCompany(data: any): any {
    let params = new HttpParams();
    params = params.append("with[]", "reservations");
    params = params.append("with[]", "requests.customer");
    params = params.append("with[]", "campa");
    params = params.append("with[]", "category");
    params = params.append("with[]", "vehicleModel");
    return this.http.post<any>(
      this.apiUrl +
      `/vehicle-with-reservation-without-contract/company?${params}`,
      data
    );
  }

  vehiclesWithoutContractByCampa(data: any): any {
    let params = new HttpParams();
    params = params.append("with[]", "vehicleModel.brand");
    params = params.append("with[]", "category");
    params = params.append("with[]", "subState.state");
    params = params.append("with[]", "campa");
    params = params.append("with[]", "tradeState");
    params = params.append("with[]", "reservations");
    params = params.append("with[]", "requests.customer");
    return this.http.post<any>(
      this.apiUrl + "/vehicle-with-reservation-without-contract/campa",
      data,
      { params }
    );
  }

  vehiclesFilterByCampa(data): any {
    return this.http.post<any>(this.apiUrl + "/vehicles/filter", data);
  }

  vehiclesFilter(data, pageSize = 5): Observable<IVehicleData> {
    let params = new HttpParams();

    params = params.append("per_page", `${pageSize}`);

    if (data._sort) {
      params = params.append(`${data._order === 'asc' ? 'order' : 'orderDesc'}`, `${data._sort}`);
    }

    if (data.pendingTasks) {
      params = params.append("with[]", "pendingTasks.statePendingTask");
      params = params.append("with[]", "pendingTasks.task.subState");
      params = params.append("with[]", "pendingTasks.incidences");
      params = params.append(
        "with[]",
        "pendingTasks.vehicle.vehicleModel.brand"
      );
      params = params.append("statePendingTasks[]", "1");
      params = params.append("statePendingTasks[]", "2");
    }

    params = params.append("page", String(data.page));
    params = params.append("with[]", "vehicleModel.brand");
    params = params.append("with[]", "category");
    params = params.append("with[]", "subState.state");
    params = params.append("with[]", "campa");
    // params = params.append("states[]", "1");
    params = params.append("with[]", "tradeState");
    params = params.append("with[]", "reservations");
    params = params.append("with[]", "requests.customer");
    params = params.append("with[]", "pendingTasks");
    params = params.append("with[]", "typeModelOrder");
    params = params.append("with[]", "square.street.zone");
    params = params.append("with[]", "orders");
    params = params.append("with[]", "withPendingTaskOrProgress");
    params = params.append("with[]", "lastReception");
    params = params.append(
      "with[]",
      "withPendingTaskOrProgress.statePendingTask"
    );

    if (Array.isArray(data.campas)) {
      data.campas.map((campa) => {
        params = params.append("campas[]", String(campa));
      });
    }

    if (data.subStates) {
      data.subStates.map(
        (subState) => (params = params.append("subStates[]", String(subState)))
      );
    }
    if (data.states) {
      data.states.map(
        (state) => (params = params.append("states[]", String(state)))
      );
    }
    if (data.plate) {
      params = params.append("plate", String(data.plate));
    }
    if (data.brands) {
      data.brands.map(
        (brand) => (params = params.append("brands[]", String(brand)))
      );
    }
    if (data.vehicleModels) {
      data.vehicleModels.map(
        (vehicleModel) =>
          (params = params.append("vehicleModels[]", String(vehicleModel)))
      );
    }
    if (data.categories) {
      data.categories.map(
        (category) => (params = params.append("categories[]", String(category)))
      );
    }
    if (data.tradeStates) {
      data.tradeStates.map(
        (tradeState) =>
          (params = params.append("tradeStates[]", String(tradeState)))
      );
    }
    if (data.ubication) {
      params = params.append("ubication", String(data.ubication));
    }

    return this.http
      .get<any>(this.apiUrl + "/vehicles/filter", { params })
      .pipe(
        map((response: IVehicleData) => response),
        catchError((err) => throwError(err))
      );
  }

  vehiclesFilterAll(
    page = 0,
    per_page = 5,
    arrayParams = [],
    state = null
  ): Observable<any> {
    if (!!state) {
      arrayParams.unshift(`whereHasBudgetPendingTask[],${state}`);
    }
    let params = this.getParams(arrayParams);
    params = params.append("page", `${page}`);
    params = params.append("per_page", `${per_page}`);
    return this.http.get(this.apiUrl + "/vehicles/filter", { params });
  }

  vehiclesAllExit(arrayParams): Observable<any> {
    let params = this.getParams(arrayParams);
    return this.http.get(this.apiUrl + "/vehicles/filter", { params });
  }

  /** op-commercial */
  getVehiclesTotalsByState(arrayParams): Observable<any> {
    let params = this.getParams(arrayParams);
    return this.http.get(
      this.apiUrl + "/vehicles/totals/by-state",
      { params }
    );
  }

  getQuestionnaire(arrayParams: any [] = []) {
    let params = this.getParams(arrayParams);
    return this.http.get(`${this.apiUrl}/questionnaire`, { params });
  }

  getVehiclesBrand() {
    let params = new HttpParams();
    params = params.append("with[]", "model");
    return this.http.get(this.apiUrl + "/brands");
  }

  getVehiclesBrandModel(id) {
    let params = new HttpParams();
    params = params.append("with[]", "vehicleModels");
    return this.http.get(this.apiUrl + `/brands/${id}`, { params });
  }

  getVehiclesModels() {
    let params = new HttpParams();
    params = params.append("with[]", "vehicleModel.brand");
    return this.http.get(this.apiUrl + `/vehicle-models`);
  }

  filterVehiclesDates(data) {
    return this.http.post<any>(this.apiUrl + "/vehicles/by-state-date", data);
  }

  downloadVehiclesByCompany() {
    return this.http.get<any>(this.apiUrl + `/vehicles/download`);
  }

  GetPlateByURL(item) {
    this.plate = item;
  }

  GetPlateByURLPrincipal(item) {
    this.dataPrincipal = item;
  }

  GetPlateByURLFileVehicle(item) {
    this.dataPrincipal = item;
  }

  getVehicleByPlate(plate: string, reception_id: any = null) {
    let params = new HttpParams();
    params = params.append("plate", plate);
    params = params.append("with[]", "vehicleModel.brand");
    params = params.append(
      "with[]",
      "pendingTasks.budgetPendingTasks.stateBudgetPendingTask"
    );
    params = params.append("with[]", "pendingTasks.task");
    params = params.append("with[]", "lastReception.vehiclePictures");

    if (reception_id) {
      params = params.append("withReceptionId", reception_id);
    }

    return this.http.get<any>(this.apiUrl + `/vehicles/filter`, { params });
  }

  byIdWithParams(id: number, arrayParams: any): Observable<any> {
    let params = this.getParams(arrayParams);
    return this.http.get(this.apiUrl + `/vehicles/${id}`, { params });
  }

  questionnarieByIdWithParams(id: number, arrayParams: any) {
    let params = this.getParams(arrayParams);
    return this.http.get<any>(this.apiUrl + `/questionnaire/${id}`, { params });
  }

  filterVehiclesWithParams(arrayParams: any) {
    let params = this.getParams(arrayParams);
    return this.http.get<any>(this.apiUrl + `/vehicles/filter`, { params });
  }

  filterBudgetPendingTaskWithParams(
    page = 0,
    per_page = 5,
    arrayParams = [],
    state = null,
    plate = null
  ) {
    if (state) {
      // arrayParams.unshift(`whereHasBudgetPendingTask[],${state}`);
    }
    let params = this.getParams(arrayParams);
    if (plate) {
      params = params.append("vehiclePlate", `${plate}`);
    }
    params = params.append("page", `${page}`);
    params = params.append("per_page", `${per_page}`);
    return this.http.get<any>(this.apiUrl + `/budget-pending-task`, { params });
  }

  changeSubStateVehicles(data: any) {
    return this.http.post<any>(
      this.apiUrl + "/vehicles/change-sub-state",
      data
    );
  }

  transferFromPendingTask(data: any) {
    return this.http.post<any>(
      this.apiUrl + "/pending-tasks/transfer",
      data
    );
  }

  importVehicles(data: any, runDelete = false): Observable<any> {
    return this.http.post(
      runDelete ? `${this.apiUrl}/vehicles/delete-massive` : `${this.apiUrl}/vehicles/create-from-excel`,
      data
    );
  }

  defleeting(vehicle_id) {
    return this.http.post(`${this.apiUrl}/vehicles/defleeting/${vehicle_id}`, {});
  }

  defleetingList(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/vehicles/defleeting-list`, data);
  }

  private getParams(arrayParams) {
    let params = new HttpParams();
    arrayParams.map((element) => {
      let param = element.split(",");
      params = params.append(param[0].trim(), param[1].trim());
    });
    return params;
  }

  undefleeting(vehicle_id) {
    return this.http.get(`${this.apiUrl}/vehicles/undefleet/${vehicle_id}`);
  }

  newRepection(vehicle_id): Observable<any> {
    return this.http.post(`${this.apiUrl}/reception`, { vehicle_id });
  }

  get user(): any {
    return this.storageService.getCurrentUser() || {};
  }

  disabledVehicle(vehicle: any): boolean {
    if (this.user.role_id === INVARAT) {
      return false; // No deshabilitar el vehículo si el role_id es 7
    }
    if (!vehicle || !_.find(this.user.campas, {id: vehicle.campa_id})) {
      return true;
    }
    // if (vehicle.sub_state && vehicle.sub_state.state.id != 6 && [2, 3].indexOf(this.user.role_id) > -1) {
    //   return true;
    // }
    if (vehicle && vehicle.sub_state_id === 10) {
      return true;
    }
    if ([1].indexOf(this.user.role_id) > -1) {
      return false;
    }
    if (!vehicle?.campa_id) {
      return true;
    }
    return  ([2, 12].indexOf(this.user.role_id) === -1 && [11,12,23,29].indexOf(vehicle.sub_state_id) > -1) || this.isUnApprovedCheckList(vehicle?.last_questionnaire);


    //return  (this.user.role_id != 2 && this.user.role_id != 3 && [11,12,23,29].indexOf(vehicle.sub_state_id) > -1) || this.isUnApprovedCheckList(vehicle?.last_questionnaire);
  }

  endPoints(tasks, indexTag, event = null, extraParams = []): any {
    const events = {};
    events[indexTag] = {
      length: 0,
      pageIndex: 0,
      pageSize: 5,
      previousPageIndex: 0,
    };
    let endpoints: Array<Observable<any>> = new Array<Observable<any>>();
    let arrayParams = [];
    if ( this.user.role_id === 9) {
      arrayParams = [
        `byUserRole,${this.user.role_id}`,
        "with[],lastQuestionnaire",
      ];
    } else {
      arrayParams = JSON.parse(
        JSON.stringify(this.arrayParamsApproved)
      );
    }
    const arrayParams2 = [
      "with[],pendingTask.task",
      "with[],pendingTask.vehicle.vehicleModel.brand",
      "with[],stateBudgetPendingTask",
      "hasVehicle,1"
    ];

    if ([8, 9].indexOf(this.user.role_id) > -1) {
      arrayParams2.push(`roleIds[],${this.user.role_id}`);
    }

    const campas = this.headerService.getHeaderCampa();
    campas.forEach((campa_id) => {
      arrayParams.push(`campas[],${String(campa_id)}`);
      arrayParams2.push(`campaIds[],${String(campa_id)}`);
    });

    tasks.forEach((t) => {
      arrayParams.push(`lastReceptionFirstPendingTaskIds[],${String(t.id)}`);
      arrayParams2.push(`taskIds[],${String(t.id)}`);
    });

    arrayParams.push("approvedPendingTasksNotNull,1");
    arrayParams.push("with[],damages");

    if (!!event) {
      events[indexTag] = event;
    }
    const arr = indexTag == 0 ? arrayParams.concat(extraParams) : arrayParams2.concat(extraParams);
    endpoints = indexTag == 0 ? [
      this.vehiclesFilterAll(
        events[indexTag].pageIndex + 1,
        events[indexTag].pageSize,
        arr
      ),
    ] : [
      this.filterBudgetPendingTaskWithParams(
        events[indexTag].pageIndex + 1,
        events[indexTag].pageSize,
        arr,
        "0",
        this.plate
      ),
    ];
    return { events, endpoints, arrayParams: arr };
  }
  uploadImage(body: any): Observable<any>{
    return this.http.post(`${this.apiUrl}/vehicle-pictures`, body);

  }

  /** Gets car pictures by vehicle id. */
  // getPicturesByVehicleId(id: number): Observable<any> {
  //   let params = new HttpParams();
  //   params = params.append('with[]', 'vehiclePictures');
  //   return this.http.get(`${this.apiUrl}/vehicles/${id}`, { params });
  // }

  public dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
      type: 'image/jpg'
    });
  }

  isUnApprovedCheckList(last_questionnaire) {
    return last_questionnaire ? !last_questionnaire.datetime_approved : false;
  }

  addComment(data: Object): Observable<any> {
    return this.http.post<any>(this.apiUrl + "/vehicles/comments", data);
  }


  getPendingTaskVehicleByPlate(arrayParams = []): Observable<any> {
    let params = this.getParams(arrayParams);

    return this.http.get(this.apiUrl + "/vehicles/filter", { params });
  }

  cancelDefleeting(id, body): Observable<any> {
    return this.http.post(this.apiUrl + `/vehicles/cancel-defleeting/${id}`, body);
  }

  // getVehiclesByDefleetingRequest(arrayParams = []): Observable<any> {
  //   let params = this.getParams(arrayParams);

  //   return this.http.get(this.apiUrl + "/vehicles/defleeting-filter", { params });
  // }


  getVehiclesByDefleetingRequest(arrayParams: any) {
    let params = this.getParams(arrayParams);
    return this.http.get<any>(this.apiUrl + `/authorized-defleeting`, { params });
  }

  uploadFile(file: File, typeImport: string): Observable<any> {
    const formData = new FormData();
    formData.append('archivo-ejemplo', file);
    formData.append('typeImport', typeImport);
    // Configura el encabezado Content-Type correctamente
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });

    // Agrega el encabezado al objeto de opciones
    const options = { headers: headers };
    return this.http.post(`${this.apiUrl+'/upload-defleeting'}`, formData);
  }


  importVehicleSig(file: File, campa_id: string): Observable<any> {
    const formData = new FormData();
    formData.append('import-sig', file);
    formData.append('campa_id', campa_id);
    // Configura el encabezado Content-Type correctamente
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    });

    return this.http.post(`${this.apiUrl+'/vehicles-sig/import'}`, formData);
  }

  requestAuthorizedDefleeting(body): Observable<any> {
    return this.http.post(this.apiUrl + `/vehicles/request-authorized-defleeting`, body);
  }

  createRequestDefleeting(body): Observable<any> {
    return this.http.post(this.apiUrl + `/vehicles/create-request-defleeting`, body);
  }
}
