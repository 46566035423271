<div class="container-spinner" *ngIf="loading">
    <mat-spinner class="spinner" [diameter]="70"></mat-spinner>
</div>
<div class="container-chat-dialog">
  <button mat-icon-button class="close-button" (click)="exit()">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
    <div class="data-vehicle">
        <p>Matrícula: <strong>{{vehicle?.plate}},</strong></p>
        <p>Recepción: <strong>{{reception?.created_at | date: 'dd/MM/yyyy HH:mm'}},</strong></p>
        <p>Intervención: <strong>{{pendingTask?.task.name}}</strong></p>
    </div>
      <form novalidate [formGroup]="formGroup">
          <mat-form-field appearance="outline">
              <mat-label>Comentario</mat-label>
              <textarea matInput class="text-comment" formControlName="comment"></textarea>
          </mat-form-field>
          <div class="add-comment">
            <mat-form-field appearance="outline">
              <mat-label>Motivo</mat-label>
              <mat-select formControlName="type">
                <mat-option value="">Otro</mat-option>
                <mat-option value="INCIDENCIA">Incidencia</mat-option>
                <mat-option value="RETRASO">Retraso</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-raised-button matTooltip="Añadir comentario" (click)="addMessage()">
              <span style="color: #fff">Enviar</span>&nbsp;
              <mat-icon class="hover" style="color: white;">send</mat-icon>
            </button>
          </div>

      </form>
</div>
