import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {RouteService} from 'src/app/services/route.service';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.css']
})
export class RoutesComponent implements OnInit {

  displayedColumns: string[] = ['name', 'editar'];
  public dataSource = new MatTableDataSource<any>();
  routes: any;
  isLoading = true;
  noRoutesData: Observable<boolean> = of(true);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    public routeService: RouteService,
    public router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getRoutes();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Mostrando';
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  getRoutes(): void {
    this.routeService.getAll().subscribe(
      routes => {
        // this.routes = result.routes;
        this.dataSource.data = routes;
        this.isLoading = false;
        this.noRoutesData = this.dataSource.connect().pipe(map(data => data.length === 0));
      });
  }

  editRoute() {
    this.router.navigateByUrl('/editroute');
  }

}
