import { ChangeDetectorRef, Component, OnInit, Output, ViewChild, EventEmitter, Input, HostListener, ElementRef } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { StatisticService } from 'src/app/services/statistic.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-stock-by-month',
  templateUrl: './stock-by-month.component.html',
  styleUrls: ['./stock-by-month.component.css']
})
export class StockByMonthComponent implements OnInit {

  offsetWidth: number;
  @Input() title;
  @Input() groupName;
  @Input() pick = [];
  data = [];
  group: any = {}
  show: boolean;
  @Output() onData = new EventEmitter<any>();
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.setOffsetWidth();
  }
  constructor(
    private el: ElementRef,
    private statisticService: StatisticService,
    private cdr: ChangeDetectorRef
  ) { }

  setOffsetWidth() {
    this.offsetWidth = this.el.nativeElement.firstChild.offsetWidth;
    const canva = this.el.nativeElement.querySelector('canvas');
    if (canva) {
      const fix = 100;
      canva.style.width = `${this.offsetWidth - fix}px`;
    }
  }

  ngOnInit(): void {
    const sub = this.statisticService.getStockByMonth().subscribe((res) => {
      res.forEach((x) => {
        this.barChartData.labels.push(x.date);
        this.barChartData.datasets[0].data.push(x.deleted)
        this.barChartData.datasets[1].data.push(x.total)
      });
      this.onData.next({
        stockByMonth: {
          labels: this.barChartData.labels,
          data: this.barChartData.datasets
        }
      });
      this.show = true;
      this.cdr.detectChanges();
      this.setOffsetWidth();
      sub.unsubscribe();
    }, (err) => {
      sub.unsubscribe();
    })
  }
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 10
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [
    StockByMonthComponent
  ];

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      { data: [], label: 'Eliminado' },
      { data: [], label: 'Total' }
    ]
  };

  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
  }

}
