<div *ngIf="show" class="row">
    <mat-card style="width: 100%">
        <mat-card-header>
            <div style="margin-right: 32px;width: 100%;text-align: center;">{{title}}</div>
        </mat-card-header>
        <mat-card-content>
            <div>
                <div>
                    <div class="chart">
                        <canvas baseChart [data]="pieChartData" [type]="pieChartType" [options]="pieChartOptions"
                            [plugins]="pieChartPlugins">
                        </canvas>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>