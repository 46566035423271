import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective, NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {ErrorStateMatcher} from '@angular/material/core';
import Swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
    const invalidParent = !!(control?.parent?.invalid && control?.parent?.dirty);

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {

  password: string;
  passwordConfirm: string;
  userId: string;
  code: number;
  email: any;
  spinnerSubject = new BehaviorSubject<boolean>(false);
  public showSpinner: Observable<boolean>;
  public loginInvalid: boolean;
  resetForm: UntypedFormGroup;
  // tslint:disable-next-line:max-line-length
  regexEmailPattern = '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';
  regexCodePattern = '^[0-9]{1,6}$';
  matcher = new MyErrorStateMatcher();
  hide = true;
  hideConfirm = true;

  @ViewChild('elementCode', {static: true, read: ElementRef}) elementCode: ElementRef;
  @ViewChild('elementPassword', {static: true, read: ElementRef}) elementPassword: ElementRef;
  @ViewChild('elementConfirmPassword', {static: true, read: ElementRef}) elementConfirmPassword: ElementRef;

  constructor(
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private route: Router,
    private readonly renderer: Renderer2
  ) {
    this.email = this.route.getCurrentNavigation().extras.state;
  }

  ngOnInit(): void {
    this.showSpinner = this.spinnerSubject.asObservable();
    this.resetForm = this.fb.group({
      email: [undefined, {
        validators: [Validators.required, Validators.pattern(this.regexEmailPattern)],
        updateOn: 'change',
      }],
      code: [undefined, {
        validators: [Validators.required, Validators.pattern(this.regexCodePattern)],
        updateOn: 'change',
      }],
      password: ['', {
        validators: [Validators.required],
        updateOn: 'change',
      }],
      confirmPassword: ['']
    }, {validators: this.checkPasswords});

    this.resetForm.controls.email.patchValue(this.email);

  }

  ngAfterViewInit(): void {
    // (this.element.nativeElement as HTMLInputElement).readOnly = false;
    const $this = this;
    setTimeout(() => {
      ($this.elementCode.nativeElement as HTMLInputElement).readOnly = false;
      ($this.elementPassword.nativeElement as HTMLInputElement).readOnly = false;
      ($this.elementConfirmPassword.nativeElement as HTMLInputElement).readOnly = false;
    }, 1000);
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : {notSame: true};
  };

  resetPassword(): void {
    this.spinnerSubject.next(true);
    delete this.resetForm.value.confirmPassword;
    this.userService.resetPassword(this.resetForm.value).pipe(
      finalize(() => this.spinnerSubject.next(false))
    ).subscribe(data => {
      if (data.message === 'Code not available') {
        Swal.fire({
          icon: 'error',
          title: 'Reestablecer contraseña',
          text: 'Código inválido.',
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Reestablecer contraseña',
          text: 'La contraseña ha sido reestablecida satisfactoriamente.',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(dataReset => {
          if (dataReset.isConfirmed) {
            this.route.navigateByUrl('/login');
          }
        });
      }
    });
  }

}
