<sat-calendar cdkTrapFocus
    [id]="datepicker.id"
    [ngClass]="datepicker.panelClass"
    [startAt]="datepicker.startAt"
    [startView]="datepicker.startView"
    [minDate]="datepicker._minDate"
    [maxDate]="datepicker._maxDate"
    [dateFilter]="datepicker._dateFilter"
    [rangeHoverEffect]="datepicker.rangeHoverEffect"
    [headerComponent]="datepicker.calendarHeaderComponent"
    [footerComponent]="datepicker.calendarFooterComponent"
    [selected]="datepicker._selected"
    [dateClass]="datepicker.dateClass"
    [@fadeInCalendar]="'enter'"
    (selectedChange)="datepicker.select($event)"
    (yearSelected)="datepicker._selectYear($event)"
    (monthSelected)="datepicker._selectMonth($event)"
    [beginDate]="datepicker._beginDate"
    [endDate]="datepicker._endDate"
    [rangeMode]="datepicker.rangeMode"
    [closeAfterSelection]="datepicker.closeAfterSelection"
    [orderPeriodLabel]="datepicker.orderPeriodLabel"
    (dateRangesChange)="datepicker._selectRange($event)"
    (beginDateSelectedChange)="setBeginDateSelected($event)"
    (_userSelection)="close()">
</sat-calendar>
