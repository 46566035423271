<div class="container py-5">
  <!--div class="d-flex justify-content-end mb-4">
    <button (click)="exportKpis()" mat-stroked-button color="primary">Generar Informe</button>
  </div-->
  <div class="row">
    <div class="col-3">
      <app-op-commercial [pick]="pickState" [groupName]="'state'" [title]="'Grafico de estados'" (onData)="onData($event)"></app-op-commercial>
    </div>
    <div class="col-3">
      <app-op-commercial [pick]="pickSubState" [groupName]="'subState'" [title]="'Grafico de sub-estados por taller'" (onData)="onData($event)"></app-op-commercial>
    </div>
    <div class="col-6">
      <app-average-by-substate [title]="'Grafico de promedio por sub-estado'"></app-average-by-substate>
    </div>
    <div class="col-6">
      <app-stock-by-month [title]="'Grafico de stock por mes'" (onData)="onData($event)"></app-stock-by-month>
    </div>
    <div class="col-6">
      <app-average-by-task [title]="'Grafico de promedio por sub-estado'"></app-average-by-task>
    </div>
  </div>
</div>

<table id="campa-table" style="display: none;">
  <tr>
    <td>KPI ́s Stock Campa</td>
  </tr>
  <!-- <tr *ngFor="let label of data?.state?.labels; index as i">
    <td>{{label}}</td>
    <td>{{data.state.data[i]}}</td>
    <td></td>
  </tr> -->
</table>

<table id="states-table" style="display: none;">
  <tr>
    <td>KPI ́s Estados ALD Flex</td>
  </tr>
  <tr *ngFor="let label of data?.state?.labels; index as i">
    <td>{{label}}</td>
    <td>{{data.state.data[i]}}</td>
    <td></td>
  </tr>
</table>
