import { ChangeDetectorRef, Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { StatisticService } from 'src/app/services/statistic.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-op-commercial',
  templateUrl: './op-commercial.component.html',
  styleUrls: ['./op-commercial.component.css']
})
export class OpCommercialComponent implements OnInit {
  @Input() title;
  @Input() groupName;
  @Input() pick = [];
  data = [];
  group: any = {}
  show: boolean;
  @Output() onData = new EventEmitter<any>();
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  constructor(
    private statisticService: StatisticService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const sub = this.statisticService.getStockByState().subscribe((res) => {
      this.group = _.pick(_.groupBy(res, this.groupName), this.pick);
      this.pieChartData.labels = _.keys(this.group);
      this.data = [];
      this.pieChartData.labels.forEach((key: any) => {
        this.data.push(_.sumBy(this.group[key], 'total'));
      });
      const total = _.sumBy(this.data);
      this.data = this.data.map((value) => {
        return parseFloat((value * 100 / total).toString()).toFixed(2);
      })
      this.pieChartData.datasets = [{
        data: this.data
      }];
      const value = {};
      value[this.groupName] = {
        group: this.group,
        labels: this.pieChartData.labels,
        data: this.data
      };
      this.onData.next(value);
      this.show = true;
      this.cdr.detectChanges();
      sub.unsubscribe();
    }, (err) => {
      sub.unsubscribe();
    })
  }
  // Pie
  public pieChartOptions: any = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      datalabels: {
        formatter: (value, ctx) => {
          return '';
        }
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label} : ${tooltipItem.dataset.data[tooltipItem.dataIndex]}%`;
          }
        }
      }
    },
  };

  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [],
    datasets: [{
      data: []
    }]
  };
  public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [DatalabelsPlugin];


}
