<button mat-icon-button class="close-button" (click)="onClose()">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h2 mat-dialog-title>Vehículo {{ checkDefleeting ? 'En estudio' : !!vehicle?.datetime_defleeting ? 'Defletado' : 'en estado: ' +(vehicle?.sub_state?.name + '/' + vehicle?.sub_state?.state?.name) }}</h2>
<mat-dialog-content>
  <h3 *ngIf="!isChanelDefleeting && !vehicle?.datetime_defleeting">
    Este vehiculo no se encuentra en el canal ALD FLEX ó canal BIPI
  </h3>
  <h3 *ngIf="!!vehicle?.datetime_defleeting || (isChanelDefleeting && !hasPendingTaskStarted)">El vehiculo {{vehicle?.plate}} <b>{{ !!vehicle?.datetime_defleeting ? '' : 'NO' }}</b> se encuentra defletado.</h3>
  <p *ngIf="!!vehicle?.datetime_defleeting">
    <b>Fecha: </b>
    {{ vehicle?.datetime_defleeting }}
  </p>
  <h3 *ngIf="isChanelDefleeting && hasPendingTaskStarted">
    Este vehículo se encuentra en estado "{{ vehicle?.sub_state?.name }} / {{ vehicle?.sub_state?.state?.name }}" y tiene tareas pendientes o iniciadas.
  </h3>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="isChanelDefleeting">
  <!-- <button *ngIf="!vehicle?.datetime_defleeting && vehicle?.sub_state_id === rDefleeting" mat-button class="mat-raised-button" (click)="onRequest()">Cancelar Solicitud Defletado</button> -->
  <button *ngIf="!vehicle?.datetime_defleeting && [rDefleeting, 22, 10].indexOf(vehicle?.sub_state_id)===-1 && !hasPendingTaskStarted && (vehicle?.sub_state?.state_id !== 6 || user.role_id === 1)" mat-button class="mat-raised-button" (click)="onRequest()">Solicitar Estudio</button>

  <button *ngIf="checkDefleeting && user.role_id === 12" mat-button class="mat-raised-button" [mat-dialog-close]="data" (click)="onDefleeting()">Defletar</button>
  <!-- <button *ngIf="!!checkDefleeting" mat-button class="mat-raised-button" [mat-dialog-close]="data" (click)="onDefleeting()">Retomar vehículo</button> -->
</mat-dialog-actions>
