import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IInitTaskData } from '../../../interfaces/dialog.interface';

@Component({
  selector: 'app-init-check-list-repair-dialog',
  templateUrl: './init-check-list-repair-dialog.component.html',
  styleUrls: ['./init-check-list-repair-dialog.component.css']
})
export class InitCheckListRepairDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InitCheckListRepairDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IInitTaskData
  ) { }

  ngOnInit(): void {}

  setTypeTask(type) {
    switch (type) {
      case 1:
        return 'Prueba dinámica 1'
      case 2:
        return 'Checklist inicial'
      case 3:
        return 'Presupuesto'
      case 4:
        return 'Reparación'
      case 5:
        return 'Prueba dinámica 2'
      case 6:
        return 'Checklist final'
      case 0:
        return ' '
      default:
        return 'Prueba dinámica 1'
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
