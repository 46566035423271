<div class="container py-5">
  <div class="row">
    <mat-card style="width: 100%;">
      <mat-card-header>
        <div class="col-8">
          <p class="greenLink"><a [routerLink]="'/dashboard'">Inicio </a> > Grupo de tareas</p>
          <!--          <h2>Grupo de Tareas</h2>-->
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="col-12">
          <div class="row">
            <!--            <div class="col-12 text-right">-->
            <!--              <a class="green " [routerLink]="'/editroute'"> <span class="material-icons">add_circle</span> Crear grupo-->
            <!--                de-->
            <!--                tareas </a>-->
            <!--            </div>-->
            <div class="my-4 col-6">
              <div class="example-header">
                <mat-form-field appearance="outline">
                  <mat-label>Buscar</mat-label>
                  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Buscar ...">
                </mat-form-field>
              </div>
            </div>
            <div class="my-4 col-6 text-right">
              <button mat-raised-button class="btn-green" style="margin-right: 5px;"
                      (click)="editRoute()">
                <mat-icon style="font-size: 20px !important;">add</mat-icon>
                Crear grupo de tareas
              </button>
              <button mat-raised-button class="btn-green" [hidden]="noRoutesData | async"
                      (click)="exporter.exportTable('xlsx', {fileName:'routes-list'} )">
                <mat-icon style="font-size: 20px !important;">file_download</mat-icon>
                Excel
              </button>
            </div>
            <!--            <div class="my-4 col-6 text-right">-->
            <!--              <button mat-button class="btn btn-primary"-->
            <!--                      (click)="exporter.exportTable('xlsx', {fileName:'routes-list'} )">-->
            <!--                <span>CSV</span>-->
            <!--                <mat-icon>get_app</mat-icon>-->
            <!--              </button>-->
            <!--            </div>-->
          </div>
          <div class="mat-elevation-z8 mb-5">
            <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort
                   class="mat-elevation-z8">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre</th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
              <ng-container matColumnDef="editar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <a [routerLink]="['/editroute', element.route_id]">
                    <mat-icon style="color:#03989E">search</mat-icon>
                  </a>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
              <mat-progress-spinner
                color="primary"
                mode="indeterminate">
              </mat-progress-spinner>
            </mat-card>
            <div *ngIf="!isLoading">
              <div *ngIf="noRoutesData | async" class="no-records mat-typography">
                <h3>No hay registros</h3>
              </div>
            </div>
            <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
                           [showFirstLastButtons]="true"></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
