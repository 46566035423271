import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { StorageService } from "../core/services/storage.service";

@Injectable({
  providedIn: "root",
})
export class SquareService {
  private _apiUrl = environment.api_url;
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  getSquaresWithParams(arrayParams = []): Observable<any> {    
    let params = this.getParams(arrayParams);
    return this.http.get(this._apiUrl + "/squares", { params });
  }

  saveSquare(name: string, streetId: number): Observable<any> {
    const bodySquare = {
      name,
      street_id: streetId,
    };
    return this.http.post(this._apiUrl + "/squares", bodySquare);
  }

  updateSquare(name: string, streetId: number, id: number): Observable<any> {
    const bodySquare = {
      name,
      street_id: streetId,
    };
    return this.http.put(this._apiUrl + `/squares/${id}`, bodySquare);
  }

  deleteSquare(id: number){
    return this.http.delete(this._apiUrl + `/squares/${id}`);
  }

   /** Updates vehicle Kilometers. */
   updateSquartVehicle(body): Observable<any> {
    return this.http.put(`${this._apiUrl}/squares/${body.id}`, body);
  }

  private getParams(arrayParams) {
    let params = new HttpParams();
    arrayParams.map((element) => {
      let param = element.split(",");
      params = params.append(param[0], param[1]);
    });
    return params;
  }
}
