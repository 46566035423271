import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.css']
})
export class RecoverComponent implements OnInit {
  form: UntypedFormGroup;
  email: string;
  password: string;
  hide = true;
  identity = null;
  public loginInvalid: boolean;
  spinnerSubject = new BehaviorSubject<boolean>(false);
  public showSpinner: Observable<boolean>;
  public disableBtn: boolean;
  // tslint:disable-next-line:max-line-length
  regexEmailPattern = '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private route: Router
  ) {
  }

  ngOnInit(): void {
    this.disableBtn = true;
    this.showSpinner = this.spinnerSubject.asObservable();
    this.form = this.fb.group({
      email: [undefined, {
        validators: [Validators.required, Validators.pattern(this.regexEmailPattern)],
        updateOn: 'change',
      }],
    });

    this.form.valueChanges
      .subscribe(value => {
        if (this.form.dirty) {
          this.spinnerSubject.next(false);
          this.disableBtn = false;
        }
        if (!this.form.valid) {
          this.disableBtn = true;
        }
      });
  }

  verifyEmail(): void {
    this.spinnerSubject.next(true);
    this.userService.verifyEmail({email: this.form.controls.email.value}).pipe(
      finalize(() => {
        this.spinnerSubject.next(false);
        Swal.fire({
          icon: 'success',
          title: 'Recuperar contraseña',
          text: 'Se le ha enviado un código de recuperación, por favor revise su correo.',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(data => {
          if (data.isConfirmed) {
            this.route.navigateByUrl('/reset-password', {state: this.form.controls.email.value});
          }
        });
      })
    ).subscribe(result => {
    });
  }

}
