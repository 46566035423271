<mat-card *ngIf="showSpinner | async" class="card-spinner">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</mat-card>
<div *ngIf="isContent" class="row">
  <h2 mat-dialog-title class="header">No se puede registar la ubicación del vehículo {{vehicle?.plate}}</h2>
  <h2 mat-dialog-title class="header">Vehículo {{ vehicle?.sub_state?.display_name }}</h2>
  <mat-dialog-actions align="center" class="p-4" style="width: 100%;">
    <button mat-button class="mat-raised-button mr-3" (click)="cancel()">
      Cerrar
    </button>
  </mat-dialog-actions>
</div>
<div *ngIf="!isContent && !!vehicle" class="row">
  <h2 mat-dialog-title class="header">{{data.title || 'Registrar ubicación del vehículo: '}} {{vehicle?.plate}}</h2>
  <div class="col-12">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>
        Zona:
        <span class="number-squart">{{ zones.length }} Zonas disponibles</span>
      </mat-label>
      <mat-select [(ngModel)]="zone_id" name="zone_id" (selectionChange)="onChange($event)">
        <mat-option *ngFor="let item of zones" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12" *ngIf="zone_id">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>
        Área:
        <span class="number-squart">{{ groupStreets[zone_id]?.length }} Áreas disponibles</span>
      </mat-label>
      <mat-select [(ngModel)]="street_id" name="street_id" (selectionChange)="onChange($event)">
        <mat-option *ngFor="let item of groupStreets[zone_id]" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12" *ngIf="street_id">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>
        Plaza:
        <span class="number-squart">{{ groupSquares[street_id]?.length }} Plazas disponibles</span>
      </mat-label>
      <mat-select [(ngModel)]="square_id" name="square_id" (selectionChange)="onChange($event)">
        <mat-option *ngFor="let item of groupSquares[street_id]" [value]="item.id">
          <span [style.opacity]="vehicle.square && item.vehicle_id === vehicle.square.vehicle_id ? 1 : 0" class="mr-2 iconify" data-icon="mdi:car" data-inline="false" style="zoom: 1.6;"></span>

          {{item.name}} {{ item.vehicle ? '(' + item.vehicle.plate + ')' : '' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-12" style="width: 100%;">
    <p style="text-align: center;" *ngIf="ubication">Ubicación: {{ubication}}</p>
  </div>

  <div *ngIf="add_task_id && square_id" class="col-12">
    <mat-form-field appearance="outline" class="custom-text-area" style="width: 100%;">
        <mat-label>Motivo de devolución</mat-label>
        <textarea matInput [(ngModel)]="commentary" placeholder="Deje un comentario"
            style="color: black !important;">
        </textarea>
    </mat-form-field>
  </div>

  <mat-dialog-actions align="center" class="p-4" style="width: 100%;">
    <button mat-button class="mat-raised-button mr-3" (click)="cancel()">
      Cerrar
    </button>

    <button mat-button class="mat-raised-button mr-3" (click)="updateUbication()" [disabled]="add_task_id ? !square_id || !commentary : !square_id">
      {{textButtomSave}}
    </button>
  </mat-dialog-actions>
</div>