import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubStateService {

  env = environment;
  apiUrl = this.env.api_url

  constructor(private http: HttpClient) { }

  getAll(arrayParams : any [] = []): Observable<any> {
    let params = this.getParams(arrayParams);
    return this.http.get(this.apiUrl + "/sub-states/getall", { params })
  }

  private getParams(arrayParams) {
    let params = new HttpParams();
    arrayParams.map((element) => {
      let param = element.split(",");
      params = params.append(param[0].trim(), param[1].trim());
    });
    return params;
  }
}
