import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { UserService } from "../services/user.service";
import { observable, Observable, Subscription, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TaskService {
  env = environment;
  private apiUrl = this.env.api_url;
  public token: string;

  constructor(private http: HttpClient, private userService: UserService) {}

  setParams(params, objParams) {
    const arr = Array.isArray(objParams) ? objParams : [];
    arr.forEach(param => {
      const split = param.split(',');
      if (split.length === 2) {
        params = params.append(split[0].trim(), split[1].trim());
      }
    });
    return params;
  }

  getAll(company_id = null, objParams = []): any {
    let params = new HttpParams();
    if (!!company_id) {
      params = params.append("companies[]", company_id);
    }
    params = this.setParams(params, objParams);
    params = params.append("with[]", "typeTask");
    params = params.append("with[]", "subState.state");
    params = params.append("with[]", "campas");
    return this.http.get<any>(this.apiUrl + "/tasks/getall", { params });
  }

  getById(id: number, objParams = []): any {
    let params = new HttpParams();
    if (!!objParams) {
      params = this.setParams(params, objParams);
    }
    this.token = this.userService.getToken();
    const headers = new HttpHeaders().set("Authorization", this.token);
    return !!id ? this.http.get<any>(this.apiUrl + "/tasks/" + id, { headers, params }) :  new Observable();
  }

  getStates(): any {
    this.token = this.userService.getToken();
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.get<any>(this.apiUrl + "/states", { headers });
  }

  getTypes(): any {
    this.token = this.userService.getToken();
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.get<any>(this.apiUrl + "/types-tasks/getall", { headers });
  }

  createTask(data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.post<any>(this.apiUrl + "/tasks", params, { headers });
  }

  updateTask(id, data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.put<any>(`${this.apiUrl}/tasks/update/${id}`, params, {
      headers,
    });
  }

  getPendingsTaskStatus(status: number): any {
    this.token = this.userService.getToken();
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.get<any>(this.apiUrl + "/pending-tasks/in-progress", {
      headers,
    });
  }

  getPendingsTasks(arrayParams): Observable<any> {
    let params = new HttpParams();
    params = this.setParams(params, arrayParams);
    return this.http.get(this.apiUrl + "/pending-tasks/getall", {
      params,
    });
  }

  getPendingsTasksFilter(arrayParams): Observable<any> {
    let params = new HttpParams();
    params = this.setParams(params, arrayParams);
    return this.http.get(this.apiUrl + "/pending-tasks/filter", {
      params,
    });
  }


  getStatesPendingsTask(): any {
    return this.http.get<any>(this.apiUrl + "/states-pending-tasks/getall");
  }

  getPendingsTaskByCompany(data: any): any {
    return this.http.post<any>(this.apiUrl + "/pending-tasks/by-state", data);
  }

  vehiclesByChannel() {
    let params = new HttpParams();
    params = params.append("with[]", "typeModelOrder");
    params = params.append("with[]", "lastOrder");
    params = params.append("with[]", "vehicleModel.brand");
    params = params.append("with[]", "lastReception.pendingTasks");

    return this.http.get<any>(this.apiUrl + "/invarat/vehicles-by-channel", {params});
  }

  getPendingsTaskByCampa(data: any): any {
    if(data.campas.length == 0){
        return  throwError(new Error('Campas no disponibles'));
    }
    return  this.http.post<any>(
      this.apiUrl +
        "/pending-tasks/by-state/by-campa?with[]=vehicle.campa&with[]=task&with[]=incidences&with[]=vehicle.vehicleModel.brand&with[]=vehicle.category&with[]=vehicle.subState.state",
      data
    );
  }

  createPendingTask(data: any): any {
    this.token = this.userService.getToken();
    const params = JSON.stringify(data);
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.post<any>(this.apiUrl + "/pending-tasks", params, {
      headers,
    });
  }

  createPendingtTaksVehicle(data: any): Observable<any> {
    return this.http.post(this.apiUrl + "/pending-tasks/add", data);
  }

  getPendingTasksInWorkshopMechanic(): any {
    return this.http.get<any>(this.apiUrl + "/pending-tasks/workshop-mechanic");
  }

  getPendingTasksInWorkshopChapa(): any {
    this.token = this.userService.getToken();
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.get<any>(this.apiUrl + "/pending-tasks/workshop-chapa", {
      headers,
    });
  }

  getPendingTasksInWorkshopItv(): any {
    this.token = this.userService.getToken();
    const headers = new HttpHeaders().set("Authorization", this.token);
    return this.http.get<any>(this.apiUrl + "/pending-tasks/itv", { headers });
  }

  updateGroupTaskRepair(id, data) {
    return this.http.put(this.apiUrl + `/grouptasks/update/${id}`, data);
  }

  /**
   * @deprecate use validateCheck
   * @param data
   * @returns
   */
  updateGroupTaskRental(data) {
    return this.http.post(this.apiUrl + `/grouptasks/approved-available`, data);
  }

  /**
   *
   * @param body  {questionnaire_id: this.questionnaire.id}
   */
  validateCheck(body) {
    return this.http.post(this.apiUrl + `/questionnaire/approved`, body);
  }

  updateGroupTaskDecline(data) {
    return this.http.post(this.apiUrl + `/grouptasks/decline/`, data);
  }

  updateSelectTaskDecline(data:any) {
    return this.http.put(this.apiUrl + `/question-answers/update/${data.id}`, data);
  }

  getStatePendingTask(id) {
    return this.http.get(this.apiUrl + `/states-pending-tasks/${id}`);
  }

  getSubStatePendingTask(id) {
    return this.http.get(this.apiUrl + `/sub-states/${id}`);
  }

  updateQuestionComente(id, data): any {
    return this.http.put<any>(
      this.apiUrl + `/question-answers/update/${id}`,
      data
    );
  }

  updateComent(id, data): any {
    return this.http.put<any>(
      this.apiUrl + `/question-answers/update/${id}`,
      data
    );
  }

  cancelPendingTasks(data) {
    return this.http.post<any>(
      this.apiUrl + "/pending-tasks/cancel-pending-task",
      data
    );
  }

  updatePendingTasks(id, data) {
    return this.http.put<any>(
      this.apiUrl + `/pending-tasks/update/${id}`,
      data
    );
  }

  confirmOrderPendingTasks(data) {
    return this.http.post<any>(this.apiUrl + "/pending-task/order", data);
  }

  addPendingTask(data): Observable<any> {
    return this.http.post(
      this.apiUrl + "/ald/add-pending-task",
      data
    );
  }

  updateCostTasks(id, data) {
    return this.http.put<any>(
      this.apiUrl + `/tasks/update-cost/${id}`,
      data
    );
  }
}
