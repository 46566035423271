import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserStorage } from "aws-sdk/clients/workspaces";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { StorageService } from "../core/services/storage.service";

@Injectable({
  providedIn: "root",
})
export class ZoneService {
  currentTab: number;
  dataExist = false
  isLoadingData: Observable<boolean>;
  loadingDataSubject = new BehaviorSubject(false);
  private _apiUrl = `${environment.api_url}`;
  constructor(
    private _http: HttpClient,
    private _storageService: StorageService
  ) {}

  getZones(campaList: any = []): Observable<any> {
    let params = new HttpParams();
    campaList.forEach((campa) => {
      params = params.append("campaIds[]", String(typeof campa === 'object' ? campa.id : campa  ));
    });
    return this._http.get(this._apiUrl + "/zones", { params });
  }

  createZone(campaId: number, zone: string): Observable<any> {
    const bodyZone = {
      campa_id: campaId,
      name: zone,
    };
    return this._http.post(this._apiUrl + "/zones", bodyZone);
  }

  updateZone(campaId: number, zone: string, id: number): Observable<any> {
    const bodyZone = {
      campa_id: campaId,
      name: zone,
    };
    return this._http.put(this._apiUrl + `/zones/${id}`, bodyZone);
  }
}
