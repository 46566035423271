import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NewPendingTaskDialog } from "../../../../interfaces/dialog.interface";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { TaskService } from "src/app/services/task.service";
import Swal from "sweetalert2";
import * as _ from 'lodash';
import { Subject, Subscription } from "rxjs";
import { SubStateService } from "src/app/services/sub-state.service";
import { VehicleService } from "src/app/services/vehicle.service";
import { LocationDialogComponent } from "src/app/components/shared/location-dialog/location-dialog.component";
import { PendingTasksService } from "src/app/services/pending-tasks.service";
import * as S3 from "aws-sdk/clients/s3";
import { environment } from "src/environments/environment";
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils'
import { StorageService } from "@core/services/storage.service";

const TASK_REQUEST_DEFLEET=77;
const TASK_CARROCERIA = 2;
@Component({
  selector: "app-modal-simple-task",
  templateUrl: "./modal-simple-task.component.html",
  styleUrls: ["./modal-simple-task.component.css"],
})
export class ModalSimpleTaskComponent implements OnInit {
  filterForm: UntypedFormGroup;
  task: any[] = [];
  change: Subject<any> = new Subject<any>();
  sub: Subscription;

  private env = environment;

  config: any = {
    region: this.env.AWSregion,
    accessKeyId: this.env.AWSaccessKeyId,
    secretAccessKey: this.env.AWSsecretAccessKey,
    bucketName: this.env.AWSbucketName,
  };

  s3: any = new S3({
    accessKeyId: this.config.accessKeyId,
    secretAccessKey: this.config.secretAccessKey,
    region: this.env.AWSregion,
  });
  isRequestDefleet =false;
  document: any = null;
  budget: any = {};
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalSimpleTaskComponent>,
    private subStateService: SubStateService,
    private formBuilder: UntypedFormBuilder,
    public tasksService: TaskService,
    private vehicleService: VehicleService,
    private cdr: ChangeDetectorRef,
    private pendingTasksService: PendingTasksService,
    private storageService: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: NewPendingTaskDialog
  ) { }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      state_pending_task_id: [
        undefined,
        { validators: Validators.required, updateOn: "change" },
      ],
      vehicle_id: [
        undefined,
        { validators: Validators.required, updateOn: "change" },
      ],
      tasks: [
        undefined,
        { validators: Validators.required, updateOn: "change" },
      ],
      comment: [
        undefined,
        { updateOn: "change" },
      ],
    });
    this.filterForm.patchValue({
      state_pending_task_id: 1,
      vehicle_id: this.data.vehicle.id
    });
    this.cdr.detectChanges();
    this.getTask();
    this.isRequestDefleet = !!this.data.vehicle.reception.all_approved_pending_tasks.find(el=>el.task_id===TASK_REQUEST_DEFLEET &&  [null,1,2].indexOf(el.state_pending_task_id) > -1 );
  }

  getTask() {
    this.subStateService.getAll(['noIds[],22', 'noIds[],31']).subscribe((res) => {
      let arrayParams: any[] = ['with[],subState'];
      res.forEach((value: any) => {
        if (value.state_id === 1 || value.state_id === 2) {
          arrayParams.push(`subStates[],${value.id}`);
        }
      })
      this.tasksService.getAll(1, arrayParams).subscribe((data) => {
        this.task = data;
      });
    })

  }
  openLocation(data) {
    const dialogRef = this.dialog.open(LocationDialogComponent, {
      width: "500px",
      height: "60vh",
      disableClose: true,
      autoFocus: false,
      data: {
        vehicle_id: data.vehicle_id
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!!result) {
        this.save(data);
      }
    });
  }

  SaveP0endingTaskFinished(): void {
    const data: any = _.clone(this.filterForm.value);
    if (this.isRequestDefleet && data.tasks.id === TASK_CARROCERIA){
      Swal.fire({
        icon: "error",
        title: "No se pueden agregar tareas!",
        text: "El vehículo se encuentra en estado ESTUDIO - PENDIENTE VENTA V.O.",
      });
      return;
    }
    this.save(data);
  }

  save(data: any) {
    console.log(data)
    if (this.data.vehicle.sub_state_id == 10 ){
      Swal.fire({
        icon: "error",
        title: "No se pueden agregar tareas!",
        text: "El vehículo se encuentra en estado ALQUILADO",
      });
      this.dialogRef.close();
      this.filterForm.reset();
      this.budget = {};
      this.document = null;
      return;
    }
    if (!!this.data.vehicle.last_reception && !this.data.vehicle.last_reception.approved_pending_tasks) {
      this.data.vehicle.last_reception.approved_pending_tasks = [];
    }
    let enc = false;
    if (_.findIndex(this.data.vehicle.last_reception?.pending_tasks, { state_pending_task_id: 1 }) > -1) {
      enc = true;
    }
    const tasks = _.map(Array.isArray(data.tasks) ? data.tasks : [data.tasks], (x, index) => {
      const value: any = {
        task_id: x.id,
        task_order: index + 1,
      }
      if (enc) {
        value.without_state_pending_task = true;
      }
      return value;
    });
    data.task_id = tasks[0].task_id;
    // const idxTask = this.task.findIndex(t=>t.id==data.task_id)
    // if (idxTask > -1){
    //   const idxCampa = (this.task[idxTask].campas || []).findIndex(c=>c.id == this.data.vehicle.campa_id);
    //   if (idxCampa>-1){
    //     const campa = this.task[idxTask].campas[idxCampa];
    //     if (campa && campa.pivot.cost == 1 && !this.budget.url ){
    //       Swal.fire({
    //         icon: "error",
    //         text: "La tarea seleccionada es  una tarea con coste y debe cargar un presupuesto!",
    //       });
    //       return;
    //     }
    //   }
    // }
    if (this.sub) {
      return;
    }
    this.tasksService.addPendingTask({
      state_pending_task_id: data.state_pending_task_id,
      vehicle_id: data.vehicle_id,
      task_id: data.task_id,
      observations: data.comment,
      document: this.document,
      budget: this.budget
    }).subscribe(
      (data) => {
        Swal.fire({
          icon: "success",
          title: "Tareas agregada!",
          text: "",
        });
        this.dialogRef.close();
        this.filterForm.reset();
        this.budget = {};
        this.document = null;
        this.change.next({ type: 'saveTask', data })
      },
      (err) => {
        if (err.status === 422) {
          Swal.fire({
            icon: "error",
            title: "El vehículo no tienen recepción",
            text: "¿Dese crear una recepción al vehículo?",
            showCancelButton: true,
            confirmButtonText: 'Si',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.sub = this.vehicleService.newRepection(this.data.vehicle.id).subscribe((data: any) => {
                // await Swal.fire('Recepción creada', '', 'success');
                this.SaveP0endingTaskFinished();
                this.sub.unsubscribe();
                this.sub = undefined;
              }, () => {
                Swal.fire({
                  icon: "error",
                  title: "Ha ocurrido un error!",
                  text: "",
                });
                this.sub.unsubscribe();
                this.sub = undefined;
              });
            }
          });
        } else {
          this.dialogRef.close();
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido un error!",
            text: "",
          });
        }
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  fileChangeEvent(event) {
    const zip = new JSZip();
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const filePath = `${file.name}`; // Ruta completa dentro del directorio "imagenes"
      zip.file(filePath, file);
    }

    zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
      const zipFile = new File([content], 'images.zip', { type: 'application/zip' });
      // var link = document.createElement("a");
      // link.href = window.URL.createObjectURL(zipFile);
      // link.download = `${new Date().toISOString()}.zip`;
      // link.click();
      this.uploadFile(zipFile);
    });
  }

  uploadFile(zipFile) {
    console.log(zipFile)
    const keyFile = Math.random().toString(36).substring(2);
    this.s3.upload(
      {
        Key: `${keyFile}.${zipFile.name.split(".").pop()}`,
        Bucket: this.config.bucketName,
        Body: zipFile,
        ACL: "public-read",
      },
      (error, data) => {
        if (error) {
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido un error al cargar la imagen",
            text: "Contacta con el administrador del sitio",
          });
        } else {
            this.document = data.Location
        }
      }
    );
  }
  get user(): any {
    return this.storageService.getCurrentUser() || {};
  }
  exportFile(linkUrl, ext) {
    const link: any = document.createElement("a");
    link.download = `${new Date().toISOString()}.${ext}`;
    link.href = linkUrl;
    link.target = '_blank';
    link.click();
  }

  updateQuestionaires(input) {
    input.click()
  }
  openFile(input_file, event) {
    event.preventDefault();
    Swal.fire({
      title: 'Debe indicar el tipo de presupuesto:',
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Nuevo',
      denyButtonText: 'Modificación',
    }).then((result) => {
      if (result.isConfirmed) {
        if (input_file) {
          input_file.dataset.budget_type = 0;
          input_file.value = null;
          input_file.click();
        }
      } else if (result.isDenied) {
        if (input_file) {
          input_file.dataset.budget_type = 1;
          input_file.value = null;
          input_file.click();
        }
      }
    });
  }
  fileChangeEventBudget(event){
    const file = event.target.files[0];
    const keyFile = Math.random().toString(36).substring(2);
    this.s3.upload(
      {
        Key: `${keyFile}.${file.name.split(".").pop()}`,
        Bucket: this.config.bucketName,
        Body: file,
        ACL: "public-read",
      },
      (error, data) => {
        if (error) {
          Swal.fire({
            icon: "error",
            title: "Ha ocurrido un error al cargar presupuesto",
            text: "Contacta con el administrador del sitio",
          });
        } else {;
          this.budget.url = data.Location
          this.budget.type = event.target.dataset.budget_type;
        }
      }
    );
  }
}
