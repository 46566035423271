<input type="file" #file style="display: none" (change)="onFilesAdded()" />
<div class="container" fxLayout="column" fxLayoutAlign="space-evenly stretch">
  <div>
    <mat-hint style="display: flex; justify-content: center; margin-bottom: 20px;">Solo se admiten archivos con formato
      xlsx.
    </mat-hint>
  </div>
  <div class="d-flex">
    <div>
      <a href="assets/download-excel-file/MobiusExcelExample.xlsx" download="archivo-ejemplo.xlsx">
        <mat-icon>file_download</mat-icon>
        Descargar excel de
        ejemplo
      </a>
    </div>
    <div style="margin-left: auto;">
      <input type="file" (change)="onChange($event)" #inputFile>
    </div>
  </div>

  <!-- This is the content of the dialog, containing a list of the files to upload -->
  <mat-dialog-content fxFlex>
    <mat-list>
      <mat-list-item *ngFor="let file of files" class="mat-typography">
        <!--        <mat-icon>file</mat-icon>-->
        <h4 mat-line>
          <mat-icon>attachment</mat-icon>
          {{file.name}}
        </h4>
      </mat-list-item>
    </mat-list>
    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center;margin-top: 20px;">
      <mat-progress-spinner *ngIf="isLoading" color="primary" [diameter]="40" [strokeWidth]="5" mode="indeterminate">
      </mat-progress-spinner>
      <br>
    </div>
    <p *ngIf="isLoading" style="text-align: center;">Procesando los datos, por favor espere...</p>
    <div class="row" *ngIf="allVehicles.length > 0 && !isLoading">
      <div class="col-12">
        <mat-label>
          Cantidad de registros a procesar: {{ allVehicles.length - lengthExistsVehicle }}; Vehiculos ya en el sistema:
          {{lengthExistsVehicle}}
        </mat-label>
      </div>
      <div class="col-12">
        <div class="text-center header mb-2">Datos del vehículo</div>

        <form [formGroup]="form" class="row">
          <div class="col-4">
            <mat-form-field class="row" appearance="outline">
              <mat-label>{{fieldName["plate"]}}</mat-label>
              <input formControlName="plate" oninput="this.value = this.value.toUpperCase().trim()" matInput />
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="row" appearance="outline">
              <mat-label>{{fieldName["observations"]}}</mat-label>
              <textarea matInput formControlName="observations" style="color: black !important;">
            </textarea>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>{{fieldName["campa_id"]}}</mat-label>
              <mat-select formControlName="campa_id">
                <mat-option *ngFor="let campa of campas" [value]="campa.id">
                  {{campa.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>Empresa</mat-label>
              <mat-select formControlName="company_id">
                <mat-option *ngFor="let item of companies" [value]="item.id">{{ item.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>{{fieldName["vin"]}}</mat-label>
              <input formControlName="vin" matInput />
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>{{fieldName["kms"]}}</mat-label>
              <input formControlName="kms" matInput />
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>{{fieldName["version"]}}</mat-label>
              <input formControlName="version" matInput />
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>{{fieldName["seater"]}}</mat-label>
              <input oninput="this.value = (this.value.match(/\d+/) || [])[0] || 0" formControlName="seater" matInput />
            </mat-form-field>
          </div>
          <!--div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>Marca</mat-label>
              <mat-select formControlName="brand_id" #singleSelect>
                <mat-option>
                  <ngx-mat-select-search formControlName="brandsFilterCtrl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let brand of brands" [value]="brand.id">{{ brand.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div-->
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>Modelo</mat-label>
              <mat-select formControlName="vehicle_model_id">
                <!--mat-option>
                  <ngx-mat-select-search formControlName="modelFilterCtrl"></ngx-mat-select-search>
                </mat-option-->
                <mat-option *ngFor="let model of models" [value]="model.id">{{ model.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!--
            <mat-form-field appearance="outline">
              <mat-label>Canal</mat-label>
              <mat-select formControlName="channel">
                <mat-option value="null"> Sin canal </mat-option>
                <mat-option *ngFor="let channel of channels" [value]="channel.id">{{ channel.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Color</mat-label>
              <mat-select formControlName="color">
                <mat-option value="0"> Sin color </mat-option>
                <mat-option *ngFor="let color of colors" [value]="color.id">
                  {{color.name}}</mat-option>
              </mat-select>
            </mat-form-field>
           
            <mat-form-field appearance="outline">
              <mat-label>Sub-estado</mat-label>
              <mat-select formControlName="subState">
                <mat-option [value]="null"> Sin Sub-estado </mat-option>
                <mat-option *ngFor="let subState of subStates" [value]="subState.id">{{ subState.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Id interno</mat-label>
              <input formControlName="remoteId" matInput />
            </mat-form-field>
          </div>
          <div class="col-6" style="padding-left: 1px !important;">
            <mat-form-field appearance="outline">
              <mat-label>Fecha matriculación</mat-label>
              <input formControlName="firstPlate" matInput [matDatepicker]="picker" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Estado</mat-label>
              <input formControlName="state" matInput />
            </mat-form-field>
            <mat-slide-toggle color="primary" formControlName="has_environment_label">Distintivo Ambiental
            </mat-slide-toggle>
          </div>
        -->
        </form>
      </div>
      <div class="d-flex justify-content-around mb-2">
        <button class="btn btn-primary me-md-2" type="button" (click)="saveVehicle()">
          {{ form?.get('id')?.value ? 'Editar' : 'Grabar' }}
        </button>
      </div>
    </div>
    <div class="row" *ngIf="auxVehicles.length > 0">
      <div class="col-12 text-center header mb-2">Configuracion</div>
      <div class="col-6" *ngFor="let obj of objField | keyvalue">
        <div class="row" style="margin: 20px;">
          <div class="col-4">
            <mat-label>
              {{ fieldName[obj.key] }}
            </mat-label>
          </div>
          <div class="col-6">
            <mat-select [(ngModel)]="objField[obj.key]" (selectionChange)="setVehicle()">
              <mat-option *ngFor="let value of columnKeys" [value]="value">
                {{value}}
              </mat-option>
            </mat-select>
          </div>
          <div class="col-2">
            <mat-icon (click)="objField[obj.key] = null; setVehicle()">clear</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <!--mat-progress-bar mode="determinate" [value]="valueProgressBar"></mat-progress-bar>
  <p class="text-center">{{(valueProgressBar).toFixed(2)}} %</p-->

  <!-- This are the actions of the dialog, containing the primary and the cancel button-->
  <!--mat-dialog-actions class="actions">
    <button *ngIf="showCancelButton" mat-button mat-dialog-close>Cerrar</button>
    <button mat-raised-button class="btn-green" [disabled]="!canBeClosed || disableImportBtn" (click)="closeDialog()">
      {{primaryButtonText}}
    </button>
  </mat-dialog-actions-->
</div>