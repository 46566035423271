<div class="container-privacy">
  <div>
    <img src="..\..\assets\focus-login-vertical.jpg" class="login__logo">
  </div>
  <div class="items">
    <div class="item">
      <h3>1. Política de Privacidad y Seguridad</h3>
      <p>La siguiente información sobre nuestra Política de Privacidad refleja el compromiso de MKD AUTOMOTIVE INTEGRAL SOLUTIONS, S.L.U.
        (en adelante Focus) por mantener y garantizar relaciones comerciales seguras mediante la protección de los datos personales,
        garantizando el derecho a la privacidad de cada uno de los usuarios de nuestros servicios. En este documento explicamos cómo
        utilizamos los datos personales de los usuarios del portal web y de la Plataforma Móvil.</p>
    </div>
    <div class="item">
      <h3>2. Definición de dato personal</h3>
      <p>Como “Dato Personal” debemos entender cualquier información concerniente a una persona física identificada o identificable.
        Entre otros, se incluyen el nombre, apellidos, la dirección postal y electrónica, así como el número de teléfono. </p>
    </div>
    <div class="item">
      <h3>3. Tratamientos y finalidades de los datos recogidos</h3>
      <p>Focus es una herramienta profesional, para utilizarla es necesario darse de alta en la empresa contratante del
        producto. No se recopilan datos de carácter personal. </p>
      <p>Los datos de los vehículos pertenecen a los clientes y su acceso está protegido por usuario y contraseña. </p>
      <span>Localización:</span>
      <p>Se utiliza la geolocalización para determinar donde se encuentra el vehículo sobre el que se está realizando el trabajo. </p>
      <span>Foto y vídeo:</span>
      <p>Se utiliza el acceso a la cámara para realizar fotos al vehículo en el que se está realizando el trabajo.</p>
    </div>
    <div class="item">
      <h3>4. Legitimación para el tratamiento de sus datos </h3>
      <p>La base legal para el tratamiento está determinada en el contrato de la empresa con la que Focus presta servicio.. </p>
    </div>
    <div class="item">
      <h3>5. Plazo de conservación de los datos </h3>
      <p>Los datos serán conservados el tiempo necesario para dar servicio al propietario de la campa y a la empresa contratante. </p>
    </div>
    <div class="item">
      <h3>6. Comunicación de sus datos personales a terceros </h3>
      <p>No se comunicarán datos a terceros.</p>
    </div>
    <div class="item">
      <h3>7. Seguridad de sus datos personales </h3>
      <p>Focus tiene una preocupación especial por garantizar la seguridad de sus datos personales. Sus datos son almacenados en nuestros
        sistemas de información, donde hemos adoptado e implantado medidas de seguridad, técnicas y organizativas, para prevenir cualquier
        pérdida o uso no autorizado por terceros, por ejemplo nuestros portales web utilizan protocolos de cifrado de datos Https. </p>
    </div>
    <div class="item">
      <h3>8. Información sobre la Utilización de cookies</h3>
      <p>Por el mero hecho de visitar el presente portal web o utilizar los servicios de Focus no queda registrado de forma automática ningún
        dato de carácter personal que identifique a un Usuario.</p>
    </div>
    <div class="item">
      <h3>9. Derechos de los usuarios</h3>
      <p>Todos los usuarios pueden ejercitar cualquier de los derechos otorgados por la normativa de protección de datos, como el derecho de acceso,
        limitación del tratamiento, supresión, portabilidad, etc. mediante escrito dirigido a Focus o enviando un correo electrónico a
        informatica@grupomobius.com.</p>
      <p>En el caso de que cualquier usuario no se encuentre satisfecho con la solución aportada por la empresa, podrá acudir y tramitar la
        pertinente reclamación ante la Agencia Española de Protección de Datos. </p>
    </div>
    <div class="item">
      <h3>10. Cambios a la Política de Privacidad </h3>
      <p>Nuestra Política de Privacidad podrá sufrir actualizaciones, debidas a cambios y necesidades legales, así como debidas a mejoras y
        cambios incluidos en la forma de ofrecer y prestar nuestros servicios y utilidades de la aplicación. Por ello, le recomendamos que
        visite y acceda a nuestra Política de Privacidad periódicamente, para poder tener acceso y conocer los últimos cambios que hayan podido
        ser incorporados. En caso de que dichos cambios guarden relación con el consentimiento prestado por el usuario, en tal caso le será
        enviada una notificación independiente y por separado para recabarlo nuevamente. </p>
      <p>Si durante la lectura le ha surgido alguna duda o cuestión sobre nuestra Política de Privacidad o quiere ejercitar algún
        derecho o acción relativa a sus datos personales, por favor póngase en contacto con nosotros en la siguiente dirección de correo
        electrónico informatica@grupomobius.com. </p>
    </div>
  </div>
</div>
