import * as CampaActions from './campa.action';

const initialState: any = {
  ids: []
};

export function campaReducer(state: any = initialState, action: CampaActions.ChangeCampa) {
  switch (action.type) {
    case CampaActions.CHANGE_CAMPA:
      return {
        ...state,
        id: action.payload
      };
      break;
    default:
      return state;
      break;
  }
}
