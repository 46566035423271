import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { UserService } from "../services/user.service";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { StorageService } from "../core/services/storage.service";
import { AuthenticationService } from "./shared/authentication.service";
import { LoginObject } from "./shared/login-object.model";
import Swal from 'sweetalert2';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  email: string;
  password: string;
  loginError: boolean;
  hide = true;
  identity = null;
  loginInvalid = false;
  showSpinner: boolean;
  disableBtn: boolean;
  public error: { code: number; message: string } = null;
  // tslint:disable-next-line:max-line-length
  regexEmailPattern =
    "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])";

  constructor(
    private fb: UntypedFormBuilder,
    public userService: UserService,
    private authenticationService: AuthenticationService,
    public storageService: StorageService,
    public router: Router,
    private changeDetectorRefs: ChangeDetectorRef,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [
        undefined,
        {
          validators: [
            Validators.required,
            Validators.pattern(this.regexEmailPattern),
          ],
          updateOn: "change",
        },
      ],
      password: [""],
    });
    this.loginError = true;
    this.logout();
    if (this.userService.getToken() !== "") {
      if (this.storageService.getCurrentUser().role_id !== 7) {
        this.router.navigateByUrl(this.authenticationService.navegateMain);
      } else {
        this.router.navigateByUrl("/validation");
      }
    }
    this.loginForm.valueChanges.subscribe((value) => {
      if (this.loginForm.dirty) {
        this.loginInvalid = false;
        this.showSpinner = false;
        this.disableBtn = false;
      }
      if (!this.loginForm.valid) {
        this.disableBtn = true;
      }
    });
  }

  login(): void {
    // const data = {email: this.email, password: this.password};
    this.showSpinner = true;
    this.error = null;
    if (this.loginForm.valid) {
      this.authenticationService
        .login(new LoginObject(this.loginForm.value))
        .subscribe(
          (data: any) => {
            this.showSpinner = false;
            this.loginInvalid = data && data.user.role_id == 4;
            if (!this.loginInvalid) {
              this.storageService.setCurrentSession(data);
              if (this.storageService.isAuthenticated()) {
                const navigationExtras: NavigationExtras = {
                  queryParamsHandling: "preserve",
                  preserveFragment: true,
                };
                this.userService.fetchNonification.next(true)
                this.router.navigate([this.authenticationService.navegateMain], navigationExtras);
              }
            } else {
              this.logout();
              Swal.fire({
                icon: 'error',
                title: 'Este usuario no tiene acceso al sistema',
                text: 'Este usuario es solo para la app.',
                allowOutsideClick: false,
                allowEscapeKey: false
              });
            }
            this.changeDetectorRefs.detectChanges();
          },
          (err) => {
            if (err.status === 401) {
              this.loginInvalid = true;
            }
            this.showSpinner = false;
            this.changeDetectorRefs.detectChanges();
          }
        );
    }
  }

  logout(): void {
    this.route.params.subscribe((params) => {
      const logout = +params.sure;
      if (logout === 1) {
        sessionStorage.removeItem("identity");
        this.userService.removeToken();
        this.router.navigateByUrl("/login");
      }
    });
  }
}
