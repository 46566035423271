import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { StorageService } from "@core/services/storage.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { BrandService } from "src/app/services/brand.service";
import * as _ from "lodash";
import { CampasService } from "src/app/services/campas.service";
import { TypeModelOrderService } from "src/app/services/type-model-order.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { StockService } from "../../services/stock.service";
import Swal from "sweetalert2";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import { StatesService } from "src/app/services/states.service";
import { map } from "rxjs/operators";
import { MatTableExporterDirective } from "mat-table-exporter";
import { HeaderService } from "../../service/header.service";
import * as moment from "moment";
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
import { MatSort, Sort } from "@angular/material/sort";
import { ExcelService } from "@core/services/excel.service";
import { AccessorieService } from "src/app/services/accessorie.service";
const api_sort = false;

@Component({
  selector: "app-stock",
  templateUrl: "./stock.component.html",
  styleUrls: ["./stock.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class StockComponent implements OnInit {
  applyfilter: boolean = false;
  selectedType: string = "in";
  brands: any[] = [];
  date: any = {};
  dateOut: any = {};
  dateStock: any = {};
  dateDelivery: any = {};
  dateAccesories: any = {};
  plate: any;
  plateStock: any;
  plateDelivery: any;
  plateAccesories: any;
  accesoryName: any;
  campaList: any[] = [];
  event: any = {};
  stockList: any[] = [];
  vehiclesInCampaList: any[] = [];
  vehiclesDeliveryList: any[] = [];
  vehiclesAccesoriesList: any[] = [];
  vehiclesOutList: any[] = [];
  typeModelOrders: any[] = [];
  accessoriesList: any[] = [];
  statesList: any[] = [];
  includeSubStateNull: string = '0'
  stockDataSource = new MatTableDataSource<any>();
  loadingDataInSubject = new BehaviorSubject(false);
  isLoadingDataIn: Observable<boolean>;
  loadingDataStockSubject = new BehaviorSubject(false);
  isLoadingDataStock: Observable<boolean>;
  loadingDataOutSubject = new BehaviorSubject(false);
  isLoadingDataDelivery: Observable<boolean>;
  isLoadingDataAccesories: Observable<boolean>;
  loadingDataDeliverySubject = new BehaviorSubject(false);
  loadingDataAccesoriesSubject = new BehaviorSubject(false);
  isLoadingDataOut: Observable<boolean>;
  stockDisplayedColumns: string[] = [
    "created_at",
    "vehicle.plate",
    "vehicle.vehicle_model.brand.name",
    "vehicle.vehicle_model.name",
    "campa.name",
    "type_model_order.name",
  ];
  vehiclesInCampaDataSource = new MatTableDataSource<any>();
  vehiclesInCampaDisplayedColumns: string[] = [
    "last_reception.created_at",
    "plate",
    "vehicle_model.brand.name",
    "vehicle_model.name",
    "campa.name",
    "type_model_order.name",
    "sub_state.state.name",
  ];
  vehiclesOutCampaDataSource = new MatTableDataSource<any>();
  vehiclesOutCampaDisplayedColumns: string[] = [
    "created_at",
    "vehicle.plate",
    "vehicle.vehicle_model.brand.name",
    "vehicle.vehicle_model.name",
    "vehicle.type_model_order.name",
    "vehicle.last_delivery_vehicle.data_delivery.note",
  ];
  vehiclesDeliveryDataSource = new MatTableDataSource<any>();
  vehiclesDeliveryDisplayedColumns: string[] = [
    "created_at",
    "campa.name",
    "vehicle.type_model_order.name",
    "data_delivery.customer",
    "vehicle.plate",
    "vehicle.vehicle_model.brand.name",
    "vehicle.vehicle_model.name",
  ];
  vehiclesAccesoriesDataSource = new MatTableDataSource<any>();
  vehiclesAccesoriesDisplayedColumns: string[] = [
    "created_at",
    "accessory.name",
    "vehicle.type_model_order.name",
    "vehicle.plate",
    "vehicle.vehicle_model.brand.name",
    "vehicle.vehicle_model.name",
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTableExporterDirective)
  matTableExporter: MatTableExporterDirective;
  filterFormIn: UntypedFormGroup;
  filterFormStock: UntypedFormGroup;
  filterFormOut: UntypedFormGroup;
  filterFormDelivery: UntypedFormGroup;
  filterFormAccesories: UntypedFormGroup;
  @ViewChild('sort', { static: false }) sort: MatSort;
  @ViewChild('sort1', { static: false }) sort1: MatSort;
  @ViewChild('sort2', { static: false }) sort2: MatSort;
  @ViewChild('sort3', { static: false }) sort3: MatSort;

  constructor(
    private _cdr: ChangeDetectorRef,
    private excelService: ExcelService,
    private _brandService: BrandService,
    private _campaService: CampasService,
    private _statesService: StatesService,
    private _storageService: StorageService,
    private _headerService: HeaderService,
    private typeModelServices: TypeModelOrderService,
    private _formBuilder: UntypedFormBuilder,
    private headerService: HeaderService,
    private _stockService: StockService,
    private _accessoriesService: AccessorieService
  ) { }

  ngOnInit(): void {
    this.headerService.getfiltros().subscribe(() => {
      this.getStockAll();
    });
    this.buildFormFilterStock();
    this.buildFormFilterIn();
    this.buildFormFilterOut();
    this.buildFormFilterDelivery();
    this.buildFormFilterAccesories();
    this.isLoadingDataIn = this.loadingDataInSubject.asObservable();
    this.isLoadingDataStock = this.loadingDataStockSubject.asObservable();
    this.isLoadingDataOut = this.loadingDataOutSubject.asObservable();
    this.isLoadingDataDelivery = this.loadingDataDeliverySubject.asObservable();
    this.isLoadingDataAccesories = this.loadingDataAccesoriesSubject.asObservable();
    const user: any = this._storageService.getCurrentUser() || {};
    let obs: Observable<any>;
    if (user.id === 1) {
      obs = this._campaService.getAllWithParameters([]);
    } else {
      obs = of(user.campas);
    }
    const subs = obs.subscribe((response) => {
      const campas = this._headerService.getHeaderCampa();
      this.campaList = response;
      this.filterFormStock.get("campas").setValue(
        _.filter(this.campaList, (x) => {
          return campas.indexOf(x.id) > -1;
        })
      );
      this._cdr.detectChanges();
    });
    this.getBrandList();
    this.getTypeOrderV();
    this.getStates();
    this.getStockAll();
  }

  sortData(sort: Sort) {
    if (api_sort) {
      this.getStockAll();
    }
  }

  importAsXlsx() {
    // this.matTableExporter.exportTable("xlsx", {
    //   fileName: `${this.getCurrentNameTable()}`,
    //   sheet: "sheet_name",
    // });

    switch (this.selectedType) {
      case "in": {
        this.loadingDataInSubject.next(true);
        let filterArrayIn = this.getArrayParamsIn();
        const arrayParams = [
          `page,${1}`, `per_page,${this.event.length}`,
          'with[],vehicle.accessories',
          'with[],vehicle.vehicleModel.brand',
          'with[],vehicle.typeModelOrder',
          'with[],vehicle.campa'
        ];
        this.headerService.headerCampa.forEach((id: any) => {
          arrayParams.push(`campaIds[],${id}`);
        });

        const params = filterArrayIn.concat(arrayParams);
        const subs = this._stockService.getReceptions(params).subscribe(
          (res) => {
            const arr = res.data.map((resp) => {
              return {
                Matrícula: resp.vehicle ? resp.vehicle.plate : "-",
                Recepción: this.setDate(resp.created_at),
                Marca: resp.vehicle?.vehicle_model
                  ? resp.vehicle.vehicle_model.brand.name
                  : "-",
                Modelo: resp.vehicle?.vehicle_model
                  ? resp.vehicle.vehicle_model.name
                  : "-",
                Campa: resp.campa ? resp.campa.name : "-",
                Negocio: resp.vehicle?.type_model_order
                  ? resp.vehicle.type_model_order?.name
                  : "-",
              };
            });
            this.excelService.exportAsExcelFile(
              arr,
              `${this.getCurrentNameTable()}`
            );
            this.loadingDataInSubject.next(false);
            Swal.fire({
              icon: "success",
              title: "Creación de archivo",
              text: "El archivo se genero exitosamente.",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
            subs.unsubscribe();
          },
          () => {
            this.loadingDataInSubject.next(false);
            subs.unsubscribe();
          }
        );
        break;
      }
      case "stock": {
        this.loadingDataStockSubject.next(true);
        const arrayParams = [`page,${1}`, `per_page,${this.event.length}`];
        arrayParams.push('with[],color');
        arrayParams.push('with[],category');
        arrayParams.push('with[],campa');
        arrayParams.push('withUbication,1');
        arrayParams.push('with[],lastReception.lastPendingTaskWithState.task');
        arrayParams.push('with[],lastReception.lastPendingTaskWithState.statePendingTask');
        arrayParams.push('with[],accessoriesTypeAccessory');
        const subs = this._stockService.getVehiclesInCampa(this.getArrayParams().concat(arrayParams).concat(this.getArrayParamsStock())).subscribe((res) => {
          const arr = res.vehicles.data.map((resp) => {
            return {
              "Matrícula": resp.plate,
              "Fecha de recepción": moment.utc(resp.last_reception.created_at).local().format("DD/MM/YYYY HH:mm"),
              "Kilómetros": resp.kms,
              "Marca": resp?.vehicle_model?.brand?.name || "-",
              "Modelo": resp?.vehicle_model?.name || "-",
              "Color": resp?.color?.name || "-",
              "Estado": resp?.sub_state?.state?.name || "-",
              "Sub-Estado": resp?.sub_state?.name || "-",
              "Inicio estado": moment.utc(resp.last_change_state).local().format("DD/MM/YYYY HH:mm"),
              "Inicio sub-estado": moment.utc(resp.last_change_sub_state).local().format("DD/MM/YYYY HH:mm"),
              "Observaciones": resp.observations,
              "Accesorios": (resp?.accessories_type_accessory || []).map(a => a.name).join(", "),
              "Etiqueta M.A.": resp.has_environment_label == 1 ? "Sí" : "No",
              "Campa": resp?.campa.name || "-",
              "Código de campa": resp.campa_id,
              "Próxima ITV": resp.next_itv || "-",
              "Categoría": resp?.category?.name || "-",
              "Negocio": resp?.type_model_order?.name || "-",
              "Tarea": resp?.last_reception?.last_pending_task_with_state?.task?.name || "-",
              "Estado de la tarea": resp?.last_reception?.last_pending_task_with_state?.state_pending_task?.name || "-",
              "Fecha Inicio Tarea": resp?.last_reception?.last_pending_task_with_state ? moment.utc(resp.last_reception?.last_pending_task_with_state.datetime_start).local().format("DD-MM-YYYY HH:mm") : "-",
              "Ubicación": resp?.square && resp?.square?.street && resp?.square.street?.zone ? `${resp.square.name} ${resp.square.street.name} ${resp.square.street.zone.name}` : "-",
              "Observaciones de la tarea": resp?.last_reception?.last_pending_task_with_state?.observations || "-"
            };
          });
          this.excelService.exportAsExcelFile(
            arr,
            `${this.getCurrentNameTable()}`
          );
          this.loadingDataStockSubject.next(false);
          Swal.fire({
            icon: "success",
            title: "Creación de archivo",
            text: "El archivo se genero exitosamente.",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          subs.unsubscribe();
        },
          () => {
            this.loadingDataStockSubject.next(false);
            subs.unsubscribe();
          })
        // let arrayParams = this.getArrayParams().concat(this.getArrayParamsStock());

        // this.excelService.export('/stock-vehicles', `stock-vehículos-${moment().format('DD-MM-YYYY')}-${(new Date()).getTime()}`, arrayParams).then(() => {
        //   this.loadingDataStockSubject.next(false);
        // });
        break;
      }
      case "out": {
        this.loadingDataOutSubject.next(true);
        const arrayParams = [`page,${1}`, `per_page,${this.event.length}`];
        let filterArrayOut = this.getArrayParamsOut();
        this.headerService.headerCampa.forEach((id: any) => {
          arrayParams.push(`campaIds[],${id}`);
        });
        const params = filterArrayOut.concat(arrayParams);
        const subs = this._stockService.getVehiclesExit(params).subscribe(
          (res) => {
            const arr = res.data.map((resp) => {
              return {
                Fecha: moment.utc(resp.created_at).local().format("DD-MM-YYYY"),
                Matrícula: resp.vehicle ? resp.vehicle.plate : "-",
                Marca: resp.vehicle?.vehicle_model
                  ? resp.vehicle.vehicle_model.brand.name
                  : "-",
                Modelo: resp.vehicle?.vehicle_model
                  ? resp.vehicle.vehicle_model.name
                  : "-",
                Negocio: resp.vehicle?.type_model_order
                  ? resp.vehicle.type_model_order?.name
                  : "-",
                Observaciones: resp.vehicle.last_delivery_vehicle
                  ? resp.vehicle.last_delivery_vehicle.data_delivery.note
                  : "-",
              };
            });
            this.excelService.exportAsExcelFile(
              arr,
              `${this.getCurrentNameTable()}`
            );
            this.loadingDataOutSubject.next(false);
            Swal.fire({
              icon: "success",
              title: "Creación de archivo",
              text: "El archivo se genero exitosamente.",
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
            subs.unsubscribe();
          },
          () => {
            this.loadingDataOutSubject.next(false);
            subs.unsubscribe();
          }
        );

        break;
      }
      case "delivery": {
        this.loadingDataDeliverySubject.next(true);
        let filterArrayDelivery = this.getArrayParamsDelivery();
        let arrayParams = [
          'pendindTaskNull,0',
          'vehicleDeleted,0',
          ...filterArrayDelivery
        ];
        this.campaList.forEach((campa: any) => {
          arrayParams.push(`campaIds[],${campa.id}`);
        });
        this.excelService.export('/delivery-vehicles/export', this.getCurrentNameTable(), arrayParams).then(() => {
          this.loadingDataDeliverySubject.next(false);
        }).catch(() => {
          this.loadingDataDeliverySubject.next(false);
        })
        break;
      }
      case "accesories": {
        this.loadingDataAccesoriesSubject.next(true);
        let filterArrayAccesories = this.getArrayParamsAccesories();
        let arrayParams = [
          'with[],vehicle.campa',
          'with[],vehicle.vehicleModel.brand',
          'with[],vehicle.typeModelOrder',
          'noPaginate,1',
          ...filterArrayAccesories
        ];
        const campas = this._headerService.getHeaderCampa();

        campas.forEach((x: any) => {
          arrayParams.push(`campaIds[],${x}`);
        });
        this._accessoriesService.getAccessoriesVehicle(arrayParams).subscribe((res) => {
          const arr: any[] = res.data.filter(x=>!!x?.vehicle && !!x?.vehicle?.campa).map((item: any) => {
            return {
              Registrado: this.setDate(item.created_at),
              Accesorio: item.accessory.name,
              Negocio: item.vehicle.type_model_order.name,
              Campa: item.vehicle.campa.name,
              Chasis:item.vehicle.vin,
              "Matrícula": item.vehicle.plate,
              Marca: item.vehicle.vehicle_model.brand.name,
              Modelo: item.vehicle.vehicle_model.name
            }

          });
          this.excelService.exportAsExcelFile(
            arr,
            `${this.getCurrentNameTable()}`
          );
          this.loadingDataAccesoriesSubject.next(false);
        },()=>{
          this.loadingDataAccesoriesSubject.next(false);
        });

        // this.excelService.export('/accessory-vehicle/export', this.getCurrentNameTable(), arrayParams).then(() => {
        //   this.loadingDataAccesoriesSubject.next(false);
        // }).catch(() => {
        //   this.loadingDataAccesoriesSubject.next(false);
        // })
        break;
      }
    }
  }

  getCurrentNameTable(): string {
    if (this.selectedType === "in") {
      return "Lista Entrada Vehículos";
    }
    if (this.selectedType === "stock") {
      return "Lista Vehículos en campa";
    }
    if (this.selectedType === "out") {
      return "Lista Salida Vehículos";
    }
    if (this.selectedType === "delivery") {
      return "Lista Entrega Vehículos";
    }
    if (this.selectedType === "accesories") {
      return "Lista Accesorios del Vehículos";
    }
  }

  getArrayParams() {
    const arrayParams = [
      `page,${this.event.pageIndex + 1}`,
      `per_page,${this.event.pageSize}`,
    ];
    const data: any = {};
    data["_sort"] =
      this.sort && this.sort["_direction"] ? this.sort.active : "";
    data["_order"] = this.sort && this.sort["_direction"];
    if (data._sort) {
      arrayParams.push(
        `${data._order === "asc" ? "order" : "orderDesc"},${data._sort}`
      );
    }
    this.headerService.headerCampa.forEach((id: any) => {
      arrayParams.push(`campaIds[],${id}`);
    });
    return arrayParams;
  }

  getStockAll(paginate = {}) {
    const campas = this.headerService.getHeaderCampa();
    if (campas.length === 0) {
      switch (this.selectedType) {
        case "in": {
          this.stockList = [];
          this.stockDataSource = new MatTableDataSource(this.stockList);
          break;
        }
        case "stock": {
          this.vehiclesInCampaList = [];
          this.vehiclesInCampaDataSource = new MatTableDataSource(
            this.vehiclesInCampaList
          );
          break;
        }
        case "out": {
          this.vehiclesOutList = [];
          this.vehiclesOutCampaDataSource = new MatTableDataSource(
            this.vehiclesOutList
          );
          break;
        }
        case "delivery": {
          this.vehiclesDeliveryList = [];
          this.vehiclesDeliveryDataSource = new MatTableDataSource(
            this.vehiclesDeliveryList
          );
          break;
        }
        case "accesories": {
          this.vehiclesAccesoriesList = [];
          this.vehiclesAccesoriesDataSource = new MatTableDataSource(
            this.vehiclesAccesoriesList
          );
          break;
        }
      }
      return;
    }
    const event: any = _.assign(
      {
        length: 0,
        pageIndex: 0,
        pageSize: 5,
        previousPageIndex: 0,
      },
      paginate
    );

    this.event = event;

    switch (this.selectedType) {
      case "in": {
        this.loadingDataInSubject.next(true);
        const subs = this._stockService.getReceptions(this.getArrayParams().concat(this.getArrayParamsIn())).subscribe(
          (res) => {
            this.stockList = [];
            this.stockDataSource = new MatTableDataSource([]);
            this.stockList.push(...res["data"]);
            this.stockDataSource.data = this.stockList;
            this.stockDataSource = new MatTableDataSource(this.stockList);
            if (!api_sort) {
              this.stockDataSource.sortingDataAccessor = (item, property) => {
                return _.at(item, property);
              };
            }
            this.stockDataSource.sort = this.sort2;
            subs.unsubscribe();
            this.loadingDataInSubject.next(false);
            setTimeout(() => {
              this.event.length = res.total;
              this._cdr.detectChanges();
            }, 500);
            this._cdr.detectChanges();
          },
          () => {
            subs.unsubscribe();
          }
        );
        break;
      }
      case "stock": {
        this.loadingDataStockSubject.next(true);
        const subs = this._stockService.getVehiclesInCampa(this.getArrayParams().concat(this.getArrayParamsStock())).subscribe(
          (res) => {
            this.vehiclesInCampaList = [];
            this.vehiclesInCampaDataSource = new MatTableDataSource([]);
            const vehiclesInCampaList = res.vehicles["data"].map((x) => {
              x.last_pending_task = this.getLastPendingTask(x);
              return x;
            });
            this.vehiclesInCampaList.push(...vehiclesInCampaList);
            this.vehiclesInCampaDataSource.data = this.vehiclesInCampaList;
            this.vehiclesInCampaDataSource = new MatTableDataSource(
              this.vehiclesInCampaList
            );
            if (!api_sort) {
              this.vehiclesInCampaDataSource.sortingDataAccessor = (item, property) => {
                return _.at(item, property);
              };
            }
            this.vehiclesInCampaDataSource.sort = this.sort3;
            this.loadingDataStockSubject.next(false);
            subs.unsubscribe();
            this.event.length = 0;
            setTimeout(() => {
              this.event.length = res.vehicles.total;
              this._cdr.detectChanges();
            }, 500);
            this._cdr.detectChanges();
          },
          () => {
            subs.unsubscribe();
          }
        );
        break;
      }
      case "out": {
        this.loadingDataOutSubject.next(true);
        const subs = this._stockService.getVehiclesExit(this.getArrayParams().concat(this.getArrayParamsOut())).subscribe(
          (res) => {
            this.vehiclesOutList = [];
            this.vehiclesOutCampaDataSource = new MatTableDataSource([]);
            this.vehiclesOutList.push(...res.data);
            this.vehiclesOutCampaDataSource.data = this.vehiclesOutList;
            this.vehiclesOutCampaDataSource = new MatTableDataSource(
              this.vehiclesOutList
            );
            if (!api_sort) {
              this.vehiclesOutCampaDataSource.sortingDataAccessor = (item, property) => {
                return _.at(item, property);
              };
            }
            this.vehiclesOutCampaDataSource.sort = this.sort;
            this.event.length = 0;
            this.loadingDataOutSubject.next(false);
            setTimeout(() => {
              this.event.length = res.total;
              this._cdr.detectChanges();
            }, 500);
            subs.unsubscribe();
            this._cdr.detectChanges();
          },
          () => {
            subs.unsubscribe();
          }
        );
        break;
      }
      case "delivery": {
        this.loadingDataDeliverySubject.next(true);
        const subs = this._stockService.getVehiclesDelivery(this.getArrayParams().concat(this.getArrayParamsDelivery())).subscribe(
          (res) => {
            this.vehiclesDeliveryList = [];
            this.vehiclesDeliveryDataSource = new MatTableDataSource([]);
            const vehiclesDeliveryList = res["data"].map((x) => {
              x.data_delivery =
                !!x.data_delivery && typeof x.data_delivery === "string"
                  ? JSON.parse(x.data_delivery)
                  : x.data_delivery;
              return x;
            });
            this.vehiclesDeliveryList.push(...vehiclesDeliveryList);
            this.vehiclesDeliveryDataSource.data = this.vehiclesDeliveryList;
            this.vehiclesDeliveryDataSource = new MatTableDataSource(
              this.vehiclesDeliveryList
            );
            if (!api_sort) {
              this.vehiclesDeliveryDataSource.sortingDataAccessor = (item, property) => {
                return _.at(item, property);
              };
            }
            this.vehiclesDeliveryDataSource.sort = this.sort1;
            this.event.length = 0;
            this.loadingDataDeliverySubject.next(false);
            setTimeout(() => {
              this.event.length = res.total;
              this._cdr.detectChanges();
            }, 500);
            subs.unsubscribe();
            this._cdr.detectChanges();
          },
          () => {
            subs.unsubscribe();
          }
        );
        break;
      }
      case "accesories": {
        this.loadingDataAccesoriesSubject.next(true);
        const subs = this._stockService.getVehiclesAccesory(this.getArrayParams().concat(this.getArrayParamsAccesories())).subscribe(
          (res) => {
            this.vehiclesAccesoriesList = [];
            this.vehiclesAccesoriesDataSource = new MatTableDataSource([]);
            const vehiclesAccesoriesList = res["data"].map((x) => {
              x.data_Accesories =
                !!x.data_Accesories && typeof x.data_Accesories === "string"
                  ? JSON.parse(x.data_Accesories)
                  : x.data_Accesories;
              return x;
            });
            this.vehiclesAccesoriesList.push(...vehiclesAccesoriesList);
            this.vehiclesAccesoriesDataSource.data = this.vehiclesAccesoriesList;
            this.vehiclesAccesoriesDataSource = new MatTableDataSource(
              this.vehiclesAccesoriesList
            );
            if (!api_sort) {
              this.vehiclesAccesoriesDataSource.sortingDataAccessor = (item, property) => {
                return _.at(item, property);
              };
            }
            this.vehiclesAccesoriesDataSource.sort = this.sort1;
            this.event.length = 0;
            this.loadingDataAccesoriesSubject.next(false);
            setTimeout(() => {
              this.event.length = res.total;
              this._cdr.detectChanges();
            }, 500);
            subs.unsubscribe();
            this._cdr.detectChanges();
          },
          () => {
            subs.unsubscribe();
          }
        );
        break;
      }
    }
  }

  addSubStateNullToParams(params) {

    let include = '0'
    let states = params.filter(ele => { return ele.includes('states[]') })
    if (states.length >= this.statesList.length - 1) {
      include = '1'
    }
    let filterArrayStock = this.getArrayParamsStock();
    let selectSubStateNull = filterArrayStock.find(ele => { return ele.includes('states[],undefined') })
    if (selectSubStateNull !== undefined) {
      include = '1'
    }
    return 'withoutSubState,' + include
  }

  getLastPendingTask(vehicle) {
    return vehicle.last_reception
      ? _.first(vehicle.last_reception.approved_pending_tasks)
      : null;
  }

  getBrandList() {
    const sub = this._brandService.getAll().subscribe(
      (res) => {
        this.brands = res.brands;
        sub.unsubscribe();
      },
      () => {
        sub.unsubscribe();
      }
    );
  }

  getStates() {
    const sub = this._statesService
      .getStates()
      .pipe(map((r: any[]) => r.filter((s) => s.id !== 5)))
      .subscribe(
        (resp) => {
          this.statesList = resp;
          sub.unsubscribe();
        },
        () => {
          sub.unsubscribe();
        }
      );
  }

  getTypeOrderV() {
    const sub = this.typeModelServices.getAll().subscribe(
      (resp) => {
        this.typeModelOrders = resp.type_model_orders;
        sub.unsubscribe();
      },
      () => {
        sub.unsubscribe();
      }
    );
  }

  buildFormFilterIn() {
    this.filterFormIn = this._formBuilder.group({
      brands: [[]],
      campas: [[]],
      plate: [[]],
      business: [[]],
      type: [[]],
      searchDateFrom: [""],
      searchDateTo: [""],
    });
    this.filterFormIn.get("searchDateFrom").valueChanges.subscribe((date) => {
      if (!!date) {
        this.date["from"] = moment(date.startOf('day').format('YYYY-MM-DD HH:mm')).utc().format("YYYY-MM-DD HH:mm");
        this.getStockAll();
      }
    });

    this.filterFormIn.get("searchDateTo").valueChanges.subscribe((date) => {
      if (!!date) {
        this.date["to"] = moment(date.endOf('day').format('YYYY-MM-DD HH:mm')).utc().format("YYYY-MM-DD HH:mm");
        this.getStockAll();
      }
    });
  }

  buildFormFilterStock() {
    this.filterFormStock = this._formBuilder.group({
      stocks: [[]],
      campas: [[]],
      searchDateFrom: [""],
      searchDateTo: [""],
    });
    this.filterFormStock.get("searchDateFrom").valueChanges.subscribe((date) => {
      if (!!date) {
        this.dateStock["from"] = moment(date.startOf('day').format('YYYY-MM-DD HH:mm')).utc().format("YYYY-MM-DD HH:mm");
        this.getStockAll();
      }
    });

    this.filterFormStock.get("searchDateTo").valueChanges.subscribe((date) => {
      if (!!date) {
        this.dateStock["to"] = moment(date.endOf('day').format('YYYY-MM-DD HH:mm')).utc().format("YYYY-MM-DD HH:mm");
        this.getStockAll();
      }
    });
  }

  buildFormFilterOut() {
    this.filterFormOut = this._formBuilder.group({
      brands: [[]],
      business: [[]],
      searchDateFrom: [""],
      searchDateTo: [""],
    });
    this.filterFormOut.get("searchDateFrom").valueChanges.subscribe((date) => {
      if (!!date) {
        this.dateOut["from"] = moment(date.startOf('day').format('YYYY-MM-DD HH:mm')).utc().format("YYYY-MM-DD HH:mm");
        this.getStockAll();
      }
    });

    this.filterFormOut.get("searchDateTo").valueChanges.subscribe((date) => {
      if (!!date) {
        this.dateOut["to"] = moment(date.endOf('day').format('YYYY-MM-DD HH:mm')).utc().format("YYYY-MM-DD HH:mm");
        this.getStockAll();
      }
    });
  }

  buildFormFilterDelivery() {
    this.filterFormDelivery = this._formBuilder.group({
      brands: [[]],
      business: [[]],
      searchDateFrom: [""],
      searchDateTo: [""],
    });
    this.filterFormDelivery.get("searchDateFrom").valueChanges.subscribe((date) => {
      if (!!date) {
        this.dateDelivery["from"] = moment(date.startOf('day').format('YYYY-MM-DD HH:mm')).utc().format("YYYY-MM-DD HH:mm");
        this.getStockAll();
      }
    });

    this.filterFormDelivery.get("searchDateTo").valueChanges.subscribe((date) => {
      if (!!date) {
        this.dateDelivery["to"] = moment(date.endOf('day').format('YYYY-MM-DD HH:mm')).utc().format("YYYY-MM-DD HH:mm");
        this.getStockAll();
      }
    });
  }


  buildFormFilterAccesories() {
    this.filterFormAccesories = this._formBuilder.group({
      brands: [[]],
      business: [[]],
      searchDateFrom: [""],
      searchDateTo: [""],
    });
    this.filterFormAccesories.get("searchDateFrom").valueChanges.subscribe((date) => {
      if (!!date) {
        this.dateAccesories["from"] = moment(date.startOf('day').format('YYYY-MM-DD HH:mm')).utc().format("YYYY-MM-DD HH:mm");
        this.getStockAll();
      }
    });

    this.filterFormAccesories.get("searchDateTo").valueChanges.subscribe((date) => {
      if (!!date) {
        this.dateAccesories["to"] = moment(date.endOf('day').format('YYYY-MM-DD HH:mm')).utc().format("YYYY-MM-DD HH:mm");
        this.getStockAll();
      }
    });
  }


  cleanFilters() {
    switch (this.selectedType) {
      case "in": {
        this.filterFormIn.reset();
        this.date = {};
        break;
      }
      case "out": {
        this.filterFormOut.reset();
        this.dateOut = {};
        this.plate = null;
        break;
      }
      case "stock": {
        this.plateStock = null;
        this.dateStock = {};
        this.filterFormStock.reset();
        break;
      }
      case "delivery": {
        this.filterFormDelivery.reset();
        this.dateDelivery = {};
        this.plateDelivery = null;
        break;
      }
      case "accesories": {
        this.filterFormAccesories.reset();
        this.dateAccesories = {};
        this.plateAccesories = null;
        this.accesoryName = null;
        break;
      }
      default: {
        break;
      }
    }
    this.getStockAll();
  }


  get objOutColumns(): any {
    return {
      created_at: "Fecha",
      "vehicle.plate": "Matrícula",
      "vehicle.vehicle_model.brand.name": "Marca",
      "vehicle.vehicle_model.name": "Modelo",
      "vehicle.type_model_order.name": "Negocio",
      "vehicle.last_delivery_vehicle.data_delivery.note": "Observaciones",
    };
  }

  getArrayParamsIn(): any[] {
    // 'subStatesNotIds[],10',
    let arrayParams = [
      'whereHasVehicle,0',
      'with[],typeModelOrder',
      'with[],vehicle.accessories',
      'with[],vehicle.vehicleModel.brand',
      'with[],vehicle.typeModelOrder',
      'with[],vehicle.campa'
    ];

    if (this.date.from) {
      arrayParams.push(`createdAtFrom,${this.date.from}`);
    }

    if (this.date.to) {
      arrayParams.push(`createdAtTo,${this.date.to}`);
    }

    const plate: any[] = this.filterFormIn.controls.plate.value || '';
    if (plate) {
      arrayParams.push(`vehiclePlate,${plate}`);
    }

    const brands: any[] = this.filterFormIn.controls.brands.value || [];
    if (brands.length > 0) {
      brands.forEach((x) => {
        arrayParams.push(`brandIds[],${x.id}`);
      });
    }

    const business: any[] = this.filterFormIn.controls.business.value || [];
    if (business.length > 0) {
      business.forEach((x) => {
        arrayParams.push(`typeModelOrderIds[],${x.id}`);
      });
    }

    const campa: any[] = this.filterFormIn.controls.campas.value || [];
    if (campa.length > 0) {
      campa.forEach((x) => {
        arrayParams.push(`campaIds[],${x.id}`);
      });
    }
    return arrayParams;
  }

  getArrayParamsStock(): any[] {
    let arrayParams = [];

    if (this.plateStock) {
      arrayParams.unshift(`plate,${this.plateStock}`);
    }

    if (this.dateStock.from) {
      arrayParams.push(`lastReceptionCreatedAtFrom,${this.dateStock.from}`);
    }

    if (this.dateStock.to) {
      arrayParams.push(`lastReceptionCreatedAtTo,${this.dateStock.to}`);
    }

    [4, 5, 10].forEach((id) => {
      arrayParams.push(`statesNotIds[],${id}`)
    });

    const stock: any[] = this.filterFormStock.controls.stocks.value || [];
    if (stock.length > 0) {
      stock.forEach((x) => {
        arrayParams.push(`states[],${x.id}`);
      });
    }
    arrayParams.push('defleetingAndDelivery,1')
    return arrayParams;
  }

  getArrayParamsOut(): any[] {
    let arrayParams = [];

    if (this.dateOut.from) {
      arrayParams.push(`createdAtFrom,${this.dateOut.from}`);
    }

    if (this.dateOut.to) {
      arrayParams.push(`createdAtTo,${this.dateOut.to}`);
    }

    if (this.plate) {
      arrayParams.unshift(`vehiclePlate,${this.plate}`);
    }

    const brands: any[] = this.filterFormOut.controls.brands.value || [];
    if (brands.length > 0) {
      brands.forEach((x) => {
        arrayParams.push(`vehicleBrandIds[],${x.id}`);
      });
    }

    const business: any[] = this.filterFormOut.controls.business.value || [];
    if (business.length > 0) {
      business.forEach((x) => {
        arrayParams.push(`vehicleTypeModelOrderIds[],${x.id}`);
      });
    }

    return arrayParams;
  }

  getArrayParamsDelivery(): any[] {
    let arrayParams = ['with[],campa'];
    if (this.dateDelivery.from) {
      arrayParams.push(`createdAtFrom,${this.dateDelivery.from}`);
    }

    if (this.dateDelivery.to) {
      arrayParams.push(`createdAtTo,${this.dateDelivery.to}`);
    }

    if (this.plateDelivery) {
      arrayParams.unshift(`vehiclePlate,${this.plateDelivery}`);
    }

    const brands: any[] = this.filterFormDelivery.controls.brands.value || [];
    if (brands.length > 0) {
      brands.forEach((x) => {
        arrayParams.push(`vehicleBrandIds[],${x.id}`);
      });
    }

    const business: any[] =
      this.filterFormDelivery.controls.business.value || [];
    if (business.length > 0) {
      business.forEach((x) => {
        arrayParams.push(`vehicleTypeModelOrderIds[],${x.id}`);
      });
    }

    return arrayParams;
  }

  getArrayParamsAccesories(): any[] {
    let arrayParams = ['with[],accessory', 'orderDesc,vehicle_id'];
    if (this.dateAccesories.from) {
      arrayParams.push(`createdAtFrom,${this.dateAccesories.from}`);
    }

    if (this.dateAccesories.to) {
      arrayParams.push(`createdAtTo,${this.dateAccesories.to}`);
    }

    if (this.plateAccesories) {
      arrayParams.unshift(`vehiclePlate,${this.plateAccesories}`);
    }

    if (this.accesoryName) {
      arrayParams.unshift(`accesoryName,${this.accesoryName}`);
    }

    const brands: any[] = this.filterFormAccesories.controls.brands.value || [];
    if (brands.length > 0) {
      brands.forEach((x) => {
        arrayParams.push(`vehicleBrandIds[],${x.id}`);
      });
    }

    const business: any[] =
      this.filterFormAccesories.controls.business.value || [];
    if (business.length > 0) {
      business.forEach((x) => {
        arrayParams.push(`vehicleTypeModelOrderIds[],${x.id}`);
      });
    }

    return arrayParams;
  }

  setDate(date) {
    return date ? moment.utc(date).local().format("DD/MM/YYYY hh:mm A") : '-'
  }
}
