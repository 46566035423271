import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
    transform(collection: Array<any>, property: string, type: string = 'asc', sortOrder = []): Array<any> {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!collection || collection.length === 0) {
            return [];
        }
        const groupedCollection = collection.reduce((previous, current) => {
            if (!previous[current[property]]) {
                previous[current[property]] = [current];
            } else {
                previous[current[property]].push(current);
            }

            return previous;
        }, {});

        const data = Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));

        if (type == 'desc') {
            return data.sort((a, b) => b.key.localeCompare(a.key));
        }

        if (type == 'asc') {
            return data.sort((a, b) => a.key.localeCompare(b.key));
        }

        if (type == 'custom') {
            const ordering = {};
            for (let j = 0; j < sortOrder.length; j++) {
                ordering[sortOrder[j]] = j;
            }
            return data.sort((a, b) => {
                return (ordering[a.key] - ordering[b.key]);
            });
        }

        return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
    }
}
